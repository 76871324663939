import React, { useState,useRef, useEffect } from 'react'
import * as FaIcons from 'react-icons/fa'
import './header.css'
import { Link, useNavigate } from 'react-router-dom'
import {  removeUserSession } from '../../utilis/Common'
import { get_ApiManager, logout_ApiManager, post_ApiManager } from '../ApiManager/ApiManager';
import swal from 'sweetalert';
import moment from 'moment'

const NavBar = (props) => {
    const history = useNavigate();
    const ref = useRef();
    const [open, setOpen] = useState(false);
    const [userRole, setUserRole] = useState(false);
    const [attendance, setAttendance] = useState(false);
    const [ip, setIP] = useState([null]);
    
    useEffect(() => {
        const checkIfClickedOutside = e => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          if (open && ref.current && !ref.current.contains(e.target)) {
            setOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [open])

    const handleOpen = async () => {
       if(open === false){
        attendanceStatus();
    } else{
        setOpen(!open);
    }
        setUserRole(localStorage.getItem('user_role'));
    }
    const logout = () => {
        swal({
            title: "Logging out",
            text: "Are you sure to log out?",icon: "warning", buttons: true, dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
                removeUserSession();
                // logout user when password changed
                    logout_ApiManager((res) => {
                    console.log('logging out',res)
                    swal({title: "Success!",text: 'Logged out!!', type: "success", timer: 1000})
                    history('/login');
                    // window.location.reload(false);
                 })
            }   
        });
       
    }
    const attendanceStatus = () => {
        let url = `attendanceCheck`;
        get_ApiManager('', url,(res) => {
            console.log('attendance-check',res);
            setAttendance(res.data['data']);
            getip();
            setOpen(!open);
        },(err) => {
            console.log(err);
        })
    }
    const getip = async () => {
        fetch("https://geolocation-db.com/json/")
            .then(res=> res.json())
            .then(data => setIP(data))
            .catch(e => console.log(e)); 
    }
    const CheckedIn = () => {
        let url = 'attendanceCheckIn';
        const data = {
            date: moment().format("YYYY-MM-DD"),
            time: moment().format("YYYY-MM-DD HH:mm:ss"), 
            ip:  ip.IPv4+', '+ip.city+', '+ ip.country_name + ', '+ip.country_code,
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('CheckedIn',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else{ swal("Attendance Marked", 'Checked-in successfully!', "success") }
            attendanceStatus();
        },(err) => {
            console.log(err);
        });  
    }
    const CheckedOut = (id) => {
        let url = 'attendanceCheckOut';
        const data = {
            date: moment().format("YYYY-MM-DD"),
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
            id: id 
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('CheckedOut',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else{ swal("Attendance Marked", 'Checked-out successfully!', "success") }
            attendanceStatus();
            removeUserSession();
            logout_ApiManager((res) => {
                console.log('logging out',res)
                swal({title: "Success!",text: 'Logged out!!', type: "success", timer: 1000})
                history('/login'); 
            })
        },(err) => {
            console.log(err);
        });  
    }
    return(
        <li className="wrapper" ref={ref}>
            <a className="icon-button svg" onClick ={() => handleOpen()}>
                {props.icon}
            </a>
            {open && 
            <div className="dropDownn">
            <Link to={{pathname:'/my-profile'}} className="menu-item fira-font">
            <span className="icon-button"><FaIcons.FaUser size={15}/></span>
            My Profile
            </Link>
            {userRole == 2 ? 
            <>
            <Link to={{pathname:'/permissions'}} className="menu-item fira-font">
            <span className="icon-button"><FaIcons.FaKey size={15}/></span>
                Permissions
            </Link>
            <Link to={{pathname:'/main-settings'}} className="menu-item fira-font">
            <span className="icon-button"><FaIcons.FaCogs size={15}/></span>
                Settings
            </Link>
            </>
            :''}
            {attendance ?
            <>
            {attendance.in && attendance.out &&
            <a className="menu-item fira-font bold white pointer" onClick={() => CheckedIn()}>
            <span className="icon-button pointer"><FaIcons.FaRetweet size={15}/></span>Check-In </a>}
            {attendance.in && !attendance.out &&
            <a className="menu-item fira-font bold white pointer" onClick={() => CheckedOut(attendance.id)}>
            <span className="icon-button pointer"><FaIcons.FaRetweet size={15}/></span>Check-Out </a>}
            </> 
            : 
            <a className="menu-item fira-font bold white pointer" onClick={() => CheckedIn()}>
            <span className="icon-button pointer"><FaIcons.FaRetweet size={15}/></span>Check-In </a>}
            <div onClick={logout} className="menu-item fira-font bold white pointer">
            <span className="icon-button pointer"><FaIcons.FaSignOutAlt size={15}/></span>Logout
            </div>
        </div>
            }
        </li>
    )
}
export default NavBar;
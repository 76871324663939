
import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import swal from 'sweetalert';
import PhoneInput from 'react-phone-number-input';
import { post_ApiManager, get_ApiManager } from '../ApiManager/ApiManager';
import { useNavigate } from "react-router-dom";
import { countries_code } from '../../utilis/constants';
import smallSpinner from '../../assets/small.gif';

export const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
} 

class AddClient extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        this.state = {
            loading:false,
            nameError: '', emailError:'', mobileError:'', phoneError:'', descriptionError:'', countryError: '', agentError:'', subAgentError:'', genderError: '', externalAgentError:'',agentTypeError:'',
            name:'',
            email:'',
            mobile:'',
            country:'',
            superAgent:'',
            subAgent:'',
            agency:[],
            gender:'',
            city:'',
            description:'',
            countryCode: +61,
            defaultCountry:'AU',
            externalAgent:'',
            pExternalList:[],
            rExternalList:[],
            selectedProcessingAgent:'',
            selectedReferringAgent:'',
            reference:'',
            referenceBy:'',

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }
   
    componentDidMount() {
        this.getAgents();
    }

     async getAgents() {
        const data =  '';
        let url = 'getAgentsByOffice';
        get_ApiManager(data, url,(res) => {
            console.log('agents by names',res);
            this.setState({
                agency: res.data.data,
            });
        },(err) => {
            console.log(err);
        });
     }
     //submit form
     handleSubmit(event) {
        event.preventDefault();
        if (this.handleValidation()) {
            this.setState({loading:true})
            const data = {
                name : this.state.name,
                countryCode : this.state.countryCode ? this.state.countryCode: '+61',
                mobile: this.state.mobile,
                gender: this.state.gender,
                email: this.state.email,
                city: this.state.city,
                country: this.state.country,
                description: this.state.description,
                agentid: this.state.superAgent,
                subagentid: this.state.subAgent,
                external_agent_exisit: this.state.externalAgent,
                external_processing_agent: this.state.selectedProcessingAgent,
                external_referring_agent: this.state.selectedReferringAgent,
                reference_by: this.state.referenceBy,
            }
            console.log(data);
            let url = 'clients';
            post_ApiManager(data, url, (res) => {
                console.log('client added' , res);
                this.setState({loading:false})
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    this.handleNotification();
                    swal("Success!", res.data.message , "success"); 
                    this.props.navigate('/clients/view-clients')
                };   
            },(err) => {
                console.log(err);
            });
        }
     }
     handleValidation = (e) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        this.setState({nameError: null, emailError: null, mobileError: null, agentError: null, subAgentError: null, genderError: null, countryCode: null, cityError: null, descriptionError: null, externalAgentError:'', agentTypeError:''});

        if(!this.state.name){
            window.scrollTo(0,this.scrollRef);
            this.setState({nameError : 'Please enter name'})
            return false }
        else if(this.state.email && !pattern.test(this.state.email)){
            window.scrollTo(0,this.scrollRef);
            this.setState({emailError: 'Please enter correct email'})
            return false }
        else if(!this.state.mobile){
            window.scrollTo(0,this.scrollRef);
            this.setState({mobileError : 'Please enter phone'})
            return false }
        else if(!this.state.mobile.length > 13){
            window.scrollTo(0,this.scrollRef);
            this.setState({phoneError : 'invalid phone'})
            return false }
        else if(!this.state.superAgent){
            window.scrollTo(0,this.scrollRef);
            this.setState({agentError : 'Please select processing branch'})
            return false }
        else if(!this.state.subAgent){
            window.scrollTo(0,this.scrollRef);
            this.setState({subAgentError : 'Please select referring branch'})
            return false }
        else if(!this.state.gender){
            this.setState({genderError : 'Please select gender'})
            return false }
        else if(!this.state.country){
            this.setState({countryError : 'Please select country'})
            return false }
        else if(!this.state.city){
            this.setState({cityError : 'Please select city'})
            return false }
        else if(!this.state.externalAgent){
            this.setState({externalAgentError : 'Please select external link option'})
             return false 
        } else if(this.state.externalAgent === 'yes' && !this.state.selectedProcessingAgent && !this.state.selectedReferringAgent){
            this.setState({externalAgentError : 'Please select external agent'})
            return false 
        }
        else if(!this.state.description){
            this.setState({descriptionError : 'Please enter short description'})
            return false }
        else return true;
     }
     //  Call Notification bell Counter
     handleNotification(){
        const socket = this.props.socket;
        const user = this.props.user;
        socket.emit("sendNotification", {
          senderName: user,
        });
     }
     //set input values
     handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleOptionChange(e) {
        this.setState({
            [e.target.name]: e.target.options[e.target.selectedIndex].text
        });
    }
     // Get all information from apis 
    getExternalAgentList = () => {
        let url = 'getExternalAgents';
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('agentList',res);
            this.setState({
                rExternalList: res.data.data['referring'],
                pExternalList: res.data.data['processing']
            });
        },(err) => {
            console.log(err);
        })
    }
    
  render(){
    return(
        <>
         <Title title="Add Client" />
         <div className="pt-0 pl-lg-3 pr-lg-3 pl-3 pr-4">
        <div className="row pl-lg-3 pl-1 j-content-center">
            <div className="col-lg-11 bordered scroll mb-5">
                <div className="text-center pb-2 pt-3 bg-green">
                <h4 className="subtitle">Add Client</h4>
                </div>
                <form ref={this.scrollRef} action='' onSubmit={this.handleSubmit}>
                <div className="row bg-white p-4 pt-1 pb-0 ">
                <div className="col-lg-6 pl-lg-3 pt-1 pt-lg-1">
                <div className="form-group ">
                <label className="acc-label2 mb-0">Client Name*</label>
                <input type="text" name="name" onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter" />
                {this.state.nameError && <><small className="error">{this.state.nameError}</small></>}
                </div>
                </div>
                <div className="col-lg-6 pt-1 pt-lg-1">
                <div className="form-group">
                <label className="acc-label2 mb-0">Client Email</label>
                <input type="text" name="email" onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                {this.state.emailError && <><small className="error">{this.state.emailError}</small></>}
                </div>
                </div>
                <div className="col-lg-6 pl-lg-3 pt-1">
                <div className="form-group">
                <label className="acc-label2 mb-0">Mobile*</label>
                <div className="col-12 d-flex pl-0">
                <PhoneInput
                international
                defaultCountry={this.state.defaultCountry}
                name='countryCode'
                className="d-flex col-lg-6 pr-0 pl-0"
                onChange={countryCode => this.setState({ countryCode})}
                disabled={this.state.view == true ? 'disabled' : ''}
                />  
                <input type="number" name="mobile" onChange={this.handleChange} className="acc-form contact col-5"
                disabled={this.state.view == true ? 'disabled' : ''} />
                </div>
                {this.state.mobileError && <><small className="error">{this.state.mobileError}</small></>}
                </div>
                </div>
                <div className="col-lg-3 pt-1 pt-lg-1">
                <div className="form-group">
                <label className="acc-label2 mb-0">Select Processing Branch*</label>
                <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example" name="superAgent" onChange={this.handleChange}>
                <option selected>Please Select</option>
                {[].concat(this.state.agency).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map((value) =>
                <option className="light" value={value.id}>{value.agencyname}</option> )}
                </select>
                {this.state.agentError && <><small className="error">{this.state.agentError}</small></>}
                </div>
                </div>
                <div className="col-lg-3 pt-1 pt-lg-1">
                <div className="form-group">
                <label className="acc-label2 mb-0">Select Referring Branch*</label>
                <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example" name="subAgent" onChange={this.handleChange}>
                <option selected>Please Select</option>
                {[].concat(this.state.agency).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map((value) =>
                <option className="light" value={value.id}>{value.agencyname}</option> )}
                </select>
                {this.state.subAgentError && <><small className="error">{this.state.subAgentError}</small></>}
                </div>
                </div>
                <div className="col-lg-4 pl-lg-3 pt-1">
                <div className="form-group">
                <label className="acc-label2 mb-0">Select Gender*</label>
                <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example" name="gender" onChange={this.handleChange}>
                <option selected>Please Select</option>
                <option value="0">Male</option>
                <option value="1">Female</option>
                <option value="2">Other</option>
                </select>
                {this.state.genderError && <><small className="error">{this.state.genderError}</small></>}
                </div>
                </div>
                <div className="col-lg-4 pl-lg-3 pt-1 pt-lg-1">
                <div className="form-group">
                <label className="acc-label2 mb-0">Country*</label>
                <select className="form-select acc-form form-select-lg w-lg-90" onChange={this.handleOptionChange} aria-label="form-select-lg example" name="country">
                <option selected>Please Select</option>
                {countries_code.map(value => 
                <option value={value.country}>{value.value}</option>
                )}
                </select>
             
                {this.state.countryError && <><small className="error">{this.state.countryError}</small></>}
                </div>
                </div>
                <div className="col-lg-4 pl-lg-3 pt-1 pt-lg-1">
                <div className="form-group">
                <label className="acc-label2 mb-0">City*</label>
                <input type="text" name="city" onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                {this.state.cityError && <><small className="error">{this.state.cityError}</small></>}
                </div>
                </div>
                {/* External agent */}
                <div className="row pl-lg-3 mt-3 mb-1">
                <label className="col-lg-5 font-f-monst-sans size-15 f-w-500 mb-0" for="extern">Does this client have an external agent?</label>
                <p className="col-auto mx-2 font-f-monst-sans f-w-500">
                <input type="radio" className="mr-1" name="external_agent" value="yes" checked={this.state.externalAgent === 'yes'}
                onChange={(e) => this.setState({externalAgent: e.target.value} , () => {this.getExternalAgentList()})} />Yes
                </p>
                <p className="col-auto mx-1 font-f-monst-sans f-w-500">
                <input type="radio" className="mr-1" name="external_agent" value="no" checked={this.state.externalAgent === 'no'}
                onChange={(e) => this.setState({externalAgent: e.target.value})} />No
                </p>
                {this.state.externalAgentError && <><small className="error">{this.state.externalAgentError}</small></>}
                </div>
                {/* External agent options*/}
                {this.state.externalAgent === 'yes' &&
                <div className="row pl-lg-3 mt-3 mb-1">
                {/* External Referring Agent */}
                <div className='col-3'>
                <label className="acc-label2 mb-1">External Referring Agent</label>
                </div>
                <div className='col-4'>
                <select onChange={(e) => this.setState({selectedReferringAgent: e.target.value})} className="form-select acc-form form-select-lg col-lg-11" name="subagent" >
                <option value="">Please Select</option>
                {[].concat(this.state.rExternalList).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(head => 
                <option key={head.id} value={head.id}>{head.agencyname}</option>
                )}
                </select>
                </div>
                {/* External Processing Agent */}
                <div className='row mt-3 mb-2'>
                <div className='col-3'>
                <label className="acc-label2 mb-1">External Processing Agent</label>
                </div>
                <div className='col-4'>
                <select onChange={(e) => this.setState({selectedProcessingAgent: e.target.value})} className="form-select acc-form form-select-lg col-lg-11" name="subagent" >
                <option value="">Please Select</option>
                {[].concat(this.state.pExternalList).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(head => 
                <option key={head.id} value={head.id}>{head.agencyname}</option>
                )}
                </select>
                </div>
                </div>
                </div>}
                 {/* reference checkbox */}
                <div className="row pl-lg-3 my-2">
                <p className="font-f-monst-sans f-w-500 bold col-2">
                <input type='checkbox' checked={this.state.reference}  
                onClick={(e) => this.setState({reference: e.target.checked})} /> Add Reference
                </p>
                {this.state.reference &&
                <div className='col-5'>
                <input name="referenceBy" type="text" placeholder="Please Enter" 
                className="form-control acc-form w-lg-90" onChange={this.handleChange} />
                </div>}
                </div>
                <div className="col-lg-7 pl-lg-3 pt-1 pt-lg-0">
                <div className="form-group">
                <label className="acc-label2 mb-0">Description*</label>
                <textarea className="textarea form-control acc-form" name="description" 
                 placeholder="Please provide more information about work to be done" onChange={this.handleChange} rows="3" cols="6"></textarea>
                 {this.state.descriptionError && <><small className="error">{this.state.descriptionError}</small></>}
                </div>
                </div>
                <div className="col-12 mb-3 mt-1 scroll">
                 {/* show loader when date selected */}
                 {this.state.loading ?
                <img src={smallSpinner} width="15" className="pt-2" alt="loading" />:
                <button type="submit" className="btn btn-success ml-2" onClick={this.handleSubmit}>Create Client</button>}
                </div>
            </div>
            </form>
            </div>
        </div>
        </div>

        </>
    )
  }
}
export default withNavigation(AddClient);
import React, { Component } from "react";
import Switch from "react-switch";

class PermissionSwitch extends Component {

     handleChange = (status,name) => {
         if(!this.props.disabled){ 
         if(status === 1){
             status = 0
         }
         else{
             status = 1;
         }
        this.props.parentCallback(status,name);
         }
    }

render(){
return(
    <>
    {this.props.generic ?  
        <Switch
        checked={this.props.status}
        onChange={() => this.handleChange(this.props.status, this.props.keyName)}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={15}
        width={38}
        className="react-switch"
        id="material-switch"
        /> :

    <div className="row">
        <div className="col-lg-6 col-9 p-2 text-left">
        <h6 className="text-muted m-b-0 font-f-monst-sans">{this.props.text}</h6></div>
        <div className="col-lg-6 col-3 p-2 text-right">
        <Switch
            checked={this.props.status}
            onChange={() => this.handleChange(this.props.status, this.props.keyName)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={38}
            className="react-switch"
            id="material-switch"
        />
    </div>
    </div> }
           
        </>
    )
    }
}
export default PermissionSwitch;

import React, { Component } from 'react';
import './inbox.css'
import {view_ApiManager } from '../ApiManager/ApiManager';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Nav, Tab } from 'react-bootstrap';
import {faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import EmailDetailComponent from './EmailDetail';

class HandleInbox extends Component {
    constructor(props) {
        super(props);
        var userDetail = JSON.parse(localStorage.getItem('user'));
        this.state = {
            allEmails:[],
            loading:false,
            showEmail: false,
            isChecked: false,
            currentPage:1,
            perPage:10,
            lastPage:'',
            to:'',
            from:'',
            totalItem:'',
            emailDetail: [],
            loggedInUser: userDetail,
            branchName:[],
            showEmailModel: false,
            header:[],
            selectedEmail:'',
            sendModel: false,
            allAgents:[],
            selectedTab: 'all',
            activeTab: 'inbox',
        }
    }
    componentDidMount() {
        this.getPartnerChat();
    }
    getPartnerChat = () => {
        this.setState({loading:true})
        let url = `getPartnerChat`;
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('p-emails',res);
            this.setState({allEmails: res.data.data['data'],
            perPage: res.data.data['per_page'],
            loading:false,
            lastPage: res.data.data['last_page'],
            currentPage: res.data.data['current_page'],
            totalItem: res.data.data['total'],
            from: res.data.data['from'],
            to: res.data.data['to']});
        },(err) => {
            console.log(err);
        });
    }
    handleDivClick = (email, from) => {
        this.setState({ selectedEmail: email, from:from });
    };
    handleBack = () => {
        this.setState({ selectedEmail: '' });
    };
    handleNotification = () => {
        const socket = this.props.socket;
        socket.emit("sendPartnerChatNotification");
    }
    handleEmailCallback = (type) => {
        if(type === 'edit'){
            this.handleNotification();
            this.getPartnerChat();
        }
        this.setState({sendModel: false})
     }
     handleMessage = () => {
        this.getPartners();
        this.setState({sendModel:true});
     }
     getPartners = () => {
        let url = `getagents/${2}`;
        view_ApiManager(url, 1, 100,(res) => {
        console.log('partners',res);
        this.setState({ allAgents: res.data.data.data});
        },(err) => {
            console.log(err);
        });
     }
     getClientChat = () => {
        this.setState({loading:true})
        let url = `getClientChat`;
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('client-emails',res);
            this.setState({allEmails: res.data.data['data'],
                loading:false,
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                currentPage: res.data.data['current_page'],
                totalItem: res.data.data['total'],
                from: res.data.data['from'],
                to: res.data.data['to']});
        },(err) => {
            console.log(err);
        });
    }
    handleTabChange = (eventKey) => {
        this.setState({ selectedTab: eventKey, activeTab:'inbox' }); // Update the selected tab

        if (eventKey === "all") {
            this.getPartnerChat();
        } else if (eventKey === "client") {
            this.getClientChat();
        }
    }
    getPartnerSentEmail = () => {
        let url = `getPartnerSentEmails`;
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('p-sent-emails',res);
            this.setState({allEmails: res.data.data['data'],
                loading:false,
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                currentPage: res.data.data['current_page'],
                totalItem: res.data.data['total'],
                from: res.data.data['from'],
                to: res.data.data['to']});
        },(err) => {
            console.log(err);
        });
    }
    getClientSentEmail = () => {
        let url = `getClientSentEmails`;
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('c-sent-emails',res);
            this.setState({allEmails: res.data.data['data'],
                loading:false,
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                currentPage: res.data.data['current_page'],
                totalItem: res.data.data['total'],
                from: res.data.data['from'],
                to: res.data.data['to']});
        },(err) => {
            console.log(err);
        });  
    }
    handleTabs = (from, tab) => {
        this.setState({ activeTab: tab });
        if(from === 'partner'){
            if(tab === 'inbox'){
                this.getPartnerChat();
            } else{
                this.getPartnerSentEmail();
            }
        } else if(from === 'client'){
            if(tab === 'inbox'){
                this.getClientChat();
            } else{
                this.getClientSentEmail();
            }
        }
     }

    render(){
        if (this.state.selectedEmail) {
            return (
              <EmailDetailComponent
              email={this.state.selectedEmail}
              from={this.state.from} 
              onBack={this.handleBack} 
              socket={this.props.socket} />
            );
          }
        
        return(
            <> 
            <div className="bg-white rounded border m-2" style={{ height: '100vh' }}>
            <div className='row'>
            <Tab.Container id="left-tabs-example" activeKey={this.state.selectedTab} onSelect={(eventKey) => this.handleTabChange(eventKey)}>
            <Nav variant="underline" className='no-left-right-border px-2 py-1' >
            <Nav.Item><Nav.Link  eventKey="all">Partner Inbox</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="client">Client Inbox</Nav.Link></Nav.Item>
            </Nav> 
            <Tab.Content>
                <Tab.Pane eventKey="all">
                {this.state.loading ? '...' :
                <>
                <div className='row p-2' style={{background:'#f2f6fc'}}>
                {/* <div className='col-3'>
                <button className='btn btn-primary px-4' onClick={()  => this.handleMessage()}>
                <FontAwesomeIcon className="pr-2" icon={faPlus} />Send Message</button>
                </div> */}
                <div className='col-10'>
                <ul className="nav nav-tabs">
                <li className="nav-item">
                    <div className={`nav-link pointer ${this.state.activeTab === 'inbox' ? 'active' : ''}`} aria-current={this.state.activeTab === 'inbox' ? 'page' : undefined}
                    onClick={()  => this.handleTabs('partner', 'inbox')}>
                    Inbox
                    </div>
                </li>
                <li className="nav-item">
                    <div className={`nav-link pointer ${this.state.activeTab === 'sent' ? 'active' : ''}`} aria-current={this.state.activeTab === 'sent' ? 'page' : undefined}
                    onClick={()  => this.handleTabs('partner', 'sent')}> Sent </div>
                </li>
                </ul>
                </div>
                <div className='text-right col-2'>
                <FontAwesomeIcon  className="px-3 pointer" icon={faRefresh} color='blue' onClick={() => this.getPartnerChat()} />
                </div>
                </div>
                {this.state.allEmails.map((value, index) => (
                <div className={`row px-3 py-3 no-left-right-border shadow-sm pointer ${this.state.activeTab === 'inbox' && value.is_read === 0 ? 'unread':'read'}`} key={index} 
                onClick={() => this.handleDivClick(value.id, 'partner')}>
                <div className='col-1'>{++index}</div>
                <div className='col-2'>{value.sender}
                {value.counts > 0 && <small className='pl-2' style={{color:'grey'}}>{value.counts}</small>}
                {value.is_read === 0 && <> {moment(value.created_at).isSame(moment(), 'day') && (
                    <div className="rounded-text-div">New</div>)}</>}
                </div>
                <div className='col-8'>{value.subject}</div>
                <div className='col-1 size-14'> {moment(value.created_at).isSame(moment(), 'day')
                    ? moment(value.created_at).format('h:mm A') // Show time if today
                    : moment(value.created_at).format('D MMM YYYY') // Show date otherwise
                }</div>
                </div>))}
                </>}
                </Tab.Pane>
                <Tab.Pane eventKey="client">
                {this.state.loading ? '...' :
                <>
                <div className='row p-2' style={{background:'#f2f6fc'}}>
                <div className='col-10'>
                <ul className="nav nav-tabs">
                <li className="nav-item">
                    <div className={`nav-link pointer ${this.state.activeTab === 'inbox' ? 'active' : ''}`} aria-current={this.state.activeTab === 'inbox' ? 'page' : undefined}
                    onClick={()  => this.handleTabs('client', 'inbox')}>
                    Inbox
                    </div>
                </li>
                <li className="nav-item">
                    <div className={`nav-link pointer ${this.state.activeTab === 'sent' ? 'active' : ''}`} aria-current={this.state.activeTab === 'sent' ? 'page' : undefined}
                    onClick={()  => this.handleTabs('client', 'sent')}> Sent </div>
                </li>
                </ul>
                </div>
                <div className='text-right col-2'>
                <FontAwesomeIcon className="px-2 pointer" icon={faRefresh} color='#5f5f5f' onClick={() => this.getClientChat()} />
                </div>
                </div>
                {this.state.allEmails.map((value, index) => (
                <div className={`row px-3 py-3 no-left-right-border shadow-sm pointer ${this.state.activeTab === 'inbox' && value.is_read === 0 ? 'unread':'read'}`} key={index} 
                onClick={() => this.handleDivClick(value.id, 'client')}>
                <div className='col-1'>{++index}</div>
                <div className='col-2'>{value.sender}
                {value.counts > 0 && <small className='pl-2' style={{color:'grey'}}>{value.counts}</small>}
                {value.is_read === 0 && <> {moment(value.created_at).isSame(moment(), 'day') && (
                    <div className="rounded-text-div">New</div>)}</>}
                </div>
                <div className='col-8'>{value.subject}</div>
                <div className='col-1 size-14'> {moment(value.created_at).isSame(moment(), 'day')
                    ? moment(value.created_at).format('h:mm A') // Show time if today
                    : moment(value.created_at).format('D MMM YYYY') // Show date otherwise
                }</div>
                </div>))}
                </>}
                </Tab.Pane>
            </Tab.Content>
            </Tab.Container>
            <div className="row p-2">
            <div className='col-lg-6'>
            <div className="text-left justify-content-left">
            <select value={this.state.perPage} onChange={(e) => this.setState({perPage: e.target.value}, () => this.getAllEmails())}  
            className="form-select page-form col-2 text-center">
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={this.state.totalItems}>All</option>
            </select>
            </div>
            </div>
            <div className='col-lg-6'>
            <div className="text-right d-flex paginate j-content-right">{this.state.from ? this.state.from : 0}-{this.state.to ? this.state.to : 0} of {this.state.totalItem ? this.state.totalItem : 0}
            <button className="px-2 py-0 btn" onClick={() => { if (this.state.currentPage > 1) 
            this.setState({currentPage: this.state.currentPage-1}, () => this.getAllEmails() )}} style={{ cursor: this.state.currentPage === 1 ? 'default' : 'pointer' }}>
            <FaAngleLeft color={this.state.currentPage === 1 ? '#adadadc7' : ''} size={15} /></button>
            <button className="px-2 py-0 btn" style={{ cursor: this.state.currentPage === this.state.lastPage ? 'default' : 'pointer' }}
            onClick={() => {if (this.state.currentPage < this.state.lastPage) 
            this.setState({currentPage: this.state.currentPage+1}, () => this.getAllEmails() )}}>
            <FaAngleRight color={this.state.currentPage === this.state.lastPage ? '#adadadc7' : ''} size={15} /></button>
            </div>
            </div>
            </div>
            </div>
            </div>
         </>
          
        )
    }
}
export default HandleInbox
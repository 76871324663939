
import React ,{useState,useEffect, useRef}from "react"
import Title from '../GenericComponents/Title'
import { useParams } from "react-router";
import swal from 'sweetalert';
import { internalNoteDetail, internalTaskCompletion } from "../Services/apiServices"
import './internalNote.css'
import moment from "moment";
import DOMPurify from 'dompurify'
import Modals from "../GenericComponents/Modal";
import InternalNotePDF from "../GenericComponents/InternalNotePDF";

const InternalNoteDetail = () => {
    const { noteid } = useParams();
    const [details, setDetails] = useState([]);
    const [openTaskModel ,setOpenTaskModel] = useState(false)
    const [taskStatus ,setTaskStatus] = useState('');
    
    useEffect( () => {
        internalNotes();
    }, []);

    const internalNotes = async () => {
        let result = await internalNoteDetail(noteid);
        console.log('internal-notes', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            setDetails(result.data.data);
        }
    }
    const handleModelCallback = async (check,type,note) => {
        if(type === 'edit'){
            const data ={
                id: noteid,
                note: note,
                taskStatus: taskStatus,
            }
            let result = await internalTaskCompletion({data});
            console.log('task-completed', result)
            if (result.error) {
                swal({ text: result.error, icon: "warning", dangerMode: true });
            } else {
                internalNotes();
            }
        } 
        setOpenTaskModel(false)
    }
    const handleTaskStatusChange = (status) => {
        setOpenTaskModel(true)
        setTaskStatus(status)
    }

    return(
        <>
        <Title back={true} />
        <div className="row w-100 px-lg-3 mb-5">
        <InternalNotePDF
        data={details}
            />
        <div>
            <div className="border row bg-white">
            <div className="row w-100">
            <div className="m-note row p-3 align-items-center ">
                <h1 className="text-center font-monst white w-100">Meeting Note</h1>
                <h3 className="w-100 text-center bold size-18 font-monst " style={{color:'#88ffd7'}}>
                ({details && moment(details['created_at']).format('D MMM YYYY')})</h3>
            </div>
          
            <div className="row font-f-sans" style={{ border: '4px solid rgb(5 168 185)' }}>
            {/* <h2 className="p-2 w-100 text-center bold">{details && moment(details['created_at']).format('D MMM YYYY')}</h2> */}
            <div className="col-lg-2 col-4 d-flex flex-column align-items-center justify-content-center" 
            style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db'}}>
            <h5 className="bold py-3 text-center">AGENDA</h5></div>
            <div className="col-lg-10 col-8 p-3" ><p className="size-16">{details['agenda']}</p></div>

            <div className="col-lg-2 col-4 d-flex flex-column align-items-center justify-content-center" 
            style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db', borderTop: '2px solid #3498db'}}>
            <h5 className="bold py-3 text-center">MANAGED BY</h5></div>
            <div className="col-lg-10 col-8" style={{borderTop: '2px solid #3498db', background:'aliceblue'}}><p className="size-16 p-3">
            {details['manager_ids'] && details['manager_ids'].map((v, index) => (
                <React.Fragment key={index}>
                {v.label}
                <br />
                </React.Fragment>
            ))}
            </p></div>

            <div className="col-lg-2 col-4 d-flex flex-column align-items-center justify-content-center" 
            style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db', borderTop: '2px solid #3498db'}}>
            <h5 className="bold py-3 text-center">MEETING ATTENDEES</h5></div>
            <div className="col-lg-10 col-8" style={{borderTop: '2px solid #3498db'}}><p className="size-16 p-3">{details['participants'] && details['participants'].map((v, index) => (
                <React.Fragment key={index}>
                {v.label}
                <br />
                </React.Fragment>
            ))}</p></div>

            <div className="col-lg-2 col-4 d-flex flex-column align-items-center justify-content-center" 
            style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db',borderTop: '2px solid #3498db'}}>
            <h5 className="bold py-3 text-center">Date & Time</h5></div>
            <div className="col-lg-10 col-8" style={{borderTop: '2px solid #3498db'}}><p className="size-16 p-3 px-4">{moment(details['meeting_date']).format('D MMM YYYY')} {details['start_time']} - {details['end_time']}</p></div>

            <div className="col-lg-2 col-4 d-flex flex-column align-items-center justify-content-center" 
            style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db',borderTop: '2px solid #3498db'}}>
            <h5 className="bold py-3 text-center">Due Date</h5></div>
            <div className="col-lg-10 col-8" style={{borderTop: '2px solid #3498db', background:'aliceblue'}}><p className="size-16 p-3 px-4" style={{background:'aliceblue'}}>
            {details['due_date']  && moment(details['due_date']).format('D MMM YYYY')}</p></div>

            <div className="col-lg-2 col-4" 
            style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db', borderTop: '2px solid #3498db'}}>
            <h5 className="bold py-3 text-center">Discussion</h5></div>
            <div className="col-lg-10 col-8 px-5" style={{borderTop: '2px solid #3498db'}}>
            <div style={{whiteSpace: 'pre-wrap'}} className="size-16" 
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details['discussion_notes'])}}></div></div>

            <div className="col-lg-2 col-4 d-flex flex-column align-items-center justify-content-center" 
            style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db', borderTop: '2px solid #3498db'}}>
            <h5 className="bold py-3 text-center">Task Status</h5></div>
            <div className="col-lg-10 col-8" style={{borderTop: '2px solid #3498db'}}>
            <p className="size-16 p-3">{details['task_status'] === '1' ? 
            'Partially Completed' : details['task_status'] === '2' ? 'Not Completed' : 
            details['task_status'] === '3' ? 'Completed':'Pending ' }</p></div>

            <div className="col-lg-2 col-4 d-flex flex-column align-items-center justify-content-center" 
            style={{ borderLeft: '4px solid rgb(153 229 144)', borderRight:'4px solid #3498db', borderTop: '2px solid #3498db'}}>
            <h5 className="bold py-3 text-center">Status Notes</h5></div>
            <div className="col-lg-10 col-8" style={{borderTop: '2px solid #3498db'}}>
            <p className="size-16 p-3">{details['task_notes']}</p></div>

            <div className="d-flex p-3" style={{borderTop: '2px solid #3498db'}}>
            {(details['task_status'] <= 2 || details['task_status'] === '2') ?
             <select
            className="form-select acc-form col-lg-3"
            onChange={(e) => handleTaskStatusChange(e.target.value)} value={details['task_status']}  >
            <option value={0} disabled selected>
                Select Task Status
            </option>
            <option value={1}>Partially Completed</option>
            <option value={2}>Not Completed</option>
            <option value={3}>Complete Task</option>
            </select>
            :
            <button className="btn btn-success" disabled>Task Completed </button>
            }
            </div>
            
            </div>

            </div>
            </div>
        </div>
       
        </div>

        <Modals
        show={openTaskModel}
        title={'Task Completion'}
        data={'Add Note for Task completion'} 
        type={'category'}
        textarea="true"
        noteType={false}
        parentCallback={handleModelCallback}/>

        </>
    )
}
export default InternalNoteDetail
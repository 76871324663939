
import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import * as FaIcons from 'react-icons/fa';
import { Link } from 'react-router-dom';


class ProviderSetting extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            loading:false,
            allPermissions: permissions,
        }
    }
 render(){
    return(
        <>
         <Title title='Provider Setting' />
         <div className="pt-lg-5 pl-lg-3 pr-lg-3 pl-5 pr-5">
         {this.state.allPermissions['providers'] === 1 ?
         <div className="row pl-3">

            <div className="col-xl-3 col-md-6 mb-lg-6 mb-6">
            <Link className="no-underline" to={'/providers/view-levels'}>
                <div className="card app-card shadow">
                    <div className="card-block h-100">
                        <div className="row text-center">
                            <div className="col-10">
                            <h4 className="text-c-green main-app">View Courses Levels</h4>
                            </div>
                            <div className="col-2 text-right pt-2">
                            <FaIcons.FaRocket size={26} color={'#000000b5'} />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer dashboard-card-footer bg-green">
                    </div>
                </div>
            </Link>
            </div>

            <div className="col-xl-3 col-md-6 mb-lg-6 mb-6 ml-lg-4">
                <Link className="no-underline" to='/providers/view-intakes'>
                    <div className="card app-card shadow">
                        <div className="card-block h-100">
                            <div className="row text-center">
                                <div className="col-10">
                                <h4 className="text-c-green main-app">View Courses Intakes</h4>
                                </div>
                            <div className="col-2 text-right pt-2">
                            <FaIcons.FaRocket size={26} color={'#000000b5'} />
                        </div>
                    </div>
                </div>
                    <div className="card-footer dashboard-card-footer bg-green">
                    </div>
                </div>
                </Link>
            </div>

         </div>: 'You dont have permission to access this pages!'}
         </div>

        </>
    )
 }
}
export default ProviderSetting
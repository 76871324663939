
import React from 'react';

class MyAppointments extends React.PureComponent {
    constructor(props) {
        super(props);
        };
  

  componentDidMount() {
  
  }

  
  render() {
    return (
      <>
        
      
      </>
      );
      }
    }
    
export default MyAppointments

import React ,{useState,useEffect} from "react";
import Title from "../GenericComponents/Title";
import { Link, useParams } from "react-router-dom"
import { get_ApiManager } from "../ApiManager/ApiManager";
import DOMPurify from 'dompurify'
import Moment from 'react-moment';
import Background from '../../assets/bg.jpg';


const AlertDetail = () => {
    const { id } = useParams();
    const [details, setDetails] = useState([]);

    const style = {
        backgroundImage:`url(${Background})` ,width:'100%', height:'auto',
       backgroundPosition: 'center',
    }

    useEffect(() => {
        getDetail();
        markAsSeen();
     }, [id]);

     const getDetail = () => {
        let url = `alertDetail/${id}`;
        console.log(url)
        get_ApiManager('',url,(res) => {
            console.log('alert-detail',res);
            setDetails(res.data.data);
        },(err) => {
            console.log(err);
         });
     }

     const markAsSeen = () => {
        let url = `markAlertAsSeen/${id}`;
        console.log(url);
        get_ApiManager('',url,(res) => {
            console.log('marked-seen',res);
        },(err) => {
            console.log(err);
         });
     }

    return(
        <>
        <Title title='Alert' />
        <div className="row mb-4 w-100 px-lg-4">
        {/* My Birthday */}
        {details['type'] == 'mybirthday' ?
        <div className="edit-color px-lg-3 py-lg-2 mb-lg-3 border rounded mt-4" style={style}>
        <h2 className="text-center capital birthday-title py-4 px-5 mx-5 mt-4 font-f-monst-sans">{details['title']}!</h2>
        <div className="px-5 mx-5">
        <div className={`birthday-text py-4 px-5 mx-5`} style={{whiteSpace: 'pre-wrap'}} 
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details['description'])}}></div>
        </div>
        
        <small className="row acc-label2 py-4"><Moment date={details['updated_at']}  format="D MMM YYYY, hh:mm" withTime></Moment></small>
        </div>
        :
        <div className="edit-color px-lg-3 py-lg-2 mb-lg-3 border rounded mt-2">
        {details['type'] == 'birthday' &&
        <div className="col-12 text-center">
        <img src={`${process.env.PUBLIC_URL}/assets/img/birthday.png`}  width="auto" height="100" />
        </div>}
        <h2 className="capital pt-3 font-f-monst-sans bold">{details['title']}</h2>
        <div className={`acc-note py-3`} style={{whiteSpace: 'pre-wrap'}} 
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details['description'])}}></div>
        
        <small className="row acc-label2 py-4"><Moment date={details['updated_at']}  format="D MMM YYYY, hh:mm" withTime></Moment></small>
        </div>}
        </div>
        </>
    )
}
export default AlertDetail
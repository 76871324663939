import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import logo from '../../assets/logo1.png';
import * as BsIcons from "react-icons/bs";
import $ from 'jquery';
import './sidebar.css';

const SidebarNav = styled.nav`
  background: #011b69;
  font-size: 10px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 450ms;
  z-index: 10;
  overflow-y: scroll;
`;

const SidebarWrap = styled.div`
  width: 100%;
  font-size: 10px;
  height: 100vh;
  font-family: sans-serif;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    if(sidebar == true){
      document.getElementById("content-width").classList.add('content-width-full');
      document.getElementById("content-width").classList.remove('content-width');
    } else{
      document.getElementById("content-width").classList.remove('content-width-full');
      document.getElementById("content-width").classList.add('content-width');
    }
    setSidebar(!sidebar);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSidebar(false);
        document.getElementById("content-width").classList.add('content-width-full');
        document.getElementById("content-width").classList.remove('content-width');
      } else {
        setSidebar(true);
        document.getElementById("content-width").classList.remove('content-width-full');
        document.getElementById("content-width").classList.add('content-width');
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    localStorage.getItem('token') &&
    !window.location.toString().includes("preview/invoice") && (
      <>
        <div className="navbar2">
          <button className="navbar-toggle" onClick={toggleSidebar}>
            ☰
          </button>
        </div>
        <div id="sidebar-width" className={`sidebar-width ${sidebar ? 'show' : 'hide'}`}>
          <IconContext.Provider value={{ color: '#fff' }}>
            <SidebarNav sidebar={sidebar} className='side-width-auto'>
              <SidebarWrap>
                <div id="logo" className="sidebar-brand-text mx-2">
                  <button className="navbar-toggle" onClick={toggleSidebar}>
                    ☰
                  </button>
                </div>
                <div>
                  {SidebarData.map((item, index) => {
                    return <SubMenu item={item} key={index} />;
                  })}
                </div>
              </SidebarWrap>
            </SidebarNav>
          </IconContext.Provider>
        </div>
      </>
    )
  );
};

export default Sidebar;


import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import Search from '../GenericComponents/Search';
import Paginations from '../GenericComponents/Pagination';
import moment from 'moment';
import ExportOptions from '../GenericComponents/ExportOptions'
import { view_ApiManager } from '../ApiManager/ApiManager';
import { Link } from 'react-router-dom';
import './clients.css';
import SelectionBar from '../Counseling/SelectionBar';
import { Sorting } from '../GenericComponents/SortTables';
import { FaTimesCircle } from 'react-icons/fa';

class Reports extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            currentPage: 1,
            totalItem:'',
            lastPage:'',
            perPage: 10,
            sDate: null,
            eDate: null,
            type:null,
            stype:null,
            days: null,
            reports:[],
            showHistory: false,
            selectedId:'',
            selectedReport:[],
            perItem:'',
            order:'ASC',
            allPermissions: permissions,
            headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1},
                {title: 'Name' , dataKey: "name",align: "text-left", key:2},
                {title: 'Course/Package', dataKey: "coursename", align: "text-left",key:3 },
                {title: 'Contact' , dataKey: "mobile", align: "text-center",key:4},
                {title: 'Start Date', dataKey: 'startdate',align:"text-center",key:5},
                {title: 'End Date', dataKey: 'enddate',align:"text-center",key:6},
            ],
            visa_headers: [
                {title: '#' , dataKey: "index", align: "text-center",key:1},
                {title: 'Name' , dataKey: "name",align: "text-left",key:2},
                {title: 'Visa Type', dataKey: "visa_type", align: "text-center",key:3},
                {title: 'Visa Country', dataKey: "country", align: "text-center",key:4},
                {title: 'Contact' , dataKey: "mobile", align: "text-center",key:5},
                {title: 'Issue Date', dataKey: 'issuedate',align:"text-center",key:6},
                {title: 'Expiry Date', dataKey: 'expirydate',align:"text-center",key:7},
                {title: 'Created At', dataKey: 'created_at',align:"text-center",key:8},
            ],
            passport_headers: [
                {title: '#' , dataKey: "index", align: "text-center", key:1},
                {title: 'Name' , dataKey: "name",align: "text-left" ,key:2},
                {title: 'Contact' , dataKey: "mobile", align: "text-center", key:3},
                {title: 'Passport No', dataKey: 'passportno',align:"text-center", key:4},
                {title: 'Passport Country', dataKey: 'country',align:"text-center", key:5},
                {title: 'Issue Date', dataKey: 'issuedate',align:"text-center", key:6},
                {title: 'Expiry Date', dataKey: 'expirydate',align:"text-center", key:7},
                {title: 'Created At', dataKey: 'created_at',align:"text-center", key:8},
            ],
            license_headers: [
                {title: '#' , dataKey: "index", align: "text-center", key:1},
                {title: 'Name' , dataKey: "name",align: "text-left", key:2},
                {title: 'Contact' , dataKey: "mobile", align: "text-center", key:3},
                {title: 'License No', dataKey: 'licenseno',align:"text-center", key:4},
                {title: 'Expiry Date', dataKey: 'expirydate',align:"text-center", key:5},
                {title: 'Created At', dataKey: 'created_at',align:"text-center", key:6},
            ],
            oshc_headers: [
                {title: '#' , dataKey: "index", align: "text-center",key:1},
                {title: 'Name' , dataKey: "name",align: "text-left", key:2},
                {title: 'Contact' , dataKey: "mobile", align: "text-center", key:3},
                {title: 'Provider Name', dataKey: 'providername',align:"text-center", key:4},
                {title: 'Membership no', dataKey: 'membernumber',align:"text-center", key:5},
                {title: 'Issue Date', dataKey: 'issuedate',align:"text-center", key:6},
                {title: 'Expiry Date', dataKey: 'expirydate',align:"text-center", key:7},
            ],
            skill_headers: [
                {title: '#' , dataKey: "index", align: "text-center", key:1},
                {title: 'Name' , dataKey: "name",align: "text-left", key:2},
                {title: 'Contact' , dataKey: "mobile", align: "text-center", key:3},
                {title: 'Assessing Body', dataKey: 'assessingbody',align:"text-center",key:4},
                {title: 'Reference no', dataKey: 'referencenumber',align:"text-center", key:5},
                {title: 'Assessing Date', dataKey: 'assessingdate',align:"text-center", key:6},
                {title: 'Expiry Date', dataKey: 'expirydate',align:"text-center", key:7},
            ],
            police_headers: [
                {title: '#' , dataKey: "index", align: "text-center", key:1},
                {title: 'Name' , dataKey: "name",align: "text-left", key:2},
                {title: 'Contact' , dataKey: "mobile", align: "text-center", key:3},
                {title: 'Issuing Authority', dataKey: 'issuingauthority',align:"text-center", key:4},
                {title: 'Reference no', dataKey: 'referencenumber',align:"text-center", key:5},
                {title: 'Issue Date', dataKey: 'issuedate',align:"text-center", key:6},
                {title: 'Expiry Date', dataKey: 'expirydate',align:"text-center", key:7},
            ],
            test_headers: [
                {title: '#' , dataKey: "index", align: "text-center",key:1},
                {title: 'Name' , dataKey: "name",align: "text-left", key:2},
                {title: 'Contact' , dataKey: "mobile", align: "text-center", key:3},
                {title: 'Test Type', dataKey: 'testtype',align:"text-center", key:4},
                {title: 'Reference no', dataKey: 'referencenumber',align:"text-center", key:5},
                {title: 'Issue Date', dataKey: 'issuedate',align:"text-center", key:6},
                {title: 'Expiry Date', dataKey: 'expirydate',align:"text-center", key:7},
            ],
            health_headers: [
                {title: '#' , dataKey: "index", align: "text-center",key:1},
                {title: 'Name' , dataKey: "name",align: "text-left", key:2},
                {title: 'Provider' , dataKey: "mobile", align: "text-center", key:3},
                {title: 'Amount', dataKey: 'testtype',align:"text-center", key:4},
                {title: 'Issue Date', dataKey: 'issuedate',align:"text-center", key:5},
                {title: 'Expiry Date', dataKey: 'expirydate',align:"text-center", key:6},
                {title: 'Refund', dataKey: 'health_applied',align:"text-center", key:7},
            ],
            fee_headers: [
                {title: '#' , dataKey: "index", align: "text-center",key:1},
                {title: 'Name' , dataKey: "name",align: "text-left", key:2},
                {title: 'Category' , dataKey: "categoryname",align: "text-left", key:3},
                {title: 'Course' , dataKey: "cursename",align: "text-left", key:4},
                {title: 'Fee Refund', dataKey: 'tution_refund',align:"text-center", key:5},
            ],
        }
    }
     // Get all users from apis 
     async generateReport() {
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let $startDate = this.state.sDate;
        let $endDate = this.state.eDate;
        let $days = this.state.days;
        let $type= this.state.type;
        let $stype = this.state.stype;
        let url = `generateReport/${$days}/${$startDate}/${$endDate}/${$type}/${$stype}`;
        console.log(url);
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('generate report',res);
            this.setState({
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                reports: res.data.data['data'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            if(this.state.type == 'course'){
            let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        client: item.client,
                        coursename: item.coursename,
                        mobile: item.mobile,
                        startdate: item.startdate,
                        enddate: item.enddate,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            } else if(this.state.type == 'visa'){
                let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        visa_type: item.visa_type,
                        country: item.country,
                        mobile: item.mobile,
                        issuedate: item.issuedate,
                        expirydate: item.expirydate,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            } else if(this.state.type == 'health'){
                let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        visa_type: item.visa_type,
                        country: item.country,
                        mobile: item.mobile,
                        issuedate: item.issuedate,
                        expirydate: item.expirydate,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            } else if(this.state.type == 'passport'){
                let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        passportno: item.passportno,
                        country: item.country,
                        mobile: item.mobile,
                        issuedate: item.issuedate,
                        expirydate: item.expirydate,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            } else if(this.state.type == 'license_headers'){
                let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        licenseno: item.licenseno,
                        mobile: item.mobile,
                        expirydate: item.expirydate,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            } else if(this.state.type == 'oshc_headers'){
                let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        mobile: item.mobile,
                        providername: item.providername,
                        membernumber: item.membernumber,
                        issuedate: item.issuedate,
                        expirydate: item.expirydate,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            } else if(this.state.type == 'skill_headers'){
                let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        mobile: item.mobile,
                        assessingbody: item.assessingbody,
                        referencenumber: item.referencenumber,
                        assessingdate: item.assessingdate,
                        expirydate: item.expirydate,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            } else if(this.state.type == 'police_headers'){
                let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        mobile: item.mobile,
                        issuingauthority: item.issuingauthority,
                        referencenumber: item.referencenumber,
                        assessingdate: item.assessingdate,
                        expirydate: item.expirydate,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            } else if(this.state.type == 'fee'){
                let options = res.data.data['data'].map(function (item) {
                    return { 
                        index: item.index,
                        name: item.name,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedReport: options})
            }
        },(err) => {
            console.log(err);
        });
    }
     //CallBack function to get changed status name and value and call api to save results
    handleCallback = (value, name) => {
        this.setState({reports:[]});
        if(name === 'sDate'){
            this.setState({days: null});
            this.setState({sDate: value})
        } else if(name === 'eDate'){
            this.setState({days: null});
            this.setState({eDate: value})
        } else if(name === 'days'){
            this.setState({sDate: null, eDate:null});
            this.setState({days: value})
        } else if(name === 'type'){
            if(value !== 'course' && this.state.type == 'course'){
                this.setState({stype : null});
            }
            this.setState({type: value})
        } else if(name === 'sortby'){
            this.setState({stype: value})
        }
    }
     // CallBack function handle pagination
    handlePaginationCallback = (i, perPage) =>{
        if(perPage && perPage != ''){
            this.setState({ perPage : perPage, currentPage:1}, () => {  this.generateReport();  }); 
        } else {this.setState({ currentPage : i}, () => { this.generateReport(); }); }
    }
     //Submit form and call api to fetch results
     onSubmit(e) {
        e.preventDefault();
        if(this.state.type){
            this.generateReport();
        }
     }
     onClear = () => {
        this.setState({
            sDate:null, eDate:null, days:null, type:null, stype:null, counseling:[],
            totalItem: '', perPage: 10, reports: [], lastPage: '', perItem: '', currentPage:1
        })
    }
     // Table sorting
     sorting = (col) => {
        const sortResult = Sorting(this.state.reports, col, this.state.order)
        this.setState({order: sortResult[0], reports: sortResult[1]})
     }
        //Search Api
     handleSearchCallback = (keyword) => {
        if(keyword){
            this.setState({search: keyword});
            let currentPage = this.state.currentPage;
            let perPage = this.state.perPage;
            let $startDate = this.state.sDate;
            let $endDate = this.state.eDate;
            let $days = this.state.days;
            let $type= this.state.type;
            let $stype = this.state.stype;
            let url = `search/reports/${keyword}/${$days}/${$startDate}/${$endDate}/${$type}/${$stype}`;
            view_ApiManager(url, currentPage, perPage,(res) => {
                console.log('search result',res);
                this.setState({
                    totalItem: res.data.data['total'],
                    perPage: res.data.data['per_page'],
                    reports: res.data.data['data'],
                    lastPage: res.data.data['last_page'],
                    perItem: res.data.data['data'].length,
                });
            },(err) => {
                console.log(err);
            });
        } else{
            this.setState({currentPage:1});
            this.generateReport();
        }
    }

 render(){
    const role = JSON.parse(localStorage.getItem('user_role'));
    let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
    var headers = '';
    if(this.state.type == 'course'){ headers =  this.state.headers;  }
    else if(this.state.type == 'visa'){ headers =  this.state.visa_headers;  }
    else if(this.state.type == 'passport'){ headers =  this.state.passport_headers;  } 
    else if(this.state.type == 'license'){ headers =  this.state.license_headers;  } 
    else if(this.state.type == 'oshc'){ headers =  this.state.oshc_headers ;  }   
    else if(this.state.type == 'skill'){ headers =  this.state.skill_headers;  } 
    else if(this.state.type == 'police'){ headers =  this.state.police_headers;  }  
    else if(this.state.type == 'ielts'){ headers =  this.state.test_headers;  } 
    else if(this.state.type == 'health'){ headers =  this.state.health_headers;  }
    else if(this.state.type == 'fee'){ headers =  this.state.fee_headers;  } 
    else if(this.state.type == 'commissions' && this.state.stype != 'overdue'){ headers =  this.state.commission_headers;  } 
    else if(this.state.type == 'commissions' && this.state.stype == 'overdue'){ headers =  this.state.commission_overdue_headers;  } 
    
    return(
        <>
         <Title title="View Reports" />
         <div className="row w-100 pl-3 pr-3 mb-5">
         {this.state.allPermissions['reports'] === 1 ?
         <>
         <div className="row p-3 bg-white border pt-2 mb-3">
         <SelectionBar 
          selectedsDate={this.state.sDate}
          selectedeDate={this.state.eDate}
          selectedDays={this.state.days} 
          selectedID={this.state.stype} 
          selectedType={this.state.type}
          stypeLabel={this.state.stype}
          data={this.state.allUsers}
          parentCallback = {this.handleCallback}
          type='report'
          />
         <div className='filter-settings'>
           {/* selected start date */}
         {this.state.sDate &&
         <div className='label dropDown-label'>
         {this.state.sDate} 
         <span onClick={() => this.setState({sDate:null})}><FaTimesCircle size={15} color={'#fffffff2'} /></span>
         </div>}

          {/* selected end date */}
         {this.state.eDate &&
         <div className='label dropDown-label'>
         {this.state.eDate} 
         <span onClick={() => this.setState({eDate:null})}><FaTimesCircle size={15} color={'#fffffff2'} /></span>
        </div>}

         {/* selected days */}
         {this.state.days &&
         <div className='label dropDown-label'>
         {this.state.days} days
         <span onClick={() => this.setState({days:null})}><FaTimesCircle size={15} color={'#fffffff2'} /></span>
         </div>}

         {/* selected type */}
         {this.state.type &&
         <div className='label dropDown-label'>
         {this.state.type}
         <span onClick={() => this.setState({type:null})}><FaTimesCircle size={15} color={'#fffffff2'} /></span>
         </div>}

         {/* selected sorty by */}
         {this.state.stype &&
         <div className='label dropDown-label'>
         {this.state.stype}
         <span onClick={() => this.setState({stype:null})}><FaTimesCircle size={15} color={'#fffffff2'} /></span>
         </div>}

        <div className='d-flex float-right'>
        <button onClick={(e) => this.onSubmit(e)} className="btn btn-success mt-2 pl-4 pr-4 mr-2">Search</button>
        <button type="button" onClick={() => this.onClear()} className="btn btn-primary pr-4 mt-2 pl-4">Reset</button>
        </div>

         </div>
         </div>
         <Search 
        client={true} 
        onSearchChange= {this.handleSearchCallback}   />
         {this.state.type && this.state.reports.length >0  ? 
         <>
         <table id="reports" className="table table-bordered bg-white table-responsive mb-2">
          {/* Headers */}
          <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>
         {head.title}</th>
         ))}
         </tr>
         </thead>
         {/* End Headers */}
         {this.state.type == 'course' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index++}>
         <td className='text-center'>{count++}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-left'>{value.coursename}</td>
         <td className='text-center'>{value.mobile}</td>
         <td className='text-center w-10'><small>{value.startdate ? moment(value.startdate).format('D MMMM YYYY') : '--'}</small></td>
         <td className='text-center'><small>{value.enddate ? moment(value.enddate).format('D MMMM YYYY') : '--' }</small></td>
         </tr>
         )}
         </tbody> 
         : 
         this.state.type == 'visa' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-center'>{value.visatype ? value.visatype : '--'}</td>
         <td className='text-center'>{value.country ? value.country : '--'}</td>
         <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>
         <td className='text-center w-15'>{value.issuedate ? value.issuedate : '--'}</td>
         <td className='text-center w-15'>{value.expirydate ? value.expirydate : '--'}</td>
         <td className='text-center w-15'><small>{moment(value.created_at).format('D MMMM YYYY')}</small></td>
         </tr>
         )}
         </tbody> 
         :   
         this.state.type == 'passport' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>
         <td className='text-center'>{value.passportno ? value.passportno : '--'}</td>
         <td className='text-center'>{value.country ? value.country : '--'}</td>
         <td className='text-center'>{value.issuedate ? value.issuedate: '--'}</td>
         <td className='text-center'>{value.expirydate ? value.expirydate: '--'}</td>
         <td className='text-center'><small>{moment(value.created_at).format('D MMMM YYYY')}</small></td>
         </tr>
         )}
         </tbody>
         :
         this.state.type == 'license' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-clie' , dataKey: "mobile", align: "text-center", key:3},
                {title: 'nt-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>
         <td className='text-center'>{value.licenseno ? value.licenseno : '--'}</td>
         <td className='text-center'>{value.expirydate ? value.expirydate: '--'}</td>
         <td className='text-center'><small>{moment(value.created_at).format('D MMMM YYYY')}</small></td>
         </tr>
         )}
         </tbody>
         : 
         this.state.type == 'oshc' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>
         <td className='text-center'>{value.providername ? value.providername : '--'}</td>
         <td className='text-center'>{value.membernumber ? value.membernumber: '--'}</td>
         <td className='text-center'>{value.issuedate ? moment(value.issuedate).format('D MMMM YYYY'): '--'}</td>
         <td className='text-center'>{value.expirydate ? moment(value.expirydate).format('D MMMM YYYY'): '--'}</td>
         </tr>
         )}
         </tbody>
         :
         this.state.type == 'skill' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>
         <td className='text-center'>{value.assessingbody ? value.assessingbody : '--'}</td>
         <td className='text-center'>{value.referencenumber ? value.referencenumber: '--'}</td>
         <td className='text-center'>{value.assessingdate ? moment(value.assessingdate).format('D MMMM YYYY'): '--'}</td>
         <td className='text-center'>{value.expirydate ? moment(value.expirydate).format('D MMMM YYYY'): '--'}</td>
         </tr>
         )}
         </tbody>
         :
         this.state.type == 'police' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>
         <td className='text-center'>{value.issuingauthority ? value.issuingauthority: '--'}</td>
         <td className='text-center'>{value.referencenumber ? value.referencenumber: '--'}</td>
         <td className='text-center'>{value.issuedate ? moment(value.issuedate).format('D MMMM YYYY'): '--'}</td>
         <td className='text-center'>{value.expirydate ? moment(value.expirydate).format('D MMMM YYYY'): '--'}</td>
         </tr>
         )}
         </tbody>
         :
         this.state.type == 'ielts' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>
         <td className='text-center'>{value.testtype ? value.testtype: '--'}</td>
         <td className='text-center'>{value.referencenumber ? value.referencenumber: '--'}</td>
         <td className='text-center'>{value.issuedate ? moment(value.issuedate).format('D MMMM YYYY'): '--'}</td>
         <td className='text-center'>{value.expirydate ? moment(value.expirydate).format('D MMMM YYYY'): '--'}</td>
         </tr>
         )}
         </tbody>
         :
         this.state.type == 'health' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         value.oshc &&
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-center'>{value.oshc ? value.oshc['providername'] : '' }</td>
         <td className='text-center'>{value.oshc ? value.oshc['amount'] : '' }</td>
         <td className='text-center'>{value.oshc ? value.oshc['issuedate'] : '' }</td>
         <td className='text-center'>{value.oshc ? value.oshc['expirydate'] : '' }</td>
         <td className='text-center'>{value.health_refund == 1 && value.health_applied != 1 ? 'Apply' :
         value.health_refund == 1 && value.health_applied == 1 ? 'Applied' : 'N/A'}</td>
         </tr>
         )}
         </tbody>
         :
         this.state.type == 'fee' ?
         <tbody>
         {this.state.reports.map((value, index) => 
         <tr key={index}>
         <td className='text-center'>{++index}</td>
         <td className='text-left'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.name}</Link></td>
         <td className='text-left'> {value.categoryname}</td>
         <td className='text-left'> {value.coursename}</td>
         <td className='text-center'>{value.tution_refund == 1 && value.refund_applied != 1 ? 'Apply' :
          value.refund_applied == 1 ? 'Applied' : 'N/A'}</td>
         </tr>
         )}
         </tbody>
          :''
         }
         </table>
         </>:''}
         <Paginations
            currentPage= {this.state.currentPage}
            total= {this.state.totalItem} 
            itemsPerPage= {this.state.perPage}
            lastPage={this.state.lastPage}
            totalItems={this.state.totalItem}
            perItem={this.state.perItem}
            onPageChange= {this.handlePaginationCallback} 
        />
           {/* Exports */}
        {role === 2 &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Reports.pdf'} 
         csvfilename={'Reports.csv'} 
         header="Reports List"
         xlsxfilename={'Reports'} 
         columns={this.state.type == 'course' ? this.state.headers :
         this.state.type == 'visa' ? this.state.visa_headers :
         this.state.type == 'passport' ? this.state.passport_headers :
         this.state.type == 'license' ? this.state.license_headers :
         this.state.type == 'oshc' ? this.state.oshc_headers : 
         this.state.type == 'skill' ? this.state.skill_headers : 
         this.state.type == 'police' ? this.state.police_headers : 
         this.state.type == 'ielts' ? this.state.test_headers :''}
         data={this.state.selectedReport} />
         </div>}
        {/* End Exports */}
         </>
        : 'You dont have permission to access this pages!'}
         </div>
        </>
    )
  }
}
export default Reports;


import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

class CustomColumnsModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedColumns: [],
            duplicate:'',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleModal(){
        this.props.parentCallback(''); 
    } 
    handleSubmit() {
        this.props.parentCallback('add', this.state.selectedColumns, this.state.duplicate);
    }
      // handle input change
    handleInputChange(checked, i) {
        const list = [...this.props.allcolumns];
        list[i]['checked'] = checked;
        this.setState({selectedColumns: list}) 
    };

  render(){ 
    return(
        <>
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={this.props.show} onHide={()=>this.handleModal()}>  
            <Modal.Header closeButton>
            <div className="text-center col-11 font-f-sans white">Set Columns</div>
            </Modal.Header>  

            <Modal.Body className="ml-2">
            <div className="row">
            <div className="form-group row col-11">
            <label className="font-f-monst-sans bold pb-2">Select Columns to show in table</label>
            {this.props.allcolumns.map((value, i) => {
            return(
            value.canChange === true &&
            <div className="col-4" key={i}>
            <div className="form-check">
            <input className="form-check-input" name="value1" type="checkbox" 
            defaultChecked={value.checked}
            onChange={e => this.handleInputChange(e.target.checked, i) } />
            <label className="form-check-label">{value.title}</label>
            </div>
            </div>)})}
            </div>

            {this.props.showDuplicateEntry &&
            <>
            <div className="form-check mt-3">
            <input className="form-check-input" name="duplicate" type="checkbox" 
            defaultChecked={this.props.defaultDuplicate}
            onChange={e => this.setState({duplicate: e.target.checked}) } />
            <label className="form-check-label bold">Show Duplicate Enteries</label>
            </div>
            </>}
            </div>
            </Modal.Body>  
            <Modal.Footer>  
            <Button className="btn btn-danger" onClick={()=>this.handleModal()}> Cancel </Button>  
            <Button className="btn btn-success" onClick={this.handleSubmit}> Add </Button>  
            </Modal.Footer>  
        </Modal>
        </>
    )
    }
}
export default CustomColumnsModel
import React from 'react';

const Footer =() => {
    return(
    <footer className="page-footer font-small">

    <div className="footer-copyright text-center py-2">Copyright ©
    <a href="https://aptitudemigration.com.au/"> Aptitude Migration</a>.2021
    </div>


    </footer>
    )
}
export default Footer;
import React from "react";
import Spinner from '../../assets/loader.gif';

const Loader = () => {
    return(
    <div className="divLoader">
        <img src={Spinner} width="100" className="fp-loader" alt="loading" />
    </div>
    )
}
export default Loader;

import React, {useEffect, useState} from "react"
import './search.css'
import moment from 'moment';
import { educationStatus, visaStatus } from "../../utilis/appStatus";
import * as FcIcons from 'react-icons/fc';
import CustomColumnsModel from "./CustomColumnsModel";
import Multiselect from "multiselect-react-dropdown";
import {active_status, expo_branches, genericDiscipline } from "../../utilis/constants";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompass } from "@fortawesome/free-solid-svg-icons";

const MultipleSearch = ({onSearchChange, onColumnsChange, input1Data, input2Data, input3Data,input4Data, allColumns, input1, input1dropdown, input2, input2Date, input2Search,input3Search,input4Search, input2Category, input2value, input3, input3Notefordropdown,  input3dropdown, input3Date, input4, inputGender, input5, input5Userdropdown, input5Date, input5dropdown, input5countrydropdown, input6Statuses, input6Date, input6studyarea, input6MigStatus, input6EduStatus, input6, input6Agents, input7, allUsers, allAgents, input7Agents, input8dropdown, input8Status, status, input7branchdropdown , input9dropdown,input10dropdown, events, input1Search, showDuplicateEntry, duplicate, selectedStatus, input9Users },) => {
  const [openCustomColumnsModel , setOpenCustomColumnsModel] = useState(false);
  const [startDate, setStartDate] = useState('');
  const animatedComponents = makeAnimated();

  useEffect(() => {

  },[])
  
    // Get values of completed note from modal call back and call change status api function
    const handleModelCallback = (edit, selectedColumns, duplicate) => {
        if(edit === 'add'){
            onColumnsChange(selectedColumns, duplicate);
            setOpenCustomColumnsModel(false);
        } else{
            setOpenCustomColumnsModel(false);
        }
     }

     const onUserSelect = (selectedList) => {
        onSearchChange(selectedList, 'input5');
     }
     const onUserRemove = (selectedList) => {
        onSearchChange(selectedList, 'input5');
     }
     const onAgentSelect = (selectedList) => {
        onSearchChange(selectedList, 'input6');
     }
     const onAgentRemove = (selectedList) => {
        onSearchChange(selectedList, 'input6');
     }
     const onAgent2Select = (selectedList) => {
      onSearchChange(selectedList, 'input7');
    }
     const onAgent2Remove = (selectedList) => {
      onSearchChange(selectedList, 'input7');
    }
    const onAssignedToSelect = (selectedList) => {
      onSearchChange(selectedList, 'input8');
    }
     const onAssignedToRemove = (selectedList) => {
      onSearchChange(selectedList, 'input8');
    }
    const onAssignedToSelect9 = (selectedList) => {
      onSearchChange(selectedList, 'input9');
    }
     const onAssignedToRemove9 = (selectedList) => {
      onSearchChange(selectedList, 'input9');
    }
    const handleDate = (value, input) => {
      setStartDate(value);
      onSearchChange(value, input)
    }
    const clearAll = () => {
      onSearchChange('', 'clear')
    }
    // Get Handle Selected Intakes Options
    const handleChange = (selectedOption) => {
      onSearchChange(selectedOption.label, 'input1')
    }
    const handleChange2 = (selectedOption) => {
      onSearchChange(selectedOption.label, 'input2')
    }
    const handleChange3 = (selectedOption) => {
      onSearchChange(selectedOption.label, 'input3')
    }
    const handleChange4 = (selectedOption) => {
      onSearchChange(selectedOption.label, 'input4')
    }

    return(
        <>
        <div className="row mb-3">
        <form >
        <div className="row inner-form py-2 px-3">
        <div className="d-flex j-content-right">
        {allColumns &&
        <div className="pt-0 pointer pr-1">
        <FcIcons.FcAutomatic className="mb-1" onClick={() => setOpenCustomColumnsModel(true)} size={20}  />
         </div>}
        {allColumns &&
        <div className="pt-0 pointer pr-1">
        <div className="size-13 pt-1 font-sans" style={{color:'#4a4aff'}} onClick={() => clearAll()}> Clear All </div>
        </div>}
         </div>
      
        {input1 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" 
        placeholder={input1} onChange={(e) => onSearchChange(e.target.value, 'input1')} />
        </div>}

        {input1Search &&
        <div className='col-lg-3 col-6 pr-2 mt-1'>
        <Select
        className="app-form days px-0"
        placeholder={input1Search}
        closeMenuOnSelect={true}
        components={animatedComponents}
        onChange={handleChange}
        options={input1Data}
        />
        </div>}

        {input1dropdown &&
        <div className='col-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input1')} className="form-select app-form mt-1 w-lg-90 days">
        <option value="">Please Select</option>
        <option value={1}>Migration</option>
        <option value={2}>Education</option>        
        </select>
        </div>}

        {input2 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" 
        placeholder={input2} onChange={(e) => onSearchChange(e.target.value, 'input2')} />
        </div>}

        {input2Search &&
        <div className='col-lg-3 col-6 pr-2 mt-1'>
        <Select
        className="app-form days px-0"
        placeholder={input2Search}
        closeMenuOnSelect={true}
        components={animatedComponents}
        onChange={handleChange2}
        options={input2Data}
        />
        </div>}

        {input2Date &&
        <div className='col-lg-3 col-6'>
        <div className="d-flex">
        <label className='mt-2 acc-label2'>{input2Date}</label>
        <input type="date" className="date-input search m-1 shadow-sm" 
        placeholder={input2} onChange={(e) => onSearchChange(e.target.value, 'input2')} />
        </div>
        </div>}

        {input2Category &&
        <div className='col-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input2')} className="app-form form-select mt-1 w-lg-90">
        <option value="">{input2Category}</option>
        <option value="Education">Education</option>
        <option value="Migration">Migration</option>        
        </select>
        </div>}

        {input3 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" 
        placeholder={input3} onChange={(e) => onSearchChange(e.target.value, 'input3')} />
        </div>}

        {input3Search &&
        <div className='col-lg-3 col-6 pr-2 mt-1'>
        <Select
        className="app-form days px-0"
        placeholder={input3Search}
        closeMenuOnSelect={true}
        components={animatedComponents}
        onChange={handleChange3}
        options={input3Data}
        />
        </div>}

        {input3Date &&
        <div className='col-lg-3 col-6'>
        <div className="d-flex">
        <label className='mt-2 acc-label2'>{input3Date}</label>
        <input type="date" min={moment(input2value).format('YYYY-MM-DD')} className="date-input search m-1 shadow-sm" 
        placeholder={input3} onChange={(e) => onSearchChange(e.target.value, 'input3')} />
        </div>
        </div>}

        {input3Notefordropdown &&
       <div className='col-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input3')} className="app-form form-select mt-1 w-lg-90">
        <option value="">Please NoteFor</option>
        <option value="clients">Clients</option>
        <option value="edu">Education</option>
        <option value="mig">Migration</option>  
        <option value="providers">Providers</option>        
        </select>
        </div>}

        {input3dropdown &&
        <div className='col-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input3')} className="days form-select mt-1">
        <option value="">Please Select</option>
        <option value={'0'}>Admin</option>
        <option value={'1'}>User</option>
        <option value={'2'}>Super Admin</option>        
        </select>
        </div>}

        {input4 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" 
        placeholder={input4} onChange={(e) => onSearchChange(e.target.value, 'input4')} />
        </div>}

        {input4Search &&
        <div className='col-lg-3 col-6 pr-2 mt-1'>
        <Select
        className="app-form days px-0"
        placeholder={input4Search}
        closeMenuOnSelect={true}
        components={animatedComponents}
        onChange={handleChange4}
        options={input4Data}
        />
        </div>}

        {inputGender &&
        <div className='col-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'gender')} className="form-select app-form mt-1 w-lg-90" style={{color:'#000000a6'}}>
        <option value="">Select Gender</option>
        <option value={0}>Male</option>
        <option value={1}>Female</option>      
        </select>
        </div>}

        {input5 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" 
        placeholder={input5} onChange={(e) => onSearchChange(e.target.value, 'input5')} />
        </div>}

        {input5dropdown &&
        <div className='col-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input5')} className="form-select app-form mt-1" name="email" >
        <option value="">Please Select</option>
        <option value='pending'>Pending</option>
        <option value='completed'>Completed</option>        
        </select>
        </div>}

        {input5Userdropdown &&
        <div className='col-lg-2 pr-lg-2 col-6'>
        <Multiselect
        options={[].concat(allUsers).sort((a, b) => a.name > b.name ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide"
        placeholder={input5Userdropdown}
        displayValue="name"
        onSelect={onUserSelect}
        onRemove={onUserRemove}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input5countrydropdown &&
        <div className='col-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input5')} className="form-select app-form mt-1 w-lg-90" name="input5" >
        <option value="">{input5countrydropdown}</option>
        <option>Australia</option>                                
        <option>UK</option>
        <option>USA</option>        
        </select></div>}

        {input5Date &&
        <div className='col-lg-3 col-6'>
        <div className="d-flex mt-1">
        <label className='mt-2 acc-label2'>{input5Date}</label>
        <input type="date" className="date-input search m-1 shadow-sm" 
        placeholder={input5Date} onChange={(e) => handleDate(e.target.value, 'input5')} />
        </div>
        </div>}

        {input6Statuses &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={[].concat(status).sort((a, b) => a.name > b.name ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide"
        placeholder={input6Statuses}
        displayValue="title"
        onSelect={onAgentSelect}
        onRemove={onAgentRemove}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input6Date &&
        <div className='col-lg-3 col-6'>
        <div className="d-flex mt-1">
        <label className='mt-2 acc-label2'>{input6Date}</label>
        <input type="date" min={startDate} className="date-input search m-1 shadow-sm" 
        placeholder={input6Date} onChange={(e) => onSearchChange(e.target.value, 'input6')} />
        </div>
        </div>}

        {input6MigStatus &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={[].concat(visaStatus).sort((a, b) => a.title > b.title ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide w-lg-90"
        placeholder={input6MigStatus}
        displayValue="title"
        onSelect={onAgentSelect}
        onRemove={onAgentSelect}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input6EduStatus &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={[].concat(educationStatus).sort((a, b) => a.title > b.title ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide w-lg-90"
        placeholder={input6EduStatus}
        displayValue="title"
        onSelect={onAgentSelect}
        onRemove={onAgentSelect}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input6studyarea &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={genericDiscipline}
        placeholder={input6studyarea}
        className="form-select app-form multi mt-1 pl-1 chip-hide w-lg-90"
        displayValue="title"
        onSelect={onAgentSelect}
        onRemove={onAgentSelect}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input6Agents &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={[].concat(allAgents).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide w-lg-90"
        placeholder="Select SuperAgents"
        displayValue="agencyname"
        onSelect={onAgentSelect}
        onRemove={onAgentSelect}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}
        
        {input6 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" 
        placeholder={input6} onChange={(e) => onSearchChange(e.target.value, 'input6')} />
        </div>}

        {input7 &&
        <div className='col-lg-2 col-6'>
        <input type="text" className="form-control search bg-white w-lg-90 m-1 shadow-sm" 
        placeholder={input7} onChange={(e) => onSearchChange(e.target.value, 'input7')} />
        </div>}

        {input7branchdropdown && 
        <div className='col-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input7')}
        className="form-select app-form mt-1">
        <option value=''>{input7branchdropdown}</option> 
        {[].concat(expo_branches).sort((a, b) => a.value > b.value ? 1 : -1).map(value => 
        <option
        key={value.value} value={value.title}>{value.title}</option>
        )}
        </select>
        </div>}

        {input7Agents &&
        <div className='col-lg-2 col-6'>
        <Multiselect
        options={[].concat(allAgents).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide w-lg-90"
        placeholder="Select SubAgents"
        displayValue="agencyname"
        onSelect={onAgent2Select}
        onRemove={onAgent2Remove}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input8dropdown &&
        <div className='col-lg-2 pr-lg-2 col-6'>
        <Multiselect
        options={[].concat(allUsers).sort((a, b) => a.name > b.name ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide ml-1 index-5"
        placeholder={input8dropdown}
        displayValue="name"
        onSelect={onAssignedToSelect}
        onRemove={onAssignedToRemove}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input8Status &&
        <div className='col-lg-2 col-6 pr-lg-2'>
        <Multiselect
        options={[].concat(status).sort((a, b) => a.title > b.title ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide ml-1"
        placeholder={input8Status}
        displayValue="title"
        onSelect={onAssignedToSelect}
        onRemove={onAssignedToRemove}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input9Users &&
        <div className='col-lg-2 pr-lg-2 col-6'>
        <Multiselect
        options={[].concat(allUsers).sort((a, b) => a.name > b.name ? 1 : -1)}
        className="form-select app-form multi mt-1 pl-1 chip-hide ml-1 index-5"
        placeholder={input9Users}
        displayValue="name"
        onSelect={onAssignedToSelect9}
        onRemove={onAssignedToRemove9}
        showCheckbox={true}
        disablePreSelectedValues={true}
        hidePlaceholder={false}
        />
        </div>}

        {input9dropdown &&
        <div className='col-lg-2 pr-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input9')}
        className="form-select app-form mt-1">
        <option value=''>{input9dropdown}</option> 
        {[].concat(expo_branches).sort((a, b) => a.value > b.value ? 1 : -1).map(value => 
        <option
        key={value.value} value={value.title}>{value.title}</option>
        )}
        </select>
        </div>}

        {input10dropdown &&
        <div className='col-lg-2 pr-lg-2 col-6'>
        <select onChange={(e) => onSearchChange(e.target.value, 'input10')}
        className="form-select app-form mt-1">
        <option value=''>{input10dropdown}</option> 
        {events.map(value => 
        <option key={value.name} value={value.name}>{value.name}</option>
        )}
        </select>
        </div>}

        {selectedStatus &&
        <>
        <div className='col-lg-2'>
        <div className='input-group form-group mt-1'>
        <div className="input-group-prependd">
            <span className="input-group-text ml-0"><FontAwesomeIcon icon={faCompass} /></span>
        </div>
        <select onChange={(e) => onSearchChange(e.target.value, 'activestatus')} name="status" className="form-select app-form pb-1">
        <option value="" disabled>Select App Status</option>
        {active_status.map(value => 
        <option selected={selectedStatus == value.title ? true : false}
         key={value.title} className="bg-grey light">{value.title}</option>
        )}
        </select>
        </div>
        </div>
        </>}
        
      
        </div>
        </form>
        </div>
        {allColumns &&
        <CustomColumnsModel
        show={openCustomColumnsModel}
        parentCallback={handleModelCallback}
        allcolumns={allColumns}
        defaultDuplicate={duplicate}
        showDuplicateEntry={showDuplicateEntry}
        />
        }
        </>
    )
}
export default MultipleSearch;
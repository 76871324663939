import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as BiIcons from 'react-icons/bi';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <FaIcons.FaHome />,
  },
  {
    title: 'Counseling',
    path: '/counseling',
    icon: <FaIcons.FaAccusoft />,
  },
  {
    title: 'Manage Accounts',
    path: '/accounts',
    icon: <FaIcons.FaUsersCog />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Add Accounts',
        path: '/accounts/add-account/',
        icon: <BiIcons.BiUserPlus />,
        cName: 'sub-nav'
      },
      {
        title: 'View Accounts',
        path: '/accounts/view-accounts',
        icon: <BiIcons.BiUserPin />,
        cName: 'sub-nav'
      },
    ]
  },
  {
    title: 'Manage Agents',
    path: '/agents',
    icon: <BiIcons.BiStreetView />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Add Agents',
        path: '/agents/add-agent',
        icon: <BiIcons.BiUserPlus />,
        cName: 'sub-nav'
      },
      {
        title: 'View Agents',
        path: '/agents/view-agents',
        icon: <BiIcons.BiUserPin />,
        cName: 'sub-nav'
      },
    ]
  },
  {
    title: 'Manage Clients',
    path: '/team',
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Add Clients',
        path: '/clients/add-client',
        icon: <BiIcons.BiUserPlus />,
        cName: 'sub-nav'
      },
      {
        title: 'View Clients',
        path: '/clients/view-clients',
        icon: <FaIcons.FaUsers/>,
        cName: 'sub-nav'
      },
      {
        title: 'View External Clients',
        path: `/external-clients/${'total'}`,
        icon: <FaIcons.FaUserAlt/>,
        cName: 'sub-nav'
      },
      {
        title: 'My Clients',
        path: '/clients/my-clients',
        icon: <FaIcons.FaUserFriends />,
        cName: 'sub-nav'
      },
      {
        title: 'Assign Clients',
        path: '/clients/assign-clients',
        icon: <BiIcons.BiMessageSquareCheck />,
        cName: 'sub-nav'
      },
      {
        title: 'Follow-ups',
        path: `/view-all-followups/${'all'}`,
        icon: <BiIcons.BiPhoneOutgoing />,
        cName: 'sub-nav'
      },
      {
        title: 'My Follow-ups',
        path: `/view-all-followups/${'my'}`,
        icon: <BiIcons.BiPhone/>,
        cName: 'sub-nav'
      },
      {
        title: 'View Leads',
        path: '/clients/view-leads',
        icon: <BiIcons.BiShieldQuarter />,
        cName: 'sub-nav'
      },
      {
        title: 'Reports',
        path: '/clients/reports',
        icon: <BiIcons.BiClipboard />,
        cName: 'sub-nav'
      },
    ]
  },
  {
    title: 'Manage Applications',
    path: '/messages',
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'My Applications',
        path: '/applications/main-applications',
        icon: <FaIcons.FaEnvelopeOpenText />
      },
      {
        title: 'Assign Applications',
        path: '/applications/assign-applications',
        icon: <FaIcons.FaExchangeAlt />
      }
    ]
  },
  {
    title: 'Manage Appointments',
    path: '/appointments',
    icon: <FaIcons.FaRegCalendarAlt/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Add Appointments',
        path: `/appointments/add-appointment/${'add'}`,
        icon: <FaIcons.FaRegCalendarPlus />
      },
      {
        title: 'View Appointments',
        path: '/appointments/view-appointments',
        icon: <FaIcons.FaRegCalendarCheck />
      },
      {
        title: 'My Appointments',
        path: '/appointments/view-my-appointments',
        icon: <FaIcons.FaRegCalendar />
      }
    ]
  },
  {
    title: 'Manage Providers',
    path: '/support',
    icon: <FaIcons.FaBoxOpen/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Add Provider',
        path: '/providers/add-provider',
        icon: <FaIcons.FaSynagogue />
      },
      {
        title: 'View Providers',
        path: '/providers/view-providers',
        icon: <FaIcons.FaUniversity />
      },
      {
        title: 'Provider Setting',
        path: '/providers/settings',
        icon: <FaIcons.FaConnectdevelop />
      },
    ]
  },
  {
    title: 'Manage Finance',
    path: '/finance',
    icon: <FaIcons.FaMoneyCheckAlt/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'View Cost',
        path: '/finance/professtional-cost',
        icon: <FaIcons.FaCommentsDollar />
      },
      {
        title: 'View Commissions',
        path: '/finance/view-commissions',
        icon: <FaIcons.FaCommentsDollar />
      },
    ]
  },
  {
    title: 'Manage Branches',
    path: '/manageBranch',
    icon: <FaIcons.FaCodeBranch />,
  },
  {
    title: 'HR Management',
    path: '/hr',
    icon: <FaIcons.FaUserSecret/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Attendance',
        path: '/attendence',
        icon: <FaIcons.FaUserCheck />
      },
      {
        title: 'Manage Leaves',
        path: '/manage-leaves',
        icon: <FaIcons.FaPlaneDeparture />
      },
      {
        title: 'Manage Roles',
        path: '/permissions/user-role',
        icon: <FaIcons.FaUserInjured />
      },
      {
        title: 'Daily Reports',
        path: '/all-reports',
        icon: <FaIcons.FaDrawPolygon />
      },
      {
        title: 'Alerts',
        path: '/create-alerts',
        icon: <FaIcons.FaRegBell />
      },
    ]
  },
  {
    title: 'Leave',
    path: '/leave',
    icon: <FaIcons.FaPlaneDeparture/>,
  },
  {
    title: 'Events',
    path: '/events/main',
    icon: <FaIcons.FaLayerGroup/>,
  },
  {
    title: 'Help',
    path: '/s',
    icon: <IoIcons.IoMdHelpCircle />,
  }
];

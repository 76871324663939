
import React, { Component } from 'react'
import swal from 'sweetalert';
import Title from '../GenericComponents/Title';
import { get_ApiManager, post_ApiManager } from '../ApiManager/ApiManager';
import moment from 'moment';

class DailyTask extends Component {
    constructor(props) {
        super(props);
        var user = JSON.parse(localStorage.getItem('user'));
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            user: user,
            allTasks:[],
            role:'',
            taskStatus:'',
            stage: 0,
            report_id:'',
            userRemarks:'',
            managerRemarks:'',
            allPermissions: permissions,
              managerTask: [],
              tasks3: [
                { index: 1, task: '', managerChecked: false, status: '' },
                { index: 2, task: '', managerChecked: false, status: '' },
                { index: 3, task: '', managerChecked: false, status: '' },
                { index: 4, task: '', managerChecked: false, status: '' },
                { index: 5, task: '', managerChecked: false, status: '' },
                { index: 6, task: '', managerChecked: false, status: '' },
                { index: 7, task: '', managerChecked: false, status: '' },
                { index: 8, task: '', managerChecked: false, status: '' },
                { index: 9, task: '', managerChecked: false, status: '' },
                { index: 10, task: '', managerChecked: false, status: '' },
                // Continue adding tasks as needed...
            ],
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleTaskChange = this.handleTaskChange.bind(this);
        this.handleManagerTaskChange = this.handleManagerTaskChange.bind(this);
    }

    componentDidMount() {
        this.getRoleTask()
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
     }

    getRoleTask = () => {
        let url = `get-role-task/${this.state.user['id']}`;
        get_ApiManager('', url,(res) => {
            console.log('all-tasks',res);
            console.log(res.data.data['report'])
            if(res.data.data['report'] === 0){
                this.setState({allTasks: res.data.data['categories'], role: res.data.data['role'] });
            } else{
                if(res.data.data['stage'] > 1){
                    this.setState({
                        managerTask: res.data.data['managerTask']
                    });
                } 
                this.setState({
                    allTasks: res.data.data['categories'],
                    role: res.data.data['role'],
                    stage: res.data.data['stage'],
                    report_id: res.data.data['report_id'],
                    tasks3: res.data.data['selfTasks'],
                    userRemarks: res.data.data['userRemarks'],
                    managerRemarks: res.data.data['managerRemarks']
                });
            }
        },(err) => {
            console.log(err);
        });
    }

    handleInputChange(checked, task, taskIndex, categoryIndex) {
        // Update the task object with new status
        const updatedTask = { ...task, status: checked };
    
        // Update the allTasks array with the updated task
        this.setState(prevState => {
            const updatedTasks = prevState.allTasks.map((cat, index) => {
                if (index === categoryIndex) {
                    return {
                        ...cat,
                        tasks: cat.tasks.map((t, idx) => {
                            if (idx === taskIndex) {
                                return updatedTask;
                            }
                            return t;
                        })
                    };
                }
                return cat;
            });
            console.log(updatedTasks);
            return { allTasks: updatedTasks };
        });
    }

    handleTaskChange(index, fieldName, value) {
        this.setState(prevState => ({
            tasks3: prevState.tasks3.map((task, idx) => {
                if (idx === index) {
                    return { ...task, [fieldName]: value };
                }
                return task;
            })
        }), () => {
            console.log(this.state.tasks3);  // This will log the updated state
        });
    }

    handleManagerTaskChange(index, fieldName, value){
        this.setState(prevState => ({
            managerTask: prevState.managerTask.map((task, idx) => {
                if (idx === index) {
                    return { ...task, [fieldName]: value };
                }
                return task;
            })
        }), () => {
            console.log(this.state.managerTask);  // This will log the updated state
        });
    }


    submitReport(){
        const filteredTaskList3 = this.state.tasks3.filter(task => task.task.trim() !== '');
        if(this.state.stage === 0){
            if (filteredTaskList3.some(task => !task.time || task.time.trim() === '')) {
                swal("Error", "Please select estimated time of top daily basis tasks!", "error");
                return; // Stop the function from proceeding if validation fails
            }
        }
        if(this.state.stage === 1){
            if (filteredTaskList3.some(task => !task.status || task.status.trim() === '')) {
                swal("Error", "Please select task status of top daily basis tasks!", "error");
                return; // Stop the function from proceeding if validation fails
            }
        } 
        if(this.state.stage === 2 && this.state.allPermissions['daily_report_remarks'] === 1){
            if (this.state.managerTask.some(task => !task.status || task.status.trim() === '')) {
                swal("Error", "Please select task status of top daily basis tasks!", "error");
                return; // Stop the function from proceeding if validation fails
            } 
        }
        let url = 'submit-report';
        const data = {
            taskList: this.state.allTasks,
            taskList2: filteredTaskList3,
            userid: this.state.user['id'],
            roleid: this.state.user['user_role'],
            managerTask: this.state.managerTask,
            userRemarks: this.state.userRemarks, 
            managerRemarks: this.state.managerRemarks
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('report-submitted',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
                this.getRoleTask();
            } 
        },(err) => {
            console.log(err);
        });
    }
    addNewTask = () => {
        this.setState(prevState => ({
            tasks3: [
                ...prevState.tasks3,
                { 
                    index: prevState.tasks3.length + 1, 
                    task: '', 
                    managerChecked: false, 
                    status: ''
                }
            ]
        }));
    }
        
    render(){
        return(
            <>
            <Title title="Daily Worksheet"
            btnCol="col-lg-8 text-center pt-lg-1" 
            btn={true}
            btnText="View History" 
            btnClass="float-right btn btn-primary"
            btnUrl={`/user-reports/${this.state.user['id']}`}/>
            {this.state.user['user_role'] && (this.state.allTasks && this.state.allTasks[0])  ?
            <div className="mx-3 mt-2 mb-5">
            <h2 className='text-center pb-2 mb-0' style={{color: 'blue'}}>{moment().format('MMMM Do YYYY')}</h2>
            {this.state.allTasks.map((task, categoryIndex) => (
                <div key={categoryIndex}>
                    <h2 className='row text-center j-content-center p-2 size-22 mb-0' 
                        style={{background:'burlywood'}}>{task.category}</h2>
                    <table className="table table-bordered bg-white">
                        <thead>
                            <tr>
                                <th className='text-center size-15 w-10'>Sr No.</th>
                                <th className='size-15'>Operations</th>
                                <th className='text-center size-15 w-20'>{this.state.role}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {task.tasks.map((task1, taskIndex) => (
                                <tr key={taskIndex}>
                                    <td className='text-center size-16'>{taskIndex + 1}</td>
                                    <td className='size-16'>{task1.task}</td>
                                    <td className='text-center size-16'> 
                                        <input className="check-input ml-3" type="checkbox" disabled={this.state.stage !== 0 ? true : false}
                                            checked={task1.status || false} 
                                            onChange={e => this.handleInputChange(e.target.checked, task1, taskIndex, categoryIndex)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

            <h2 className='row text-center j-content-center p-2 size-22 mb-0' style={{background:'burlywood'}}>
            Top 10 Work done on Daily Basis
            </h2>
            <table className="table table-bordered mb-2 bg-white">
            <thead>
                <tr>
                    <th className='text-center size-15 w-10'>Sr No.</th>
                    <th className='size-15'>What Task To Do?</th>
                    <th className='text-center size-15 w-10'>Estimated Time</th>
                    <th className='text-center size-15 w-20'>Task Completed</th>
                </tr>
            </thead>
            <tbody>
            {this.state.tasks3.map((task, index) => (
                <tr key={task.id}>
                <td className='text-center size-16'>{task.index}</td>
                <td className='size-16'>
                    <input type="text" name='task' className="form-control" disabled={this.state.stage !== 0 ? true : false}
                        value={task.task || ''}  // Ensure it's a controlled component
                        onChange={(e) => this.handleTaskChange(index, 'task', e.target.value)} />
                </td>

                <td className='text-center size-16'>
                    <select value={task.time || ''}  // Ensure it's a controlled component
                            onChange={(e) => this.handleTaskChange(index, 'time', e.target.value)}
                            name='time' className="form-select acc-form w-100 mb-1" disabled={this.state.stage !== 0 ? true : false} >
                        <option value=''>Select</option>
                        <option value='10-min'>10 min</option>
                        <option value='15-min'>15 min</option>
                        <option value='30-min'>30 min</option>
                        <option value='45-min'>45 min</option>
                        <option value='1-hour'>1 hour</option>
                        <option value='1.5-hour'>1.5 hour</option>
                        <option value='2-hour'>2 hour</option>
                        <option value='2.5-hour'>2.5 hour</option>
                        <option value='3-hour'>3 hour</option>
                        <option value='3.5-hour'>3.5 hour</option>
                        <option value='4-hour'>4 hour</option>
                        <option value='4.5-hour'>4.5 hour</option>
                        <option value='5-hour'>5 hour</option>
                        <option value='5.5-hour'>5.5 hour</option>
                        <option value='6-hour'>6 hour</option>
                        <option value='6.5-hour'>6.5 hour</option>
                        <option value='7-hour'>7 hour</option>
                        <option value='7.5-hour'>7.5 hour</option>
                        <option value='8-hour'>8 hour</option>
                        <option value='8.5-hour'>8.5 hour</option>
                        <option value='9-hour'>9 hour</option>
                        <option value='9.5-hour'>9.5 hour</option>
                        <option value='10-hour'>10 hour</option>
                    </select>
                </td>
                    
                <td className='text-center size-16'>
                    <select value={task.status || ''}  // Ensure it's a controlled component
                            onChange={(e) => this.handleTaskChange(index, 'status', e.target.value)}
                            name='status' className="form-select acc-form w-100 mb-1" disabled={this.state.stage === 1 ? false : true} >
                        <option value=''>Please select</option>
                        <option value='completed'>Completed</option>
                        <option value='partially'>Partially Completed</option>
                        <option value='notCompleted'>Not Completed</option>
                    </select>
                </td>
                </tr>
            ))}

            </tbody>

            </table>
            <button className="btn btn-success mb-3" onClick={this.addNewTask}>
            Add New Task
            </button>
            {this.state.stage > 0  &&
            <div className='row border bg-white mt-3'>
            <div className="form-group p-4">
            <label className="acc-label2 mb-1">Leave Comment (optional)</label>
            <textarea value={this.state.userRemarks} className="textarea form-control acc-form" name="userRemarks" disabled={this.state.stage > 1 ? true : false}
             placeholder="Please Enter" onChange={this.handleChange} rows="7" cols="60"></textarea>
            </div>
            </div>}

            {this.state.stage > 1 &&
            <div className='mt-4'>
            <h2 className='row text-center j-content-center p-4 size-22 mb-0' 
                style={{background:'burlywood'}}>Manager Remarks</h2>
            <table className="table table-bordered bg-white">
                <thead>
                    <tr>
                        <th className='text-center size-15'>Sr No.</th>
                        <th className='size-15'>Operations</th>
                        <th className='text-center size-15 w-20'>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.managerTask.map((mtask, index) => (
                        <tr key={index}>
                            <td className='text-center size-16'>{index + 1}</td>
                            <td className='size-16'>{this.state.stage > 2 ? mtask.task : mtask.tasks}</td>
                            <td className='text-center size-16'> 
                            {mtask.type === 1 ?
                            <select value={mtask.status} onChange={(e) => this.handleManagerTaskChange(index, 'status', e.target.value)}
                                name='status' className="form-select acc-form w-100 mb-1" 
                                disabled={this.state.stage === 2 && this.state.allPermissions['daily_report_remarks'] === 1 ? false : true}>
                             <option value=''>Please select</option>
                             <option value='completed'>Completed</option>
                             <option value='partially'>Partially Completed</option>
                             <option value='notCompleted'>Not Completed</option>
                             </select>
                             :
                             mtask.type === 2 ?
                             <select value={mtask.status} onChange={(e) => this.handleManagerTaskChange(index, 'status', e.target.value)}
                                name='status' className="form-select acc-form w-100 mb-1" 
                                disabled={this.state.stage === 2 && this.state.allPermissions['daily_report_remarks'] === 1 ? false : true}>
                             <option value=''>Please select</option>
                             <option value='coordinated'>Coordinated</option>
                             <option value='notCordinated'>Not Coordinated</option>
                             </select>
                             :
                             mtask.type === 3 ?
                             <select value={mtask.status} onChange={(e) => this.handleManagerTaskChange(index, 'status', e.target.value)}
                                name='status' className="form-select acc-form w-100 mb-1" 
                                disabled={this.state.stage === 2 && this.state.allPermissions['daily_report_remarks'] === 1 ? false : true}>
                             <option value=''>Please select</option>
                             <option value='yes'>Yes</option>
                             <option value='no'>No</option>
                             </select>:''}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='row mt-3 border bg-white'>
            <div className="form-group p-4">
            <label className="acc-label2 mb-1">Manager's Remarks (optional)</label>
            <textarea className="textarea form-control acc-form" value={this.state.managerRemarks} disabled={this.state.stage === 2 && this.state.allPermissions['daily_report_remarks'] === 1 ? false : true}
            name="managerRemarks" placeholder="Please Enter" onChange={this.handleChange} rows="7" cols="60"></textarea>
            </div>
            </div>
            </div>}

            <div className='w-100 j-content-center text-center'>
        
           
            {this.state.stage === 1 ?  
            <button className='btn btn-info mb-5 mt-3 py-2 px-5' onClick={() => this.submitReport()}>
            Submit Final Report</button>
            : this.state.stage === 2 && this.state.allPermissions['daily_report_remarks'] === 1 ? 
            <button className='btn btn-info mb-5 mt-3 py-2 px-5' onClick={() => this.submitReport()}>
            Submit Manager's Remarks</button>
            :
            this.state.stage > 1 ? "" :
            <button className='btn btn-info mb-5 mt-3 py-2 px-5' onClick={() => this.submitReport()}>
            Submit Report</button>}
            </div>
                
            </div>
             :
            'No role assigned yet'}
            </>
        )
    }
}
export default DailyTask

import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import * as FaIcons from 'react-icons/fa';
import { Link } from 'react-router-dom';
import "../Dashboard/dashboard.css";
import { get_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';

class MainEvent extends Component {
    constructor(props) {
        super(props);
        var pageURL= window.location.pathname;
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            pageURL:pageURL,
            loading:false,
            allPermissions: permissions,
            selectedEvent:'',
            getMyFollowups:'',
            getAllFollowups:'',
            getMyFollowupsCompleted:'',
        }
    }
    componentDidMount() {
        this.getEvents();
        this.showExpoTotal();
    }

    async showExpoTotal(){
        let url = 'getExpoFollowupTotal';
        get_ApiManager('', url,(res) => {
          console.log('total-followups',res);
          this.setState({
            getMyFollowupsPending:res.data.data['myFollowUpsPending'],
            getMyFollowupsCompleted:res.data.data['myFollowUpsCompleted'],
            getAllFollowups:res.data.data['totalFollowUps'],
          });
        },(err) => {
            console.log(err);
        }); 
      }  

    async getEvents() {
        let url = 'getEvents';
        view_ApiManager(url, 1, 10,(res) => {
            console.log('expo-list',res);
            let selected = res.data.data['data'].find((element) => {
                return element.status == 'active';
              })
            this.setState({selectedEvent: selected['name']});
        },(err) => {
          console.log(err);
        });
    }
    render(){
        return(
        <>
        <div className="p-1">
        <Title back={true} title={"Events"} />
        <div className="pt-3 pl-lg-3 pr-lg-3 px-4 mb-1 row">
        {/* Expo 2022 */}
        {this.state.allPermissions['expo-2022'] === 1 &&
        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
        <div className="card dashboard-card w-100 h-100">
        <div className='upper h-100'>
            <div className="card-block h-100">
            <Link className="no-underline" to={'/expo2022/study-aborad-aug-sep'} >
            <div className="row text-center pb-lg-5 mb-lg-1">
            <div className="col-10">
            <h4 className="text-c-green bold" style={{fontSize:'17px', fontFamily:'Cinzel'}}>
            {this.state.selectedEvent ? this.state.selectedEvent : 'Events'}</h4> 
            </div>
            <div className="col-2 text-right pt-2">
            <FaIcons.FaLayerGroup className='dash-icon' size={26} color={'#000000b5'} />
           <br/>
            <>
            <small className="size-11 bold no-show" style={{color:'white'}} >a</small><br/>
            <div className="size-11 bold no-show" style={{color:'white'}} >s</div></>
            </div>
            </div>
            </Link>
            </div>
            <div className="card-footer dashboard-card-footer bg-green">
            </div>
            </div>
         </div>
         </div>}

         {/* Make List for Expo Consultant and Table */}
         {this.state.allPermissions['expo-consultant'] === 1 &&
        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
            <div className="card dashboard-card w-100 h-100">
            <div className='upper h-100'>
            <div className="card-block h-100">
            <Link className="no-underline" target="_blank" to={'/events/expo-consultant-list'}>
              <div className="row text-center pb-lg-5 mb-lg-1">
                <div className="col-10">
                  <h4 className="text-c-green main-app">Expo-Consultant List</h4>
                    </div>
                    <div className="col-2 text-right pt-2">
                      <FaIcons.FaYelp size={26} className='dash-icon' />
                      <br/>
                      <>
                      <small className="size-11 bold no-show" style={{color:'white'}} >a</small><br/>
                      <div className="size-11 bold no-show" style={{color:'white'}} >s</div></>
                    </div>
                 </div>
               </Link>
             </div>
            </div>
            <div className="card-footer footer bg-green">
            </div>
        </div>
         </div>}

         {this.state.allPermissions['expo-consultant'] === 1 &&
        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
        <div className="card dashboard-card w-100 h-100">
            <div className='upper h-100'>
            <div className="card-block h-100">
            <Link className="no-underline" target="_blank" to={'/events/create-event'}>
              <div className="row text-center pb-lg-5 mb-lg-1">
                <div className="col-10">
                  <h4 className="text-c-green main-app">Create New Event</h4>
                    </div>
                    <div className="col-2 text-right pt-2">
                      <FaIcons.FaYelp size={26} className='dash-icon' />
                      <br/>
                      <>
                      <small className="size-11 bold no-show" style={{color:'white'}} >a</small><br/>
                      <div className="size-11 bold no-show" style={{color:'white'}} >s</div></>
                    </div>
                 </div>
               </Link>
             </div>
            </div>
            <div className="card-footer footer bg-green">
            </div>
         </div>
         </div>}

         {this.state.allPermissions['expo-2022'] === 1 &&
        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
        <div className="card dashboard-card w-100 h-100">
            <div className='upper h-100'>
            <div className="card-block h-100">
            <Link className="no-underline" target="_blank" to={`/events/followup/${'mypending'}`}>
              <div className="row text-center pb-lg-5 mb-lg-1">
                <div className="col-10">
                  <h4 className="text-c-green main-app">My Followups</h4>
                  <h6 className="text-muted">{this.state.getMyFollowupsPending}</h6>
                    </div>
                    <div className="col-2 text-right pt-2">
                      <FaIcons.FaYelp size={26} className='dash-icon' />
                      <br/>
                      <>
                      <small className="size-11 bold no-show" style={{color:'white'}} >a</small><br/>
                      <div className="size-11 bold no-show" style={{color:'white'}} >s</div></>
                    </div>
                 </div>
                 </Link>
             </div>
            </div>
            <div className="card-footer footer bg-green">
            </div>
        </div>
      </div>} 

        </div>
        </div>
        </>
        )
    }
}
export default MainEvent
import React, { useState,useRef, useEffect} from 'react';
import AllNotification from './ViewNotification';
import { view_ApiManager } from '../ApiManager/ApiManager';
import './notification.css';
import {Tabs, Tab, Row, Col, Container} from "react-bootstrap";
import { Link } from 'react-router-dom';
import SeenStatus from './SeenStatus';

const Notification = (props) => {
    const ref = useRef();
    const [loading, setLoading] = useState(false);
    const [getAllNotification, setGetAllNotification] = useState([]);
    const [open, setOpen] = useState(false);
    const [perPage] = useState(40);
    
    useEffect(() => {  
        // On Click mark as seen
        if(open == true){ 
            const socket = props.socket;
            socket.emit("markSeen", () => {
            });
        }

        const checkIfClickedOutside = e => {
          if (open && ref.current && !ref.current.contains(e.target)) {
            setOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [open]);

     //Call View Notification Api
     const allNotification = async (e) => {
        let url = 'getAllNotifications';
        setLoading(true); 
            view_ApiManager(url, 1, perPage,(res) => {
            setLoading(false);
            console.log('view all Notification',res);
            setGetAllNotification(res.data.data['data']);
        },(err) => {
            setLoading(false);
            console.log(err);
        });
    }

     const handleIconClick = async (e) => {
        setOpen(!open)
        allNotification();
    }
    // call back function
     const handleCallback = (close) =>{
        if(close === 'true'){
            setOpen(false);
        }
     }


 return(
    <>
   <SeenStatus seenStatus={props.count}/>
   <div className='text-center d-flex j-content-center icon-container'>
    <li className="wrapper">
        <a className="icon-button svg" onClick ={() => handleIconClick()}>
        {props.icon} </a>
    </li>
    <span className="hover-text">Notification</span>
    </div>
    <div className={"modal n-modal fade" + (open ? "show d-block" : " d-none")} tabIndex="-1" role="dialog">
    <div className="modal-dialog n-modal-dialog " role="document">
        <div ref={ref} className="modal-content n-modal-content wrapper">
        {/* <a onClick ={() => setOpen(!open)} type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"> 
        <BiIcons.BiChevronRightCircle className="collapse-btn " type="button" size={26} color={'#000000b5'} /></span></a> */}
            <Container  className="overflow">
                 <Row className="w-100">
                     <Col>
                         <Tabs defaultActiveKey="home" id="controlled-tab-example" className="notify-tabs nav-tabs">
                             <Tab eventKey="home" title="Notifications" className="nav-tab ">
                             {loading ? 'Loading...' : <AllNotification getNotifications={getAllNotification}  parentCallback = {handleCallback} />}
                             <Link to={'/notifications/view-all'}>
                            <p onClick ={() => setOpen(!open)} className=" mb-3 pb-3 pt-3 font-f-monst-sans show-notify">Show All Notification</p>
                            </Link>
                             </Tab>
                         </Tabs>
                     </Col>
                 </Row>
             </Container>
             </div>
        </div>
    </div>
    </>
    )   
}

export default Notification;

import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { get_ApiManager, post_ApiManager } from '../ApiManager/ApiManager';
import swal from 'sweetalert';

class ExternalAgentModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            externalAgentError:'',
            externalAgent:'no',
            pExternalList:[],
            rExternalList:[],
            superAgent:'',
            subAgent:'',
            selectedProcessingAgent:'',
            selectedReferringAgent:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleModal(){
        this.setState({externalAgentError:'', externalAgent:'', selectedProcessingAgent:'',selectedReferringAgent:''});
        this.props.parentCallback(); 
    } 

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleValidation = (e) => {
        if(!this.state.externalAgent){
            this.setState({externalAgentError : 'Please select external link option'})
             return false 
        } else if(this.state.externalAgent === 'yes' && !this.state.selectedProcessingAgent && !this.state.selectedReferringAgent){
            this.setState({externalAgentError : 'Please select external agent'})
            return false 
        } else {
            return true;
        }
    }
    
    handleSubmit() {
        if (this.handleValidation()) {
            const data = {
                from: this.props.app,
                bulkList: this.props.bulkList,
                superagent: this.state.superAgent,
                subagent: this.state.subAgent,
                external_agent_exisit: this.state.externalAgent,
                external_processing_agent: this.state.selectedProcessingAgent,
                external_referring_agent: this.state.selectedReferringAgent
            }
            console.log(data);
            let url = 'externalAgentBulk';
            post_ApiManager(data, url, (res) => {
                console.log('external-agent' , res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    this.setState({selectedProcessingAgent:'',selectedReferringAgent:''})
                    swal("Success!", res.data.message , "success"); 
                    this.props.parentCallback(); 
                };   
            },(err) => {
                console.log(err);
            });
        }
    }
    // Get all information from apis 
    getExternalAgentList = (value) => {
        let url = 'getExternalAgents';
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('agentList',res);
            this.setState({
                rExternalList: res.data.data['referring'],
                pExternalList: res.data.data['processing']
            });
        },(err) => {
            console.log(err);
        })
    }

  render(){ 
    return(
        <>
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={this.props.show} onHide={()=>this.handleModal()}>  
            <Modal.Header closeButton> <div className="text-center col-11 font-f-sans white">External Agent</div></Modal.Header>  

            <Modal.Body className="ml-2">
            <div className="row">
            {/* External agent */}
            <div className="row">
            <label className="col-lg-9 font-f-monst-sans size-15 f-w-500 mb-0" for="extern">{this.props.app === 'clients' ? 'Does this client have an external agent?' : 'Does this application have an external agent?'}</label>
            <p className="col-auto mx-2 font-f-monst-sans f-w-500">
            <input type="radio" className="mr-1" name="external_agent" value="yes" checked={this.state.externalAgent === 'yes'}
            onChange={(e) => this.setState({externalAgent: e.target.value} , () => {this.getExternalAgentList()})} />Yes
            </p>
            <p className="col-auto mx-1 font-f-monst-sans f-w-500">
            <input type="radio" className="mr-1" name="external_agent" value="no" checked={this.state.externalAgent === 'no'}
            onChange={(e) => this.setState({externalAgent: e.target.value})} />No
            </p>
            {this.state.externalAgentError && <><small className="error">{this.state.externalAgentError}</small></>}
            </div>
            {/* External agent options*/}
            {this.state.externalAgent === 'yes' &&
            <div className="row mt-3">
            {/* External Referring Agent */}
            <div className='col-5'>
            <label className="acc-label2 mb-1">External Referring Agent</label>
            </div>
            <div className='col-6'>
            <select onChange={(e) => this.setState({selectedReferringAgent: e.target.value})} className="form-select acc-form form-select-lg col-lg-11" name="subagent" >
            <option value="">Please Select</option>
            {[].concat(this.state.rExternalList).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(head => 
            <option key={head.id} value={head.id}>{head.agencyname}</option>
            )}
            </select>
            </div>
             {/* External Processing Agent */}
             <div className='row mt-3 mb-2'>
            <div className='col-5'>
            <label className="acc-label2 mb-1">External Processing Agent</label>
            </div>
            <div className='col-6'>
            <select onChange={(e) => this.setState({selectedProcessingAgent: e.target.value})} className="form-select acc-form form-select-lg col-lg-11" name="subagent" >
            <option value="">Please Select</option>
            {[].concat(this.state.pExternalList).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(head => 
            <option key={head.id} value={head.id}>{head.agencyname}</option>
            )}
            </select>
            </div>
            </div>
            </div>}

            {this.props.allAgents && 
            <>
            {/* Processing Branch */}
            <div className='row mt-3'>
            <div className='col-5'>
            <label className="acc-label2 mb-1">Processing Branch</label>
            </div>
            <div className='col-6'>
            <select onChange={(e) => this.setState({superAgent: e.target.value})} className="form-select acc-form form-select-lg col-lg-11" name="superagent" >
            <option value="">Please Select</option>
            {[].concat(this.props.allAgents).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(head => 
            <option key={head.id} value={head.id}>{head.agencyname}</option>
            )}
            </select>
            </div>
            </div>
              {/* Referring Branch */}
            <div className='row mt-3'>
            <div className='col-5'>
            <label className="acc-label2 mb-1">Referring Branch</label>
            </div>
            <div className='col-6'>
            <select onChange={(e) => this.setState({subAgent: e.target.value})} className="form-select acc-form form-select-lg col-lg-11" name="subagent" >
            <option value="">Please Select</option>
            {[].concat(this.props.allAgents).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(head => 
            <option key={head.id} value={head.id}>{head.agencyname}</option>
            )}
            </select>
            </div>
            </div>
            </>}
            </div>
            </Modal.Body>  
            <Modal.Footer>  
            <Button className="btn btn-danger" onClick={()=>this.handleModal()}> Cancel </Button>  
            <Button className="btn btn-success" onClick={this.handleSubmit}> Update </Button>  
            </Modal.Footer>  
            </Modal>  
        </>
    )
    }
}
export default ExternalAgentModel
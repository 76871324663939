import React, {useState,useEffect} from "react";
import "./login.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faKey, faUser, } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import {setUserSession, setUserPermissions, getUser } from '../../utilis/Common';
import swal from 'sweetalert';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { Button,Modal} from 'react-bootstrap'; 
import { main_url } from "../../utilis/Common";
import { post_ApiManager } from "../ApiManager/ApiManager";

const Login = (props) => {
    const[email, setEmail] = useState('');
    const[resetEmail, setResetEmail] = useState('');
    const[show, setShow] = useState(false);
    const[password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [passError, setPassError] = useState(null);
    const [resetEmailError, setResetEmailError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hide, setHide] = useState(false);
    const [allEntry, setAllEntry] = useState([]);
    const [ip, setIP] = useState([null]);
    const history = useNavigate();

     //pass method to the lifecycle method
    useEffect( () => {
       getip();
      }, []);

      useEffect( () => {
        $(window).resize(function() {
          if ($(window).width() < 700) {
             setHide(true);
          } else{
            setHide(false);
          }
        });
    }, []);

    const getip = async () => {
    fetch('https://geolocation-db.com/json/')
        .then(res=> res.json())
        .then(data => setIP(data))
        .catch(e => console.log(e)); 
    }
     //Validate email and password
    const handleValidation = (e) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        setEmailError(null);
        setPassError(null);
        if(!email){
            setEmailError('Please Enter Email');
            return false;
        } else if (!pattern.test(email)) {
            setEmailError('Please Enter Correct Email');
            return false;
        } else if(!password){
            setPassError('Please Enter Password');
            return false;
        } else{
            return true;
        }
    }
     //submit form and call api to fetch results
    const onSubmit = (e) => {
       e.preventDefault();
       const newEntry = {email,password};
       if (handleValidation()) {
        setAllEntry([...allEntry, newEntry]);
        console.log(allEntry);
        handleLogin();
      } 
    };
    const handleLogin = () => {
        setLoading(true); 
        const data = {
            email:email,
            password:password,
            loading:true,
        }
        try {
        axios.post(main_url()+'/api/v1/user-login',data)
        .then(res => {
            console.log('res',res);
            setLoading(false);
            if (res.data.status === true ) {
                setUserSession(res.data.token, res.data.user);
                setUserPermissions(res.data.permissions);
                if(localStorage.getItem('token')){
                    var user = getUser();
                    const socket = props.socket;
                    socket?.emit("newUser", user['name'], user['profile_image_url']);
                    history( '/dashboard');
                    window.location.reload(false);
                }
            } else{
                console.log('false status',res.data.message);
                setLoading(false);
                swal(res.data.message, {icon: "error",});      
            }
        })
        } catch (e) {
        setLoading(false);
        console.log(e)
        swal("Oops!", "Something went wrong!", "error");
        }
    }
    const handleModal = () => {
        setShow(!show);
    } 
    const checkEmail = () => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        setResetEmailError(null);
        if(!resetEmail){
            setResetEmailError('Please enter email');
            return false 
        }
        else if(!pattern.test(resetEmail)){
            setResetEmailError('Please enter correct email');
            return false 
        }
        else{
            let url = 'verifyEmail';
            var data = {resetEmail: resetEmail}
            post_ApiManager(data, url, (res) => {
                console.log('verified email' , res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {swal("Success!", res.data.message, "success").then(() => { setShow(false)}) };  
            },(err) => {
                console.log(err);
                swal("Oops!", "Something went wrong!", "error")
            });
        }
    }

   return(
       <div className="fill-window" style={{  background: `url('${process.env.PUBLIC_URL}/assets/img/login-bg.jpg')`,backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center',opacity:'1'}}>
       <div className="row h-100">
       {!hide && 
        <div className="col-lg-6 ">
        <div className="header h-100">
        <div style={{height:'15%'}}></div>
        <div className="container-fluid">
        <h1 className="h-color text-center pt-lg-5 mt-lg-5 custom-font4 bold">Welcome <br/> To <br/>Aptitude Migration</h1>
        <small className="cursive-font bold h-color float-right">(Vision to Succeed)</small>
        <div className="logo text-center">
        <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`}  width="150" height="auto" />
        </div>
        </div>
        </div>
        </div>}
   
        <div className="col-lg-6 col-12">
        {!hide && <div style={{height:'15%'}}></div>}
        <div className="container">
        {hide && 
         <div>
         <h3 className="h-color text-center custom-font4 bold pt-5 mt-3">Welcome <br/> To <br/>Aptitude Migration</h3> 
         </div>}
        <div className="d-flex justify-content-center h-100 pt-4 pt-lg-5 mt-lg-3">
        <div className="card login-card w-75">
        <form className="pl-lg-5 pr-lg-5 p-3 mt-lg-1 pb-3 pt-5" action='' onSubmit={onSubmit}>
            {/* show email input */}
        <div className="row p-1">
        <div className="input-group form-group  col-12">
        <div className="input-group-prepend">
            <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
        </div>
        <input className="form-control login-form-control" type="email" name="email" value={email} id="email" placeholder="Enter Email" autoComplete="on"
        onChange={(e) => setEmail(e.target.value) }  /> 
        <div className="col-12 text-left">
        {emailError && <><small className="error" style={{ color: 'red' }}>{emailError}</small><br /></>}<br />	
        </div>
        </div>
        </div>
            {/* show password input */}
        <div className="row p-1">
        <div className="input-group form-group col-12">
        <div className="input-group-prepend">
        <span className="input-group-text"><FontAwesomeIcon icon={faKey} /></span>
        </div>
        <input className="form-control login-form-control" type="password" name="password" id="password" placeholder="Enter Password"  autoComplete="on" value={password} 
        onChange={(e) => setPassword(e.target.value) }  />
        <div className="col-12 text-left">
        {passError && <><small className="error">{passError}</small></>}
            </div>
        </div>
        </div>
            {/* login button */}
        <div className="form-group j-content-right text-right pt-2 pb-2">
        <input className={`btn float-right login_btn ${loading ? 'bg-theme-green': 'bg-theme-blue'}`} type="button" value={loading ? 'Loading...' : 'Login'} onClick={onSubmit} disabled={loading} /><br></br>
        </div>
        </form>
        <div className="card-footer login-card-footer ">
        <div className="d-flex justify-content-center">
            <button onClick={handleModal} className="light-blue transparent">Forgot your password?</button>
        </div>
        <div className="d-flex justify-content-center text-center">
        <small className="white">Your IP adress  {ip.IPv4} ) <br/> {ip.city} {ip.country_name} {ip.country_code}</small>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
     </div>


        <Modal className="p-modal-content" show={show} onHide={()=>this.handleModal()}>  
        <Modal.Header className="modal-header" closeButton><div className="text-white text-center col-11 font-f-sans">Reset Password</div></Modal.Header>  
        <Modal.Body>
        <div className="row">
        <div className="form-group col-10 ml-lg-4" >
        <label className="profile-label">Email:</label>
        <input name="resetEmail"  onChange={(e) => setResetEmail(e.target.value) } placeholder="Please enter your email" className="card-text form-control acc-form col-11" />
        {resetEmailError && <><small className="error" style={{ color: 'red' }}>{resetEmailError}</small><br /></>}<br />	
        </div>
        </div>
        </Modal.Body>  
        <Modal.Footer>  
        <Button onClick={()=>setShow(false) }>Cancel</Button>  
        <Button onClick={checkEmail}>Reset</Button>  
        </Modal.Footer>  
        </Modal>  
    </div>
    )
}
export default Login
export const time_slots = [
    {
        startTime: "06:30 AM " ,
        endTime: "07:00 AM " ,
        id:31,
    },
    {
        startTime: "07:00 AM " ,
        endTime: "07:30 AM " ,
        id:32,
    },
    {
        startTime: "07:30 AM " ,
        endTime: "08:00 AM " ,
        id:33,
    },
    {
        startTime: "08:00 AM " ,
        endTime: "08:30 AM " ,
        id:34,
    },
    {
        startTime: "08:30 AM " ,
        endTime: "09:00 AM " ,
        id:35,
    },
    {
        startTime: "09:00 AM " ,
        endTime: "09:30 AM " ,
        id:1,
    },
    {
        startTime: "09:30 AM" ,
        endTime: '10:00 AM',
        id:2,
    },
    {
        startTime: "10:00 AM" ,
        endTime: '10:30 AM',
        id:3,
    },
    {
        startTime: "10:30 AM" ,
        endTime: '11:00 AM',
        id:4,
    },
    {
        startTime: "11:00 AM" ,
        endTime: '11:30 AM',
        id:5,
    },
    {
        startTime: "11:30 AM" ,
        endTime: '12:00 PM',
        id:6,
    },
    {
        startTime: "12:00 PM" ,
        endTime: '12:30 PM',
        id:7
    },
    {
        startTime: "12:30 PM" ,
        endTime: '01:00 PM',
        id:8,
    },
    {
        startTime: "01:00 PM" ,
        endTime: '01:30 PM',
        id:9,
    },
    {
        startTime: "1:30 PM" ,
        endTime: '2:00 PM',
        id:10,
    },
    {
        startTime: "02:00 PM" ,
        endTime: '02:30 PM',
        id:11,
    },
    {
        startTime: "02:30 PM" ,
        endTime: '03:00 PM',
        id:12,
    },
    {
        startTime: "03:00 PM" ,
        endTime: '03:30 PM',
        id:13,
    },
    {
        startTime: "03:30 PM" ,
        endTime: '04:00 PM',
        id:14,
    },
    {
        startTime: "04:00 PM" ,
        endTime: '04:30 PM',
        id:15
    },
    {
        startTime: "04:30 PM" ,
        endTime: "05:00 PM",
        id:16,
    },
    {
        startTime: "05:30 PM" ,
        endTime: '06:00 PM',
        id:17,
    },
    {
        startTime: "06:00 PM" ,
        endTime: '06:30 PM',
        id:18,
    },
    {
        startTime: "06:30 PM" ,
        endTime: '07:00 PM',
        id:19,
    },
    {
        startTime: "07:00 PM" ,
        endTime: '07:30 PM',
        id:20,
    },
    {
        startTime: "07:30 PM" ,
        endTime: '08:00 PM',
        id:21,
    },
    {
        startTime: "08:00 PM" ,
        endTime: '08:30 PM',
        id:22,
    },
    {
        startTime: "08:30 PM" ,
        endTime: '09:00 PM',
        id:23,
    },
    {
        startTime: "09:00 PM" ,
        endTime: '09:30 PM',
        id:24,
    },
    {
        startTime: "09:30 PM" ,
        endTime: '10:00 PM',
        id:25,
    },
    {
        startTime: "10:00 PM" ,
        endTime: '10:30 PM',
        id:26,
    },
    {
        startTime: "10:30 PM" ,
        endTime: '11:00 PM',
        id:27,
    },
    {
        startTime: "11:00 PM" ,
        endTime: '11:30 PM',
        id:28,
    },
    
]
export default {time_slots}
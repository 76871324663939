import React from 'react';
import PermissionSwitch from '../GenericComponents/PermissionSwitches';
import Title from '../GenericComponents/Title';
import swal from 'sweetalert';
import { get_ApiManager, post_ApiManager } from '../ApiManager/ApiManager';
import { useNavigate } from "react-router-dom";

export const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
} 

class AddAccount extends React.Component{
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            nameError: '', phoneError: '', emailError: '', passError: '', roleError: '', countryError: '',branchError:'', locationError:'',
            name: '',
            phone: '',
            branch:'',
            email:'',
            password: '',
            role: '',
            country: '',
            ipAddress: '0',
            location:'',
            ipCheck: 0,
            migAccess: 1,
            eduAccess : 1,
            aatFcctAccess: 1,
            birthdate:'',
            allPermissions: permissions,
            branches: [],
            notes:'',
            }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        this.getBranches();
    }

    getBranches = () => {
        let url = 'getBranches'; 
        get_ApiManager('', url,(res) => {
            console.log('branches',res);
            this.setState({branches: res.data['data']});
        },(err) => {
            console.log(err);
        });
    }

    //CallBack function to get changed status name and value and call api to save results
    handleCallback = (check, keyName) =>{
        if(keyName === 'ip')
        this.setState({ ipCheck : check }); 
        else if(keyName === 'migAccess')
            this.setState({ migAccess : check });
        else if(keyName === 'eduAccess')
            this.setState({ eduAccess : check });
        else this.setState({ aatFcctAccess : check });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleValidation = (e) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        this.setState({nameError: null, emailError: null, phoneError: null, passwordError: null, roleError: null, countryError: null, locationError: null });

        if(!this.state.name){
            window.scrollTo(0, this.scrollRef.current.offsetTop);
            this.setState({nameError : 'Please enter name'})
            return false }
        else if(!this.state.phone){
            this.setState({phoneError : 'Please enter phone'})
            return false }
        else if(!this.state.email){
            this.setState({emailError: 'Please enter email'})
            return false }
        else if(!pattern.test(this.state.email)){
            this.setState({emailError: 'Please enter correct email'})
            return false }
        else if(!this.state.password){
            this.setState({passError: 'Please enter password'})
            return false }
        else if(this.state.password.length < 6){
            this.setState({passError: 'Please enter password greater than 5 digits'})
            return false }
        else if(!this.state.role){
            this.setState({roleError: 'Please select role'})
            return false }
        else if(!this.state.country){
            this.setState({countryError: 'Please select country'})
            return false }
        else if(!this.state.branch){
            this.setState({branchError: 'Please select branch'})
            return false }
        else if(!this.state.location){
            this.setState({locationError: 'Please select location'})
            return false }
        else return true
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.handleValidation()) {
            const data = {
                name : this.state.name,
                phone : this.state.phone,
                email: this.state.email,
                password: this.state.password,
                role: this.state.role,
                country: this.state.country,
                branch: this.state.branch,
                allow_ip : this.state.ipCheck,
                ip_address : this.state.ipAddress,
                mig_access: this.state.migAccess,
                edu_access: this.state.eduAccess,
                aat_access: this.state.aatFcctAccess,
                location: this.state.location,
                birthdate: this.state.birthdate,
                notes: this.state.notes
            }
            this.setState({loading: true});
            let url = 'addUser';
            post_ApiManager(data, url, (res) => {
            this.setState({loading: false});
            if(res.data.error) swal(" ", res.data.error, "error")
            else { swal("Success!", "New User Created Successfully!", "success"); 
            this.props.navigate('/accounts/view-accounts')};  
        },(err) => {
            console.log(err);
        });
      }
    }

 render(){
    return(
        <>
        <Title title="Accounts" ></Title>
        {this.state.allPermissions['accounts'] === 1 ?
        <div className="pt-0 pl-lg-3 pr-lg-3 pl-3 pr-4">
            <div className="row j-content-center mx-3">
            <div className="col-lg-12 bordered scroll mb-5 mb-lg-1">
                <div className="pb-2 pt-3 bg-green">
                <h4 className="subtitle text-center ">Add Account</h4>
                </div>
                <form  ref={this.scrollRef} action='' onSubmit={this.handleSubmit}>
                <div className="row bg-white pl-lg-4 pl-3 pr-3 pt-2 pb-2 pb-lg-3 pr-lg-1">
                <div className="col-lg-4 pl-lg-3">
                <div className="form-group">
                <label className="acc-label2">Full Name*</label>
                <input type="text" name="name" value={this.state.name} onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter" />
                {this.state.nameError && <><small className="error">{this.state.nameError}</small></>}
                </div>
                </div>

                <div className="col-lg-4 pt-1 pt-lg-0">
                <div className="form-group">
                <label className="acc-label2">Phone*</label>
                <input type="number" name="phone" value={this.state.phone} onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                {this.state.phoneError && <><small className="error">{this.state.phoneError}</small></>}
                </div>
                </div>

                <div className="col-lg-4 pt-1 pt-lg-0">
                <div className="form-group">
                <label className="acc-label2">Date of Birth</label>
                <input type="date" name="birthdate" value={this.state.birthdate} onChange={this.handleChange} className="date-input w-lg-90" placeholder="Please Enter"/>
                </div>
                </div>

                <div className="col-lg-6 pl-lg-3 pt-0">
                <div className="form-group">
                <label className="acc-label2">Email*</label>
                <input type="text" name="email" value={this.state.email} className="form-control acc-form w-lg-90" placeholder="Please Enter" onChange={this.handleChange} />
                {this.state.emailError && <><small className="error">{this.state.emailError}</small></>}
                </div>
                </div>

                <div className="col-lg-6 pt-0">
                <div className="form-group">
                <label className="acc-label2">Password*</label>
                <input type="password" name="password" value={this.state.password} onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter" />
                {this.state.passError && <><small className="error">{this.state.passError}</small></>}
                </div>
                </div>

                <div className="col-lg-4 pl-lg-3 pt-0">
                <div className="form-group">
                <label className="acc-label2">User Role*</label>
                <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example" name="role" onChange={this.handleChange} value={this.state.role}>
                <option selected>Please Select</option>
                <option value="0">Admin</option>
                <option value="1">User</option>
                <option value="2">Super Admin</option>
                </select>
                {this.state.roleError && <><small className="error">{this.state.roleError}</small></>}
                </div>
                </div>

                <div className="col-lg-4 pt-0">
                <div className="form-group">
                <label className="acc-label2">Select Country*</label>
                <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example" name="country" value={this.state.country} onChange={this.handleChange}>
                <option selected>Please Select</option>
                <option value="Australia">Australia</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Other">Other</option>
                </select>
                {this.state.countryError && <><small className="error">{this.state.countryError}</small></>}
                </div>
                </div>

                <div className="col-lg-4 pt-0">
                <div className="form-group">
                <label className="acc-label2">Select Branch*</label>
                <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example" name="branch"  onChange={this.handleChange}>
                <option >Please Select</option>
                {[].concat(this.state.branches).sort((a, b) => a.name > b.value ? 1 : -1).map(value => 
                <option key={value.name} value={value.id}>
                {value.name}
                </option>
                )}
                </select>
                {this.state.branchError && <><small className="error">{this.state.branchError}</small></>}
                </div>
                </div>
                <div className="col-lg-3 pl-lg-3">
                <div className="form-group">
                <label className="acc-label2 mb-0">Location*</label>
                <select className="form-select acc-form form-select-lg w-lg-90" onChange={this.handleChange} name="location">
                <option>Please Select</option>
                <option value="Onshore">Onshore</option>
                <option value="Offshore">Offshore</option>
                </select>
                {this.state.locationError && <><small className="error">{this.state.locationError}</small></>}
                </div>
                </div>

                <div className="col-lg-8 pl-lg-3">
                <div className="form-group">
                <label className="acc-label2 mb-1">Description</label>
                <textarea className="textarea form-control acc-form" name="notes" placeholder="Please Enter" onChange={this.handleChange} rows="4" cols="60"></textarea>
                </div>
                </div>

                <div className="col-lg-2">
                <label className="acc-label2 col-8 pt-2">Allow IP</label>
                <div className="form-group col-6 pt-1 text-left">
                <PermissionSwitch generic={true} keyName="ip" status={this.state.ipCheck} parentCallback = {this.handleCallback}/>
                </div>
                {this.state.ipCheck ?
                <div className="pb-2">
                <small> (Add multiple addresses separated by (,)) </small>
                <input type="text" name="ipAddress" value={this.state.ipAddress} onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter" />
                </div>:''}
                </div>

                <div className="col-lg-6 row">
                <div className="form-group col-4">
                <label className="acc-label2 ">Migration <br/> Access</label>
                <div className="form-group text-left">
                <PermissionSwitch generic={true} keyName="migAccess" status={this.state.migAccess} parentCallback = {this.handleCallback}/>
                </div>
                </div>

                <div className="form-group col-4">
                <label className="acc-label2">Education<br/> Access</label>
                <div className="form-group text-left">
                <PermissionSwitch generic={true} keyName="eduAccess" status={this.state.eduAccess} parentCallback = {this.handleCallback}/>
                </div>
                </div>

                <div className="form-group col-4">
                <label className="acc-label2">AAT/FCCT<br/> Access</label>
                <div className="form-group text-left">
                <PermissionSwitch generic={true} keyName="aat/fcct" status={this.state.aatFcctAccess} parentCallback = {this.handleCallback}/>
                </div>
                </div>
                </div>

                <div className="col-12 mb-2 scroll">
                <button type="submit" className="btn btn-success ml-2" onClick={this.handleSubmit}>Create Account</button>
                </div>

                </div>
                </form>
            </div>
            </div>
        </div>: 'You dont have permission to access this pages!'}
        </>
    )
}
}

export default withNavigation(AddAccount);

import React, { Component } from 'react'
import swal from 'sweetalert';
import "./permission.css";
import Title from '../GenericComponents/Title';
import PermissionSwitches from '../GenericComponents/PermissionSwitches';
import { get_ApiManager } from '../ApiManager/ApiManager';
import { faListNumeric } from '@fortawesome/free-solid-svg-icons';

class GrantRolePermission extends Component {
    constructor(props) {
        super(props);
        var user = JSON.parse(localStorage.getItem('user'));
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            param: new URL(window.location.href).searchParams.get('role'),
            permissions:[],
            loading: false,
            user: user,
            allPermissions: permissions, 
            viewAccount:false,
            viewAgent: false,
            viewReport: false,
            viewLeads: false,
            viewClients: false,
            viewEvent: false,
            allExpo:false,
            consultantList: false,
            floorManager: false,
            createAlert: false,
            appPrompt: false,
            commissionsActivity: false,
            viewFollowups: false,
            allClientsFollowups: false,
            allMigFollowups: false,
            allEduFollowups: false,
            allProviderFollowups: false,
            biometricFollowups: false,
            healthExaminationFollowups: false,
            myClientFollowups: false,
            myEduFollowups: false,
            myMigFollowups: false,
            myProviderFollowups: false,
            viewProviders: false,
            editProviders: false,
            providerAccountContact: false,
            providerAdmissionContact: false,
            providerMarketingContact: false,
            providerOwnerContact: false,
            providerOtherContact: false,
            providerCommissions: false,
            commissionButton: false,
            viewAppointments: false,
            allAppointments: false,
            viewSettingsPage: false,
            loginHistory: false,
            employeeActivity: false,
            ipAddress: false,
            viewCategory: false,
            manageTeams: false,
            manageChecklist: false,
            perUserDetail: false,
            rfiDetail: false,
            njDetail: false,
            desktopNoicc: false,
            totalMig: false,
            totalEdu: false,
            viewFinance: false,
            professionalCostPage: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.getPermissions();
    }

    getPermissions() { 
        let url = `getUserPermissions/${this.state.user['id']}`;
        get_ApiManager('', url,(res) => {
            console.log('all-permissions',res);
            this.setState({
                permissions: res.data.data['permissions'],
                loading: false,
            });
        },(err) => {
            console.log(err);
        });
    }

    async enableAll(){
        const data =  this.state.user['id'];
        let url = 'enableAllPermissions';
        get_ApiManager(data, url, (res) => {
            console.log('Enabled All Permission',res);
            this.getPermissions();
            swal("Success!", "All permisssions enabled sucessfully!", "success");
        },(err) => {
            console.log(err);
        });
    }

    handleInputChange(checked, state) {
        this.setState({ [state]: checked });
    };

    data = (text, value, valueName) => {
        return(
  
        <div className="col-lg-6 col-9 text-left">
        <span className='d-flex p-2'>
        <div className='col-9'>
        <h6 className="text-muted mb-0 size-16 font-f-monst-sans">{text}</h6>
        </div>
        <input className="check-input ml-3" name={valueName} type="checkbox" 
            checked={value}
            onChange={e => this.handleInputChange(e.target.checked, valueName) } />
            </span>
       
        </div>
        )
    }

    render(){
        return(
            this.state.allPermissions['hr-management'] === 1 ?
            <>
            <Title back={true} />
            <div className="row w-100 px-4 mb-5 mt-lg-2">
            <div className="card w-100 h-100">
            <div className="py-3 shadow bg-grey">
            <div className="nav-tabs-navigation text-center">
            <h3 className="nav-tabs-title profile-header white d-flex justify-content-center">
            Set Permissions for Role  ({this.state.param})
            </h3>
            </div>
            </div>

            <div className="card-block pl-4 pr-4 pb-0 pt-0">
            <div className="d-flex justify-content-end pt-1">
            <button type="button" onClick={() => this.enableAll() } className="btn btn-success mb-2 mt-3 pl-4 pr-4">Enable All</button>
            <button type="button" onClick={() => this.disableAll() } className="btn btn-danger mb-2 mt-3 pl-4 pr-4 ml-2">Disable All</button>
            </div>
            </div>

            <div className='px-4'>
            <h3 className="text-center p-2 font-f-roboto" style={{background:'antiquewhite'}}>Global</h3>
            <div className="row">

            {this.data("Can view Accounts?", this.state.viewAccount , 'viewAccount')}
            
            {this.data("Can view Agents?", this.state.viewAgent, 'viewAgent')}

            {this.data("Can view Reports?", this.state.viewReport, 'viewReport')}

            {this.data("Can view Leads?", this.state.viewLeads, 'viewLeads')}

            {this.data("Can view Clients?", this.state.viewClients, 'viewClients')}

            {this.data("Can view Events?", this.state.viewEvent, 'viewEvent')}

            {this.data("Can view All Expo?", this.state.allExpo, 'allExpo')}

            {this.data("Can view Consultant List?", this.state.consultantList, 'consultantList')}

            {this.data("Make Floor Manager for Events?", this.state.floorManager, 'floorManager')}

            {this.data("Can Create Alerts?", this.state.createAlert, 'createAlert')}

            {this.data("Enable App Priority Prompt?", this.state.appPrompt, 'appPrompt')}

            {this.data("Can view Commissions Activity?", this.state.commissionsActivity, 'commissionsActivity')}
            
             <h3 className="text-center p-2 font-f-roboto mt-4" style={{background:'antiquewhite'}}>Followups</h3> 

            {this.data("Can view Followups?", this.state.viewFollowups, 'viewFollowups')} 

            {this.data("Can view all Clients Followups?", this.state.allClientsFollowups, 'allClientsFollowups')}

            {this.data("Can view all Migration Followups?", this.state.allMigFollowups, 'allMigFollowups')}

            {this.data("Can view all Education Followups?", this.state.allEduFollowups, 'allEduFollowups')} 

            {this.data("Can view all Providers Followups?", this.state.allProviderFollowups, 'allProviderFollowups')}

            {this.data("Can view Biometrics Followups?", this.state.biometricFollowups, 'biometricFollowups')} 

            {this.data("Can view Health Examination Followups?", this.state.healthExaminationFollowups, 'healthExaminationFollowups')} 

            {this.data("Can view My Clients Followups?", this.state.myClientFollowups, 'myClientFollowups')} 

            {this.data("Can view My Migration Followups?", this.state.myMigFollowups, 'myMigFollowups')} 

            {this.data("Can view My Education Followups?", this.state.myEduFollowups, 'myEduFollowups')} 

            {this.data("Can view My Providers Followups?", this.state.myProviderFollowups, 'myProviderFollowups')} 

            <h3 className="text-center p-2 font-f-roboto mt-4" style={{background:'antiquewhite'}}>Providers</h3> 

            {this.data("Can view Providers?", this.state.viewProviders, 'viewProviders')} 

            {this.data("Can edit Providers?", this.state.editProviders, 'editProviders')} 

            {this.data("Can view Providers Accounts Contact?", this.state.providerAccountContact, 'providerAccountContact')} 

            {this.data("Can view Providers Admission Contact?", this.state.providerAdmissionContact, 'providerAdmissionContact')} 

            {this.data("Can view Providers Marketing Contact?", this.state.providerMarketingContact, 'providerMarketingContact')} 

            {this.data("Can view Providers Owner Contact?", this.state.providerOwnerContact, 'providerOwnerContact')} 

                <PermissionSwitches status={this.state.permissions['providers_other']} text="Can view Providers Other Contact?" keyName="providers_other" parentCallback = {this.handleCallback}/>

                <PermissionSwitches status={this.state.permissions['providers_commissions']} text="Can view Providers Commissions?" keyName="providers_commissions" parentCallback = {this.handleCallback}/>

                <PermissionSwitches status={this.state.permissions['show_application_commission']} text="Can view Commission Button in Applications?" keyName="show_application_commission" parentCallback = {this.handleCallback}/>


                <h3 className="text-left px-2 font-f-roboto pt-lg-4">Appointments</h3>
        
                <PermissionSwitches status={this.state.permissions['appointments']} text="Can view Appointments?" keyName="appointments" parentCallback = {this.handleCallback}/>

                <PermissionSwitches status={this.state.permissions['viewappointments']} text="Can view all Appointments?" keyName="viewappointments" parentCallback = {this.handleCallback}/>
        

                <h3 className="text-left px-2 font-f-roboto pt-lg-4">Settings</h3>
    
                <PermissionSwitches status={this.state.permissions['settings_page']} text="Can view Settings Page?" keyName="settings_page" parentCallback = {this.handleCallback}/>

                <PermissionSwitches status={this.state.permissions['loginhistory']} text="Can view Login History?" keyName="loginhistory" parentCallback = {this.handleCallback} />
            
                <PermissionSwitches status={this.state.permissions['employeeactivity']} text="Can view Employee Activity?" keyName="employeeactivity" parentCallback = {this.handleCallback} />

                <PermissionSwitches status={this.state.permissions['ip_address_page']} text="Can view IP Address?" keyName="ip_address_page" parentCallback = {this.handleCallback}/>
            
                <PermissionSwitches status={this.state.permissions['categories']} text="Can view Categories?" keyName="categories" parentCallback = {this.handleCallback}/>
            
                <PermissionSwitches status={this.state.permissions['manage_teams']} text="Can view Manage Teams?" keyName="manage_teams" parentCallback = {this.handleCallback}/> 

                <PermissionSwitches status={this.state.permissions['manage_checklist']} text="Can view Manage Checklist?" keyName="manage_checklist" parentCallback = {this.handleCallback} />

                
                <h3 className="text-left px-2 font-f-roboto pt-lg-4">Desktop</h3>
                <PermissionSwitches status={this.state.permissions['desk_puser']} text="Can view Per User Details?" keyName="desk_puser" parentCallback = {this.handleCallback} />
        
                <PermissionSwitches status={this.state.permissions['desk_rfi']} text="Can view Desktop RFI Details?" keyName="desk_rfi" parentCallback = {this.handleCallback}/>

                <PermissionSwitches status={this.state.permissions['desk_nj']} text="Can view Desktop NJ Details?" keyName="desk_nj" parentCallback = {this.handleCallback} />
        
                <PermissionSwitches status={this.state.permissions['desk_noicc']} text="Can view Desktop NOICC?" keyName="desk_noicc" parentCallback = {this.handleCallback}/>
    
                <PermissionSwitches status={this.state.permissions['desk_migration']} text="Can view Total Migrations?" keyName="desk_migration" parentCallback = {this.handleCallback}/>
            
                <PermissionSwitches status={this.state.permissions['desk_education']} text="Can view Total Educations" keyName="desk_education" parentCallback = {this.handleCallback}/>

                <h3 className="text-left px-2 font-f-roboto pt-lg-4">Finance</h3>
                <PermissionSwitches status={this.state.permissions['finance']} text="Can view Finance Module" keyName="finance" parentCallback = {this.handleCallback}/>
                <PermissionSwitches status={this.state.permissions['professionalCost']} text="Can view Professtional Cost Page" keyName="professionalCost" parentCallback = {this.handleCallback}/>
        
                </div>
            </div>

            </div>
            </div>
            </>
            : 'You dont have Permission to access this page'
        )
    }
}
export default GrantRolePermission


import React ,{useState,useEffect}from "react"
import { useParams} from "react-router-dom"
import Title from '../GenericComponents/Title'
import { Link } from 'react-router-dom'
import {get_ApiManager, view_ApiManager} from '../ApiManager/ApiManager'
import { Sorting } from "../GenericComponents/SortTables";
import moment from "moment";
import Paginations from "../GenericComponents/Pagination"
import ExportOptions from "../GenericComponents/ExportOptions"
import EmployeeActivityBars from "../Settings/EmployeeActivityBars"
import * as FaIcons from 'react-icons/fa'
import swal from "sweetalert"

const AttendenceForEmployee = () => {
    const { userid } = useParams();
    const[allPermissions] = useState(JSON.parse(localStorage.getItem('permissions')));
    const [order, setOrder] = useState("ASC");
    const [record, setRecord] = useState([]);
    const [perItem, setPerItem] = useState('')
    const [totalItem, setTotalItem] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState('');
    const [recordIndex, setRecordIndex] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [sDate, setsDate] = useState(null);
    const [eDate, seteDate] = useState(null);
    const [days, setDays] = useState(null);
    const [headers] = useState([ 
        {title: "#" , dataKey: "index", align: "text-center", key:1, checked: true, canChange: true},
        {title: "Employee" , dataKey: "user",align: "text-center", key:2, checked: true, canChange: true},
        {title: "Branch" , dataKey: "branch",align: "text-center", key:3, checked: true, canChange: true},
        {title: "In/Out", dataKey: "in", align: "text-center", key:4, checked: true, canChange: true},
        {title: "Total Time", dataKey: "total" ,align:"text-center", key:5, checked: true, canChange: true},
        {title: "Notes", dataKey: "notes" ,align:"text-center", key:6, checked: true, canChange: true},
    ]);

    useEffect( () => {
        getSpecificEmployeeDetail(sDate, eDate, days);
    }, [sDate, eDate, days, currentPage, perPage]);

    const getSpecificEmployeeDetail = (sDate, eDate, days) => {
        let url = `employeeAttendanceRecord/${userid}/${sDate}/${eDate}/${days}`;
        console.log(url)
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('attendance',res);
            setRecord(res.data.data['data']);
            setTotalItem(res.data.data['total']);
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setPerItem(res.data.data['data'].length);
            setRecordIndex(currentPage * perPage - perPage + 1);
            let options = res.data.data['data'].map(function (item) {
                let timeIn = new Date(item.in).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                let timeOut = new Date(item.out).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                let createdAtDate = new Date(item.created_at).toISOString().split('T')[0];
                return { 
                    Name: item.user,
                    Branch:item.branch,
                    Date: createdAtDate,
                    TimeIn: timeIn,
                    TimeOut: timeOut,
                    TimeWorked: item.total,
                }
            });
            setSelectedUsers(options);
        },(err) => {
            console.log(err);
        });
    }
     // Pagination callBack function
     const handlePaginationCallback = (i, perPage) => {
        console.log(sDate);
            if(perPage && perPage !== ''){
                setCurrentPage(1);
                setPerPage(perPage, () => { getSpecificEmployeeDetail() }); 
            } else { setCurrentPage(i, () => { getSpecificEmployeeDetail() });  } 
    }

     // Table sorting
     const sorting = (col) => {
        const sortResult = Sorting(record, col, order)
        setOrder(sortResult[0]);
    }
         //CallBack function to get changed status name and value and call api to save results
     const handleCallback = (days, startDate, endDate) => {
        setsDate(startDate);
        seteDate(endDate);
        setDays(days);
        getDates(startDate, endDate,days);
    }
    function getDates(startDate, endDate,days){
        setsDate(startDate);
        seteDate(endDate);
        setDays(days);
        getSpecificEmployeeDetail(days, startDate, endDate)
    }
      //Delete a user
    const deleteRecord = (delete_id) => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this record",icon: "warning", buttons: true, dangerMode: true,
          }).then((willDelete) => {
            if (willDelete){
            const id = delete_id;
            let url = 'deleteAttendance';
            get_ApiManager(id, url,(res) => {
            console.log('User Deleted',res);
            swal("Record deleted successfully", {icon: "success" });
            getSpecificEmployeeDetail(sDate, eDate, days);
        },(err) => {
            console.log(err);
        }); }   
       });
    }
        
    return(
        <>
        <Title title={`Attendance Record`} back={true} />
        <div className="row w-100 pl-3 pr-3 mb-5 mt-3">
          {/* search bar */}
        <EmployeeActivityBars  
         days={days} 
         clients={true}
         selectedsDate={sDate}
         selectedeDate={eDate}
         parentCallback={handleCallback} />
        {allPermissions['hr-management'] === 1 ?
        <table id="employeeAttendence" className="table table-bordered bg-white table-responsive mb-1">
          {/* Headers */}
        <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)}
         key={head.key}>{head.title}</th>))}
         </tr> 
        </thead>
         {/* End Headers */}
         <tbody>
         {record.map((value, index) => (
         <tr key={index}>
         <td className="text-center w-3">{recordIndex+index}</td>
         <td className="name-text text-center">{value.user}</td>
         <td className="name-text text-center">{value.branch}</td>
         {headers[2]['checked'] && <td className="text-center">
         {value.in && <><span className="text-c-green bold">In </span> at {moment(value.in).format('h:mm A')} On {moment(value.in).format('DD-MMM-YYYY')}</>}
         <br/>
         {value.out && <><span className="text-red bold">Out</span> at {moment(value.out).format('h:mm A')} On {moment(value.out).format('DD-MMM-YYYY')}</>}
         </td>}
         {headers[3]['checked'] && <td className="text-center">{value.out && value.total}</td>}
         {headers[4]['checked'] && <td className="text-center">{value.notes}</td>}
         </tr>))}
         </tbody>
        </table> : ''}
        <Paginations
        currentPage= {currentPage}
        total= {totalItem} 
        itemsPerPage= {perPage}
        lastPage={lastPage}
        onPageChange={handlePaginationCallback} 
        totalItems={totalItem}
        perItem={perItem}
         />
        <div className="d-flex mb-5">
        <ExportOptions
        pdffilename={'AttendanceDetail.pdf'} 
        csvfilename={'AttendanceDetail.csv'} 
        id="viewemployeediv" 
        header="Attendance Detail"
        xlsxfilename="AttendanceDetail"
        columns={headers}
        data={selectedUsers} />
        </div>
         {/* End Exports */}
        </div>
        </>
        )
}
export default AttendenceForEmployee


import React ,{useState,useEffect}from "react"
import { get_ApiManager, post_ApiManager } from "../ApiManager/ApiManager";
import {FaArrowLeft, FaReply } from "react-icons/fa";
import userlogo from '../../assets/chat.png';
import DOMPurify from 'dompurify'
import userlogo1 from '../../assets/chat2.png';
import moment from "moment";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EmailDetailComponent = (props) => {
    const { email, from } = props;
    const [mainChat, setMainChat] = useState([]);
    const [chat, setChat] = useState([]);
    const [showReply, setShowReply] = useState(false);
    const [reply, setReply] = useState('');
    const [replyError, setReplyError] = useState(null);
    const user = useState(JSON.parse(localStorage.getItem('user_id')));

    useEffect( () => {
        if(from === 'client'){
            getClientChat();
        } else{
            getChat();
        }
       markRead();
    }, []);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
     );
      const [convertedContent, setConvertedContent] = useState(null);
      const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
     }
      const convertContentToHTML = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
     }

    const getChat = () =>{
        let url = `getPartnerChatHistory/${email}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('chat',res);
            setMainChat(res.data.data['main'])
            setChat(res.data.data['chat'])
        },(err) => {
            console.log(err);
        })
    }
    const getClientChat = () =>{
        let url = `getClientChatHistory/${email}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('chat',res);
            setMainChat(res.data.data['main'])
            setChat(res.data.data['chat'])
        },(err) => {
            console.log(err);
        })
    }
    const markRead = () =>{
        let url = `markReadCms/${email}/${from}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('chat',res);
            handleNotification1();
        },(err) => {
            console.log(err);
        })
    }
    const handleNotification1 = () => {
        const socket = props.socket;
        socket.emit("sendChatNotification");
    }
    const handleClientNotification = () => {
        const socket = props.socket;
        socket.emit("sendClientChatNotification");
    }
    const handleNotification = () => {
        const socket = props.socket;
        socket.emit("sendPartnerChatNotification");
    }
  
     const sendReply = () => {
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const isEmpty = rawContent.blocks.every((block) => !block.text.trim());
        if(isEmpty){
            setReplyError('Enter your Reply!');
            return false
        } else{
            setReplyError('');
        }
        let url ;
        if(from === "client"){
           url = `replyToClientEmail`;
        } else{
           url = `replyToPartnerEmail`;
        }
        const data = {
            parentid: mainChat['id'],
            reply: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            senderid: user[0],
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('reply-sent',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success"); 
                setReply('');
                setReplyError(null)
                setShowReply(false);
                if(from === "client"){
                    handleClientNotification();
                    getClientChat();
                } else{
                    handleNotification();
                    getChat();
                }
               }  
        },(err) => {
            console.log(err);
        }); 
     }

    return(
        <>
          <div>
            <button className="btn btn-outline-secondary px-3 mt-2 py-1 mx-2" style={{borderRadius:'20px'}} onClick={props.onBack}>
            <FaArrowLeft className="mr-1" size={14}/>Back</button>
            <div className="bg-white rounded border m-2 py-2 bg-white">
            <div className="row px-2">
            <h4 className="text-center capital pt-2 font-f-sans">{mainChat['subject']}</h4>
            <div className="text-right">
            {(mainChat['appid'] && (mainChat['apptype'] === 'edu' || mainChat['apptype'] === 'education')) ?
            <Link to={`/applications/view-education-application-detail/${mainChat['appid']}`}>
            <button className="btn btn-outline-primary">Go to Application</button></Link>
            :
            (mainChat['appid'] && (mainChat['apptype'] === 'mig'|| mainChat['apptype'] === 'migration')) ?
            <Link to={`/applications/view-migration-application-detail/${mainChat['appid']}`}>
            <button className="btn btn-outline-primary">Go to Application</button></Link>
            :
            ((mainChat['apptype'] === '0'|| mainChat['apptype'] === 0)) ?
            <Link to={`/clients/view-client-detail/${mainChat['clientid']}`}>
            <button className="btn btn-outline-primary">Go to Client</button></Link> :''}
            </div>
            {chat.map((value, index) => (
            <div className="row  p-2" key={index}>
            <div className="col-1 text-center">
            <img src={(value.sender_type === 'partner' || value.sender_type === 'client') ? userlogo1 : userlogo} alt="User" className="img-fluid rounded-circle" style={{ width: '40px', height: '40px', objectFit: 'cover' }} />
            </div>
            <div className="col-9">
            <b className="capital size-16">{value.sender} {value.sender_type === 'cms' ? '(you)' : ''}</b>
            <br/>
            <div className="size-15 pt-2 pl-3" style={{whiteSpace: 'pre-wrap'}} 
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.body)}}></div>
            </div>
            <div className='col-2 size-14'>
            {moment(value.created_at).format('MMM D, YYYY, h:mm A')} </div>
            </div>))}
            <hr/>
            {/* Reply */}
            {showReply === true &&
            <div className="row my-2">
              <div className="col-1 text-center">
             <img src={userlogo} alt="User" className="img-fluid rounded-circle" style={{ width: '40px', height: '40px', objectFit: 'cover' }} />
             </div>
              <div className="col-10">
              <b className="capital">Aptitude Migration</b>
              <br/>
              <Editor
                editorState={editorState}
                placeholder={'Reply...'}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                />
              {replyError && <><small className="error">{replyError}</small></>}
              <button className="btn btn-success px-4 my-2" onClick={() => sendReply()}>Send</button>
              </div>

            </div>}
            <div>
            {(showReply === false || showReply === "false") &&
            <button  className="btn btn-outline-dark py-1 mx-3" onClick={() => setShowReply(true)} style={{borderRadius:'20px'}}> <FaReply size={16}/> Reply</button>}
            </div>
            </div>
            
            </div>
        </div>
       
        </>
    )
}
export default EmailDetailComponent

import React from "react"
import './search.css'
import * as FaIcons from 'react-icons/fa'
import { CSVLink } from "react-csv";
import jsPDF from 'jspdf';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FileExcelOutlined } from '@ant-design/icons';


const ExportOptions = ({pdffilename, xlsxfilename, csvfilename, header, columns, data}) => {
   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
   const fileExtension = ".xlsx"; 
    
   function printDocument() {
      var doc = new jsPDF('l', 'pt', 'a4');
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.autoTable(columns, data, {
        startY: 70,

        margin: { horizontal: 10 },
        styles: { overflow: "linebreak" },
        bodyStyles: { valign: "top" },
        columnStyles: { email: { columnWidth: "wrap" } },
        theme: "striped",
        showHead: "everyPage",
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(18);
          doc.setTextColor("#161C22");
          doc.text(header, data.settings.margin.left, 36);

          // Footer
          let str = "" + doc.internal.getNumberOfPages();
          doc.setFontSize(12);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          let pageSize = doc.internal.pageSize;
          let pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text("Aptitude Migrations", data.settings.margin.left, pageHeight - 10);
          doc.text(575, 830, str);
        }
      });
      doc.save(pdffilename);
    }
     // Export CSV
     const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
    
      // Adjust column widths
      const columnHeaders = Object.keys(apiData[0]);
      columnHeaders.forEach((header, index) => {
        const columnWidth = getColumnWidth(header, apiData);
        ws['!cols'] = ws['!cols'] || [];
        ws['!cols'][index] = { width: columnWidth };
      });
    
      // Bold column headers and center the text
      const headerStyle = { font: { bold: true }, alignment: { horizontal: 'center' } };
      const headerRange = XLSX.utils.decode_range(ws['!ref']);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const columnHeaderCell = XLSX.utils.encode_cell({ r: 0, c: col });
        if (!ws[columnHeaderCell]) ws[columnHeaderCell] = {};
        ws[columnHeaderCell].s = headerStyle;
        ws[columnHeaderCell].v = `${columnHeaders[col]} (${col + 1})`; // Add index to the header
        ws[columnHeaderCell].t = 's'; // Treat the header value as a string
      }
    
      // Center align all cells
      const dataRange = XLSX.utils.decode_range(ws['!ref']);
      for (let row = dataRange.s.r; row <= dataRange.e.r; row++) {
        for (let col = dataRange.s.c; col <= dataRange.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          if (!ws[cell]) ws[cell] = {};
          if (!ws[cell].s) ws[cell].s = {};
          ws[cell].s.alignment = { horizontal: 'center' };
        }
      }
    
      // Center align the first column
      for (let row = dataRange.s.r; row <= dataRange.e.r; row++) {
        const cell = XLSX.utils.encode_cell({ r: row, c: 0 });
        if (!ws[cell]) ws[cell] = {};
        if (!ws[cell].s) ws[cell].s = {};
        ws[cell].s.alignment = { horizontal: 'center' };
      }
    
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };
    
    // Helper function to calculate column width based on the largest text
    const getColumnWidth = (header, data) => {
      const columnData = data.map(row => String(row[header] || ''));
      const maxLength = Math.max(...columnData.map(text => text.length));
      const width = (maxLength > 20 ? maxLength : 20) + 2; // Add some padding
      return width;
    };
    

  return(
    <>
    <button icon={<FileExcelOutlined />} className="btn btn-primary ml-1" type='primary' onClick={() => exportToCSV(data, xlsxfilename)}>
     <FaIcons.FaFileExcel size={12} color={'rgb(255 255 255 / 94%)'} />Excel</button>

     <button type="text" className="btn btn-primary ml-1"  onClick={() => printDocument()}>
     <FaIcons.FaFilePdf size={12} color={'rgb(255 255 255 / 94%)'} />PDF</button>

     <CSVLink data={data} filename={csvfilename} className="btn btn-primary ml-1" target="_blank">
     <FaIcons.FaFileCsv size={12} color={'rgb(255 255 255 / 94%)'} />CSV</CSVLink>
    </>
    )
}
export default ExportOptions
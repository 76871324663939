
import React from 'react';


class ViewAppointments extends React.PureComponent {
    constructor(props) {
        super(props);

      }

  componentDidMount() {
    
  }
  
  render() {
    return (
      <>
        
      </>
      );
      }
    }
    
export default ViewAppointments

import React, { Component } from 'react'
import Search from '../GenericComponents/Search'
import { delete_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import ExportOptions from '../GenericComponents/ExportOptions'
import Title from '../GenericComponents/Title'
import Paginations from '../GenericComponents/Pagination'
import { Link } from 'react-router-dom'
import {Tabs, Tab, Row, Col} from "react-bootstrap";
import Loader from '../Loader/Loader'
import swal from 'sweetalert'
import { Sorting } from '../GenericComponents/SortTables'
import { FaEdit, FaEllipsisH, FaTrashAlt } from 'react-icons/fa'

class ViewAgents extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            totalItem:'',
            currentPage: 1,
            allAgents: [],
            loading: false,
            lastPage:'',
            perPage: 10,
            search:'',
            order:'ASC',
            key:'office',
            perItem:'',
            allPermissions: permissions,
            headers: [
                {title: "#" , dataKey: "index", align: "text-center", key:1},
                {title: "Agent Name" , dataKey: "agentname", key:2},
                {title: "Agency Name", dataKey: 'agencyname', align:"text-left", key:3},
                {title: "Type", dataKey: "type", align: "text-center", key:4},
                {title: "Contact" , dataKey: "contact", align: "text-center", key:5},
                {title: '', dataKey: '', key:6},
                {title: '', dataKey: '', key:7},
            ],
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getAgents();
        document.addEventListener('click', this.handleClickOutside.bind(this), true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside.bind(this), true);
    };
    handleClickOutside(event) {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.props.onClickOutside && this.props.onClickOutside();
            if (!this.ref.current.contains(event.target)) {
                this.setState({ open: false });
            }
        }
    };
    handleClick= (id) => {
        this.setState({clickedId: id});
        if(this.state.open === true) this.setState({open:false});
        else this.setState({open:true})
    }

    async getAgents() {
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        var type= '';
        if(this.state.key === 'office'){
            type = 1;
        } else if(this.state.key === 'sub'){
            type = 2;
        } else if(this.state.key === 'camp'){
            type = 3;
        } else if(this.state.key === 'super'){
            type = 5;
        } else if(this.state.key === 'bothext'){
            type = 7;
        }else {
            type = 0;
        }
        let url = `getagents/${type}`;
        console.log(url);
        this.setState({loading: true});
        view_ApiManager(url, currentPage, perPage,(res) => {
        console.log('Get All Agents',res);
        this.setState({
            allAgents: res.data.data.data,
            loading: false,
            totalItem: res.data.data['total'],
            perPage: res.data.data['per_page'],
            lastPage: res.data.data['last_page'],
            perItem: res.data.data['data'].length,
        });
        },(err) => {
            this.setState({loading: false});
            console.log(err);
        });
    }
      //Delete a user
      async deleteAgent(delete_id, name) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover ("+ name +")",icon: "warning", buttons: true, dangerMode: true,
          }).then((willDelete) => {
            if (willDelete){
            const id = delete_id;
            let url = 'agents';
            delete_ApiManager(id, url,(res) => {
            console.log('User Deleted',res);
            swal("User ("+ name +")"+" deleted successfully", {icon: "success" });
            this.getAgents(); 
            this.setState({loading: false});
        },(err) => {
            this.setState({loading: false});
            console.log(err);
        }); }   
       });
    }
    // CallBack function to get changed status name and value and call api to save results
    handleCallback = (i, perPage) =>{
        window.scrollTo(0, 0);
        if(this.state.search == ''){
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => { this.getAgents();  }); 
            } else { this.setState({ currentPage : i}, () => { this.getAgents(); }); }
        } else{
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => { this.handleSearchCallback(this.state.search);  }); 
            } else { this.setState({ currentPage : i}, () => { this.handleSearchCallback(this.state.search); }); }
        }   
    }
     // Table sorting
     sorting = (col) => {
        const sortResult = Sorting(this.state.allAgents, col, this.state.order)
        this.setState({order: sortResult[0], allAgents: sortResult[1]})
    }
      //Search Api
    handleSearchCallback = (keyword) => {
        if(keyword){
            this.setState({search: keyword});
            let currentPage = this.state.currentPage;
            let perPage = this.state.perPage;
            let url = `search/agents/${keyword}`;
            view_ApiManager(url, currentPage, perPage,(res) => {
                console.log('search result',res);
                this.setState({
                    allAgents: res.data.data['data'],
                    totalItem: res.data.data['total'],
                    perPage: res.data.data['per_page'],
                    lastPage: res.data.data['last_page'],
                    perItem: res.data.data['data'].length,
                });
            },(err) => {
                console.log(err);
            });
        } else {
            this.setState({currentPage:1});
            this.getAgents();
        }
    }
    data = (count) => {
        const role = JSON.parse(localStorage.getItem('user_role'));
        return(
        <div eventKey={this.state.key} className="row w-100 pl-1 pr-1 mb-1">
        {this.state.allPermissions['agents'] === 1 ?
        <>
        {/* search bar */}
        <Search 
        onSearchChange= {this.handleSearchCallback}  
        /> 
        {/* render table  */}
        <div className="table-responsive table-bordered mb-2">
        <table id="viewagents" className="table bg-white mb-0">
        {/* Headers */}
        <thead className="thead-dark">
        <tr>
        {this.state.headers.map(head => (
        <th id={head.dataKey} className={head.align} onClick={() => this.sorting(head.dataKey)} 
        key={head.key}>
        {head.title}</th>
        ))}
        </tr>
        </thead>
        {/* End Headers */}
        <tbody>
        {this.state.allAgents.map(value => 
        <tr key={value.id}>
        <td className="text-center">{count++}</td>
        <td className="name-text">{value.agentname}</td>
        <td className="text-left">{value.agencyname}</td>
        <td className="text-center">{value.type }</td>
        <td className="text-center">{value.contact}</td>
        <td className="text-center">
        <Link to={`/agents/view-agent/${value.id}/${'detail'}`} className=" underline"> View Detail </Link></td>
        <td className="text-center">
        <span className="wrapper icon-ref">
        <span className="dot-icon">
        <a className="a-ref-icon" onClick ={() => this.handleClick(value.id)}>
        <FaEllipsisH size={12} color={'rgb(107 107 107 / 71%)'} />
        </a>
        {this.state.open && this.state.clickedId === value.id &&
        <div className="actions-dots-dropdown">
        <Link to={`/agents/view-agent/${value.id}/${'edit'}`}
        className="btn btn-primary pl-3 pr-3 p-0 m-1 mt-2"> 
        <FaEdit size={12} color={'#fffcfc'} /> 
        </Link><br/>
        <a onClick ={() => this.deleteAgent(value.id, value.agentname)} 
        className="btn btn-danger pl-3 pr-3 p-0 m-1 mb-2"> 
        <FaTrashAlt size={12} color={'#fffcfc'} /> </a>
        </div>} </span>
        </span>
        </td>
        </tr>
        )}
        </tbody>
        </table>
        </div>
        <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handleCallback} 
        />
          {/* Exports */}
        {role === 2 &&
        <div className="d-flex mb-3">
        <ExportOptions
         pdffilename={'Agents.pdf'} 
         csvfilename={'Agents.csv'} 
         header="Agents List"
         xlsxfilename={'Agents'} 
         columns={this.state.headers}
         data={this.state.allAgents} />
        </div>}
        {/* End Exports */}
        </>: 'You dont have permission to access this pages!'}
        </div>
        )
    }
    changeTabs = (tab) => {
       this.setState({key: tab, currentPage:1}, () => this.getAgents());
    }


 render(){
     let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
     return(
         <>
         <Title title="View Agents"
         btnCol="col-lg-8 text-center pt-lg-1" 
         btn={true}
         btnText="Add Agent" 
         btnClass="float-right btn btn-success"
         btnUrl="/agents/add-agent" />
         {this.state.loading ? <Loader /> 
         :
         <Row className="row w-100 pl-3 pr-3 mb-5">
         <Col>
         <Tabs activeKey={this.state.key} onSelect={(k) => this.changeTabs(k)} 
         defaultActiveKey="office" className="app-tabs ml-0 border rounded">

            {/* Office */}
         <Tab eventKey="office" title="Aptitude Office">
         {this.data(count)}
         </Tab>

            {/* Sub Agent */}
         <Tab eventKey="sub" title="External Referring Agent">
         {this.data(count)}
         </Tab>

        {/* Camp */}
        <Tab eventKey="super" title="External Processing Agent">
         {this.data(count)}
         </Tab>

            {/* Camp */}
         <Tab eventKey="camp" title="Campaign">
         {this.data(count)}
         </Tab>

         {/* <Tab eventKey="external" title="External Agent">
         {this.data(count)}
         </Tab> */}
         <Tab eventKey="bothext" title="External Processing And Referring Agent">
         {this.data(count)}
         </Tab>

         <Tab eventKey="other" title="Others">
         {this.data(count)}
         </Tab>
         
         </Tabs>
         </Col>
         </Row>}
         </>
    )
  }
}

export default ViewAgents

import React from 'react';

function SeenStatus(props) {
    return(
        <>
         {props.seenStatus && props.seenStatus != 0 ?
        <div className="bell-text"><small className="bell-text1">{props.seenStatus}</small></div> : '' }
        </>
    )
}
export default SeenStatus;
import React from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import DOMPurify from 'dompurify';

const InternalNotePDF = ({ data = {} }) => {
  const downloadPdf = async () => {
    // Create a temporary div to hold the content dynamically
    const tempDiv = document.createElement('div');
    tempDiv.style.width = '800px';
    tempDiv.style.padding = '20px';
    tempDiv.style.backgroundColor = 'white';
    tempDiv.style.border = '4px solid rgb(5, 168, 185)';
    tempDiv.style.fontFamily = 'Arial, sans-serif';
    const formatTaskStatus = (status) => {
        switch (status) {
          case 1:
            return 'Partially Completed';
          case 2:
            return 'Not Completed';
          case 3:
            return 'Completed';
            case 0:
                return 'Pending'
          default:
            return '-';
        }
      };
      

    tempDiv.innerHTML = `
      <div style="text-align: center; background: black; color: white; padding: 20px; margin-bottom: 20px;">
        <h1 style="margin: 0; font-family: 'Montserrat', sans-serif;">Meeting Note</h1>
          <h3 style="text-align: center; color: #88ffd7; font-family: 'Montserrat', sans-serif;">
        (${moment(data['created_at']).format('D MMM YYYY')})
      </h3>
      </div>

      ${generateSection('AGENDA', data['agenda'])}
      ${generateSection('MANAGED BY', data['manager_ids']?.map(v => v.label).join(', '))}
      ${generateSection('MEETING ATTENDEES', data['participants']?.map(v => v.label).join(', '))}
      ${generateSection('DATE & TIME', `${moment(data['meeting_date']).format('D MMM YYYY')} ${data['start_time']} - ${data['end_time']}`)}
      ${generateSection('DUE DATE', data['due_date'] ? moment(data['due_date']).format('D MMM YYYY') : '')}
      ${generateSection('DISCUSSION', '', true, data['discussion_notes'])}
      ${generateSection('TASK STATUS', formatTaskStatus(data['task_status']))}
      ${generateSection('STATUS NOTES', data['task_notes'])}
    `;

    document.body.appendChild(tempDiv);

    try {
      const canvas = await html2canvas(tempDiv, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const imgWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let y = 0;
      let position = 0;

      while (heightLeft > 0) {
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        position -= pageHeight;
        if (heightLeft > 0) {
          pdf.addPage();
        }
      }

      pdf.save('internal_note.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      document.body.removeChild(tempDiv);
    }
  };

  // Function to generate a section with a heading and content
  const generateSection = (title, content, isHtml = false, htmlContent = '') => {
    if (isHtml) {
      return `
        <div style="display: flex; border-bottom: 4px solid rgb(153, 229, 144); margin-bottom: 10px;">
          <div style="width: 25%; background-color: #f8f9fa; border-right: 4px solid #3498db; padding: 10px; font-weight: bold;">
            ${title}
          </div>
          <div style="width: 75%; padding: 10px 30px; white-space: pre-wrap;">
            ${DOMPurify.sanitize(htmlContent)}
          </div>
        </div>
      `;
    }

    return `
      <div style="display: flex; border-bottom: 4px solid rgb(153, 229, 144); margin-bottom: 10px;">
        <div style="width: 25%; background-color: #f8f9fa; border-right: 4px solid #3498db; padding: 10px; font-weight: bold;">
          ${title}
        </div>
        <div style="width: 75%; padding: 10px 20px;">
          ${content || '-'}
        </div>
      </div>
    `;
  };

  return (
    <div className='text-right'>
      <button className="btn btn-primary ms-3 mb-2" onClick={downloadPdf}>
        Export To PDF
      </button>
    </div>
  );
};

export default InternalNotePDF;

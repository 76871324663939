
import React, { useEffect, useState } from "react";
import * as FaIcons from 'react-icons/fa';
import { Link, useNavigate} from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import swal from "sweetalert";
import { get_ApiManager, post_ApiManager } from "../ApiManager/ApiManager";
import '../Providers/provider.css';
import InternalMeetingModel from "../MeetingNotes/InternalNotes";

const Title = (props) => {
    const history = useNavigate();
    const [selectedBranches , setSelectedBranches] = useState([]);
    const [branchList , setBranchList] = useState([]);
    const [addMeeting ,setAddMeeting] = useState(false);

    useEffect( () => {
        if(props.showBranches){
            getAllowedBranches();
            getSelectedBranches();
       }
      }, []);

      
      const getSelectedBranches = () => {
        const data = '';
        let url = 'getSelectedBranches'
        get_ApiManager(data, url,(res) => {
          console.log('selected-branches',res);
          setSelectedBranches(res.data.data)
        },(err) => {
          throw err;
        }); 
      }

     const getAllowedBranches = () => {
        const data = '';
        let url = 'getAllowedBranches'
        get_ApiManager(data, url,(res) => {
          console.log('branches',res);
          setBranchList(res.data.data);
        },(err) => {
          throw err;
        }); 
      }
      
    const onRemove = (selectedList, selectedItem) => {
        submitBranches(selectedItem);
    }
    const onSelect = (selectedList, selectedItem) => {
          submitBranches(selectedItem);
      };
    const submitBranches = (branches) => {
        let url = 'saveBranches';
        const data = {
          branches: branches,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('saved-branches',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else { 
            getSelectedBranches();
            props.parentCallback() 
          } 
        },(err) => {
            console.log(err);
        })
    }
    const handleCallback = () => {
      props.parentCallback() 
      setAddMeeting(false)
    }

    return(
        <>
        {props.back && !props.btn ? 
        <div className="p-1">
        <div className="col-12 text-left pl-0"> <span className="pb-2 pt-0">
            <a className="back-icon pl-lg-2" onClick={() => props.backUrl ? history( '/main-settings') : history(-1)}>
            <FaIcons.FaArrowAltCircleLeft size={20}/><span className="back-text pl-lg-1">back</span>
            </a></span>
        </div>
        <div className="row">
          {/* <h4 className="pl-lg-2 page-title text-left custom-font4 bold pt-0 mb-0">{props.title}</h4> */}
          <h4 className={`text-left custom-font4 bold ${!props.back ? 'pt-1 pl-2' : ' pl-lg-3' } ${props.titleCol ? props.titleCol : 'col-lg-9'}`}>{props.title}</h4>
       
        {props.showBranches &&
        <div className="col-lg-3 col-5">
        <Multiselect
            options={branchList.slice().sort((a, b) => a.agencyname.localeCompare(b.agencyname))}
            className="form-select float-right chip-hide s-dropdown py-1 shadow-sm"
            placeholder={"Select Branch/s"}
            displayValue={'agencyname'}
            onSelect={onSelect}
            showCheckbox={true}
            disablePreSelectedValues={false}
            hidePlaceholder={false}
            onRemove={onRemove}
            selectedValues={selectedBranches}
            />
        {/* <select className="form-select app-form float-right" onChange={(e) => props.parentCallback(e.target.value)}>
        <option selected value={'null'}>Select Branch</option>
        {[].concat(props.showBranches).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(value =>
        <option key={value.id} className="light" value={value.id}>{value.agencyname}</option> )}
        </select>  */}
        </div>}
        {props.showTask &&
            <div className={`${props.taskClass ? props.taskClass : 'col-lg-2 pt-1'}`}>
            <Link to={`/task-report`}>
              <button className="btn btn-warning white w-100 fira-font">Progress Report<FaIcons.FaRegCalendarAlt size={15} /></button>
              </Link>
            </div> }

            {props.addMeeting &&
            <div onClick={() => setAddMeeting(true)} className={`${props.meetingClass ? props.meetingClass : 'col-lg-2 pt-1'}`}>
              <button className="btn btn-success white w-100 fira-font">Create Meeting Notes<FaIcons.FaRegAddressCard className="ms-1" size={15} parentCallback={handleCallback} /></button>
            </div> }
            <InternalMeetingModel show={addMeeting} parentCallback={handleCallback}/>
        </div>
        </div>
        :
        <div className="p-1" >
        <div className="row pr-2">
            {props.back && 
            <span className="pt-0 col-11">
            <a className="back-icon pl-lg-2" onClick={() => props.backUrl ? history( '/main-settings') : history(-1)}>
            <FaIcons.FaArrowAltCircleLeft size={20}/><span className="back-text pl-lg-1">back</span>
            </a></span>
            }
            <h4 className={`text-left custom-font4 bold ${!props.back ? 'pt-1 pl-2' : ' pl-lg-3' } ${props.titleCol ? props.titleCol : 'col-lg-4'}`}>{props.title}</h4>
            {props.btn ? 
            <div className={`${props.btnCol ? props.btnCol : 'col-lg-6'}`}>
            <button  type="button"  
            onClick={() => props.btnUrl ? history(props.btnUrl) : props.parentCallback() }
            className={`${props.btnClass ? props.btnClass: ''}`}>{props.btnText}</button>
            </div>
            : ''}

            {props.showTask &&
            <div className={`${props.taskClass ? props.taskClass : 'col-lg-2 pt-1'}`}>
            <Link to={`/task-report`}>
              <button className="btn btn-warning white w-100 fira-font">Progress Report<FaIcons.FaRegCalendarAlt className="ms-1" size={15} /></button>
              </Link>
            </div> }

            {props.showMeeting &&
            <div className={`${props.meetingClass ? props.meetingClass : 'col-lg-2 pt-1'}`}>
             <Link to={`/view-internal-notes`}>
              <button className="btn btn-danger white w-100 fira-font">Meeting Notes<FaIcons.FaRegAddressCard className="ms-1" size={15} /></button>
              </Link>
            </div> }

            {props.showBranches &&
            <div className={`${props.branchClass ? props.branchClass : 'col-lg-8 pt-1'}`}>
            <Multiselect
            options={branchList.slice().sort((a, b) => a.agencyname.localeCompare(b.agencyname))}
            className={`${props.branchInnerClass ? props.branchInnerClass+ ' form-select float-right chip-hide s-dropdown py-1 shadow-sm' : 'form-select float-right chip-hide s-dropdown py-1 shadow-sm'}`}
            placeholder={"Select Branch/s"}
            displayValue={'agencyname'}
            onSelect={onSelect}
            showCheckbox={true}
            disablePreSelectedValues={false}
            hidePlaceholder={false}
            onRemove={onRemove}
            selectedValues={selectedBranches}
            />
            </div>}
            {/* <div className={`${props.branchClass ? props.branchClass : 'col-lg-8 pt-1'}`}>
            <select className={`${props.branchInnerClass ? props.branchInnerClass+ ' form-select app-form float-right ' : 'form-select app-form float-right'}`}
             onChange={(e) => props.parentCallback(e.target.value)}>
            <option selected value={'null'}>Select Branch</option>
            {[].concat(props.showBranches).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(value =>
            <option key={value.id} className="light" value={value.id}>{value.agencyname}</option> )}
            </select> 
            </div>} */}
        </div>
        </div>
        }
        </>
    ) 
}
export default Title;

import React, { Component } from 'react'
import Title from '../GenericComponents/Title'
import { Link } from 'react-router-dom'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import Paginations from '../GenericComponents/Pagination'
import MultipleSearch from '../GenericComponents/MultipleSearch'
import { Sorting } from '../GenericComponents/SortTables'
import { get_ApiManager, post_ApiManager, put_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import swal from 'sweetalert'
import { main_url } from '../../utilis/Common'
import moment from 'moment'
import Modals from '../GenericComponents/Modal'

class ManageLeaves extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            order: 'ASC',
            key:'home',
            currentPage: 1,
            totalItem:'',
            lastPage:'',
            perPage: 10,
            perItem:'',
            allPermissions: permissions, 
            allStatus: [],
            allLeaves:[],
            openModel: false,
            status:'',
            id:'',
            days:'',
            user_id:'',
            allWork:[],
            status_headers: [
                {title: 'Employee' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Casual (10)', dataKey: "casual", align: "text-center" ,key:3, checked: true, canChange: true},
                {title: 'Sick (16)', dataKey: "sick", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Annual (14)' , dataKey: "annual",align: "text-center", key:5, checked: true, canChange: false},
            ],
            headers: [
                {title: 'Applied On' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Leave Type' , dataKey: "type",align: "text-center", key:3, checked: true, canChange: false },
                {title: 'Start Date', dataKey: "startdate", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'End Date', dataKey: "enddate", align: "text-center" ,key:5, checked: true, canChange: true},
                {title: 'Reason' , dataKey: "reason",align: "text-center", key:6, checked: true, canChange: false},
                {title: 'Duration' , dataKey: "duration",align: "text-center", key:7, checked: true, canChange: false},
                {title: 'Remarks' , dataKey: "remarks",align: "text-center", key:8, checked: true, canChange: false},
                {title: '' , dataKey: "",align: "text-center", key:9, checked: true, canChange: false},
            ],
            work_headers: [
                {title: 'Name' , dataKey: "name",align: "text-center", key:1, checked: true, canChange: false },
                {title: 'Leave Type' , dataKey: "type",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Start Date', dataKey: "startdate", align: "text-center" ,key:3, checked: true, canChange: true},
                {title: 'End Date', dataKey: "enddate", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Status' , dataKey: "status",align: "text-center", key:5, checked: true, canChange: false},
            ],
        }
    }
    componentDidMount() {
        this.getLeavesData();
    }
     // Get Leaves Data
     async getLeavesData (){
        this.setState({loading: true});
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = 'allLeaves';
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('leaves',res);
            this.setState({
            allLeaves: res.data.data['data'],
            totalItem: res.data.data['total'],
            perPage: res.data.data['per_page'],
            lastPage: res.data.data['last_page'],
            perItem: res.data.data['data'].length,
            });
        },(err) => {
            console.log(err);
        });
    }
     // Get Leaves Data
     async getHomeWork (){
        this.setState({loading: true});
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = 'workHomeLeaves';
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('workfromhome',res);
            this.setState({
            allWork: res.data.data['data'],
            totalItem: res.data.data['total'],
            perPage: res.data.data['per_page'],
            lastPage: res.data.data['last_page'],
            perItem: res.data.data['data'].length,
            });
        },(err) => {
            console.log(err);
        });
    }
     // Get all users from apis 
     async getStatus() {
        let url = 'getAllStatus';
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
        console.log('All Status',res);
        this.setState({
            allStatus: res.data.data['data'],
            totalItem: res.data.data['total'],
            perPage: res.data.data['per_page'],
            lastPage: res.data.data['last_page'],
            perItem:res.data.data['data'].length,
        });
        },(err) => {
            console.log(err);
        });
    }
      // Table sorting
     sorting = (col) => {
        if(this.state.key === 'home'){
            // const sortResult = Sorting(this.state.users, col, this.state.order)
            // this.setState({order: sortResult[0], users: sortResult[1]})
        } else{
            const sortResult = Sorting(this.state.allStatus, col, this.state.order)
            this.setState({order: sortResult[0], department: sortResult[1]})
        }
     }
      // CallBack function handle pagination
    handlePaginationCallback = (i, perPage) =>{
        if(perPage && perPage != ''){
            this.setState({ perPage : perPage, currentPage:1}, () => {  
            if(this.state.key === 'home'){this.getLeavesData()}
            else if(this.state.key === 'status'){ this.getStatus()}
            else if(this.state.key === 'workfromhome'){ this.getHomeWork() }  }); 
        } else {this.setState({ currentPage : i}, () => { 
            if(this.state.key === 'home'){this.getLeavesData()}
            else if(this.state.key === 'status'){ this.getStatus()}
            else if(this.state.key === 'workfromhome'){ this.getHomeWork() }
         }); }
    }

    updateValue = (id, name, value) => {
        const data = {
            id: id,
            name: name,
            value: value,
        }
        let url = 'leaves';
        put_ApiManager(data, id, url,(res) => {
            console.log('updated' , res);
            if(res.data.message){
                swal("Success!", res.data.message, "success");  
                this.getStatus();
            } 
        },(err) => {
            console.log(err);
        });
    }
    setKey = (k) => {
        if(k === 'home'){
            this.getLeavesData();
        } else if(k === 'status'){
            this.getStatus();
        } else if(k === 'workfromhome'){
            this.getHomeWork();
        }
        this.setState({key: k, currentPage:1});
    }
     // Add Status note
    handleSimpleNoteCallback = (check, type , note) => {
        if(type == 'edit'){
           if(note == '' ){
              swal({ text: "Please enter remarks!", icon: "warning", dangerMode: true })
           } else {
            this.changeStatus(note);
            this.setState({openModel: false});
           }
        }  else this.setState({openModel: false});
    }
    // Change status of application
    changeStatus = (remarks) => {
        const data = {
            remarks : remarks,
            status : this.state.status,
            id : this.state.id,
            days: this.state.days,
        }
        let url = 'changeStatus';
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('status-changed',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {  
                swal("Success!", res.data.message, "success");
                this.getLeavesData();
            }
        },(err) => {
            console.log(err);
        });
    }
    updateDays = (days, id) => {
        const data = {
            id : id,
            days: days,
        }
        let url = 'changeDays';
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('days-changed',res);
            this.getLeavesData();
            
        },(err) => {
            console.log(err);
        });
    }

    render(){
        return(
            <>
            <Title title="Leaves Management"/>
            <div className="row w-100 pl-3 pr-3 mb-4">
            <div className="row w-100">
            {this.state.allPermissions['manage_leaves'] === 1 ?
            <Row className="w-100">
            <Col>
            <Tabs activeKey={this.state.key} defaultActiveKey="home" className="app-tabs ml-0 border rounded mt-0"
            onSelect={(k) => this.setKey(k)}>
              {/* Leave Applications */}
            <Tab eventKey="home" title="Leave Applications">
            <div className="row w-100 pr-1 mb-4 pl-1">
             <div className="table-responsive mx-2 mx-lg-0 mb-2">
             <table className="table table-bordered bg-white mb-0">
             {/* Headers */}
             <thead className="bg-primary white">
             <tr>
             {this.state.headers.map(head => (
             head.checked &&
             <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th>  ))}
             </tr>
             </thead>
             {/* End Headers */}
             <tbody>
             {this.state.allLeaves.map(value => (
                <tr key={value.id}>
                <td className='text-center'>{moment(value.applied_on).format('D MMM YYYY')}</td>
                <td className='text-center'>{value.name}</td>
                <td className='text-center'>{value.type}</td>
                <td className='text-center'>{value.startdate}</td>
                <td className='text-center'>{value.duration === 'half' ? value.startdate: value.enddate}</td>
                <td className='text-center'>{value.reason}<br/>
                {value.document ?
                <a href={main_url()+`${value.document}`} target="_blank"> View File </a>:''}</td>
                <td className='text-center'> {value.duration} day =
                <div className='d-flex'>
                <input className='form-control acc-form text-center' onChange={(e) => this.updateDays(e.target.value, value.id)} defaultValue={value.days ? value.days : value.dayss} /> 
                <span className='pt-2'> days </span> </div>
                {value.duration === 'half' ? <>[{value.time_in} to {value.time_out}]</>:''} </td>
                <td className='text-center'>{value.remarks}</td>
                 <td className='text-center w-20'>
                 {value.status !== 'Pending' ? 
                 value.status :
                 <>
                 <button className='btn btn-success' 
                 onClick={() => this.setState({openModel:true, status: 'approval', id: value.id, days: value.days ?value.days: value.dayss})}>Approve</button>
                 <br/>
                 <button className='btn btn-danger mt-2 px-3'
                 onClick={() => this.setState({openModel:true, status: 'rejected', id: value.id, days: value.days})}>Reject</button>
                 </>}
                 </td>
                </tr>
              )
             )}
             </tbody>
             </table>
             </div>
             <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              /> 
             </div>
            </Tab>
              {/* Leave Status */}
            <Tab eventKey="status" title="Leave Statuses">
            <div className="row w-100 pr-1 mb-4 pl-1">
             <div className="table-responsive mx-2 mx-lg-0 mb-2">
             <table className="table table-bordered bg-white mb-0">
              {/* Headers */}
             <thead className="bg-primary white">
             <tr>
             {this.state.status_headers.map(head => (
             head.checked &&
             <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th>  ))}
             </tr>
             </thead>
             {/* End Headers */}
             <tbody>
             {this.state.allStatus.map(value => (
              <tr key={value.id}>
              <td className='text-center w-25 name-text'>{value.name}</td>
              <td className='text-center'>
              <div className="form-group d-flex j-content-center">
              <input className='form-control acc-form w-25 mr-2 bold text-center' defaultValue={value.casual} onChange={(e) => this.updateValue(value.id, 'casual', e.target.value)} name='casual' />  <span className='error pt-1 bold'> {10-value.casual}</span></div></td>
              <td className='text-center'>
              <div className="form-group d-flex j-content-center">
              <input className='form-control acc-form w-25 mr-2 bold text-center' defaultValue={value.sick} onChange={(e) => this.updateValue(value.id, 'sick', e.target.value)} name='sick' />  <span className='error pt-1 bold'> {16-value.sick}</span></div>
              </td>
              <td className='text-center'>
              <div className="form-group d-flex j-content-center">
              <input className='form-control acc-form w-25 mr-2 bold text-center' defaultValue={value.annual} onChange={(e) => this.updateValue(value.id, 'annual', e.target.value)} name='annual' />  <span className='error pt-1 bold'>{14-value.annual}</span></div>
              </td>
              </tr>
             ))}
             </tbody>
             </table>
             </div>
             <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              /> 
             </div>
            </Tab>

             {/* Leave Status */}
             <Tab eventKey="workfromhome" title="Work from Home">
            <div className="row w-100 pr-1 mb-4 pl-1">
             <div className="table-responsive mx-2 mx-lg-0 mb-2">
             <table className="table table-bordered bg-white mb-0">
              {/* Headers */}
             <thead className="bg-primary white">
             <tr>
             {this.state.work_headers.map(head => (
             head.checked &&
             <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th>  ))}
             </tr>
             </thead>
             {/* End Headers */}
             <tbody>
             {this.state.allWork.map(value => (
              <tr key={value.id}>
              <td className='text-center w-25 name-text'>{value.name}</td>
              <td className='text-center'>{value.type}</td>
              <td className='text-center'>{value.startdate}</td>
              <td className='text-center'>{value.enddate}</td>
              <td className='text-center'>{value.status}</td>
              </tr>
             ))}
             </tbody>
             </table>
             </div>
             <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              /> 
             </div>
            </Tab>
            </Tabs>
            </Col>
            </Row>: 'you dont have permission to access this page!'}
            </div>
            </div>
            <Modals
                show={this.state.openModel}
                title={'Remarks'}
                data={this.state.status === 'approval' ? 'Add Approval Remarks' : 'Add Rejection Remarks'} 
                type={'category'}
                noteType={false}
                textarea="true"
                parentCallback = {this.handleSimpleNoteCallback}
            />
            </>
        )
    }
}
export default ManageLeaves
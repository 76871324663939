
import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import PhoneInput from 'react-phone-number-input';
import swal from 'sweetalert';
import { post_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import { useNavigate } from "react-router-dom";
import { agent_type, note_type } from '../../utilis/appStatus';

export const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
} 

class AddAgent extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            agentNameError: null, agencyNameError: null, emailError: null, phoneError: null, countryCodeError: null, typeError:null, managerError:null,
            agentName: '',
            agencyName: '',
            email: '',
            phone:'',
            type:'',
            countryCode: +61,
            defaultCountry:'AU',
            description:'',
            allPermissions: permissions,
            manager: '',
            allUsers:[],
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleValidation = (e) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        this.setState({agentNameError: null, emailError: null, phoneError: null, agencyNameError: null, managerError:null});

        if(!this.state.agentName){
            this.setState({agentNameError : 'Please enter agent name'})
            return false }
        else if(!this.state.agencyName){
            this.setState({agencyNameError : 'Please enter phone'})
            return false }
        else if(!this.state.type){
            this.setState({typeError : 'Please select type'})
            return false }
        else if(this.state.type === 2 && !this.state.manager){
            this.setState({managerError : 'Please select handling manager'})
            return false }
        else if(!this.state.email){
            this.setState({emailError: 'Please enter email'})
            return false }
        else if(!pattern.test(this.state.email)){
            this.setState({emailError: 'Please enter correct email'})
            return false }
        else if(!this.state.phone){
            this.setState({phoneError : 'Please enter phone'})
            return false }
        else if(!this.state.phone.length > 13){
            this.setState({phoneError : 'invalid phone'})
            return false }
        else return true;
    }

    //set input values
     handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    //submit form
    handleSubmit(event) {
        event.preventDefault();
        if (this.handleValidation()) {
            const data = {
                agentname : this.state.agentName,
                agencyname : this.state.agencyName,
                contact: this.state.phone,
                countryCode : this.state.countryCode ? this.state.countryCode: '+61',
                email: this.state.email,
                description: this.state.description,
                type: this.state.type,
                manager: this.state.manager
            }
            console.log(data);
            this.setState({loading: true});
            let url = 'agents';
            post_ApiManager(data, url, (res) => {
            console.log('added' , res);
            this.setState({loading: false});
            if(res.data.error) swal(" ", res.data.error, "error")
            else {swal("Success!", "New agent created successfully!", "success"); 
            this.props.navigate('/agents/view-agents')};   
        },(err) => {
            this.setState({loading: false});
            console.log(err);
        });
      }
    }
    getAllUsers = () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            this.setState({allUsers: res.data.data})
        },(err) => {
            console.log(err);
        });
     }

 render(){
     return(
        <>
        <Title title="Add Agent"></Title>
        {this.state.allPermissions['agents'] === 1 ?
        <div className="pt-0 pl-lg-3 pr-lg-3 pl-3 pr-4">
        <div className="row j-content-center mx-3">
            <div className="col-lg-12 bordered scroll mb-5 mb-lg-1 ">
                <div className="text-center pb-2 pt-3 bg-green">
                <h4 className="subtitle">Add Agent</h4>
                </div>
                    <form action='' onSubmit={this.handleSubmit}>
                    <div className="row bg-white p-4 pt-2 pb-0 ">
                    <div className="col-lg-4 pl-lg-3 ">
                        <div className="form-group ">
                        <label className="acc-label2 mb-1">Agent Name*</label>
                        <input type="text" name="agentName" value={this.state.agentName} onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter" />
                            {this.state.agentNameError && <><small className="error">{this.state.agentNameError}</small></>}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                        <label className="acc-label2 mb-1">Agency Name*</label>
                        <input type="text" name="agencyName" value={this.state.agencyName} onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                            {this.state.agencyNameError && <><small className="error">{this.state.agencyNameError}</small></>}
                        </div>
                    </div>
                    <div className="col-lg-4 pt-0">
                        <div className="form-group">
                        <label className="acc-label2 mb-1">Agent Type*</label>
                        <select onChange={this.handleChange} className="form-select acc-form form-select-lg col-lg-11" name="type" >
                        <option value="">Please Select</option>
                        {[].concat(agent_type).sort((a, b) => a.title > b.title ? 1 : -1).map(head => 
                        <option key={head.value} value={head.value}>{head.title}</option>
                        )}
                        </select>
                        {this.state.typeError && <><small className="error">{this.state.typeError}</small></>}
                        </div>
                    </div>
                    <div className="col-lg-4 pl-lg-3">
                        <div className="form-group">
                        <label className="acc-label2 mb-1">Email*</label>
                        <input type="text" name="email" value={this.state.email} className="form-control acc-form w-lg-90" placeholder="Please Enter" onChange={this.handleChange} />
                            {this.state.emailError && <><small className="error">{this.state.emailError}</small></>}
                        </div>
                    </div>
                    <div className="col-lg-4 d-flex">
                        <div className="form-group">
                        <label className="acc-label2 mb-1">Phone*</label>
                        <div className="col-12 d-flex pl-0">
                        <PhoneInput
                        international
                        defaultCountry={this.state.defaultCountry}
                        name='countryCode'
                        className="d-flex col-lg-7 pr-0 pl-0"
                        onChange={countryCode => this.setState({ countryCode})}
                        disabled={this.state.view == true ? 'disabled' : ''}
                        />  
                        <input type="number" name="phone"  onChange={this.handleChange} className="acc-form contact col-5"
                        disabled={this.state.view == true ? 'disabled' : ''} />
                        </div>
                        {this.state.phoneError && <><small className="error">{this.state.phoneError}</small></>}
                        </div>
                    </div>
                    {this.state.type === 2 &&
                    <div className="col-lg-4 pt-1">
                        <div className="form-group">
                        <label className="acc-label2 mb-1">Handling Manager</label>
                        <select onChange={(e) => this.setState({manager: e.target.value})} 
                        className="form-select acc-form form-select-lg col-lg-11">
                        <option value='0' selected> Please Select </option>
                        {[].concat(this.state.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map(value =>
                        <option key={value.id} className="light" value={value.id}>{value.name}</option> )}
                        </select>
                        {this.state.managerError && <><small className="error">{this.state.managerError}</small></>}
                        </div>
                    </div>}
                    <div className="col-lg-8 pl-lg-3">
                        <div className="form-group">
                        <label className="acc-label2 mb-1">Description</label>
                        <textarea className="textarea form-control acc-form" name="description" placeholder="Please Enter" onChange={this.handleChange} rows="4" cols="60"></textarea>
                        </div>
                    </div>
                    <div className="col-12 mb-3 mt-3 scroll">
                        <button type="submit" className="btn btn-success ml-2" onClick={this.handleSubmit}>Create Agent</button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>: 'You dont have permission to access this pages!'}
    </>
     )
  }
}
export default withNavigation(AddAgent)

import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import Paginations from '../GenericComponents/Pagination'
import ExportOptions from '../GenericComponents/ExportOptions'
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom';
import { delete_ApiManager, get_ApiManager, post_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import * as FaIcons from 'react-icons/fa';
import swal from 'sweetalert'
import moment from 'moment';
import { Sorting } from '../GenericComponents/SortTables'
import { Button, Modal } from 'react-bootstrap';
import { eduStatus, visaStatus } from '../../utilis/appStatus';
import MultipleSearchs from '../GenericComponents/MultipleSearchs';
import { activate_status, active_status, category } from '../../utilis/constants';


class ViewProviders extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            name: '', category: '', campus:'', country:'', sDate:'', eDate:'',
            loading:false,
            allProviders:[],
            totalItem:'',
            currentPage: 1,
            lastPage:'',
            perPage: 10,
            open:false,
            order:'ASC',
            serach:'',
            perItem:'',
            search:'',
            allPermissions: permissions,
            activeStatus:1,
            showApplicantList: false,
            coursesApplicantList:[],
            packagesApplicantList:[],
            migCoursesApplicantList:[],
            bulk: false,
            bulkIds:[],
            headers: [
                {title: '#' , dataKey: "index", align: "text-center", key: 1, checked: true, canChange: false },
                {title: 'Provider' , dataKey: "providername", align:"text-center", key: 2, checked: true, canChange: false},
                {title: 'Category', dataKey: "categoryname", align: "text-center", key: 3, checked: true, canChange: true},
                {title: 'Campus' , dataKey: "city", align: "text-center", key: 4, checked: true, canChange: true},
                {title: 'Country', dataKey: "coutry", align: "text-center", key: 5, checked: false, canChange: true},
                {title: 'StartDate', dataKey: 'agreementstartdate', align:"text-center", key: 6, checked: true, canChange: true},
                {title: 'EndDate', dataKey: 'agreementenddate', align:"text-center", key: 7, checked: true, canChange: true},
                {title: 'Applicants', dataKey: 'applicant', align:"text-center", key: 8, checked: true, canChange: true},
                {title: '', dataKey: '', key: 9, checked: true, canChange: false},
                {title: 'CreatedAt', dataKey: 'created_at', align: "text-center", key: 10, checked: false, canChange: true},
                {title: '', dataKey: '', key: 11, checked: true, canChange: false}
            ],
            migApplicantHeaders:[
                {title: '#', align: "text-center", dataKey: "", key:1},
                {title: 'Client Name', align: "text-center", dataKey: "clientname", key:2},
                {title: 'Category' , dataKey: "category",align:"text-center", key:3},
                {title: 'Course Name', dataKey: 'coursename', align:"text-center", key:4},
                {title: 'Status', dataKey: 'statusid', align:"text-center", key:5},
            ],
            eduApplicantHeaders:[
                {title: '#', align: "text-center", dataKey: "", key:1},
                {title: 'Client Name', align: "text-center", dataKey: "clientname", key:2},
                {title: 'Category' , dataKey: "category",align:"text-center", key:3},
                {title: 'Course Name', dataKey: 'coursename', align:"text-center", key:4},
                {title: 'Status', dataKey: 'statusid', align:"text-center", key:5},
            ],
            packageApplicantHeaders:[
                {title: '#', align: "text-center", dataKey: "", key:1},
                {title: 'Client Name', align: "text-center", dataKey: "clientname", key:2},
                {title: 'Category' , dataKey: "category",align:"text-center", key:3},
                {title: 'Package Name', dataKey: 'packagename', align:"text-center", key:4},
                {title: 'Status', dataKey: 'statusid', align:"text-center", key:5},
            ],
        }
    }
    componentDidMount() {
        if(this.state.allPermissions['providers'] === 1){
            this.getProviders();
        }
        document.addEventListener('click', this.handleClickOutside.bind(this), true);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside.bind(this), true);
    };
    handleClickOutside(event) {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.props.onClickOutside && this.props.onClickOutside();
            if (!this.ref.current.contains(event.target)) {
                this.setState({ open: false });
            }
        }
    };
    handleClick= (id) =>{
        this.setState({clickedId: id});
        if(this.state.open === true) this.setState({open:false});
        else this.setState({open:true})
    }

    async getProviders() {
        this.setState({loading: true});
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = 'providers';
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('All providers',res);
            this.setState({
                allProviders: res.data.data['data'],
                loading: false,
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
        },(err) => {
            console.log(err);
        });
    }
        // CallBack function handle pagination
    handlePaginationCallback = (i, perPage) =>{
        window.scrollTo(0, 0);
        if(!this.state.search){
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.getProviders();  }); 
            } else {this.setState({ currentPage : i}, () => { this.getProviders(); }); }
        } else{
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage,currentPage:1}, () => {  this.handleSearchCallback(this.state.search);  }); 
            } else {this.setState({ currentPage : i}, () => { this.handleSearchCallback(this.state.search); }); }
        }
    }
        //Delete a provider
    async handleDelete(delete_id, name) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover ("+ name +")",icon: "warning", buttons: true, dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            const data = delete_id;
            let url = 'providers';
            delete_ApiManager(data, url,(res) => {
                console.log('Provider Deleted',res);
                swal("Provider ("+ name +") "+" deleted successfully", {icon: "success" });
                this.getProviders(); 
             },(err) => {
                 console.log(err);
                }); 
            }   
        });
     }
       // Table sorting
     sorting = (col) => {
        const sortResult = Sorting(this.state.allProviders, col, this.state.order)
        this.setState({order: sortResult[0], allProviders: sortResult[1]})
     }
     //Search Api
     handleSearchCallback = (input1, input2, input3, input4, input5, input6, input7) => {
        let Name, Campus, Category, Country, StartDate, EndDate, ActiveStatus;
        if(input1 === 'clear'){
            this.setState({name: '', category: '', campus:'', country:'', sDate:'', eDate:'', activeStatus: 1}
            ,  () =>  this.getProviders() );
        } else if (input1 === 'yes') {
            Name = this.state.name;
            Campus = this.state.campus;
            Category = this.state.category;
            Country= this.state.country;
            StartDate = this.state.sDate;
            EndDate = this.state.eDate;
            ActiveStatus = this.state.activeStatus;
          } else {
            Name = input1;
            Campus = input2;
            Category = input6;
            Country= input3;
            StartDate = input4;
            EndDate = input5;
            ActiveStatus = input7 === 'Active' ? 1 : input7 === 'Inactive' ? 0 : !input7 ? this.state.activeStatus : 2;
            this.setState({search:'yes', name:Name, campus: Campus, category: Category, country: Country, sDate: StartDate, eDate: EndDate, activeStatus: ActiveStatus});
        }
        const data = {
            name:Name,
            campus:Campus,
            category:Category,
            country:Country,
            startdate: StartDate,
            enddate: EndDate,
            activeStatus: ActiveStatus,
        }
        console.log(data);

        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = `search/providers`;
        search_ApiManager(url, data, currentPage, perPage,(res) => {
            console.log('search result',res);
            this.setState({
                allProviders: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
        },(err) => {
            console.log(err);
        });
    }
    handleColumnsCallback = (selectedColumns) => {
        this.setState({headers: selectedColumns})
     }

    applicantList = (courseid) => {
        let url = `allApplicantList/${courseid}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('applicant-list',res);
            this.setState({
                coursesApplicantList: res.data.data['courses'],
                migCoursesApplicantList: res.data.data['migcourses'],
                packagesApplicantList: res.data.data['packages'],
                showApplicantList:true})
        },(err) => {
            console.log(err);
        })
    }
     // Table sorting
    courseSorting = (col) => {
        const sortResult = Sorting(this.state.coursesApplicantList, col, this.state.order)
        this.setState({order: sortResult[0], coursesApplicantList: sortResult[1]})
    }
    courseMigModalSorting = (col) => {
        const sortResult = Sorting(this.state.migCoursesApplicantList, col, this.state.order)
        this.setState({order: sortResult[0], migCoursesApplicantList: sortResult[1]}) 
    }
     // Table sorting
    packageSorting = (col) => {
        const sortResult = Sorting(this.state.packageApplicantList, col, this.state.order)
        this.setState({order: sortResult[0], packageApplicantList: sortResult[1]})
    }
     // All Bulk
    allBulk = () => {
    const newState = this.state.allProviders.map(obj => {
            return {...obj, checked: true};
    });
    const array = [];
    this.state.allProviders.map(x => {
        array.push({ id: x.id});
    })
    this.setState({allProviders: newState, bulk: true, bulkIds: array});
    }
     // Select Bulk
    selectBulk = () => {
    const newState = this.state.allProviders.map(obj => {
        return {...obj, checked: false};
    });
    this.setState({bulk: !this.state.bulk, bulkIds: [],allProviders: newState});
    }
    onBulkClick = (id, name, checked) => {
        console.log(id, checked)
        if(checked == true){
            const newState = this.state.allProviders.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: true};
                }
                return obj;
            });
            this.setState({bulkIds: [...this.state.bulkIds, { id:id, name:name}], allProviders: newState, bulk: true});
        } else{
            const newState = this.state.allProviders.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: false};
                }
                return obj;
            });
            var array = this.state.bulkIds.filter(function(item) {return item.id !== id});
            this.setState({bulkIds: array, allProviders: newState})
        }
     }
     onBulkDelete = () => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover these providers",icon: "warning", buttons: true, dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let url = 'bulkDeleteProvider';
                const data = {
                    bulkList: this.state.bulkIds,
                }
                console.log(data);
                post_ApiManager(data, url,(res) => {
                    console.log('deleted',res);
                    if(res.data.error) swal(" ", res.data.error, "error")
                    else {
                        swal("Providers has been deleted successfully!", {icon: "success" });
                        this.getProviders(); 
                        this.setState({bulk: false, bulkIds:[]})
                        // this.handleNotification();
                    };   
                },(err) => {
                    console.log(err);
                });
            }
        }); 
     }

  render(){
    const role = JSON.parse(localStorage.getItem('user_role'));
    let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
    const headers = this.state.headers;
      return(
        <>
         <Title title="View Providers" 
            btnCol="col-lg-8 mb-3 text-center pt-lg-1" 
            btn={true}
            btnText="Add Providers" 
            btnClass="float-right btn btn-success"
            btnUrl="/providers/add-provider" 
         />
         {this.state.loading ? <Loader /> : 
         <div className="row w-100 pl-3 pr-3 mb-5">
         {this.state.allPermissions['providers'] === 1 ?
         <>
         <MultipleSearchs
         input1={headers[2]['checked'] && 'Search Name'}
         input1_type={"input"}
         input2={headers[3]['checked'] && 'Search Campus'}
         input2_type={"input"}
         input3={headers[4]['checked'] && 'Search Country'}
         input3_type={"input"}
         input4={headers[6]['checked'] && 'Start Date'}
         input4_type={"date"}
         input5={headers[7]['checked'] && 'End Date'}
         input5_type={"date"}
         input6={headers[5]['checked'] && 'Search Category'}
         input6_type={"dropdown"}
         input6_multiple={"no"}
         input6Data={category}
         input6_filter_by={"title"}
         input7={'Active Status'}
         input7_type={"dropdown"}
         input7_multiple={"no"}
         input7Data={active_status}
         input7_filter_by={"title"}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={headers}
         />
         
         <div className='w-100 mr-2 text-right'>
        <small className='underline pointer heading-blue' onClick={() => this.allBulk()}> Select All  </small>
        <FaIcons.FaRegCheckSquare color='#4343ff' onClick={() => this.selectBulk()} className='underline' size={14} />
        </div>

         <table id="viewproviders" className="table table-bordered bg-white">
         {/* Headers */}
         <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         head.checked &&
         <th id={head.dataKey} className={head.align} onClick={() => this.sorting(head.dataKey)} key={head.key}>
         {head.title}</th>
         ))}
         </tr>
         </thead>
         {/* End Headers */}
         <tbody>
         {this.state.allProviders.map((value) => 
         <tr key={value.id}>
         {headers[0]['checked'] && <td className="text-center">
         {this.state.bulk === true ? 
         <input type='checkbox' checked={value.checked} onClick={(e) => this.onBulkClick(value.id, value.name, e.target.checked)} /> : count++}
         </td>}
         {headers[1]['checked'] && <td className="text-center w-20">
         <Link to={`/providers/provider-details/${value.id}`}>{value.providername}</Link></td>}
         {headers[2]['checked'] && <td className="text-center">{value.categoryname}</td>}
         {headers[3]['checked'] && <td className="text-center name-text w-25" style={{wordBreak: 'break-all'}}>{value.city}</td>}
         {headers[4]['checked'] && <td className="text-center">{value.country}</td>}
         {headers[5]['checked'] && <td className="text-center w-10">{value.agreementstartdate ?  moment(value.agreementstartdate).format('D MMM YYYY')  : '--' }</td>}
         {headers[6]['checked'] && <td className="text-center w-10">{value.agreementenddate ? moment(value.agreementenddate).format('D MMM YYYY'): '--'}</td>}
         {headers[7]['checked'] && <td className="text-center w-10">
         <div className="pointer heading-blue" onClick={() => this.applicantList(value.id)}>({value.applicant})</div>
         </td>}
         {headers[8]['checked'] && <td className="text-center w-15">
         <Link to={`/providers/add-course/${value.id}/${value.providername}/${value.categoryname}/${value.country ? value.country : 'null'}`}>
         <button className='btn btn-primary size-12'>Add Course</button></Link></td>}
         {headers[9]['checked'] && <td className="text-center"><small>{moment(value.created_at).format('D MMM YYYY')}</small></td>}
         <td className="text-center">
          <span className="wrapper icon-ref ml-0">
          <span className="dot-icon">
          <a className="a-ref-icon" onClick ={() => this.handleClick(value.id)}>
          <FaIcons.FaEllipsisH size={12} color={'rgb(107 107 107 / 71%)'} />
          </a>
          {this.state.open && this.state.clickedId === value.id &&
          <div className="actions-dots-dropdown">
          {this.state.allPermissions['can_edit_provider'] === 1 &&
          <>
          <Link to={`/providers/edit-provider/${value.id}`} className="btn btn-primary pl-3 pr-3 p-0 m-1 mt-2">
          <FaIcons.FaEdit size={12} color={'#fffcfc'} />
          </Link><br/></>}
          <button onClick={() => this.handleDelete(value.id, value.providername)} 
          className="btn btn-danger pl-3 pr-3 p-0 m-1 mb-2"> 
          <FaIcons.FaTrashAlt size={12} color={'#fffcfc'} /> </button>
          </div>} 
          </span>
          </span>
         </td>
         </tr>
         )}
         </tbody>
         </table>
         {this.state.bulk == true && this.state.bulkIds[0] &&
        <div className='d-flex mb-2 bg-white p-2 border'>
        {role=== 2 &&
        <button className='btn btn-danger' onClick={() => this.onBulkDelete()}>Delete Selected</button>}
        </div>}
         <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handlePaginationCallback} 
         />
          {/* Exports */}
          {role === 2 &&
          <div className="d-flex mb-5">
          <ExportOptions
          pdffilename={'providers.pdf'} 
          csvfilename={'providers.csv'} 
          header="Providers List"
          xlsxfilename={'providers'}
          columns={this.state.headers}
          data={this.state.allProviders} />
          </div>}
          {/* End Exports */}
         </>: 'You dont have permission to access this pages!'}
         </div>}
         <Modal size='lg' show={this.state.showApplicantList} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => this.setState({showApplicantList:false})}>
        <Modal.Header closeButton> <div className="text-center white col-11 font-f-sans">
        Applicant List</div></Modal.Header>
        <Modal.Body>
        <form >
        <div className="row">
        <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 mb-3 pt-3">Courses</h5>
        <table className="table table-bordered bg-white mb-1">
          {/* Headers */}
          <thead className="thead-dark">
          <tr>
          {this.state.eduApplicantHeaders.map(head => (
            <th id={head.keyData}  onClick={() => this.courseSorting(head.dataKey)} className={head.align} key={head.key}>{head.title}</th>))}
            </tr>
            </thead>
            <tbody>
            {this.state.coursesApplicantList.map((v, index) =>  (
            <>
            <tr key={index}>
            <td className="text-center">{++index}</td>
            <td className="text-center name-text">
            <Link target='_blank' to={`/clients/view-client-detail/${v.clientid}`}>
            {v.clientname}</Link></td> 
            <td className="text-center">Education</td>
            <td className="text-center">{v.coursename}</td> 
            <td className="text-center"> {eduStatus.filter(value => value.value == v.statusid).map(v => (v.title))}</td> 
            </tr>
            </>))}
            </tbody>
            <tbody>
            </tbody>
        </table>
        {/* Migration Table */}
        <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 mb-3 pt-3">Migration</h5>
        <table className="table table-bordered bg-white mb-1">
        {/* Headers */}
        <thead className="thead-dark">
        <tr>
        {this.state.migApplicantHeaders.map(head => (
        <th id={head.dataKey} onClick={() => this.courseMigModalSorting(head.dataKey)} className={head.align} key={head.key}>{head.title}</th>))}
        </tr>
        </thead>
        <tbody>
        {this.state.migCoursesApplicantList.map((v, i) =>  (
        <>
        <tr key={i}> 
        <td className="text-center">{++i}</td>
        <td className="text-center name-text">
        <Link target='_blank' to={`/clients/view-client-detail/${v.clientid}`}>{v.clientname}</Link></td> 
        <td className="text-center">Migration</td>
        <td className="text-center">{v.coursename}</td> 
        <td className="text-center"> {visaStatus.filter(value => value.value == v.statusid).map(v => (v.title))}</td> 
        </tr>
        </>))}
        </tbody>
        </table>
          {/* Package Table */}
        <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 mb-3 pt-3">Packages</h5>
        <table className="table table-bordered bg-white mb-1">
          {/* Headers */}
          <thead className="thead-dark">
          <tr>
          {this.state.packageApplicantHeaders.map(head => (
            <th id={head.dataKey} onClick={() => this.packageSorting(head.dataKey)} className={head.align} key={head.key}>{head.title}</th>))}
            </tr>
            </thead>
            <tbody>
            {this.state.packagesApplicantList.map((v, index) =>  (
            <>
            <tr key={index}>
            <td className="text-center">{++index}</td>
            <td className="text-center name-text">
            <Link target='_blank' to={`/clients/view-client-detail/${v.clientid}`}>
            {v.clientname}</Link></td> 
            <td className="text-center">Education</td>
            <td className="text-center">{v.packagename}</td> 
            <td className="text-center"> {eduStatus.filter(value => value.value == v.statusid).map(v => (v.title))}</td> 
            </tr>
            </>))}
            </tbody>
            <tbody>
            </tbody>
        </table>
        </div>
        </form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="btn btn-danger" onClick={() => this.setState({showApplicantList:true})}>Cancel</Button>
        </Modal.Footer>
        </Modal>
        </>
      )
  }
}
export default ViewProviders
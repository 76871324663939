import { apiUrl } from "./AxiosHeaders";

const apiManager = {
  get: async (url, params = {}) => {
    try {
      const response = await apiUrl.get(url, { params });
      return handleSuccess(response);
    } catch (error) {
      return handleError(error);
    }
  },

  post: async (url, data) => {
    try {
      const response = await apiUrl.post(url, data);
      return handleSuccess(response);
    } catch (error) {
      return handleError(error);
    }
  },

  put: async (url, data) => {
    try {
      const response = await apiUrl.put(url, data);
      return handleSuccess(response);
    } catch (error) {
      return handleError(error);
    }
  },

  delete: async (url) => {
    try {
      const response = await apiUrl.delete(url);
      return handleSuccess(response);
    } catch (error) {
      return handleError(error);
    }
  }
};

export default apiManager;

function handleSuccess(response) {
  return {
    status: response.status,
    data: response.data,
    success: true,
  };
}

function handleError(error) {
  if (error.response) {
    return {
      status: error.response.status,
      message: error.response.data.error || error.response.data.message || "An error occurred.",
      data: error.response.data,
      success: false,
    };
  } else if (error.request) {
    return {
      status: null,
      message: "No response received from the server.",
      success: false,
    };
  } else {
    return {
      status: null,
      message: error.message,
      success: false,
    };
  }
}

import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import { Link } from 'react-router-dom';
import './applications.css';
import { FaRocket } from 'react-icons/fa';

class MainApplications extends Component {
    constructor(props) {
        super(props);
        var pageURL= window.location.pathname;
        var permissions = JSON.parse(localStorage.getItem('user'));
        this.state = {
            pageURL:pageURL,
            loading:false,
            allPermissions: permissions,
        }
    }
    render(){
        return(
            <>
             <Title title={this.state.pageURL === '/applications/main-applications' ?
             'My Applications' : 'Assign Applications'} />
             <div style={{paddingBottom:'115px'}} className="pt-lg-5 pl-lg-3 pr-lg-3 pl-5 pr-5">
            <div className="row pl-3">
                {/* Migration Access */}
            {this.state.allPermissions['migrationaccess'] === 1 &&
            <div className="col-xl-3 col-md-6 mb-lg-6 mb-6">
                <Link className="no-underline" to={this.state.pageURL === '/applications/main-applications' ?
                 `/applications/my-migrations-applications/${'null'}` : '/applications/assign-migrations'}>
                    <div className="card app-card shadow">
                        <div className="card-block h-100">
                            <div className="row text-center">
                                <div className="col-10">
                                <h4 className="text-c-green main-app">Migration Applications</h4>
                                </div>
                            <div className="col-2 text-right pt-2">
                            <FaRocket size={26} color={'#000000b5'} />
                        </div>
                    </div>
                </div>
                    <div className="card-footer dashboard-card-footer bg-green">
                    </div>
                </div>
                </Link>
            </div>}
                {/* Education Access */}
            {this.state.allPermissions['educationaccess'] === 1 &&
            <div className="col-xl-3 col-md-6 mb-lg-6 mb-6 ml-lg-4">
                <Link className="no-underline"  to={this.state.pageURL === '/applications/main-applications' ?
                 `/applications/my-education-applications/${'null'}` : '/applications/assign-education'}>
                    <div className="card app-card shadow">
                        <div className="card-block h-100">
                            <div className="row text-center">
                                <div className="col-10">
                                <h4 className="text-c-green main-app">Education Applications</h4>
                                </div>
                            <div className="col-2 text-right pt-2">
                            <FaRocket size={26} color={'#000000b5'} />
                        </div>
                    </div>
                </div>
                    <div className="card-footer dashboard-card-footer bg-green">
                    </div>
                </div>
                </Link>
            </div>}
                {/* AAT/FCCT */}
            {this.state.allPermissions['aataccess'] === 1 &&
            <div className="col-xl-3 col-md-6 mb-lg-6 mb-6 ml-lg-4">
                <Link className="no-underline" to={"./dashboard"}>
                    <div className="card app-card shadow">
                        <div className="card-block h-100">
                            <div className="row text-center">
                                <div className="col-10">
                                <h4 className="text-c-green main-app">AAT / FCCT Applications</h4>
                                </div>
                            <div className="col-2 text-right pt-2">
                            <FaRocket size={26} color={'#000000b5'} />
                        </div>
                    </div>
                </div>
                    <div className="card-footer dashboard-card-footer bg-green">
                    </div>
                </div>
                </Link>
            </div>}
            </div>
            </div>
            <br/><br/><br/><br/><br/>
            </>
        )
    }
}
export default MainApplications

import React ,{useState,useEffect, useRef}from "react"
import Title from '../GenericComponents/Title'
import { Sorting } from "../GenericComponents/SortTables";
import { deleteInternalNote, getInternalNotes, searchInternalNotes } from "../Services/apiServices";
import swal from 'sweetalert';
import Paginations from "../GenericComponents/Pagination";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaEdit, FaEye, FaRegCheckSquare, FaTrash } from "react-icons/fa";
import EditInternalNotes from "./editInternalNote";
import { view_ApiManager } from "../ApiManager/ApiManager";
import MultipleInternalNotePDF from "../GenericComponents/MultipleInternalNotePDF";
import MultipleSearchs from "../GenericComponents/MultipleSearchs";

const ViewInternalNotes = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [perItem, setPerItem] = useState('');
    const [noteIndex, setNoteIndex] = useState(0);
    const [allNotes, setAllNotes] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [loggedInUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [editInternalNote ,setEditInternalNotes] = useState(false);
    const [editRecord, setEditRecord] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [attendees, setAttendees] = useState([]);
    const [managers, setManagers] = useState([]);
    const [search, setSearch] = useState('');
    const [bulk, setBulk] = useState(false);
    const [bulkIds, setBulkIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [headers, setHeaders] = useState([ 
        {title: '#' , dataKey: "", align: "text-center", key:1, checked: true, canChange: false},
        {title: 'Managers' , dataKey: "manager_ids", align: "text-center", key:2, checked: true, canChange: false},
        {title: 'Agenda', dataKey: "agenda" , align:"text-center", key:3, checked: true, canChange: false},
        {title: 'Meeting Attendees', dataKey: "participants" , align:"text-center", key:4, checked: true, canChange: false},
        {title: 'Meeting Date', dataKey: "meeting_date" , align:"text-center", key:5, checked: true, canChange: false},
        {title: 'Due Date', dataKey: "due_date" , align:"text-center", key:6, checked: true, canChange: false},
        {title: 'Created At', dataKey: "created_at" , align:"text-center", key:7, checked: true, canChange: false},
        {title: '', dataKey: "" , align:"text-center", key:8, checked: true, canChange: false},
        {title: '', dataKey: "" , align:"text-center", key:9, checked: true, canChange: false},
    ]);

    useEffect( () => {
        internalNotes();
    }, [currentPage, perPage]);

    useEffect( () => {
        getAllUsers();
    }, []);

    const internalNotes = async () => {
        let result = await getInternalNotes({currentPage, perPage});
        console.log('internal-notes', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            setAllNotes(result.data.data.data);
            setPerPage(result.data.data['per_page']);
            setLastPage(result.data.data['last_page']);
            setTotalItem(result.data.data['total']);
            setPerItem(result.data.data.data.length);
            setNoteIndex(currentPage * perPage - perPage + 1);
        }
    }

    const sorting = (col) => {
        const sortResult = Sorting(allNotes, col, order)
        setOrder(sortResult[0]);
        setAllNotes(sortResult[1]);
    }
    const handlePaginationCallback = (i, perPage) => {
        if(perPage && perPage != ''){
            setCurrentPage(1);
            setPerPage(perPage); 
        } else { setCurrentPage(i);  }
    }
    const handlePathCallback = () => {
        internalNotes();
    } 
    const deleteInternalNotes = async (id) => {
        swal({title: "Are you sure?", text: "You want to this meeting notes?", icon: "warning", buttons: true,
        }).then(async (willDelete) => { // Mark this callback as `async`
            if (willDelete) {
                let result = await deleteInternalNote(id);
                console.log('internal-notes', result);
                if (result.error) {
                    swal(" ", result.error, "error");
                } else {
                    swal("", result.data.message, { icon: "success" });
                    await internalNotes(); // Assuming this is an asynchronous call
                }
            }
        });
    };
    const handleEditInternalNote = (row) => {
        setEditInternalNotes(true)
        setEditRecord(row)
        getAllUsers();
    }
    const handleCallback = () => {
        setEditInternalNotes(false)
    }
    const getAllUsers = () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            const participants = res.data.data.map(user => ({
                label: user.name, // Adjust the property name based on your API response
                value: user.id,
              }));
              setParticipants(participants)
        },(err) => {
            console.log(err);
        });
     }
     const handleSearchCallback = async (input1, input2, input3) => {
        let Attendees, Managers;
        if (input1 === 'clear') {
          setSearch('');
        } else{
            if (input1 === 'yes') {
                Attendees = input1;
                Managers = input2;
            } else {
                setSearch('yes');
                Attendees = input1;
                Managers = input2;
                setAttendees(Attendees);
                setManagers(Managers);
            }
            const data = {
                attendees: Attendees,
                managers: Managers,
            }
            console.log(data);
            let result = await searchInternalNotes({currentPage, perPage}, data);
             console.log('result', result)
            if (result.error) {
                swal({ text: result.error, icon: "warning", dangerMode: true });
            }else{
                setAllNotes(result.data.data.data);
                setPerPage(result.data.data['per_page']);
                setLastPage(result.data.data['last_page']);
                setTotalItem(result.data.data['total']);
                setPerItem(result.data.data.data.length);
                setNoteIndex(currentPage * perPage - perPage + 1);
            }
        }
    }
    const allBulk = () => {
        const newState = allNotes.map(obj => {
              return {...obj, checked: true};
        });
        updateCheckedNotes(newState);
        const array = [];
        allNotes.map(x => {
            array.push({ id: x.id});
        })
        setAllNotes(newState);
        setBulk(true);
        setBulkIds(array);
     }
    const selectBulk = () => {
        const newState = allNotes.map(obj => {
            return {...obj, checked: false};
        });
        setAllNotes(newState);
        setBulk(!bulk);
        setBulkIds([]);
     }
     const onBulkClick = (id, checked) => {
        if(checked == true){
            const newState = allNotes.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: true};
                }
                return obj;
            });
            updateCheckedNotes(newState);
            setAllNotes(newState);
            setBulkIds([...bulkIds, { id:id}]);
            console.log(bulkIds)
        } else{
            const newState = allNotes.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: false};
                }
                return obj;
            });
            setAllNotes(newState);
            const checkedItems = newState.filter((note) => note.checked);
            var array = bulkIds.filter(function(item) {return item.id !== id});
            setBulkIds(array);
            updateCheckedNotes(checkedItems);
        }
     }
     const updateCheckedNotes = (data) => {
        const filteredNotes = data.filter(note => note.checked);
        setCheckedItems(filteredNotes);
    };
    

    return(
        <>
        <Title title={'Internal Notes'} back={true} titleCol={'col-lg-9'} 
         addMeeting={true} meetingClass={'col-lg-3 '} parentCallback={handlePathCallback}/>
        <div className="row w-100 px-3 mb-5">
        <MultipleSearchs
         input1={'Search Attendees'}
         input1_type={"dropdown"}
         input1_multiple={"yes"}
         input1Data={participants} 
         input1_suggestion={true}
         input2={'Search Managers'}
         input2_type={"dropdown"}
         input2_multiple={"yes"}
         input2_filter_by={"title"}
         input2_suggestion={true}
         input2Data={participants} 
         onSearchChange= {handleSearchCallback}
         />
          <div className='w-100 mr-2 text-right'>
        <small className='underline pointer heading-blue' onClick={() => allBulk()}>Select All </small>
         <FaRegCheckSquare className='underline' size={14} color='#4343ff' onClick={() => selectBulk()}/></div>

        <div className='w-100 mr-2 text-right mt-3'>
         <table id="alledu" className="table table-bordered bg-white ">
         <thead className="thead-dark">
         <tr>
         {headers.map(head => (
            head.checked &&
            <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)} key={head.key}>
            {head.title}</th>
            ))}
         </tr>
         </thead>
         <tbody>
        {allNotes.map((value, index) => (
        <tr key={value.id}>
            {headers[0]['checked'] && (
                <td className="text-center">
                    {bulk ? (
                        <input
                            type="checkbox"
                            checked={value.checked || false}
                            onChange={(e) => onBulkClick(value.id, e.target.checked)}
                        />
                    ) : (
                        noteIndex + index
                    )}
                </td>
            )}

            <td className="text-center">  {value.manager_ids.map((v, index) => (
                <React.Fragment key={index}>
                {v.label}
                <br />
                </React.Fragment>
            ))}</td>
            <td className="text-center">{value.agenda}</td>
            <td className="text-center">
            {value.participants.map((v, index) => (
                <React.Fragment key={index}>
                {v.label}
                <br />
                </React.Fragment>
            ))}
            </td>
            <td className="text-center">
            {moment(value.meeting_date).format('D MMM YYYY')}
            <br/>{value.start_time} - {value.end_time}
            </td>
            <td className="text-center">{moment(value.created_at).format('D MMM YYYY')}</td>
            <td className="text-center">
            <Link to={`/internal-note-detail/${value.id}`}>
            <button className="btn btn-info">View 
            <FaEye size={12} color={'#fffcfc'} /></button>
            </Link></td>
            <td>
                {value.manager_ids.map((v, index) => (
                    v.value === String(loggedInUser['id']) && (
                        <React.Fragment key={`manager-${v}-${index}`}>
                            <button 
                                className="btn btn-primary px-3" 
                                onClick={() => handleEditInternalNote(value)}
                            >
                                Edit <FaEdit size={12} color={'#fffcfc'} />
                            </button>
                            <br />
                            <button 
                                className="btn btn-danger mt-2" 
                                onClick={() => deleteInternalNotes(value.id)}
                            >
                                Delete <FaTrash size={12} color={'#fffcfc'} />
                            </button>
                        </React.Fragment>
                    )
                ))}
            </td>

            </tr>
         ))}
         </tbody>
         </table>
        </div>
        <Paginations
         currentPage={currentPage}
         total= {totalItem}
         itemsPerPage= {perPage}
         lastPage={lastPage}
         totalItems={totalItem}
         perItem={perItem}
         onPageChange= {handlePaginationCallback} 
        />
        
        <MultipleInternalNotePDF 
        text={bulk === true ? 'Export Selected to PDF' :'Export All to PDF'} 
        dataArray={bulk ? checkedItems :allNotes} />
        </div>
    
        <EditInternalNotes 
        id={editRecord.id}
        data={editRecord}
        participants={participants}
        show={editInternalNote} 
        parentCallback={handleCallback}
        />
        </>
    )
}
export default ViewInternalNotes
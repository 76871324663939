
import React ,{useState,useEffect}from "react"
import { useParams} from "react-router-dom"
import Title from '../GenericComponents/Title'
import swal from "sweetalert"
import {useNavigate } from "react-router-dom"
import { get_ApiManager, post_ApiManager } from "../ApiManager/ApiManager"
import moment from "moment"

const ViewReport =() => {
    const { id } = useParams();
    const history = useNavigate();
    const[allPermissions] = useState(JSON.parse(localStorage.getItem('permissions')));
    const[managerRemarks, setManagerRemarks] = useState('');
    const[categoryTasks, setCategoryTasks] = useState([]);
    const[selfTasks, setSelfTasks] = useState([]);
    const[details, setDetails] = useState([]);
    const[managerTasks, setManagerTasks] = useState([ 
        { id: 1, task: 'Tasks of Shortlisted Applicants', status:'', type: 1},
        { id: 2, task: 'Coordination', status:'', type: 2},
        { id: 3, task: 'Application Status Updated', status:'', type: 3},
        { id: 4, task: 'Assign/Re-assign Work', status:'', type: 3},
        { id: 5, task: 'Comprehensive Notes Left', status:'', type: 3},
      ]);

    useEffect( () => {
        getReportDetail();
    }, []);

    const getReportDetail = () => {
        let url = `reportDetail/${id}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('detail',res);
            setDetails(res.data.data);
            setCategoryTasks(res.data.data['categories']);
            setSelfTasks(res.data.data['selfTasks']);
            if(res.data.data['stage'] >= 2){
                setManagerTasks(res.data.data['managerTask']);
            }
        },(err) => {
            console.log(err);
        });
    }

    const handleManagerTaskChange = (index, fieldName, value) => {
        setManagerTasks(prevTasks => prevTasks.map((task, idx) => {
            if (idx === index) {
                return { ...task, [fieldName]: value };
            }
            return task;
        }));
    };
    const  submitReport = () => {
        if(details['stage'] === 2 && allPermissions['daily_report_remarks'] === 1){
            if (managerTasks.some(task => !task.status || task.status.trim() === '')) {
                swal("Error", "Please select task status of top daily basis tasks!", "error");
                return; // Stop the function from proceeding if validation fails
            } 
        }
        let url = 'submit-manager-remarks';
        const data = {
            report_id: id,
            managerTask: managerTasks,
            managerRemarks: managerRemarks
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('report-submitted',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
                getReportDetail();
            } 
        },(err) => {
            console.log(err);
        });

    }

    return(
        <>
        <Title title={`Report`} back={true} />
        {allPermissions['hr-management'] === 1 ?
        <div className="mx-3 mt-2 mb-5">
        <h2 className='text-center pb-2 mb-0' style={{color: 'blue'}}>
        {moment(details['created_at']).format('MMMM Do YYYY')}</h2>
        {categoryTasks.map((task, categoryIndex) => (
                <div key={categoryIndex}>
                    <h2 className='row text-center j-content-center p-2 size-22 mb-0' 
                        style={{background:'burlywood'}}>{task.category}</h2>
                    <table className="table table-bordered bg-white">
                        <thead>
                            <tr>
                                <th className='text-center size-15 w-10'>Sr No.</th>
                                <th className='text-left size-15'>Operations</th>
                                <th className='text-center size-15 '>User</th>
                                <th className='text-center size-15 w-20'>{details['role']}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {task.tasks.map((task1, taskIndex) => (
                                <tr key={taskIndex}>
                                    <td className='text-center size-16'>{taskIndex + 1}</td>
                                    <td className='text-left size-16'>{task1.task}</td>
                                    <td className="text-center size-16">{task1.username}</td>
                                    <td className='text-center size-16'> 
                                        <input className="check-input ml-3" type="checkbox" disabled={true}
                                            checked={task1.status || false} 
                                            onChange={e => this.handleInputChange(e.target.checked, task1, taskIndex, categoryIndex)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        ))}
        <h2 className='row text-center j-content-center p-2 size-22 mb-0' style={{background:'burlywood'}}>
            Top 10 Work done on Daily Basis
        </h2>
        <table className="table table-bordered mb-2 bg-white">
            <thead>
                <tr>
                    <th className='text-center size-15 w-10'>Sr No.</th>
                    <th className='size-15'>What Task To Do?</th>
                    <th className='text-center size-15 w-20'>Task Completed</th>
                </tr>
            </thead>
            <tbody>
            {selfTasks.map((task, index) => (
                <tr key={task.id}>
                <td className='text-center size-16'>{task.index}</td>
                <td className='size-16'>
                    <textarea type="text" name='task' className="form-control" disabled={true}
                        value={task.task || ''}  // Ensure it's a controlled component
                        onChange={(e) => this.handleTaskChange(index, 'task', e.target.value)} />
                </td>
                    
                <td className='text-center size-16'>
                    <select value={task.status || ''}  // Ensure it's a controlled component
                            onChange={(e) => this.handleTaskChange(index, 'status', e.target.value)}
                            name='status' className="form-select acc-form w-100 mb-1" disabled={true} >
                        <option value=''>Please select</option>
                        <option value='completed'>Completed</option>
                        <option value='partially'>Partially Completed</option>
                        <option value='notCompleted'>Not Completed</option>
                    </select>
                </td>
                </tr>
            ))}
            </tbody>
            </table>
            <div className='row border bg-white mt-3'>
            <div className="form-group p-4">
            <label className="acc-label2 mb-1">Leave Comment (optional)</label>
            <textarea value={details['userRemarks']} className="textarea form-control acc-form" name="userRemarks" disabled={true}
             placeholder="Please Enter" rows="7" cols="60"></textarea>
            </div>
            </div>

            <div className='mt-4'>
            <h2 className='row text-center j-content-center p-4 size-22 mb-0' 
                style={{background:'burlywood'}}>Manager Remarks</h2>
            <table className="table table-bordered bg-white">
                <thead>
                    <tr>
                        <th className='text-center size-15'>Sr No.</th>
                        <th className='size-15'>Operations</th>
                        <th className='text-center size-15 w-20'>Status</th>
                    </tr>
                </thead>
                <tbody>
                {managerTasks.map((mtask, index) => (
                    <tr key={index}>
                        <td className='text-center size-16'>{index + 1}</td>
                        <td className='size-16'>{details['stage'] > 2 ? mtask.task : mtask.tasks}</td>
                        <td className='text-center size-16'> 
                        {mtask.type === 1 ?
                        <select value={mtask.status} onChange={(e) => handleManagerTaskChange(index, 'status', e.target.value)}
                            name='status' className="form-select acc-form w-100 mb-1" 
                            disabled={details['stage'] === 2 && allPermissions['daily_report_remarks'] === 1 ? false : true}>
                            <option value=''>Please select</option>
                            <option value='completed'>Completed</option>
                            <option value='partially'>Partially Completed</option>
                            <option value='notCompleted'>Not Completed</option>
                            </select>
                            :
                            mtask.type === 2 ?
                            <select value={mtask.status} onChange={(e) => handleManagerTaskChange(index, 'status', e.target.value)}
                            name='status' className="form-select acc-form w-100 mb-1" 
                            disabled={details['stage'] === 2 && allPermissions['daily_report_remarks'] === 1 ? false : true}>
                            <option value=''>Please select</option>
                            <option value='coordinated'>Coordinated</option>
                            <option value='notCordinated'>Not Coordinated</option>
                            </select>
                            :
                            mtask.type === 3 ?
                            <select value={mtask.status} onChange={(e) => handleManagerTaskChange(index, 'status', e.target.value)}
                            name='status' className="form-select acc-form w-100 mb-1" 
                            disabled={details['stage'] === 2 && allPermissions['daily_report_remarks'] === 1 ? false : true}>
                            <option value=''>Please select</option>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            </select>:''}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className='row mt-3 border bg-white'>
            <div className="form-group p-4">
            <label className="acc-label2 mb-1">Manager's Remarks (optional)</label>
            <textarea className="textarea form-control acc-form" defaultValue={details['managerRemarks']} disabled={details['stage'] === 2 && allPermissions['daily_report_remarks'] === 1 ? false : true}
            name="managerRemarks" placeholder="Please Enter" onChange={(e) => setManagerRemarks(e.target.value)} rows="6" cols="60"></textarea>
            </div>
            </div>
            </div>

            <div className='w-100 j-content-center text-center'>
            {details['stage'] === 2 && allPermissions['daily_report_remarks'] === 1 ? 
            <button className='btn btn-info mb-5 mt-3 py-2 px-5' onClick={() => submitReport()}>
            Submit Manager's Remarks</button>: ''}
            </div>
        </div>
        :''}
        </>
    )
}
export default ViewReport

import React, { useEffect } from 'react'
import SeenStatus from '../../Notification/SeenStatus';
import { useNavigate } from "react-router-dom";

const Chat = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const handleIconClick = async (e) => {
        let path = `/chat`; 
        navigate(path);
    }
    
    return(
        <>
        <SeenStatus />
        <div className='text-center d-flex j-content-center icon-container'>
        <li className="wrapper px-2">
            <a className="icon-button svg" onClick ={() => handleIconClick()}>
            {props.icon} </a>
        </li>
        <span className="hover-text">Chat</span>
        </div>
        </>
    )
}
export default Chat

import React, { Component } from 'react';
import Title from '../GenericComponents/Title'
import Paginations from '../GenericComponents/Pagination'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Loader from '../Loader/Loader'
import { search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import SelectionBar from '../Counseling/SelectionBar'
import "./finance.css"
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import ExportOptions from '../GenericComponents/ExportOptions'
import { Sorting } from '../GenericComponents/SortTables'

class ViewCost extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
          loading: false,
          currentPage: 1,
          totalItem:'',
          lastPage:'',
          perPage: 25,
          search:'',
          sDate: null,
          eDate: null,
          type: 'Education',
          within:'',
          order:'ASC',
          key:'all',
          allCost:[],
          client:'',
          application:'',
          invoiceNo:'',
          selectedCost:[],
          allPermissions: permissions,
          all_headers: [
              {title: '#' , dataKey: "index", align: "text-center" , key:1},
              {title: 'Client' , dataKey: "clientname",align: "text-left", key:2},
              {title: 'InvoiceNo' , dataKey: "invoice_No",align: "text-left", key:3},
              {title: 'Application', dataKey: "coursename", align: "text-center" ,key:4 },
              {title: 'Type' , dataKey: "type", align: "text-center",key:5},
              {title: 'InvoiceDate', dataKey: 'invoice_date',align:"text-center",key:6},
              {title: 'DueDate', dataKey: 'due_date',align:"text-center",key:7},
              {title: 'Paid', dataKey: 'paid',align:"text-center",key:8},
              {title: 'Credit', dataKey: 'credit',align:"text-center",key:9},
              {title: 'Remaining', dataKey: 'remaining',align:"text-center",key:10},
              {title: 'Currency', dataKey: 'currency',align:"text-center",key:11},
              {title: 'Status', dataKey: 'status',align:"text-center",key:12},
              {title: 'Email', dataKey: 'email_status',align:"text-center",key:15},
              {title: 'Installments', dataKey: 'installments',align:"text-center",key:13},
              {title: 'UpdatedAt' , dataKey: "cost_date", align: "text-center",key:14},
          ],
          awaiting_headers: [
              {title: '#' , dataKey: "index", align: "text-center" , key:1},
              {title: 'Client' , dataKey: "clientname",align: "text-left", key:2},
              {title: 'InvoiceNo' , dataKey: "invoice_No",align: "text-left", key:3},
              {title: 'Application', dataKey: "coursename", align: "text-center" ,key:4},
              {title: 'Type' , dataKey: "type", align: "text-center",key:5},
              {title: 'InvoiceDate' , dataKey: "invoice_date", align: "text-center",key:6},
              {title: 'DueDate', dataKey: 'due_date',align:"text-center",key:9},
              {title: 'OverDue', dataKey: 'due_date',align:"text-center",key:10},
              {title: 'Paid', dataKey: 'paid',align:"text-center",key:11},
              {title: 'Credit', dataKey: 'credit',align:"text-center",key:12},
              {title: 'Remaining', dataKey: 'remaining',align:"text-center",key:13},
              {title: 'Currency', dataKey: 'currency',align:"text-center",key:14},
              {title: 'Status', dataKey: 'status',align:"text-center",key:15},
              {title: 'Email', dataKey: 'email_status',align:"text-center",key:18},
              {title: 'Installments', dataKey: 'installments',align:"text-center",key:16},
              {title: 'UpdatedAt' , dataKey: "cost_date", align: "text-center",key:17},
          ],
          paid_headers: [
              {title: '#' , dataKey: "index", align: "text-center" , key:1},
              {title: 'Client' , dataKey: "clientname",align: "text-left", key:2},
              {title: 'InvoiceNo' , dataKey: "invoice_No",align: "text-left", key:3},
              {title: 'Application', dataKey: "coursename", align: "text-center" ,key:4 },
              {title: 'Type' , dataKey: "type", align: "text-center",key:5},
              {title: 'Paid Date', dataKey: 'last_payment',align:"text-center",key:6},
              {title: 'Paid', dataKey: 'paid',align:"text-center",key:7},
              {title: 'Currency', dataKey: 'currency',align:"text-center",key:8},
              {title: 'Status', dataKey: 'status',align:"text-center",key:9},
              {title: 'Email', dataKey: 'email_status',align:"text-center",key:12},
              {title: 'UpdatedAt' , dataKey: "cost_date", align: "text-center",key:11},
          ],
          installments_headers: [
              {title: '#' , dataKey: "index", align: "text-center" , key:1},
              {title: 'Client' , dataKey: "clientname",align: "text-left", key:2},
              {title: 'InvoiceNo' , dataKey: "invoice_No",align: "text-left", key:3},
              {title: 'Application', dataKey: "coursename", align: "text-center" ,key:4 },
              {title: 'Type' , dataKey: "type", align: "text-center",key:5},
              {title: 'Installment' , dataKey: "installments", align: "text-center",key:6},
              {title: 'DueDate', dataKey: 'due_date',align:"text-center",key:7},
              {title: 'Amount', dataKey: 'amount',align:"text-center",key:9},
              {title: 'Remaining', dataKey: 'remaining',align:"text-center",key:11},
              {title: 'Credit', dataKey: 'credit',align:"text-center",key:10},
              {title: 'Currency', dataKey: 'currency',align:"text-center",key:12},
              {title: 'Email', dataKey: 'email_status',align:"text-center",key:15},
              {title: 'Status', dataKey: 'status',align:"text-center",key:13},
              
          ],
          overpaid_headers: [
            {title: '#' , dataKey: "index", align: "text-center" , key:1},
            {title: 'Client' , dataKey: "name",align: "text-left", key:2},
            {title: 'InvoiceNo' , dataKey: "invoice_No",align: "text-left", key:3}, 
            {title: 'Application', dataKey: "coursename", align: "text-center" ,key:4 },
            {title: 'Type' , dataKey: "type", align: "text-center",key:5},
            {title: 'DueDate', dataKey: 'due_date',align:"text-center",key:6},
            {title: 'Paid Date', dataKey: 'poadate',align:"text-center",key:7},
            {title: 'Total', dataKey: 'paid',align:"text-center",key:8},
            {title: 'Paid', dataKey: 'remaining',align:"text-center",key:9},
            {title: 'Currency', dataKey: 'currency',align:"text-center",key:10},
            {title: 'Email', dataKey: 'email_status',align:"text-center",key:15},
            {title: 'Status', dataKey: 'status',align:"text-center",key:11},
            
          ],
        }
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
      this.getCost();
    }

    async getCost() {
      this.setState({loading: true});
      let currentPage = this.state.currentPage;
      let perPage = this.state.perPage;
      let type = this.state.type == 'Education' ? 2 : 1; 
      let url = `getProfesstionalCost/${this.state.key}/${type}`;
      view_ApiManager(url, currentPage, perPage,(res) => {
        console.log('cost',res);
        this.setState({
          loading: false,
          allCost: res.data.data.data,
          totalItem: res.data.data['total'],
          perPage: res.data.data['per_page'],
          lastPage: res.data.data['last_page'],
          perItem: res.data.data['data'].length,
        });
        let options = res.data.data.data.map(function (item) {
          return { 
            index:item.index,
            clientname: item.clientname,
            coursename: item.coursename,
            packagename: item.packagename,
            type: item.type,
            invoice_No: item.invoice_No,
            invoice_date: item.invoice_date,
            paid: item.paid,
            credit: item.credit,
            remaining: item.remaining,
            currency: item.currency,
            status: item.status,
            due_date: item.due_date,
            installments: item.installments,
            cost_entry_date: item.cost_date,
            dueby: item.dueby,
          };
        })
        this.setState({selectedCost: options})
        },(err) => {
            console.log(err);
        });
    }
      //CallBack function to get changed status name and value and call api to save results
    handleSearchCallback = (value, name) => {
        if(name === 'type'){
          this.setState({type: value})
        } else if(name === 'within'){
          this.setState({within: value, sDate: '', eDate:''})
        } else if(name === 'sDate') {
            this.setState({sDate: value})
        } else if(name === 'eDate') {
          this.setState({eDate:value})
        } else if(name === 'client') {
          this.setState({client:value})
        } else if(name === 'application') {
          this.setState({application:value})
        } else if(name === 'currency') {
          this.setState({currency:value})
        } else if(name === 'invoiceno') {
          this.setState({invoiceNo:value})
        }
    }
       // Table sorting
    sorting = (col) => {
      const sortResult = Sorting(this.state.allCost, col, this.state.order)
      this.setState({order: sortResult[0], allCost: sortResult[1]})
     }
      // CallBack function handle pagination
    handlePaginationCallback = (i, perPage) =>{
        if(perPage && perPage != ''){
            this.setState({ perPage : perPage, currentPage:1}, () => {  this.getCost();  }); 
        } else {this.setState({ currentPage : i}, () => { this.getCost(); }); }
    }
    onClear = () => {
      this.setState({
          sDate:'',
          eDate:'', 
          within:'',
          client:'',
          application:'',
          currency:'',
          invoiceNo:'',
      })
      this.getCost();
    }
    //Submit form and call api to fetch results
    onSubmit(e) {
      e.preventDefault();
      this.getSearchResult();
    }
         // Call counseling api
     async getSearchResult(){
      const data = {
        type: this.state.type === 'Education' ? 2 : 1,
        within: this.state.within,
        sdate: this.state.sDate,
        edate: this.state.eDate,
        client: this.state.client,
        application: this.state.application,
        currency: this.state.currency,
        invoiceNo: this.state.invoiceNo,
        tab: this.state.key,
      }
      console.log(data);
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = `search/professtionalCost`;
        search_ApiManager(url, data, currentPage, perPage,(res) => {
            console.log('search result',res);
            this.setState({
                allCost: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
        },(err) => {
            console.log(err);
      });  
    }
    data = () => {
      return(
        <div className="row selectedValues">
        {/* selected type */}
        {this.state.type &&
        <div className="tag-bg w-auto">
        {/* <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({type:''})}></button> */}
        <div className="list-tag">{this.state.type}</div>
        </div>}
        {/* selected course */}
        {this.state.within &&
        <div className="tag-bg w-auto">
        <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({within: '', sDate: '', eDate:''})}>
        </button><div className="list-tag">{this.state.within}</div>
        </div>}
        {/* selected start date */}
        {this.state.sDate &&
        <div className="tag-bg w-auto">
        <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({sDate:''})}>
        </button><div className="list-tag">{this.state.sDate}</div>
        </div>}
        {/* selected start date */}
        {this.state.eDate &&
        <div className="tag-bg w-auto">
        <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({eDate:''})}>
        </button><div className="list-tag">{this.state.eDate}</div>
        </div>}
        {/* selected client */}
        {this.state.client &&
        <div className="tag-bg w-auto">
        <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({client:''})}>
        </button><div className="list-tag">{this.state.client}</div>
        </div>}
        {/* selected application */}
        {this.state.application &&
        <div className="tag-bg w-auto">
        <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({application:''})}>
        </button><div className="list-tag">{this.state.application}</div>
        </div>}
        {/* selected currency */}
        {this.state.currency &&
        <div className="tag-bg w-auto">
        <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({currency:''})}>
        </button><div className="list-tag">{this.state.currency}</div>
        </div>}
         {/* selected invoiceno */}
         {this.state.invoiceNo &&
        <div className="tag-bg w-auto">
        <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({invoiceNo:''})}>
        </button><div className="list-tag">{this.state.invoiceNo}</div>
        </div>}

        {/* Search Button */}
        <div className="col-12">
        <div className='d-flex float-right'>
        <button onClick={this.onSubmit} className="btn btn-search mt-2 pl-4 pr-4 mr-2">
        <i className="fa fa-search pr-2" aria-hidden="true"></i>Search Course</button>
        <button type="button" onClick={() => this.onClear()} className="btn btn-primary pr-4 mt-2 pl-4">Reset</button>
        </div>
        </div>

        </div>
      )
    }

    render(){
      const role = JSON.parse(localStorage.getItem('user_role'));
      return(
          <>
          <Title title="Professtional Cost" />
          <div className="row w-100 pl-3 pr-3">
          {this.state.allPermissions['professionalCost'] === 1 ?
            <div className="row w-100">
            <Row className="w-100">
            <Col>
      
           <Tabs activeKey={this.state.key} defaultActiveKey="all" className="app-tabs ml-0 border rounded mt-0"
            onSelect={(k) => this.setState({key:k, sDate:null, eDate:null, within:'', sDate:'', eDate:'', application:'', currency:'', invoiceNo:'', client:''}, () => this.getCost())} >
            {/* All */}
            <Tab eventKey="all" title="All">
            {this.state.loading === true ? <Loader/> :
            this.state.key === 'all' ? 
            <div eventKey="all" className="pt-0 mb-3">
            <div className="row p-3 bg-white border pt-1 mb-2 pb-1">
            <SelectionBar 
            parentCallback = {this.handleSearchCallback}
            type='finance' 
            selectedsDate={this.state.sDate}
            selectedeDate={this.state.eDate}
            selectedType={this.state.type}
            selectedWithin={this.state.within}
            invoiceno={this.state.invoiceNo}
            from='all' />
            
            {this.data()}

            </div>
            <div className="row w-100 pr-1 mb-4 pl-1">
            <div id="viewcostdiv">
            <table id="viewcost" className="table table-bordered bg-white mb-2 table-responsive">
            {/* Headers */}
            <thead className="thead-dark">
            <tr>
            {this.state.all_headers.map(head => (
            <th id={head.dataKey} className={head.align} key={head.key} 
            onClick={() => this.sorting(head.dataKey)}> {head.title}</th> ))}
            </tr>
            </thead>
            {/* End Headers */}
            <tbody>
            {this.state.allCost.map((value, index) => {
              let remaining = value.remaining;
              return(
              <tr key={index}>
              <td className='text-center'>{++index}</td>
              <td className='text-left name-text'>
              <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.clientname}</Link></td>
              <td className='text-center name-text'>
              <Link to={`/invoice/edit-invoice/${value.invoice_id}`}>
              {value.invoice_No && 'INV-'+value.invoice_No}</Link></td>
              <td className='text-center w-20 name-text'>
              {value.courseid == 0 ? value.packagename: value.coursename}
              </td>
              <td className='text-center'>{this.state.type}</td>
              <td className='text-center w-15'>{value.invoice_date && moment(value.invoice_date).format('D MMMM YYYY')}</td>
              <td className='text-center w-15'>{value.plan_duedate ? moment(value.plan_duedate).format('D MMMM YYYY') : moment(value.due_date).format('D MMMM YYYY')}</td>
              <td className='text-center'>{value.paid}</td>
              <td className='text-center'>{value.credit}</td>
              <td className='text-center'>
              {this.state.type == 'Education' ? 
              <Link target="_blank" to={`/applications/view-education-application-detail/${value.appid}?cost=${true}`}>
              {remaining}</Link>
              :
              <Link target="_blank" to={`/applications/view-migration-application-detail/${value.appid}?cost=${true}`}>
              {remaining}</Link>}</td>
              <td className='text-center'>{value.currency}</td>
              <td className={`text-center bold sira-font size-12 
              ${value.status == 'Paid' ? 'active' : 
              value.status == 'Over Paid' ? 'heading-blue' : 
              value.status == 'Awaiting Payment' ? 'text-red':''}`}> {value.status} </td>
              <td className='text-center active' style={{fontFamily: 'sans-serif',fontSize: '14px',textTransform: 'capitalize'}}>
                 {value.email_status > 0 ? 'sent ('+value.email_status+")" : ''}</td>
              <td className='text-center'>{value.installments > 0 ? value.installments : 'N/A'}</td>
              <td className='text-center'><small>
              {value.cost_date && moment(value.cost_date).format('D MMMM YYYY')}</small></td>
              </tr> )}
            )}
            </tbody>
            </table>
            </div>
              <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              /> 
               {/* Exports */}
              {role === 2 &&
              <div className="d-flex mb-5">
              <ExportOptions 
              pdffilename={'ProfessionalCostList.pdf'} 
              csvfilename={'ProfessionalCostList.csv'} 
              id="viewcostdiv" 
              header="Professtional Cost"
              xlsxfilename="ProfessionalCostList"
              columns={this.state.all_headers}
              data={this.state.selectedCost} />
              </div>}
                {/* End Exports */}
            </div>
            </div>:''}
            </Tab>
              {/* Awaiting Payment */}
            <Tab eventKey="awaiting" title="Awaiting Payment">
            {this.state.loading === true ? <Loader/> :
            this.state.key === 'awaiting' ? 
            <div eventKey="awaiting" className="pt-0 mb-3">
            <div className="row p-3 bg-white border pt-1 mb-2 pb-1">
            <SelectionBar 
              parentCallback = {this.handleSearchCallback}
              type='finance' 
              selectedsDate={this.state.sDate}
              selectedeDate={this.state.eDate}
              selectedType={this.state.type}
              selectedWithin={this.state.within}
              currency={this.state.currency}
              invoiceno={this.state.invoiceNo}
              searchCol='col-lg-3 ml-2'
              from='awaiting' />
              
              {this.data()}
            
            </div>
            <div id="viewcostdiv2" className="row w-100 pr-1 mb-5 pl-1">
            <table id="viewcost2" className="table table-bordered bg-white mb-2 table-responsive">
            {/* Headers */}
            <thead className="thead-dark">
            <tr>
            {this.state.awaiting_headers.map(head => (
            <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>
            {head.title}</th>
            ))}
            </tr>
            </thead>
              {/* End Headers */}
              <tbody>
              {this.state.allCost.map((value, index) => {
                return(
                <>
                <tr key={index}>
                <td className='text-center'>{++index}</td>
                <td className='text-left name-text w-10'>
                <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.clientname}</Link></td>
                <td className='text-center name-text'> <Link to={`/invoice/edit-invoice/${value.invoice_id}`}>
                 {value.invoice_No && 'INV-'+value.invoice_No}</Link></td>
                <td className='text-center w-20 name-text'>{value.courseid == 0 ? value.packagename: value.coursename}</td>
                <td className='text-center'>{this.state.type}</td>
                <td className='text-center w-15'>{value.invoice_date && moment(value.invoice_date).format('D MMMM YYYY')}</td>
                {value.dueby < 0 ?
                <td className='text-center w-15 error'>{value.due_date ? moment(value.due_date).format('D MMMM YYYY') : 'N/A'}</td>
                : <td className='text-center w-15'>{value.due_date ? moment(value.due_date).format('D MMMM YYYY') : 'N/A'}</td>}
                <td className='text-center error'>{value.dueby < 0 && Math.abs(value.dueby)+' Days'}</td>
                <td className='text-center'>{value.paid}</td>
                <td className='text-center'>{value.credit}</td>
                <td className='text-center'>
                {this.state.type == 'Education' ? 
                <Link target="_blank" to={`/applications/view-education-application-detail/${value.appid}?cost=${true}`}>
                {value.remaining}</Link>
                :
                <Link target="_blank" to={`/applications/view-migration-application-detail/${value.appid}?cost=${true}`}>
                {value.remaining}</Link>}</td>
                <td className='text-center'>{value.currency}</td>
                <td className='text-center active bold sira-font text-red size-12'>{value.status} </td>
                <td className='text-center active' style={{fontFamily: 'sans-serif',fontSize: '14px',textTransform: 'capitalize'}}>
                 {value.email_status > 0 ? 'sent ('+value.email_status+")" : ''}</td>
                <td className='text-center'>{value.installments > 0 ? value.installments : 'N/A'}</td>
                <td className='text-center'>
                <small>{value.cost_date && moment(value.cost_date).format('D MMMM YYYY')}</small></td>
                </tr>
                </>
              )}
              )}
              </tbody>
             </table>
             <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              /> 
                {/* Exports */}
              {role === 2 &&
              <div className="d-flex mb-5">
              <ExportOptions 
              pdffilename={'AwaitingCostList.pdf'} 
              csvfilename={'AwaitingCostList.csv'} 
              id="viewcostdiv2" 
              header="Professtional Cost"
              xlsxfilename="ProfessionalCostList"
              columns={this.state.awaiting_headers}
              data={this.state.selectedCost} />
              </div>}
                {/* End Exports */} 
            </div>
            </div>:''}
            </Tab>
              {/* Paid Payment */}
            <Tab eventKey="paid" title="Paid">
            {this.state.loading === true ? <Loader/> :
            this.state.key === 'paid' ? 
            <div eventKey="paid" className="pt-0 mb-3">
            <div className="row p-3 bg-white border pt-1 mb-2 pb-1">
            <SelectionBar 
              parentCallback = {this.handleSearchCallback}
              type='finance' 
              selectedsDate={this.state.sDate}
              selectedeDate={this.state.eDate}
              selectedType={this.state.type}
              selectedWithin={this.state.within}
              invoiceno={this.state.invoiceNo}
              from='paid' />

            {this.data()}

            </div>
            <div id="viewcostdiv3" className="row w-100 pr-1 mb-5 pl-1">
            <table id="viewcost3" className="table table-bordered bg-white mb-2 table-responsive">
              {/* Headers */}
            <thead className="thead-dark">
            <tr>
            {this.state.paid_headers.map(head => (
            <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>
            {head.title}</th> ))}
            </tr>
            </thead>
              {/* End Headers */}
            <tbody>
            {this.state.allCost.map((value, index) => {
              let remaining = value.total - value.paid;
              remaining = remaining.toFixed(2);
              return(
              <>
              <tr key={index}>
              <td className='text-center'>{++index}</td>
              <td className='text-left name-text'>
              <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.clientname}</Link></td>
              <td className='text-center name-text'> <Link to={`/invoice/edit-invoice/${value.invoice_id}`}>
              {value.invoice_No && 'INV-'+value.invoice_No}</Link></td>
              <td className='text-center w-20 name-text'>{value.courseid == 0 ? value.packagename: value.coursename}</td>
              <td className='text-center'>{value.type == 1 ? 'Migration' : 'Education'}</td>
              <td className='text-center w-15'>{value.last_payment ? moment(value.last_payment).format('D MMMM YYYY') : 'N/A'}</td>
              <td className='text-center'>
              {this.state.type == 'Education' ?  
              <Link target="_blank" to={`/applications/view-education-application-detail/${value.appid}?cost=${true}`}>
              {value.paid}</Link>
              : <Link target="_blank" to={`/applications/view-migration-application-detail/${value.appid}?cost=${true}`}>
              {value.paid}</Link>}</td>
              <td className='text-center'>{value.currency}</td>
              <td className='text-center active bold sira-font size-12'> {value.status}</td> 
              <td className='text-center active' style={{fontFamily: 'sans-serif',fontSize: '14px',textTransform: 'capitalize'}}>
                 {value.email_status > 0 ? 'sent ('+value.email_status+")" : ''}</td>
                <td className='text-center w-15'>{value.cost_date && moment(value.cost_date).format('D MMMM YYYY')}</td>
              </tr>
              </> )}
            )}
            </tbody>
            </table>
            <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              /> 
                {/* Exports */}
              {role === 2 &&
              <div className="d-flex mb-5">
              <ExportOptions 
              pdffilename={'PaidCostList.pdf'} 
              csvfilename={'PaidCostList.csv'} 
              id="viewcostdiv3" 
              header="Professtional Cost"
              xlsxfilename="ProfessionalCostList"
              columns={this.state.paid_headers}
              data={this.state.selectedCost} />
              </div>}
                {/* End Exports */}  
            </div>
            </div>:''}
            </Tab>
              {/* Installment Payment */}
            <Tab eventKey="installments" title="Installments">
            {this.state.loading === true ? <Loader/> :
            this.state.key === 'installments' ? 
            <div eventKey="installments" className="pt-0 mb-3">
            <div className="row p-3 bg-white border pt-1 mb-2 pb-1">
            <SelectionBar 
              parentCallback = {this.handleSearchCallback}
              type='finance' 
              selectedsDate={this.state.sDate}
              selectedeDate={this.state.eDate}
              selectedType={this.state.type}
              selectedWithin={this.state.within}
              invoiceno={this.state.invoiceNo}
              from='installments' />

            {this.data()}

            </div>
            <div id="viewcostdiv4" className="row w-100 pr-1 mb-5 pl-1">
            <table id="viewcost4" className="table table-bordered bg-white mb-2 table-responsive">
              {/* Headers */}
            <thead className="thead-dark">
            <tr>
            {this.state.installments_headers.map(head => (
            <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>
            {head.title}</th>
            ))}
            </tr>
            </thead>
              {/* End Headers */}
              <tbody>
              {this.state.allCost.map((value, index) => {
                let remaining = value.total - value.paid;
                remaining = remaining.toFixed(2);
                return(
                <>
                {value['installments'] > 0 ?
                <tr key={index}>
                <td className='text-center'>{++index}</td>
                <td className='text-left name-text'>
                <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.clientname}</Link></td>
                <td className='text-center name-text'> <Link to={`/invoice/edit-invoice/${value.invoice_id}`}>
                 {value.invoice_No && 'INV-'+value.invoice_No}</Link></td>
                <td className='text-center name-text w-20'>{value.courseid == 0 ? value.packagename: value.coursename}</td>
                <td className='text-center'>{this.state.type}</td>
                <td className='text-center'> {value['payments'] && value['payments'].map((v) => { return<>{v.qtr}/{value.installments}<br/><br/></>})}</td>
                <td className='text-center w-25'><small>{value['payments'] && value['payments'].map((v) => { return<>{moment(v.paymentdate).format('D MMM YYYY')}<br/><br/></>})}</small></td>
                <td className='text-center w-10'><small>{value['payments'] && value['payments'].map((v) => { return<>{v.amount}<br/><br/></>})}</small></td>
                <td className='text-center'>
                {this.state.type == 'Education' ?  
                <Link target="_blank" to={`/applications/view-education-application-detail/${value.appid}?cost=${true}`}>
                {value['payments'] && value['payments'].map((v) => { return<>{v.remaining_amount}<br/><br/></>})}</Link>
                : <Link target="_blank" to={`/applications/view-migration-application-detail/${value.appid}?cost=${true}`}>
                {value['payments'] && value['payments'].map((v) => { return<>{v.remaining_amount}<br/><br/></>})}</Link>}</td>
                <td className='text-center'>{value.credit}</td>
                <td className='text-center'>{value.currency}</td>
                <td className='text-center active' style={{fontFamily: 'sans-serif',fontSize: '14px',textTransform: 'capitalize'}}>
                 {value.email_status > 0 ? 'sent ('+value.email_status+")" : ''}</td>
                <td className={`text-center bold sira-font size-12 
                ${value.status == 'Paid' ? 'active' : 
                value.status == 'Over Paid' ? 'heading-blue' : 
                value.status == 'Awaiting Payment' ? 'text-red':''}`}> {value.status} </td>
                </tr>:''}
                </>)
              })}
              </tbody>
            </table>
            <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              />  
                 {/* Exports */}
              {role === 2 &&
              <div className="d-flex mb-5">
              <ExportOptions 
              pdffilename={'InstallmentCostList.pdf'} 
              csvfilename={'InstallmentCostList.csv'} 
              id="viewcostdiv4" 
              header="Professtional Cost"
              columns={this.state.paid_headers}
              data={this.state.selectedCost} />
              </div>}
                {/* End Exports */}
            </div>
            </div>:''}
            </Tab>
              {/* Over Paid*/}
            <Tab eventKey="overpaid" title="Over Paid">
            {this.state.loading === true ? <Loader/> :
            this.state.key === 'overpaid' ? 
            <div eventKey="overpaid" className="pt-0 mb-3">
            <div className="row p-3 bg-white border pt-1 mb-2 pb-1">
            <SelectionBar 
              parentCallback={this.handleSearchCallback}
              type='finance' 
              from='overpaid' />
              
              {this.data()}

            </div>
            <div id="viewcostdiv5" className="row w-100 pr-1 mb-5 pl-1">
            <table id="viewcost5" className="table table-bordered bg-white mb-2 table-responsive">
              {/* Headers */}
            <thead className="thead-dark">
            <tr>
            {this.state.overpaid_headers.map(head => (
            <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>
            {head.title}</th> ))}
            </tr>
            </thead>
              {/* End Headers */}
              <tbody>
              {this.state.allCost.map((value, index) => {
                let remaining = value.total - value.paid;
                remaining = remaining.toFixed(2);
                return(
                <>
                {value['status'] == 'Over Paid' ?
                <tr key={index}>
                <td className='text-center'>{++index}</td>
                <td className='text-left name-text'><Link to={`/clients/view-client-detail/${value.clientid}`}>{value.clientname}</Link></td>
                <td className='text-center name-text'> <Link to={`/invoice/edit-invoice/${value.invoice_id}`}>
                 {value.invoice_No && 'INV-'+value.invoice_No}</Link></td>
                <td className='text-center name-text w-20'>{value.courseid == 0 ? value.packagename: value.coursename}</td>
                <td className='text-center'>{this.state.type}</td>
                <td className='text-center w-15'>{value.due_date && moment(value.due_date).format('D MMMM YYYY')}</td>
                <td className='text-center w-15'>{value.last_payment ? moment(value.last_payment).format('D MMMM YYYY') : ''}</td>
                <td className='text-center w-15'>{value.paid}</td>
                <td className='text-center heading-blue'>
                {this.state.type == 'Education' ?  
                <Link target="_blank" to={`/applications/view-education-application-detail/${value.appid}?cost=${true}`}>
                {value.remaining}</Link>
                : <Link target="_blank" to={`/applications/view-migration-application-detail/${value.appid}?cost=${true}`}>
                {value.remaining}</Link>}</td>
                <td className='text-center'>{value.currency}</td>
                <td className='text-center active' style={{fontFamily: 'sans-serif',fontSize: '14px',textTransform: 'capitalize'}}>
                 {value.email_status > 0 ? 'sent ('+value.email_status+")" : ''}</td>
                <td className={`text-center bold sira-font size-12 
                ${value.status == 'Paid' ? 'active' : 
                value.status == 'Over Paid' ? 'heading-blue' : 
                value.status == 'Awaiting Payment' ? 'text-red':''}`}> {value.status} </td>
                </tr>:''}
                </>)}
              )}
              </tbody>
            </table>
            <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              />  
                {/* Exports */}
              {role === 2 &&
              <div className="d-flex mb-5">
              <ExportOptions 
               pdffilename={'OverCostList.pdf'} 
               csvfilename={'OverCostList.csv'} 
               id="viewcostdiv5" 
               header="Professtional Cost"
               xlsxfilename={'Cost'}
               columns={this.state.paid_headers}
               data={this.state.selectedCost} />
              </div>}
                {/* End Exports */}
            </div>
            </div>:''}
            </Tab>
          
           </Tabs>
           </Col>
           </Row>
           </div>
           : 'You dont have permission to access this pages!'}
           </div>
          </>
        )
    }
}
export default ViewCost;
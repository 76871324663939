
import React ,{useState,useEffect, useRef} from "react"
import { Link, useParams} from "react-router-dom"
import Title from '../GenericComponents/Title'
import { delete_ApiManager, post_ApiManager, search_ApiManager, view_ApiManager } from "../ApiManager/ApiManager"
import MultipleSearchs from "../GenericComponents/MultipleSearchs"
import { FaEdit, FaEllipsisH, FaEye, FaRegCheckSquare, FaTrashAlt } from "react-icons/fa"
import Paginations from "../GenericComponents/Pagination"
import moment from "moment"
import { external_agent_type } from "../../utilis/appStatus"
import swal from 'sweetalert'
import { Sorting } from "../GenericComponents/SortTables"
import ExportOptions from "../GenericComponents/ExportOptions"
import { Button, Modal } from "react-bootstrap"

const ExternalClients = (props) => {
    const { from } = useParams();
    const scrollRef = useRef()
    const [currentPage, setCurrentPage] = useState(1);
    const [perItem, setPerItem] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [type, setType] = useState('null');
    const [order, setOrder] = useState("ASC");
    const [eIndex, setEIndex] = useState('');
    const [search, setSearch] = useState('');
    const [client, setClient] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [gender, setGender] = useState('');
    const [assignedTo, setAssignedTo] = useState([]);
    const [counselor, setCounselor] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [bulk, setBulk] = useState(false);
    const [bulkIds, setBulkIds] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem('user')));
    const [selectedClients, setSelectedClients] = useState([]);
    const [openRow, setOpenRow] = useState(null); // Track which row's dropdown is open
    const ref = useRef([]);
    const [showApplicantList, setShowApplicantList] = useState(false);
    const [list, setList] = useState([]);
    const [appType, setAppType] = useState("");
    const [clientName, setClientName] = useState("");
    const [headers, setHeaders] = useState([ 
        {title: '#' , dataKey: "index", align: "text-center", key: 1, checked: true, canChange: false},
        {title: 'Counselor', dataKey: "counselor", align:"text-center", key: 2, checked: true, canChange: false },
        {title: 'Client' , dataKey: "name", align:'w-10', key: 3, checked: true, canChange: false},
        {title: 'Email' , dataKey: "email", align: "text-center ",  key: 4, checked: false, canChange: false},
        {title: 'Phone' , dataKey: "mobile", align: "text-center ",  key: 5, checked: false, canChange: true},
        {title: 'Country', dataKey: "country", align: "text-center ",  key: 6, checked: false, canChange: true },
        {title: 'City', dataKey: "city", align: "text-center ",  key: 7, checked: false, canChange: true },
        {title: 'Gender', dataKey: "gender", align: "text-center ",  key: 8, checked: false, canChange: true },
        {title: 'AssignedTo', dataKey: "assignedTo", align:"text-center",  key:9, checked: true, canChange: true },
        {title: 'Active Status', dataKey: "active_status", align:"text-center", key: 10, checked: true, canChange: true },
        {title: 'CreatedAt', dataKey: "created_at", align:"text-center",  key: 11, checked: false, canChange: true},
        {title: 'External Type' , dataKey: "external_type", align:"text-center", key:12, checked: true, canChange: true},
        {title: 'External Agent' , dataKey: "external_agent", align:"text-center", key:13, checked: true, canChange: true},
        {title: 'Branch', dataKey: "branch", align:"text-center", key: 14, checked: false, canChange: true }, 
        {title: 'Mig App', dataKey: "migAppCount", align:"text-center", key: 15, checked: true, canChange: true }, 
        {title: 'Edu App', dataKey: "eduAppCount", align:"text-center", key: 16, checked: true, canChange: true }, 
        {title: '', align:'w-5', dataKey: '', key: 17, checked: true, canChange: false },
    ]);

    useEffect( () => {
        if(!search){
            getExternalClients();
        } else{

        }
    }, [currentPage, perPage]);

    useEffect( () => {
        getAllUsers();
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, []); 

      // Get all provider details
      const getExternalClients = () => {
        let url = `getExternalClients/${from}`;
        console.log(url, perPage, currentPage);
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('ext-clients',res);
            setAllClients(res.data.data['data']);
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setEIndex(currentPage * perPage - perPage + 1);
            let options = res.data.data['data'].map(function (item) {
                return { 
                index: item.index,
                counselor: item.counselor,
                name: item.name,
                email: item.email,
                mobile: item.mobile,
                country: item.country,
                gender: item.gender,
                created_at: moment(item.created_at).format('YYYY-MM-D'),
                external_type: item.external_type,
                external_agent: item.external_agent,
                referenceBy: item.referred_by
                };
            })
            setSelectedClients(options)
        },(err) => {
            console.log(err);
        });
    }
    const handlePaginationCallback = (i, perPage) => {
        window.scrollTo(0, scrollRef);
        if(perPage && perPage != ''){
            setCurrentPage(1);
            setPerPage(perPage); 
        } else { setCurrentPage(i);  }
     };
     const onBulkClick = (id, checked) => {
        if(checked == true){
            const newState = allClients.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: true};
                }
                return obj;
            });
            setAllClients(newState);
            setBulkIds([...bulkIds, { id:id}]);
            console.log(bulkIds)
        } else{
            const newState = allClients.map(obj => {
                if (obj.id == id) {
                  return {...obj, checked: false};
                }
                return obj;
            });
            setAllClients(newState);
            var array = bulkIds.filter(function(item) {return item.id !== id});
            setBulkIds(array);
        }
     }
     const handleSearchCallback = (input1, input2, input3, input4, input5, input6, input7, input8, input9) => {
        let Client, Email, Mobile, Country, City, Gender, AssignedTo, Counselor, ExternalType;
        if (input1 === 'clear') {
            setSearch('');
        } else{
            if (input1 === 'yes') {
                Client = client;
                Email = email;
                Mobile = mobile;
                Country = country;
                City = city;
                Gender = gender;
                AssignedTo = assignedTo;
                Counselor = counselor;
                ExternalType = type;
            }else {
                setSearch('yes');
                Client = input1;
                Email = input2;
                Mobile = input3;
                Country = input4;
                City = input5;
                Gender = input6;
                AssignedTo = input7;
                Counselor = input8;
                ExternalType = input9;
                setClient(Client);
                setEmail(Email);
                setMobile(Mobile);
                setCountry(Country);
                setCity(City);
                setGender(Gender);
                setAssignedTo(AssignedTo);
                setCounselor(Counselor);
                setType(ExternalType);
            }
            const data = {
                client: Client,
                email: Email,
                mobile: Mobile,
                country: Country,
                city: City,
                gender: Gender,
                assignedTo: AssignedTo,
                counselor: Counselor,
                type: ExternalType,
            }
            console.log(data);
            let url = `search/externalClient`;
            search_ApiManager(url, data, currentPage, perPage,(res) => {
                console.log('search result',res);
                setAllClients(res.data.data['data']);
                setPerPage(res.data.data['per_page']);
                setLastPage(res.data.data['last_page']);
                setTotalItem(res.data.data['total']);
                setPerItem(res.data.data['data'].length);
                setEIndex(currentPage * perPage - perPage + 1);
                let options = res.data.data['data'].map(function (item) {
                    return { 
                    index: item.index,
                    counselor: item.counselor,
                    name: item.name,
                    email: item.email,
                    mobile: item.mobile,
                    country: item.country,
                    gender: item.gender,
                    created_at: moment(item.created_at).format('YYYY-MM-D'),
                    external_type: item.external_type,
                    external_agent: item.external_agent,
                    referenceBy: item.referred_by
                    };
                })
                setSelectedClients(options)

            },(err) => {
                console.log(err);
            });
        }
        
    }
    const handleColumnsCallback = (selectedColumns) => {
        setHeaders(selectedColumns);
     }
     const getAllUsers = async () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            setAllUsers(res.data.data);
        },(err) => {
            console.log(err);
        });
     }
     const onBulkDelete = () => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover these clients",icon: "warning", buttons: true, dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let url = 'assignedBulkDelete';
                const data = {
                    bulkList: bulkIds,
                }
                console.log(data);
                post_ApiManager(data, url,(res) => {
                    console.log('clients-deleted',res);
                    if(res.data.error) swal(" ", res.data.error, "error")
                    else {
                        swal("Clients deleted successfully", {icon: "success" });
                        getExternalClients()
                        setBulk(false);
                        setBulkIds([]);
                    };   
                },(err) => {
                    console.log(err);
                });
            }
        }); 
     }
     const onBulkAssigned = (assignedId) => {
        let url = 'assignedBulkClients';
        const data = {
            bulkList: bulkIds,
            assignedId: assignedId,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('Clients Assigned',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
                getExternalClients();
                setBulk(false);
                setBulkIds([]);
            } 
        },(err) => {
            console.log(err);
        })
     }
     const allBulk = () => {
        const newState = allClients.map(obj => {
              return {...obj, checked: true};
        });
        const array = [];
        allClients.map(x => {
            array.push({ id: x.id});
        })
        setAllClients(newState);
        setBulk(true);
        setBulkIds(array);
     }
     const selectBulk = () => {
        const newState = allClients.map(obj => {
            return {...obj, checked: false};
        });
        setAllClients(newState);
        setBulk(!bulk);
        setBulkIds([]);
     }
     const sorting = (col) => {
        const sortResult = Sorting(allClients, col, order)
        setOrder(sortResult[0]);
        setAllClients(sortResult[1]);
    }
    const deleteClient = (delete_id, name) => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover ("+ name +")",icon: "warning", buttons: true, dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            let url = 'clients';
            delete_ApiManager(delete_id, url,(res) => {
            console.log('User Deleted',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Client ("+ name +")"+" deleted successfully", {icon: "success" });
                this.setState({ currentPage:1});
                this.getExternalClients(); 
            };   
        },(err) => {
            console.log(err);
        }); }   
       });
    }
    const handleClickOutside = (event) => {
        ref.current.forEach((rowRef, index) => {
          if (rowRef && !rowRef.contains(event.target)) {
            setOpenRow(null); // Close any open dropdown if clicked outside
          }
        });
      };
    const toggleDropdown = (id) => {
        setOpenRow(openRow === id ? null : id); // Toggle the dropdown open/close for the specific row
      };
    const openModel = (e, list, client, type) => {
        console.log(list)
        setClientName(client);
        e.preventDefault();
        setList(list);
        setAppType(type);
        setShowApplicantList(true)
    }

    return(
        <>
        <Title title={'External Agent Clients'} 
        back={true} />
        <div className="row w-100 pl-3 pr-3 mb-5">
        <MultipleSearchs
         input1={headers[1]['checked'] && 'Search Client'}
         input1_type={"input"}
         input2={headers[3]['checked'] && 'Search Email'}
         input2_type={"input"}
         input3={headers[4]['checked'] && 'Search Mobile'}
         input3_type={"input"}
         input4={headers[5]['checked'] && 'Search Country'}
         input4_type={"input"}
         input5={headers[6]['checked'] && 'Search City'}
         input5_type={"input"}
         input6={headers[7]['checked'] && 'Search Gender'}
         input6_type={"input"}
         input7={headers[8]['checked'] && 'Search AssignedTo'}
         input7_type={"dropdown"}
         input7Data={allUsers}
         input7_multiple={"yes"}
         input7_filter_by={"name"}
         input8={headers[1]['checked'] && 'Search Counselor'}
         input8_type={"dropdown"}
         input8Data={allUsers}
         input8_multiple={"yes"}
         input8_filter_by={"name"}
         input9={'External Type'}
         input9_type={"dropdown"}
         input9Data={external_agent_type}
         input9_multiple={"yes"}
         input9_filter_by={"title"}
         onSearchChange={handleSearchCallback}
         onColumnsChange={handleColumnsCallback}
         allColumns={headers}
         />
         <div className='w-100 mr-2 text-right'>
         <small className='underline pointer heading-blue' onClick={() => allBulk()}> Select All  </small>
         <FaRegCheckSquare color='#4343ff' onClick={() => selectBulk()} className='underline' size={14} />
         </div>

         <div>
         <table id="viewclients" className={`table table-bordered bg-white table-responsive ${bulkIds[0] ? 'mb-0' : 'mb-2'}`}>
         {/* Headers */}
         <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         head.checked &&
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => sorting(head.dataKey)}> {head.title}</th> ))}
         </tr>
         </thead>

         <tbody>
         {allClients.map((value, index) => (
            <tr key={index++}>
            {headers[0]['checked'] && <td className='text-center'>{bulk === true ? 
            <input type='checkbox' checked={value.checked} onClick={(e) => onBulkClick(value.id, e.target.checked)} /> 
            : eIndex+index}</td>}
            {headers[1]['checked'] && <td className='text-center name-text w-15'>{value.userid === 0 ? 'Manual Entry' : value.counselor}</td>}
            {headers[2]['checked'] && <td><Link className='w-15' to={`/clients/view-client-detail/${value.id}`}>{value.name}</Link></td>}
            {headers[3]['checked'] && <td className='text-center'>{value.email ? value.email : '--'}</td>}
            {headers[4]['checked'] && <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>}
            {headers[5]['checked'] && <td className='text-center'>{value.country}</td>}
            {headers[6]['checked'] && <td className='text-center'>{value.city}</td>}
            {headers[7]['checked'] && <td className='text-center'>{value.gender}</td>}
            {headers[8]['checked'] && <td className='text-center'>{value.assignedTo}</td>}
            {headers[9]['checked'] && <td className='text-center'>{value.active_status == 0 ? 'Deactivated' : 'Active'}</td>}
            {headers[10]['checked'] && <td className='text-center'>{moment(value.created_at).format('D MMMM YYYY')}</td>}
            {headers[11]['checked'] && <td className='text-center'>{value.external_type}</td>}
            {headers[12]['checked'] && <td className='text-center'>{value.external_agent}</td>}
            {headers[13]['checked'] && <td className='text-center'>{value.branch}</td>}
            {headers[14]['checked'] && (<td className='text-center pointer theme-blue'>{value.migAppCount > 0 && (
                <div onClick={(e) => openModel(e, value.migApps, value.name, 'mig')}> ({value.migAppCount})<br/>View </div>)}</td>)}
            {headers[15]['checked'] && (<td className='text-center theme-blue pointer'>{value.eduAppCount > 0 && (
                <div onClick={(e) => openModel(e, value.eduApps, value.name, 'edu')}> ({value.eduAppCount}) <br/>View</div>)}</td>)}
            <td className="text-center">
            <span className="wrapper icon-ref ml-0" ref={(el) => (ref.current[index] = el)}>
            <span className="dot-icon">
            <a className="a-ref-icon" onClick={() => toggleDropdown(value.id)}>
            <FaEllipsisH size={12} color={'rgb(107 107 107 / 71%)'} />
            </a>
            {openRow === value.id && 
            <div className="actions-dots-dropdown">
            <Link to={`/clients/view-client-detail/${value.id}`}>
            <button className="btn btn-info pl-3 pr-3 p-0 m-1 mb-2"> 
            <FaEye size={12} color={'#fffcfc'} /> </button></Link>
            <br/>
            {userRole['role'] === 2 &&
            <button onClick={() => deleteClient(value.id, value.providername)} 
            className="btn btn-danger pl-3 pr-3 p-0 m-1 mb-2"> 
            <FaTrashAlt size={12} color={'#fffcfc'} /> </button>}
        
            </div>} 
            </span>
            </span>
            </td>
            </tr>
         ))}
         </tbody>
         </table>
         {bulk == true && bulkIds[0] &&
        <div className='d-flex mb-2 bg-white p-2 border'>
        <select className="form-select app-form col-2" name="assignedID" 
        onChange={(e) => onBulkAssigned(e.target.value)} >
        <option value='0'>Assign Selected </option>
        <option value='0' disabled>Unassigned</option>
        {[].concat(allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map(value =>
        <option key={value.id} className="light" value={value.id}>{value.name}</option> )}
        </select> 

        {userRole['role'] === 2 &&
        <button className='btn btn-danger ml-2' onClick={() => onBulkDelete()}>Delete Selected</button>}

        </div>}
         </div>
         <Paginations
            currentPage={currentPage}
            total= {totalItem}
            itemsPerPage= {perPage}
            lastPage={lastPage}
            totalItems={totalItem}
            perItem={perItem}
            onPageChange= {handlePaginationCallback} 
        />
          {(userRole['role'] === 2 || userRole['id'] === 18) &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Clients.pdf'} 
         csvfilename={'Clients.csv'} 
         xlsxfilename={'Clients'} 
         header="External Clients List"
         columns={headers}
         data={selectedClients} />
         </div>}
        </div>
        <Modal size='lg' show={showApplicantList} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowApplicantList(false) }>
        <Modal.Header closeButton> <div className="text-center white col-11 font-f-monst-sans">
        {appType === 'mig' ? "Migration " : "Education "} Application</div></Modal.Header>
        <Modal.Body>
        <form >
        <div className="row">
        <table className="table table-bordered bg-white mb-1">
          {/* Headers */}
          <thead className="thead-dark">
          <tr>
          <th>#</th>
          <th className="text-center">Client</th>
          <th className="text-center">Course</th>
          <th className="text-center">Provider</th>
          <th className="text-center">Status</th>
            </tr>
            </thead>
            <tbody>
            {list && list.length > 0 && (
             list.map((v, index) =>
            <>
            <tr key={index}>
            <td className="text-center">{++index}</td>
            <td className="text-center">{clientName}</td> 
            <td className="text-center name-text">
            {appType === 'mig' ? 
            <Link target='_blank' to={`/applications/view-migration-application-detail/${v.appid}`}>
            {v.coursename}</Link>
            :
            <Link target='_blank' to={`/applications/view-education-application-detail/${v.appid}`}>
            {v.coursename ? v.coursename : v.packagename}</Link>}
            </td> 
            <td className="text-center">{v.providername} </td> 
            <td className="text-center">{v.appstatus} </td> 
            </tr>
            </>))}
            </tbody>
            <tbody>
            </tbody>
        </table>
        </div>
        </form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="btn btn-danger" onClick={() => setShowApplicantList(false)}>Cancel</Button>
        </Modal.Footer>
        </Modal>
        </>
    )
}
export default ExternalClients
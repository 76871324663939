import axios from "axios";
import { main_url } from "../../utilis/Common";

const token = localStorage.getItem("token");
export const apiUrl = axios.create({
        baseURL: main_url()+'/api/v1/',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
});
    
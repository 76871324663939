import React, { useState,useRef, useEffect} from 'react'
import { view_ApiManager } from '../ApiManager/ApiManager';
import Moment from 'react-moment';
import './notification.css';
import {Tabs, Tab, Row, Col, Container} from "react-bootstrap";
import SeenStatus from './SeenStatus';
import * as FaIcons from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Search from '../GenericComponents/Search';
import MultipleSearch from '../GenericComponents/MultipleSearch';

const Alerts = (props) => {
    const ref = useRef();
    const [loading, setLoading] = useState(false);
    const [getAllAlerts, setGetAllAlerts] = useState([]);
    const [open, setOpen] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [perPage] = useState(40);
    const navigate = useNavigate();
    
    useEffect(() => {  
        // On Click mark as seen
        // if(open == true){ 
        //     getSeenAlert();
        // }

        const checkIfClickedOutside = e => {
          if (open && ref.current && !ref.current.contains(e.target)) {
            setOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [open]);

         //Call View Notification Api
    const allAlerts = async (e) => {
        let url = 'getAllAlerts';
        setLoading(true); 
            view_ApiManager(url, 1, perPage,(res) => {
            setLoading(false);
            setGetAllAlerts(res.data.data['data']);
            console.log('all-alerts',res);
        },(err) => {
            console.log(err);
        });
    }

    const handleIconClick = async (e) => {
        setOpen(!open)
        allAlerts();
    }
    const callDetailPage = (id) => {
        setOpen(!open)
        handleAlert(id);
        setKeyword('');
        let path = `/alert-detail/${id}`; 
        navigate(path);
    }

     //  Call Notification bell Counter
    const handleAlert = ($id) => {
        const socket = props.socket;
        const user = props.user;
        socket.emit("seenAlert", {
        senderName: user,
        alert_id : $id
        });
    }
    const searchKeyword = () => {
        let url = `searchAlert/${keyword}`;
        console.log(url);
            view_ApiManager(url, 1, perPage,(res) => {
            setGetAllAlerts(res.data.data['data']);
            console.log('search',res);
        },(err) => {
            console.log(err);
        }); 
    }
    const clearAll = () => {
        setKeyword('');
        allAlerts();
    }

 return(
    <>
     <div className='text-center d-flex j-content-center icon-container'>
    <SeenStatus seenStatus={props.alertCount}/>
    <li className="wrapper">
        <a className="icon-button svg" onClick ={() => handleIconClick()}>
        {props.icon} </a>
    </li>
    <span className="hover-text">Alerts</span>
    </div>

    <div className={"modal n-modal fade" + (open ? "show d-block" : " d-none")} tabIndex="-1" role="dialog">
    <div className="modal-dialog n-modal-dialog " role="document">
        <div ref={ref} className="modal-content n-modal-content wrapper">
            <Container className="overflow">
            <div className='d-flex w-100'>
            <input type="text" 
            className="search-input form-control search bg-white my-2"
            placeholder="Search" 
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            />
              <button onClick={() => searchKeyword()} className='btn btn-search my-2 w-25 search-icon'
                   type="submit"><i className="fa fa-search"></i></button>
            </div>
            <div className='float-right w-100 text-right pr-1 size-13 pt-1 font-sans pointer' 
            style={{color:'#4a4aff'}} onClick={() => clearAll()}>Clear Seacrh</div>
                 <Row className="w-100">
                     <Col>
                             {loading ? 'Loading...' : 
                                <>
                                {getAllAlerts.map((value, index) => {
                                return(
                                <div key={index} className={`pb-2 pt-2 alert-hover notify row pl-0 w-100 ${value.seen_status == 0 ? ' unseen-bg' : ''}`}>
                                <div className='row pointer' onClick={() => callDetailPage(value.id)}>
                                <>
                                <div className="col-1 pl-2">
                                <FaIcons.FaRegBell className="app-c" size={20}/>
                                </div>
                                <div className='col-10 pl-2'>
                                <div className="bold capital acc-label size-14 pl-1" >{value.title}</div>
                                <small className="row acc-label2"><Moment date={value.updated_at}  format="D MMM YYYY, hh:mm" withTime></Moment></small>
                                </div>
                                {value.seen_status == 0 &&
                                <div className='col-1'> <FaIcons.FaCircle className="app-c" size={8}/></div>}
                                </></div>
                                </div>)})}
                                </>
                              }
                     </Col>
                 </Row>
             </Container>
             </div>
        </div>
    </div>
    </>
    )   
}
export default Alerts;
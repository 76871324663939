
import React from "react";
import {useNavigate} from "react-router-dom";

const Search = ({onSearchChange, btn, btnText, btnUrl, leftbtn, search}) => {
    const history = useNavigate();

    return(
        <div className="col-12">
        {btn ? 
        <div className="col-md-12 d-flex justify-right float-right pr-0 pb-3">
        <button type="button" className="btn btn-success" onClick={() => history(btnUrl)}>{btnText}</button>
        </div> : ''}
        <div className="col-md-12 text-right d-flex float-right pr-3 pb-2 pl-0">
        <div className="col-lg-9 pl-0">
        {leftbtn == true && 
        <div className="justify-left float-left pr-0">
        <button type="button" className="btn btn-success" onClick={() => history(btnUrl)}>{btnText}</button>
        </div>
        }
        </div>
        {search === false ? '' :
        <input type="text" 
        className="form-control search bg-white col-lg-3 col-6"
        placeholder="Search" 
        onChange={(e) => onSearchChange(e.target.value)}
        />}
        </div>
        </div>
    )
}
export default Search;
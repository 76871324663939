import React, { Component } from 'react';
import Paginations from '../GenericComponents/Pagination'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DOMPurify from 'dompurify'
import { search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import "./finance.css"
import ExportOptions from '../GenericComponents/ExportOptions'
import { Sorting } from '../GenericComponents/SortTables'
import Title from '../GenericComponents/Title'
import { commissionSortBy, paymentStatus } from '../../utilis/appStatus';
import MultipleSearchs from '../GenericComponents/MultipleSearchs';

class ViewCommissions extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        var userDetail = JSON.parse(localStorage.getItem('user')); 
        this.state = {
          client:'', course:'', status:'', sortType:'',
          loading: false,
          currentPage: 1,
          totalItem:'',
          lastPage:'',
          perPage: 25,
          search:'',
          order:'ASC',
          perItem:'',
          allPermissions: permissions,
          selectedCommissions:[],
          allCommissions:[],
          showHistory: false,
          selectedId:'',
          userRole: userDetail,
          provider:'',
          histroyHeaders: [
            {name: 'PaymentDate' , align: "text-center" ,field: "name", key:2},
            {name: 'Amount' , field: "amount", align: "text-center", key:3},
          ],
          headers: [
            {title: '#' , dataKey: "index", align: "text-center",key:1, checked: true, canChange: false},
            {title: 'Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: true},
            {title: 'Installment#' , dataKey: "installment",align: "text-center", key:3, checked: true, canChange: true},
            {title: 'Course' , dataKey: "coursename",align: "text-center", key:4, checked: true, canChange: true},
            {title: 'Amount' , dataKey: "amount",align: "text-center", key:5, checked: true, canChange: true},
            {title: 'Com%' , dataKey: "commission",align: "text-center", key:6, checked: true, canChange: true},
            {title: 'Incl_Com' , dataKey: "commissionincl",align: "text-center", key:7, checked: false, canChange: true},
            {title: 'Excl_Com' , dataKey: "commissionexcl",align: "text-center", key:8, checked: false, canChange: true},
            {title: 'Due_Date' , dataKey: "duedate",align: "text-center", key:9, checked: true, canChange: true},
            {title: 'Payment_Date' , dataKey: "paymentdate",align: "text-left", key:10, checked: true, canChange: true},
            {title: 'Remaining' , dataKey: "remaining_amount",align: "text-left", key:11, checked: true, canChange: true},
            {title: 'Status', dataKey: 'status',align:"text-center", key:12, checked: true, canChange: true},
            {title: 'Discount' , dataKey: "discount",align: "text-center", key:13, checked: true, canChange: true},
            {title: 'Dis_Incl_Gst' , dataKey: "discount_incl_gst", align: "text-center", key:14, checked: false, canChange: true},
            {title: 'Dis_Excl_Gst' , dataKey: "discount_excl_gst", align: "text-center", key:15, checked: false, canChange: true},
            {title: 'Bonus' , dataKey: "app_bonus",align: "text-center", key:16, checked: false, canChange: true},
            {title: 'Comments' , dataKey: "notes",align: "text-center", key:17, checked: true, canChange: true},
            {title: 'Provider' , dataKey: "pname",align: "text-center", key:18, checked: true, canChange: true},
          ],
          overdue_headers: [
            {title: '#' , dataKey: "index", align: "text-center",key:1, checked: true, canChange: false},
            {title: 'Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: true},
            {title: 'Installment#' , dataKey: "installment",align: "text-center", key:3, checked: true, canChange: true},
            {title: 'Course' , dataKey: "coursename",align: "text-center", key:4, checked: true, canChange: true},
            {title: 'Amount' , dataKey: "amount",align: "text-center", key:5, checked: true, canChange: true},
            {title: 'Com%' , dataKey: "commission",align: "text-center", key:6, checked: true, canChange: true},
            {title: 'Incl_Com' , dataKey: "commissionincl",align: "text-center", key:7, checked: false, canChange: true},
            {title: 'Excl_Com' , dataKey: "commissionexcl",align: "text-center", key:8, checked: false, canChange: true},
            {title: 'Due_Date' , dataKey: "duedate",align: "text-center", key:9, checked: true, canChange: true},
            {title: 'DueBy' , dataKey: "dueby",align: "text-center", key:10, checked: true, canChange: true},
            {title: 'Remaining' , dataKey: "remaining_amount",align: "text-left", key:11, checked: true, canChange: true},
            {title: 'Status', dataKey: 'status',align:"text-center", key:12, checked: true, canChange: true},
            {title: 'Discount' , dataKey: "discount",align: "text-center", key:13, checked: true, canChange: true},
            {title: 'Dis_Incl_Gst' , dataKey: "discount_incl_gst", align: "text-center", key:14, checked: false, canChange: true},
            {title: 'Dis_Excl_Gst' , dataKey: "discount_excl_gst", align: "text-center", key:15, checked: false, canChange: true},
            {title: 'Bonus' , dataKey: "app_bonus",align: "text-center", key:16, checked: true, canChange: true},
            {title: 'Comments' , dataKey: "notes",align: "text-center", key:17, checked: true, canChange: true},
            {title: 'Provider' , dataKey: "pname",align: "text-center", key:18, checked: true, canChange: true},
          ],
        }
        this.handleSearchCallback = this.handleSearchCallback.bind(this);
    }
    componentDidMount() {
      window.scrollTo(0, 0);
      this.getCommissions();
    }

    async getCommissions() {
      this.setState({loading: true});
      let currentPage = this.state.currentPage;
      let perPage = this.state.perPage;
      console.log(this.state.perPage);
      let url = `getAllCommissions`;
      console.log(url);
      view_ApiManager(url, currentPage, perPage,(res) => {
          console.log('All-Commissions',res);
          this.setState({
              allCommissions: res.data.data['data'],
              loading: false,
              totalItem: res.data.data['total'],
              perPage: res.data.data['per_page'],
              lastPage: res.data.data['last_page'],
              perItem: res.data.data['data'].length,
          });
          let options = res.data.data['data'].map(function (item) {
              return { 
                index: item.index,
                        name: item.clientname,
                        coursename: item.coursename,
                        installment_no: item.installment_no,
                        amount: item.amount,
                        commission: item.commission,
                        commissionincl: item.commissionincl,
                        commissionexl: item.commissionexcl,
                        duedate: item.duedate,
                        dueby: item.dueby,
                        paiddate: item.paiddate,
                        remaining_amount: item.remaining_amount,
                        status: item.status,
                        discount: item.discount,
                        discount_incl_gst: item.discount_incl_gst,
                        discount_excl_gst: item.discount_excl_gst,
                        app_bonus: item.app_bonus,
                        created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
            })
            this.setState({selectedCommissions: options})
      },(err) => {
          console.log(err);
      });
    }
     // CallBack function handle pagination
     handlePaginationCallback = (i, perPage) =>{
      window.scrollTo(0,this.scrollRef);
      if(this.state.search == ''){
          if(perPage && perPage != ''){
              this.setState({ perPage : perPage, currentPage:1}, () => {  this.getCommissions();  }); 
          } else {this.setState({ currentPage : i}, () => { this.getCommissions(); }); }
      } else{
          if(perPage && perPage != ''){
              this.setState({ perPage : perPage, currentPage:1}, () => {  this.handleSearchCallback(this.state.search);  }); 
          } else {this.setState({ currentPage : i}, () => { this.handleSearchCallback(this.state.search); }); }   
      }
    } 
      //Search Api
     async handleSearchCallback (input1, input2, input3, input4, input5){
        let Client, Course, Status, Sort, Provider;
        if (input1 === 'yes') {
          Client = this.state.client;
          Course = this.state.course;
          Status = this.state.status;
          Sort = this.state.sortType;
          Provider = this.state.provider;
        } else{
          Client = input1;
          Course = input2;
          Status = input3;
          Sort = input4;
          Provider = input5;
          this.setState({search:'yes', client:Client, course: Course, status: Status, sortType: Sort, provider: Provider});
        }
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = `search/commissions`;
        const data = {
          client:Client,
          course:Course,
          status:Status,
          sort:Sort,
          provider: Provider,
      }
      console.log(data);
      search_ApiManager(url, data, currentPage, perPage,(res) => {
          console.log('search-result',res);
          this.setState({
            allCommissions: res.data.data['data'],
            loading: false,
            totalItem: res.data.data['total'],
            perPage: res.data.data['per_page'],
            lastPage: res.data.data['last_page'],
            perItem: res.data.data['data'].length,
          });
          let options = res.data.data['data'].map(function (item) {
              return { 
                name: item.clientname,
                coursename: item.coursename,
                installment_no: item.installment_no,
                amount: item.amount,
                commission: item.commission,
                commissionincl: item.commissionincl,
                commissionexl: item.commissionexcl,
                duedate: item.duedate,
                dueby: item.dueby,
                paiddate: item.paiddate,
                remaining_amount: item.remaining_amount,
                status: item.status,
                discount: item.discount,
                discount_incl_gst: item.discount_incl_gst,
                discount_excl_gst: item.discount_excl_gst,
                app_bonus: item.app_bonus,
                provider: item.pname,
                created_at: moment(item.created_at).format('YYYY-MM-D'),
              };
          })
          this.setState({selectedCommissions: options})
      },(err) => {
          console.log(err);
      });    
    }
     // Table sorting
     sorting = (col) => {
      const sortResult = Sorting(this.state.allCommissions, col, this.state.order)
      this.setState({order: sortResult[0], allCommissions: sortResult[1]})
   }
   handleColumnsCallback = (selectedColumns) => {
      if(this.state.sortType == 'overdue'){
        this.setState({overdue_headers: selectedColumns})
      } else{
        this.setState({headers: selectedColumns})
      }
   }

    render(){
      const role = JSON.parse(localStorage.getItem('user_role'));
      let headers = this.state.headers;
        if(this.state.sortType == 'overdue'){
          headers = this.state.overdue_headers;
        } 
        return(
            <>
            <Title title="View Commissions" />
            <div className="row w-100 pl-3 pr-3 mb-5">
          
            <MultipleSearchs
            input1={'Search Client'}
            input1_type={"input"}
            input2={'Search Course'}
            input2_type={"input"}
            input3={'Search Status'}
            input3_type={"dropdown"}
            input3_multiple={"no"}
            input3Data={paymentStatus}
            input4={'Search SortBy'}
            input4_type={"dropdown"}
            input4_multiple={"no"}
            input4Data={commissionSortBy}
            input4_filter_by={"label"}
            input5={'Search Provider'}
            input5_type={"input"}
            onSearchChange={this.handleSearchCallback}
            onColumnsChange={this.handleColumnsCallback}
            allColumns={headers}
            />
            {this.state.allPermissions['show_application_commission'] === 1  &&
            <>
            <table id="viewexpoList" className="table table-bordered bg-white table-responsive">
              {/* Headers */}
              <thead className="thead-dark">
              <tr>
              {headers.map(head => (
              head.checked &&
              <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}> {head.title}</th> ))}
              </tr>
              </thead>
              {/* End Headers */}
              <tbody>
              {this.state.allCommissions.map((value, index) => 
              <tr key={index}>
              {headers[0]['checked'] && <td className='text-center'>{++index}</td>}
              {headers[1]['checked'] && <td className='text-left'>
              <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.clientname}</Link></td>}
              {headers[2]['checked'] && <td className='text-center'> {value.installment_no}</td>}
              {headers[3]['checked'] && <td className='text-center name-text'>
              <Link to={`/applications/view-education-application-detail/${value.appid}`}>{value.coursename}</Link></td>}
              {headers[4]['checked'] && <td className='text-center name-text'>{value.amount}</td>}
              {headers[5]['checked'] && <td className='text-center'>{value.commission}</td>}
              {headers[6]['checked'] && <td className='text-center'>{value.commissionincl}</td>}
              {headers[7]['checked'] && <td className='text-center'>{value.commissionexcl}</td>}
              {headers[8]['checked'] && <td className='text-center'> {value.duedate ? moment(value.duedate).format('D MMM YYYY') :''}</td>}
              {this.state.sortType == 'overdue' ?
              <>
              {value.dueby &&
              <> {headers[9]['checked'] && <td className='text-center error'>
              {value.dueby < 0 && Math.abs(value.dueby)+' Days'}</td>}
              </> }
              {headers[10]['checked'] && <td className='text-center'>{value.remaining_amount}</td>}
              {headers[11]['checked'] && <td className={`text-center bold 
              ${value.status == 'pending' ? 'text-red': 'text-c-green'}`}>{value.status}</td>}
              {headers[12]['checked'] && <td className='text-center'>{value.discount}</td>}
              {headers[13]['checked'] && <td className='text-center'>{value.discount_incl_gst}</td>}
              {headers[14]['checked'] && <td className='text-center'>{value.discount_excl_gst}</td>}
              {headers[15]['checked'] && <td className='text-center'>{value.app_bonus}</td>}
              {headers[16]['checked'] && <td className='text-center'><div className="acc-note pl-2 bold" style={{whiteSpace: 'pre-wrap'}} 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.notes)}}></div></td>}
              {headers[17]['checked'] && <td className='text-center'>{value.pname}</td>}
              </>:
              <>
              {headers[9]['checked'] && <td className='text-center'>{value.paiddate ? moment(value.paiddate).format('D MMM YYYY') :''}</td>}
              {headers[10]['checked'] && <td className='text-center'>{value.remaining_amount}</td>}
              {headers[11]['checked'] && <td className={`text-center bold 
              ${value.status == 'pending' ? 'text-red': 'text-c-green'}`}>{value.status}</td>}
              {headers[12]['checked'] && <td className='text-center'>{value.discount}</td>}
              {headers[13]['checked'] && <td className='text-center'>{value.discount_incl_gst}</td>}
              {headers[14]['checked'] && <td className='text-center'>{value.discount_excl_gst}</td>}
              {headers[15]['checked'] && <td className='text-center'>{value.app_bonus}</td>}
              {headers[16]['checked'] && <td className='text-center'><div className="acc-note pl-2 bold" style={{whiteSpace: 'pre-wrap'}} 
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.notes)}}></div></td>}
              {headers[17]['checked'] && <td className='text-center'>{value.pname}</td>}
              </>}
              </tr>
              )}
              </tbody>
            </table>
              <Paginations
              currentPage= {this.state.currentPage}
              total= {this.state.totalItem} 
              itemsPerPage= {this.state.perPage}
              lastPage={this.state.lastPage}
              totalItems={this.state.totalItem}
              perItem={this.state.perItem}
              onPageChange= {this.handlePaginationCallback} 
              />
              {/* Exports */}
              {role === 2 &&
              <div className="d-flex mb-5">
              <ExportOptions
              pdffilename={'Commissions.pdf'} 
              csvfilename={'Commissions.csv'} 
              xlsxfilename={'Commissions'} 
              header="Commissions List"
              id="viewclientsdiv3" 
              columns={this.state.headers}
              data={this.state.selectedCommissions} />
              </div>}
              {/* End Exports */}
            </>}
            </div>
            </>
        )
    }
}
export default ViewCommissions
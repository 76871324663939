
import React ,{useState,useEffect}from "react"
import {useNavigate, useParams} from "react-router-dom"
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeAnimated from 'react-select/animated';
import Modals from '../GenericComponents/Modal'
import { faBlog, faUserFriends, faUserShield, } from '@fortawesome/free-solid-svg-icons'
import Title from '../GenericComponents/Title';
import "react-datepicker/dist/react-datepicker.css";
import './appointment.css';
import Select from 'react-select';
import moment from 'moment';
import smallSpinner from '../../assets/small.gif';
import { time_slots } from '../../utilis/slots';
import { post_ApiManager, get_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
 
function AddAppointment(props) {
    const {clientid } = useParams();
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const animatedComponents = makeAnimated();
    const [ slot1StartTime, setSlot1StartTime] = useState('');
    const [ slot2StartTime, setSlot2StartTime] = useState('');
    const [ slot3StartTime, setSlot3StartTime] = useState('');
    const [ slot4StartTime, setSlot4StartTime] = useState('');
    const [ slot1EndTime, setSlot1EndTime] = useState('');
    const [ slot2EndTime, setSlot2EndTime] = useState('');
    const [ slot3EndTime, setSlot3EndTime] = useState('');
    const [ slot4EndTime, setSlot4EndTime] = useState('');
    const [ userID, setUserID] = useState('');
    const [ clientID, setClientID] = useState('');
    const [ seletedClient, setSelectetedClient] = useState([]);
    const [ seletedCounselor, setSelectetedCounselor] = useState([]);
    const [ details, setDetails] = useState();
    const [ typeID, setTypeID] = useState('');
    const [ notes, setNotes] = useState('');
    const [ show, setShow] = useState(false);
    const [ open, setOpen] = useState(false);
    const [ allUsers, setAllUsers] = useState([]);
    const [ allClients, setAllClients] = useState([]);
    const [ bookedSlots, setBookedSlots] = useState([]);
    const [ selectedSlots, setSelectedSlots] = useState([]);
    const [ allTypes, setAllTypes] = useState([]);
    const [ slotId1, setSlotId1] = useState(null);
    const [ slotId2, setSlotId2] = useState(null);
    const [ slotId3, setSlotId3] = useState(null);
    const [ slotId4, setSlotId4] = useState(null);
    const [ startDate, setStartDate] = useState(new Date());
    const [ clientDisabled, setClientDisabled] = useState(true);
    const [ typeDisabled, setTypeDisabled] = useState(true);
    const [ dateDisabled, setDateDisabled] = useState(true);
    const [ datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [allPermissions] = useState(JSON.parse(localStorage.getItem('permissions')));

    useEffect( () => {
        if(clientid != 'add'){
            setTypeDisabled(false);
            getAppointmentDetail(clientid);
        }
        getAllClients(); 
        getAllUsers();
        getAllCategories();
      }, []);

     // Get all users from apis 
    const getAllUsers = async () => {
        let currentPage, perPage = 0;
        let url = 'getAllUsers';
        view_ApiManager(url, currentPage, perPage,(res) => {
        console.log('Get all Users',res);
        let options = res.data.data.map(function (user) {
            return { value: user.id, label: user.name};
          });
          setAllUsers([].concat(options).sort((a, b) => a.label > b.label ? 1 : -1));
    },(err) => {
            console.log(err);
        });
    }
     // Get all clients from apis 
    const getAllClients = async () => {
        let data='';
        let url = 'clients';
        get_ApiManager(data, url,(res) => {
        console.log('Get all clients',res);
        setAllClients(res.data.data);
        let options = res.data.data.map(function (client) {
            return { value: client.id, label: client.name};
          });
          setAllClients([].concat(options).sort((a, b) => a.label > b.label? 1 : -1));
    },(err) => {
            console.log(err);
        });
    }

    const getAppointmentDetail = (id) => {
        let data= id;
        let url = "appointmentDetail";
        get_ApiManager(data, url,(res) => {
        console.log('get-appointment',res);
        setDetails(res.data.data);
        let clients ={ value: res.data.data['clientid'], label: res.data.data['client_name']};
        let counselor ={ value: res.data.data['user'], label: res.data.data['counselor_name']};
        setSelectetedClient(clients);
        setSelectetedCounselor(counselor);
        setStartDate(res.data.data['date']);
    },(err) => {
            console.log(err);
        });
    }
     // Get all categories from apis 
    const getAllCategories = () => {
        let data='';
        let url = 'getAppointmentCategory';
        get_ApiManager(data, url,(res) => {
        console.log('Get all categories',res);
        setAllTypes(res.data.data);
    },(err) => {
            console.log(err);
        });
    }
      // Enable Client 
     const enableClient = (userid) => {
         setUserID(userid);
        if(userid !== ''){
            setClientDisabled(false)
        } else {
            setClientDisabled(true);
        }
    }
      // Enable Type
     const enableType = (clientid) => {
         setClientID(clientid);
        if(clientid !== ''){
            setTypeDisabled(false)
        } else {
            setTypeDisabled(true);
        }
    }
      // Enable Date
     const enableDate = (typeid) => {
        setTypeID(typeid);
        if(typeid !== ''){
            setDateDisabled(false)
        } else {
            setDateDisabled(true);
        }
    }
     //CallBack function 
     const handleCallback = () => {
        setShow(true);
    }
     //CallBack function Model and get categories from api
     const handleModelCallback = (toggle,type, name) => {
        setShow(!toggle);
        if(type === 'edit'){
        let url = 'addAppointmentCategory';
        const data = {name: name}
        post_ApiManager(data, url,(res) => {
            console.log('Category Added',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {swal("Success!", res.data.message, "success"); getAllCategories() } 
        },(err) => {
            console.log(err);
        });
        }
    }
     //Get Selected Slots 
     const handleSlots = (slotID, startTime, endTime) => {
        var continued = true;
        //    check for aleady booked appointments
        if(bookedSlots.length){
            bookedSlots.forEach(element => {
                if(element.startTime === startTime){
                    return continued = false;
                }  
            });
        }
       

        //    if not booked already then add into booked slot array
        if(continued === true){
        if(slotId1 === null){
            setSlotId1(slotID);
            setSlot1StartTime(startTime);
            setSlot1EndTime(endTime);
        } else if(slotId2 === null && (slotID === (slotId1 + 1) ) && slotId1 !== null){
            setSlotId2(slotID);
            setSlot2StartTime(startTime);
            setSlot2EndTime(endTime);
        } else if(slotId3 === null && (slotID === (slotId2 + 1) ) && slotId2 !== null && slotId1 !== null){
            setSlotId3(slotID);
            setSlot3StartTime(startTime);
            setSlot3EndTime(endTime);
        } else if(slotId4 === null && (slotID === (slotId3 + 1) ) && slotId3 !== null && slotId2 !== null && slotId1 !== null){
            setSlotId4(slotID);
            setSlot4StartTime(startTime);
            setSlot4EndTime(endTime);
        } else {
            if( slotId1 !== null &&
                slotId2 !== null  && 
                slotId3 !== null  &&
                slotId4 !== null ) { 
                swal("Sorry!", "you can not book more than 4 slots for same client"); }
            }
            
        }
    }
     // Get avaliable slots of selected date from api
     const handleDateChange = (Date) => {
        setLoading(true);
        let selectedDate;
        if(Date){
            selectedDate =  moment(Date).format('YYYY-MM-D');
        }
        const data= {id: userID, date: selectedDate};
        let url = 'checkSlots';
        post_ApiManager(data, url,(res) => {
            console.log('bookedSlots',res);
            setBookedSlots(res.data.data);
            setStartDate(Date);
            clearSlots();
            setLoading(false);
            setOpen(true);
        },(err) => {
            console.log(err);
        });
    }

     const bookAppointment = () => {
       var date = moment(startDate).format('YYYY-MM-D');
       selectedSlots.forEach(element => {
       element.startTime = moment(element.startTime, ["h:mm A"]).format("HH:mm:ss");
       element.endTime = moment(element.endTime, ["h:mm A"]).format("HH:mm:ss");
       return element.startTime, element.endTime;
    });

     var start, end ='';
     var length = selectedSlots.length;
    selectedSlots.forEach((element, index) => {
        if(index === 0){
            start = element.startTime;
        }
        if(index === (length-1)){
            end = element.endTime;
        }
     });
    const data = {
        user_id: userID,
        client_id: clientid === 'add' ? clientID: clientid,
        type_id:  typeID,
        date: date, 
        startTime:start,
        endTime: end,
        note: notes
    }
        let url = 'addAppointment'
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('appointment added' , res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                handleNotification();
                swal("Success!", res.data.message, "success"); 
                history('/appointments/view-appointments')
            }; 
        },(err) => {
            console.log(err);
            swal("Oops!", "Something went wrong!", "error")
        });   
    }
     //add slots when book slot button clicked
     const addSlots = () => {
        var newArray = [];
        var obj = {};
        if(slotId1 !== null){
        obj = {
            'id': slotId1,
            'startTime': slot1StartTime, 
            'endTime': slot1EndTime};
            newArray.push(obj);
        }
        if(slotId2 !== null){
            obj = {
                'id': slotId2,
                'startTime': slot2StartTime, 
                'endTime': slot2EndTime };
                newArray.push(obj);
            } 
        if(slotId3 !== null){
            obj = {
                'id': slotId3,
                'startTime': slot3StartTime, 
                'endTime': slot3EndTime};
                newArray.push(obj);
            } 
        if(slotId4 !== null){
            obj = {
                'id': slotId4,
                'startTime': slot4StartTime, 
                'endTime': slot4EndTime, };
                newArray.push(obj);
            } 
            setSelectedSlots(newArray);
            setOpen(false);
    }
     //Clear all slots
     const clearSlots = () => {
        setSelectedSlots([]);
        setSlotId1(null);
        setSlotId2(null);
        setSlotId3(null);
        setSlotId4(null);
        setSlot1StartTime('');
        setSlot2StartTime('');
        setSlot3StartTime('');
        setSlot4StartTime('');
        setSlot1EndTime('');
        setSlot2EndTime('');
        setSlot3EndTime('');
        setSlot4EndTime('');
    }
     //  Call Notification bell Counter
    const handleNotification = () => {
        const socket = props.socket;
        const user = props.user;
        socket.emit("sendNotification", {
          senderName: user,
        });
     }
     // Get Handle Selected Intakes Options
    const handleChange = (selectedOption) => {
        enableType(selectedOption.value);
      }
      // Get Handle Selected Intakes Options
    const handleUserChange = (selectedOption) => {
        enableClient(selectedOption.value);
    }

    return(
        <>
        <Title title="Add Appointment" 
        btnCol="col-lg-7 text-center pt-lg-1" 
        btn={true}
        btnText="Add Appointment Type" 
        btnClass="float-right btn btn-primary"
        parentCallback={handleCallback} 
        />
        {allPermissions['appointments'] === 1 ?
        <div className="pt-lg-2 mb-lg-2 ml-lg-4 mr-4">
        <div className="row mr-lg-5 ml-lg-5">
            <div className="bordered bg-white scroll mb-5 mb-0 mb-lg-1">
            <div className="pb-2 pt-3 bg-green">
            <h4 className="subtitle text-center ">{clientid === 'add' ? 'Add Appointment' : 'Edit Appointment'}</h4>
            </div>
            <div className="row p-lg-5 pb-lg-5 pl-lg-4 pl-2 p-3 pt-lg-1 pt-1 mb-6">
                 {/* counselor */}
                <div className="col-lg-4 col-12 pt-3">
                <div className="input-group form-group j-content-center">
                <div className="input-group-prepend">
                <span style={{ background :'#d1d1d1'}} className="input-group-text"><FontAwesomeIcon icon={faUserShield} /></span></div>
                <Select
                className="app-form col-lg-9 days px-0"
                placeholder='Select Counselor'
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={handleUserChange}
                options={allUsers}
                value={seletedCounselor}
                />
                </div>
                </div>
                  {/* Clients */}
                <div className="col-lg-4 col-12 pt-3">
                <div className="input-group form-group j-content-center">
                <div className="input-group-prepend">
                <span style={{ background :'#d1d1d1'}} className="input-group-text"><FontAwesomeIcon icon={faUserFriends} /></span></div>
                {clientid !== 'add' ? 
                <Select
                className="app-form col-lg-9 days px-0"
                placeholder='Select Client'
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={handleChange}
                options={allClients}
                value={seletedClient}
                />
                :
                <Select
                className="app-form col-lg-9 days px-0"
                placeholder='Select Client'
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={handleChange}
                options={allClients}
                />}
                </div>
                </div>
                  {/* type */}
                <div className="col-lg-4 col-12 pt-3">
                <div className="input-group form-group j-content-center">
                <div className="input-group-prepend">
                <span style={{ background :'#d1d1d1'}} className="input-group-text"><FontAwesomeIcon icon={faBlog} /></span></div>
                <select disabled={typeDisabled}  className="form-select app-form" data-live-search="true"
                name="typeID" onChange={(e) => enableDate(e.target.value)} >
                <option value=''> Select Appointment Type*</option> 
                {allTypes.map((value) =>
                <option key={value.id} className="light" value={value.id}>{value.name}</option> )}
                </select> 
                </div>
                </div>
                 {/* Select Date */}
                <div className="col-12 pb-2 pt-3 pl-lg-2">
                <div className="row">
                <div className="col-lg-2 ml-2">
                <button className={"btn btn-primary pl-3 pr-3 " + (selectedSlots && selectedSlots[0]  ? 'mt-3': '') + (dateDisabled === true ? 'disabled' :'')}
                onClick={() => setDatePickerIsOpen(!datePickerIsOpen)}>Select Date*</button>
                </div>
                <div className={"col-lg-2 ml-lg-0 ml-1 mt-0 p-2 p-lg-0 "+ (selectedSlots && selectedSlots[0]  ? 'mt-3': '')}>
                <DatePicker
                className="form-control app-form"
                selected={startDate}
                onChange={handleDateChange}
                onClickOutside={() => setDatePickerIsOpen(!datePickerIsOpen)}
                disabled
                open={datePickerIsOpen}
                />
                </div>
                <div className="col-lg-1 ml-2">
                {/* show loader when date selected */}
                {loading ?
                <img src={smallSpinner} width="15" className="pt-2" alt="loading" />
               : selectedSlots[0] ? 
                <div className="flex-colum mt-2 pl-2">
                <div className="clear pointer"  onClick={() => clearSlots()}>Clear</div>
                <div className="change p-1 pointer " onClick={() => setOpen(true)}>Change</div>
                 </div>: ''}
                </div> 
                <div className="col-lg-6 ml-1 row">
                {selectedSlots.map((v, k) => 
                <div key={k} className="col-3">
                    <div className="selected-slot-card card ml-1">
                    <div className="card-body slots">
                    {v.startTime}<br/>{v.endTime}
                    </div>
                    </div>
                    </div>
                )}
                </div>
                </div>
                </div>
                 {/* Notes */}
                <div className="col-lg-8 pl-lg-4 p-2 p-lg-0">
                <div className="form-group">
                <label className="acc-label">Notes*</label>
                <textarea className="textarea form-control app-form" 
                name="notes" placeholder="Please Enter" 
                onChange={(e) => setNotes(e.target.value) } rows="4" cols="60"></textarea>
                </div>
               </div> 
               {selectedSlots[0] && notes ? 
               <button className="btn btn-success mt-3" onClick={() => bookAppointment()}>Book Appointment</button>   : ''}  
              </div>
            </div>
        </div>
       {/* add appointment type model */}
        <Modals
        show={show}
        title={'New Appointment Type'}
        data={'Add Category Name'} 
        type={'category'}
        parentCallback={handleModelCallback}/>

        <div className={"modal slot-modal fade" + (open ? "show d-block" : " d-none")} tabIndex="-1" role="dialog">
        <div className="modal-dialog slot-modal-dialog " role="document">
        <div className="modal-content slot-modal-content">

         <div className="col-lg-12 pl-lg-3 pb-4 pt-4">
            <h4 className="app-subtitle text-center mb-3 black">Available Slots for <b> ({moment(startDate).format('D-MM-YYYY')}) </b> </h4>
            <div className="row pl-3">
            {time_slots.map((slots) =>
            <div  
            key={slots.id}
            onClick={()=> handleSlots(slots.id,slots.startTime, slots.endTime )} 
            className={"slot-card card w-11 m-1 " + 
            (slotId1 === slots.id  ||
            slotId2 === slots.id   ||
            slotId3 === slots.id   ||
            slotId4 === slots.id  ? 'app-active' :
            bookedSlots[0] ? bookedSlots.map((booked) => {
               return (
               (moment(booked.startTime, ["h:mm A"]).format("HH:mm:ss") == moment(slots.startTime, ["h:mm A"]).format("HH:mm:ss") 
               || moment(booked.endTime, ["h:mm A"]).format("HH:mm:ss") == moment(slots.endTime, ["h:mm A"]).format("HH:mm:ss") 
               || (moment(booked.startTime, ["h:mm A"]).format("HH:mm:ss") 
               < moment(slots.endTime, ["h:mm A"]).format("HH:mm:ss") &&
               (moment(booked.endTime, ["h:mm A"]).format("HH:mm:ss") 
                > moment(slots.endTime, ["h:mm A"]).format("HH:mm:ss")))

              
                ) 
               ? ' app-disabled ' : ' ')
            })
            
            : ' ')}>
            <div className="card-body slots">
            {slots.startTime} <br/> {slots.endTime}
            </div>
            </div> )}
            <div className="d-flex j-content-right pr-3">
            <button className={"btn btn-success " + 
            (slotId1 === null  && 
             slotId2 === null  &&
             slotId3 === null  && 
             slotId4 === null  ? 'd-none' : ' mr-2')} 
             onClick={() => addSlots()}>Book Slots</button> 
               <button className={"btn btn-primary mr-2 " + 
            (slotId1 === null  && 
             slotId2 === null  &&
             slotId3 === null  && 
             slotId4 === null  ? 'd-none' : ' ')} 
             onClick={() => clearSlots()}>Clear Slots</button> 
            <button className="btn btn-danger" onClick={() =>  setOpen(false)}>Cancel</button>  
            </div>
            </div>
         </div>

        </div>
        </div>
        </div> 
        </div>: 'You dont have permission to access this pages!'}
        </>
        )
    
}
export default AddAppointment
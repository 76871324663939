

import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { get_ApiManager } from '../ApiManager/ApiManager';
import {migrationType, visaStatus,  noiccStatus, eoiStatus, eduStatus, roiStatus, migrationStatus} from "../../utilis/appStatus";

class GenericMerge extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    handleModal(){
        this.props.parentCallback(this.props.show, 'cancel'); 
    } 
    handleSubmit(value){
        this.props.parentCallback(this.props.show,'edit', value, this.props.list);
    }

    render(){ 
        return(
            <>
             <Modal aria-labelledby="contained-modal-title-vcenter" centered  show={this.props.show} onHide={() => this.handleModal()}>  
                <Modal.Header closeButton={() => this.handleModal()}><div className="text-center col-11 font-f-sans white">{this.props.title}</div></Modal.Header>  
                <Modal.Body className="ml-2 mr-2">
                <div className="row p-2 mb-2 j-content-center">
                <div className="form-group col-lg-12 d-flex pl-0" >
                <label className="profile-label pt-1 col-lg-6">Merge selected clients to:</label>
                <select className="form-select app-form" onChange={(e) => this.handleSubmit(e.target.value)} 
                 >
                <option value=''>Please Select </option>
                {this.props.list.map(a =>
                <option value={a.id} key={a.id} className="light">{a.name} </option> )}
               
                </select>
                </div>
                </div>
                </Modal.Body>
                </Modal>
            </>
        )
    }
}
export default GenericMerge

import React ,{useState,useEffect, useRef} from "react";
import Title from '../GenericComponents/Title';
import {useNavigate} from "react-router-dom"
import './provider.css'
import swal from 'sweetalert';
import { australiaCampus, canadaCampus, countries_code, franceCampus, newZealandCampus, pakistanCampus, uaeCampus, ukCampus, usaCampus } from '../../utilis/constants'
import { post_ApiManager, get_ApiManager } from '../ApiManager/ApiManager'
import { Multiselect } from "multiselect-react-dropdown";

const AddProvider =() => {
    const titleRef = useRef()
    const history = useNavigate();
    const [input, setInput] = useState('');
    const [tags, setTags] = useState([]);
    const [isKeyReleased, setIsKeyReleased] = useState(false);
    const[nameError, setNameError] = useState('');
    const[campusError, setCampusError] = useState('');
    const[categoryError, setCategoryError] = useState('');
    const[countryError , setCountryError] = useState('');
    const[startDateError , setStartDateError] = useState('');
    const[endDateError , setEndDateError] = useState('');
    const[descriptionError , setDescriptionError] = useState('');
    const[categories, setCategories] = useState([]);
    const[selectedOption, setSelectedOption] = useState([]);
    const[selectedCampus, setSelectedCampus] = useState([]);
    const[name, setName] = useState('');
    const[category, setCategory] = useState('');
    const[country, setCountry] = useState('');
    const[campus, setCampus] = useState([]);
    const[startdate, setStartDate] = useState('');
    const[enddate, setEndDate] = useState('');
    const[description, setDescription] = useState('');
    const [allPermissions] = useState(JSON.parse(localStorage.getItem('permissions')));
    const[personList, setPersonList] = useState([{ name : "", email: "" ,mobile: "", landline:"", designation:""}]);

    useEffect(() => {
      getCategory();
    }, []);
    
    const getCategory = async () => {
      let url = 'allcategories';
      let data = '';
      get_ApiManager(data, url,(res) => {
          console.log('Migration Category',res);
          setCategories(res.data['data']);
      },(err) => {
          console.log(err);
      })
    }
    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...personList];
      list[index][name] = value;
      setPersonList(list); 
    };
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
      const list = [...personList];
      list.splice(index, 1);
      setPersonList(list)
    };
    // handle click event of the Add button
    const handleAddClick = () => {
      setPersonList([...personList, { name : "", email: "" ,mobile: "", landline:"", designation:""}]);
    };

    const handleValidation = () => { 
      setNameError('');
      setCampusError('');
      setCategoryError('');
      setCountryError('');
      setStartDateError('');
      setEndDateError('');
      setDescriptionError('');

        if(!name){
          setNameError('Please enter name');
          window.scrollTo(0, titleRef);
          return false 
        } else if(selectedCampus.length <= 0){
          setCampusError('Please enter campus');
          window.scrollTo(0, titleRef);
          return false 
        } else if(!category){
          setCategoryError('Please enter category');
          window.scrollTo(0, titleRef);
          return false 
        } else if(!country){
          setCountryError('Please enter country');
          window.scrollTo(0, titleRef);
          return false 
        } else if(category == 8){
          if(!startdate){
            setStartDateError('Please select start date');
            return false 
          } else if(!enddate){
            setEndDateError('Please select end date');
            return false 
          } 
          else{
            return true;
          }
        } else if(!description){
            setDescriptionError('Please enter short description');
            return false 
        } else {
          return true;
        }
    }
     //Submit form and call api to fetch results
    const onSubmit = (e) => {
      e.preventDefault();
      let array2 = [];
      array2 = selectedCampus.map(object => object.value);
      var newArray = array2.toString();
      if (handleValidation()) {
        const data = {
          name : name,
          city : newArray,
          country : country,
          categoryid: category,
          agreement_startdate: startdate,
          agreement_enddate: enddate,
          description: description,
          person_contact_list: personList,
          designation: selectedOption,
        }
        console.log(data);
        let url = 'providers';
        post_ApiManager(data,url,(res) => {
          console.log('Added Provider',res);
          if(res.data.error){ swal(" ", res.data.error, "error")}
          else {  
            swal("Success!", res.data.message, "success");
            history('/providers/view-providers')
          }
        },(err) => {
          console.log(err);
        });
      }
    }
    const onTagChange = (e) => {
      const { value } = e.target;
      setInput(value);
    };
    const onKeyDown = (e) => {
      const { key } = e;
      const trimmedInput = input.trim();
    
      if (key === ',' || key === ' ' && trimmedInput.length && !tags.includes(trimmedInput)) {
        e.preventDefault();
        setTags(prevState => [...prevState, trimmedInput]);
        setInput('');
      }
    
      if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
        const tagsCopy = [...tags];
        const poppedTag = tagsCopy.pop();
        e.preventDefault();
        setTags(tagsCopy);
        setInput(poppedTag);
      }
    
      setIsKeyReleased(false);
    };
    const onKeyUp = () => {
      setIsKeyReleased(true);
    }
    const deleteTag = (e,index) => {
      e.preventDefault();
      setTags(prevState => prevState.filter((tag, i) => i !== index))
    }
    const onCampusSelect = (selectedList) => {
      setSelectedCampus(selectedList);
     }
     const onCampusRemove = (selectedList) => {
      setSelectedCampus(selectedList);
     }

    const setValue = (value) => {
      if(value == 'Australia'){
        let options = australiaCampus.map(function (intake) {
          return { value: intake.title, label: intake.title, group: intake.group};
        })
        setCampus(options);
      } else if(value == 'Pakistan'){
        let options = pakistanCampus.map(function (intake) {
          return { value: intake.title, label: intake.title};
        })
        setCampus(options);
      } else if(value == 'UK'){
        let options = ukCampus.map(function (intake) {
          return { value: intake.title, label: intake.title};
        })
        setCampus(options);
      } else if(value == 'Canada'){
        let options =canadaCampus.map(function (intake) {
          return { value: intake.title, label: intake.title};
        })
        setCampus(options);
      } else if(value == 'New Zealand'){
        let options = newZealandCampus.map(function (intake) {
          return { value: intake.title, label: intake.title};
        })
        setCampus(options);
      } else if(value == 'USA'){
        let options = usaCampus.map(function (intake) {
          return { value: intake.title, label: intake.title};
        })
        setCampus(options);
      } else if(value == 'France'){
        let options = franceCampus.map(function (intake) {
          return { value: intake.title, label: intake.title};
        })
        setCampus(options);
      } else if(value == 'United Arab Emirates'){
        let options = uaeCampus.map(function (intake) {
          return { value: intake.title, label: intake.title};
        })
        setCampus(options);
      } setCountry(value);
     }
    
  return(
      <>
      <Title title="Add Provider"></Title>
      <div className="j-content-center mx-2">
      {allPermissions['providers'] === 1 ?
      <div className="col-lg-12 bordered mb-5">
      <div className="pb-2 pt-3 bg-green">
      <h4 className="subtitle text-center ">Add New Provider</h4>
      </div>
      <form ref={titleRef} action=''>
      <div className="row bg-white pl-2 pr-3 py-2 pb-lg-3 pr-lg-1 border">

        <div className="col-lg-3 pl-lg-3">
        <div  className="form-group">
        <label className="acc-label2 size-16">Provider Name*</label>
        <input type="text" name="name" onChange={(e) => setName(e.target.value)} className="form-control acc-form" placeholder="Please Enter" />
        {nameError && <><small className="error">{nameError}</small></>}
        </div>
        </div>

        <div className="col-lg-3 pl-lg-3">
        <div className="form-group">
        <label className="acc-label2 size-16">Category</label>
        <select className="form-select acc-form form-select-lg" onChange={(e) => setCategory(e.target.value)} aria-label="form-select-lg example">
        <option selected>Please Select</option>
        {[].concat(categories).sort((a, b) => a.name > b.name ? 1 : -1).map((value, index) => 
        <option key={index} value={value.id}>{value.name}</option>
        )}
        </select>
        {categoryError && <><small className="error">{categoryError}</small></>}
        </div>
        </div>

        <div className="col-lg-3 pl-lg-3">
        <div className="form-group">
        <label className="acc-label2 size-16">Country*</label>
        <select className="form-select acc-form form-select-lg w-lg-90" onChange={(e) => setValue(e.target.value)} aria-label="form-select-lg example" name="country">
        <option selected>Please Select</option>
        {countries_code.map(value => 
        <option key={value.country} value={value.value}>{value.value}</option>
        )}
        </select>
        {countryError && <><small className="error">{countryError}</small></>}
        </div>
        </div>

        <div className="col-lg-3 pl-lg-3">
        <div className="form-group w-lg-90">
        <label className="acc-label2 size-16">Campus*</label>
        <Multiselect
        options={[].concat(campus).sort((a, b) => a.label > b.label ? 1 : -1)}
        groupBy="group"
        className="form-select app-form multi"
        placeholder="Campus"
        displayValue="label"
        onSelect={onCampusSelect}
        onRemove={onCampusRemove}
        closeIcon="circle"
        showCheckbox={true}
        showArrow={false}
        hidePlaceholder={false}
        />
        </div>
        {campusError && <><small className="error">{campusError}</small></>}
        </div>
        
        <div className="col-lg-12 p-1 text-center">
        <h4 className="size-18 acc-label bold">Contact Person(s)</h4>
        </div>

        <div className="border">
        {personList.map((x, i) => {
          return(
          <div key={x} className="row pl-1 pr-3 py-2 pb-lg-3 pr-lg-3">
          <div className="col-lg-3 pl-lg-3">
          <div className="form-group">
          <label className="acc-label2 size-16">Person Name</label>
          <input type="text" name="name" onChange={e => handleInputChange(e, i)} className="form-control acc-form" placeholder="Please Enter" />
          <br/>
  
          </div>
          </div>

          <div className="col-lg-3 pl-lg-3">
          <div className="form-group">
          <label className="acc-label2 size-16">Person Email</label>
          <input type="email" name="email" onChange={e => handleInputChange(e, i)} className="form-control acc-form" placeholder="Please Enter" />
          </div>
          </div>

          <div className="col-lg-2 pl-lg-3">
          <div className="form-group">
          <label className="acc-label2 size-16">Designation</label>
          <select className="form-select acc-form form-select-lg" name="designation" onChange={e => handleInputChange(e, i)}>
          <option selected>Please Select</option>
          <option value="Accounts Manager">Accounts Manager</option>
          <option value="Admissions Manager">Admissions Manager</option>
          <option value="Marketing Manager">Marketing Manager</option>
          <option value="Owner/Director">Owner/Director</option>
          <option value="Other">Other</option>
          </select>
          </div>
          </div>

          <div className="col-lg-2 pl-lg-3">
          <div className="form-group">
          <label className="acc-label2 size-16">Mobile</label>
          <input type="email" name="mobile" onChange={e => handleInputChange(e, i)} className="form-control acc-form" placeholder="Please Enter" />
          </div>
          </div>

          <div className="col-lg-2 pl-lg-3">
          <div className="form-group">
          <label className="acc-label2 size-16">Landline</label>
          <input type="email" name="landline" onChange={e => handleInputChange(e, i)} className="form-control acc-form" placeholder="Please Enter" />
          </div>
          </div>

          <div className="d-flex pl-lg-3 py-1">
          {personList.length !== 1 && <button
          className="btn btn-danger mr-2" onClick={() => handleRemoveClick(i)}>Remove</button>}
          {personList.length - 1 === i &&
          <button className="btn btn-primary" onClick={() => handleAddClick()}>Add More</button>}
          </div>
          </div> )
        })}
        </div>

        <div className="col-lg-12 p-1 text-center mt-2">
        <h4 className="size-18 acc-label bold">Contract Information</h4>
        </div>

        {category == 8 &&
        <>
        <div className="col-lg-3 pl-lg-4">
        <div className="form-group">
        <label className="acc-label2 size-16">Agreement Start Date*</label>
        <input type="date" onChange={(e) => setStartDate(e.target.value)} className="date-input w-100" placeholder="Please Enter" />
        {startDateError && <><small className="error">{startDateError}</small></>}
        </div>
        </div>

        <div className="col-lg-3 pl-lg-4">
        <div className="form-group">
        <label className="acc-label2 size-16">Agreement End Date*</label>
        <input type="date" onChange={(e) => setEndDate(e.target.value)} className="date-input w-100" placeholder="Please Enter" />
        {endDateError && <><small className="error">{endDateError}</small></>}
        </div>
        </div>
        </> }

        <div className="col-lg-6 pl-lg-4 pr-lg-4">
        <div className="form-group">
        <label className="acc-label2 size-16">Note</label>
        <textarea className="textarea form-control app-form" onChange={(e) => setDescription(e.target.value)} placeholder="Please Enter" 
         name="name" rows="3" cols="60"></textarea>
         {descriptionError && <><small className="error">{descriptionError}</small></>}
        </div>
        </div>
        <div className="d-flex">
        <button onClick={onSubmit} className="btn btn-success mt-4 ml-lg-2 pl-4 pr-4">Add Provider</button>
        </div>
      </div>
      </form>
      </div>: 'You dont have permission to access this pages!'}
      </div>
      </>
  )
}
export default AddProvider;
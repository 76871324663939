
import React, { Component } from 'react'
import Title from '../GenericComponents/Title'
import './hr.css'
import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { get_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import moment from 'moment';
import Paginations from '../GenericComponents/Pagination';
import MultipleSearchs from '../GenericComponents/MultipleSearchs';
import ExportOptions from '../GenericComponents/ExportOptions';

class AllDailyReport extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            key:'home',
            allPermissions: permissions,
            currentPage: 1,
            totalItem:'',
            lastPage:'',
            perPage: 10,
            submittedUsers:[],
            notSubmiteedUsers:[],
            allHistory:[],
            selectedHistory:[],
            allUsers:[],
            search:'',
            counselor:[],
            startDate:'',
            endDate:'',
            headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'User' , dataKey: "username",align: "text-left", key:2, checked: true, canChange: false },
                {title: 'Role' , dataKey: "rolename",align: "text-center", key:3, checked: true, canChange: false},
                {title: 'Manager Remarks', dataKey: "", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: '', dataKey: "", align: "text-center" ,key:5, checked: true, canChange: true},
                {title: 'Created_At', dataKey: "", align: "text-center" ,key:6, checked: true, canChange: true},
            ],
        }
        this.handleSearchCallback = this.handleSearchCallback.bind(this);
    }
    componentDidMount() {
        this.getSubmittedUsers();
        this.getNotSubmittedUsers();
    }

    getSubmittedUsers = () => {
        let url = `daily-submissions`;
        get_ApiManager('', url,(res) => {
            console.log('submitted-users',res);
            this.setState({submittedUsers: res.data.data});
        },(err) => {
                console.log(err);
        }); 
    }

    getNotSubmittedUsers = () => {
        let url = `daily-not-submissions`;
        get_ApiManager('', url,(res) => {
            console.log('not-submitted-users',res);
            this.setState({notSubmiteedUsers: res.data.data});
        },(err) => {
                console.log(err);
        }); 
    }

    getAllHistory = () => {
        let url = 'getAllHistory';
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('all-history',res);
            this.setState({
                allHistory: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            })
            let options = res.data.data['data'].map(function (item) {
                return { 
                    index:item.index,
                    user:item.username,
                    role:item.rolename,
                    user_remarks: item.user_remarks,
                    manager_remarks:item.manager_remarks,
                    manager_remark:item.remarksSubmission === 1 ? 'Given' : 'Not Given',
                    created_at: moment(item.created_at).format('YYYY-MM-D'),
                    // task1: item.selfTask[0] && item.selfTask[0]['task'],
                    // topTask2: item.selfTask[1] && item.selfTask[1]['task'],
                    // topTask3: item.selfTask[2] && item.selfTask[2]['task'],
                    // topTask3: item.selfTask[3] && item.selfTask[3]['task']
                }
            });
            this.setState({selectedHistory: options})
            this.getUsers();
        },(err) => {
            console.log(err);
        })
    }

    getUsers = () => {
        let currentPage, perPage = 0;
        let url = 'getAllUsers';
        view_ApiManager(url, currentPage, perPage,(res) => {
        console.log('All Users',res);
        this.setState({allUsers: res.data.data});
        },(err) => {
            console.log(err);
        });
    }

    tabChange = (k) => {
        if(k == 'home'){
            this.setState({key:k}, () => {
                this.getSubmittedUsers();
                this.getNotSubmittedUsers();
            })
        } else if (k === 'history'){
            this.setState({key:k}, () => {
                this.getAllHistory();
            })
        } else{
            this.setState({key:k})
        }
    }

    handlePaginationCallback = (i, perPage) =>{
        if(perPage && perPage != ''){
            this.setState({ perPage : perPage, currentPage:1}, () => { this.getAllHistory() }); 
        } else {this.setState({ currentPage : i}, () => { this.getAllHistory() }); }
    }

    handleSearchCallback = (input1, input2, input3) => {
        let Counselor, StartDate, EndDate;
        if (input1 === 'clear') {
            this.setState({assignedTo:[], startDate:'', endDate:''})
          } else if (input1 === 'yes') {
            Counselor = this.state.counselor;
            StartDate = this.state.startDate;
            EndDate = this.state.endDate;
          } else {
            Counselor = input1;
            StartDate = input2;
            EndDate = input3;
            this.setState({search:'yes', counselor:Counselor, startDate: StartDate, endDate: EndDate});
          }
          const data = {
            counselor: Counselor,
            startdate: StartDate ? StartDate : 'null',
            enddate:  EndDate ? EndDate : 'null',
        }
        console.log(data);
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = `search/dailyReport`;
        search_ApiManager(url, data, currentPage, perPage,(res) => {
            console.log('search result',res);
            this.setState({
                allHistory: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            let options = res.data.data['data'].map(function (item) {
                return { 
                    index:item.index,
                    user:item.username,
                    role:item.rolename,
                    user_remarks: item.user_remarks,
                    manager_remarks:item.manager_remarks,
                    created_at: moment(item.created_at).format('YYYY-MM-D'),
                    // task1: item.selfTask[0] && item.selfTask[0]['task'],
                    // topTask2: item.selfTask[1] && item.selfTask[1]['task'],
                    // topTask3: item.selfTask[2] && item.selfTask[2]['task'],
                    // topTask3: item.selfTask[3] && item.selfTask[3]['task']
                }
            });
            this.setState({selectedHistory: options})
    
        },(err) => {
            console.log(err);
        });
    }

    render(){
        return(
            this.state.allPermissions['daily_report_remarks'] === 1 ?
            <>
            <Title title="Daily Reports"  /> 
            <div className="row w-100 pl-3 pr-3 mb-5 mt-lg-2">
            <Tabs activeKey={this.state.key} defaultActiveKey="home" className="app-tabs ml-0 border rounded mt-0"
            onSelect={(k) => this.tabChange(k)}>

             <Tab eventKey="home" title="Daily Submissions">
             <div eventKey="home" className="pt-0 mb-3">
             <div className="row p-3 bg-white border pt-1 mb-5 pb-1">
             <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 mb-3 pt-3 mt-3">Today's Report</h5>
             
             <div className='col-lg-8 mb-3'>
             <div className="card card-radius">
             <div className="bg-green title-border-radius"><h5 className="card-title mx-2 my-2 white">Report Submissions</h5></div>
                <div className="card-body py-0 row">
                <table class="table">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">User</th>
                    <th  scope="col">Submitted At</th>
                    <th className='text-center' scope="col">Manager Remarks</th>
                    <th className='' scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                {this.state.submittedUsers.map((value, index) => (
                    <tr key={index}>
                    <th scope="row">{++index}</th>
                    <td className='name-text'>{value.username}</td>
                    <td >{moment(value.created_at).format('D MMM YYYY')}</td>
                    <td className='text-center'>{value.remarksSubmission === 1 ? 'Given' : 'Not Given'}</td>
                    <td className='text-right'>
                    <Link to={`/view-report/${value.id}`}>View Report</Link></td>
                    </tr>
                ))}
                </tbody>
                </table>
                </div>
             </div>   
             </div>

             <div className='col-lg-4 mt-lg-0 mt-3'>
             <div className="card card-radius ml-lg-5">
             <div className="bg-green title-border-radius"><h5 className="card-title mx-2 my-2 white">Not Submitted Users</h5></div>
             <div className="card-body py-0 row j-content-center">
             <table class="table">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th className='text-left' scope="col">Not Submitted</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.notSubmiteedUsers.map((value, index) => (
                    <tr>
                    <th scope="row">{++index}</th>
                    <td className='text-left'>{value.name}</td>
                    </tr>
                ))}
                </tbody>
                </table>
             </div>
             </div>
             </div>

             </div>
             </div>

             </Tab>

             <Tab eventKey="history" title="Employee History">
             <div eventKey={this.state.key} className="pt-0 mb-3">
             <div className="row bg-white border mb-5">
             <MultipleSearchs
                input1={'Search Counselor'}
                input1_type={"dropdown"}
                input1_multiple={"yes"}
                input1Data={this.state.allUsers} 
                input2={'Start Date'}
                input2_type={"date"}
                input3={'End Date'}
                input3_type={"date"}
                onSearchChange= {this.handleSearchCallback}
                />
             <div className="row w-100 mb-2 px-2"> 
             <div className="table-responsive table-bordered mb-2">
             <table id={this.state.key} className="table bg-white mb-0">
             <thead className="thead-dark">
             <tr>
             {this.state.headers.map(head => (
             head.checked &&
             <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th>  ))}
             </tr>
             </thead>
             <tbody>
             {this.state.allHistory.map((value, index) => (
                <tr key={index++}>
                <td className='text-center'>{++index}</td>
                <td className='text-left name-text'>{value.username}</td>
                <td className='text-center'>{value.rolename}</td>
                <td className='text-center'>{value.remarksSubmission === 1 ? 'Given' : 'Not Given'}</td>
                <td className='text-center'>
               {value.rolename ? <Link to={`/view-report/${value.id}`}>View Report</Link>:''}</td>
               <td className='text-center'>{moment(value.created_at).format('D MMM YYYY')}</td>
                </tr>
             ))}
             </tbody>
             </table>
             </div>
             <Paginations
                currentPage= {this.state.currentPage}
                total= {this.state.totalItem} 
                itemsPerPage= {this.state.perPage}
                lastPage={this.state.lastPage}
                onPageChange= {this.handlePaginationCallback} 
                totalItems={this.state.totalItem}
                perItem={this.state.perItem}
                />
             <div className="d-flex mb-5">
             <ExportOptions
             pdffilename={'dailyReports.pdf'} 
             csvfilename={'dailyReports.csv'} 
             xlsxfilename={'Daily Reports'} 
             header="Reports List"
             id="viewclientsdiv3" 
             columns={this.state.headers}
             data={this.state.selectedHistory} />
             </div>
             </div>
             </div>
             </div>
             </Tab>


            </Tabs>
            </div>
            </>:''
        )
    }
}
export default AllDailyReport
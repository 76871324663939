
import React, { Component } from 'react';
import Title from '../GenericComponents/Title'
import Paginations from '../GenericComponents/Pagination'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { get_ApiManager, post_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import { Sorting } from '../GenericComponents/SortTables'
import DOMPurify from 'dompurify'
import * as FaIcons from 'react-icons/fa'
import Loader from '../Loader/Loader'
import swal from 'sweetalert'
import Modals from '../GenericComponents/Modal';
import smallSpinner from '../../assets/small.gif';
import { Link } from 'react-router-dom';
import { expo_branches } from '../../utilis/constants';
import moment from 'moment';
import ExportOptions from '../GenericComponents/ExportOptions';
import MultipleSearchs from '../GenericComponents/MultipleSearchs';
import { form_type, preferred_country } from '../../utilis/appStatus';
import { main_url } from "../../utilis/Common";

class ExpoAugSep extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            name: '', email: '', country:'', contact:'', selectedbranch:'', city:'', preferCountry:'', studyArea:[],
            loading: false,
            id:'',
            currentPage: 1,
            totalItem:'',
            lastPage:'',
            perPage: 10,
            key:'leads',
            all:[],
            allUsers:[],
            order:'ASC',
            allPermissions: permissions,
            status:'leads',
            showNote: false,
            search:'',
            assignedTo:[],
            showButton:true,
            branch:'',
            start:'',
            dealingClientId:'',
            dealingClient:'',
            totalTime:'',
            showEndModel:false,
            selectedEvents:[],
            assigned:'',
            timer: moment(0, "seconds").format("HH:mm:ss"),
            updatedStartTime: null,
            startTime: null,
            timeInterval: null,
            showStartTimeInput: false,
            selectedExpoAttendee:[],
            selectedExpoConcluded:[],
            events:[],
            selectedEvent:'',
            bulk: false,
            bulkIds:[],
            formType:'',
            website_headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Email' , dataKey: "email",align: "text-center", key:3, checked: true, canChange: false},
                {title: 'Contact', dataKey: "phone", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Country', dataKey: "country", align: "text-center" ,key:5, checked: true, canChange: true},
                {title: 'Destination', dataKey: "destination", align: "text-center" ,key:6, checked: true, canChange: true},
                {title: 'Education', dataKey: "education_level", align: "text-center" ,key:7, checked: false, canChange: true},
                {title: 'University', dataKey: "university", align: "text-center" ,key:8, checked: false, canChange: true},
                {title: 'Inquiry' , dataKey: "inquiry", align: "text-center",key:9, checked: true, canChange: false},
                {title: 'Form Type' , dataKey: "type", align: "text-center",key:10, checked: true, canChange: false},
                {title: '' , dataKey: "", align: "text-center",key:11, checked: true, canChange: false},
                {title: 'Created_At' , dataKey: "created_at", align: "text-center",key:12, checked: true, canChange: true},
            ],
            leads_headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Email' , dataKey: "email",align: "text-center", key:3, checked: true, canChange: false},
                {title: 'Contact', dataKey: "mobile", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Country', dataKey: "country", align: "text-center" ,key:5, checked: true, canChange: true},
                {title: 'City', dataKey: "city", align: "text-center" ,key:6, checked: false, canChange: true},
                {title: 'AddedBy', dataKey: "addedby", align: "text-center" ,key:7, checked: true, canChange: true},
                {title: 'ReferredBy', dataKey: "referred_name", align: "text-center" ,key:8, checked: false, canChange: false},
                {title: '' , dataKey: "", align: "text-center",key:9, checked: true, canChange: false},
            ],
            attendees_headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Email' , dataKey: "email",align: "text-center", key:3, checked: true, canChange: false},
                {title: 'Contact', dataKey: "mobile", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Country', dataKey: "country", align: "text-center" ,key:5, checked: false, canChange: true},
                {title: 'City', dataKey: "city", align: "text-center" ,key:6, checked: false, canChange: true},
                {title: 'Preferred Country' , dataKey: "", align: "text-center",key:7, checked: true, canChange: true},
                {title: 'Study Area' , dataKey: "discipline", align: "text-center",key:8, checked: true, canChange: true},
                {title: 'ReferredBy', dataKey: "referred_name", align: "text-center" ,key:9, checked: false, canChange: true},
                {title: '' , dataKey: "", align: "text-center",key:10, checked: true, canChange: false},
                {title: '' , dataKey: "delete", align: "text-center",key:11, checked: false, canChange: false},
            ],
            waiting_headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Email' , dataKey: "email",align: "text-center", key:3, checked: true, canChange: false },
                {title: 'Contact', dataKey: "contact", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Country', dataKey: "country", align: "text-center" ,key:5,checked: false, canChange: true },
                {title: 'City', dataKey: "city", align: "text-center" ,key:6, checked: false, canChange: true},
                {title: 'Preferred Country' , dataKey: "", align: "text-center",key:7,checked: true, canChange: true},
                {title: 'Study Area' , dataKey: "discipline", align: "text-center",key:8,checked: true, canChange: true},
                {title: 'ReferredBy', dataKey: "referred_name", align: "text-center" ,key:9, checked: false, canChange: true},
                {title: '' , dataKey: "", align: "text-center",key:10, checked: true, canChange: false},
                {title: '' , dataKey: "delete", align: "text-center",key:11, checked: true, canChange: false},
            ],
            consulting_headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1,checked: true, canChange: false},
                {title: 'Name' , dataKey: "name",align: "text-center", key:2,checked: true, canChange: false},
                {title: 'Email' , dataKey: "email",align: "text-center", key:3,checked: true, canChange: false},
                {title: 'Contact', dataKey: "contact", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Country', dataKey: "country", align: "text-center" ,key:5 ,checked: false, canChange: true },
                {title: 'City', dataKey: "city", align: "text-center" ,key:6 ,checked: false, canChange: true },
                {title: 'Preferred Country' , dataKey: "", align: "text-center",key:7,checked: true, canChange: true},
                {title: 'Study Area' , dataKey: "discipline", align: "text-center",key:8,checked: true, canChange: true},
                {title: 'AssignedTo' , dataKey: "assigned", align: "text-center",key:9,checked: true, canChange: true},
                {title: 'Conclusion_Note' , dataKey: "concluded_note", align: "text-center",key:10,checked: true, canChange: true},
                {title: 'Action' , dataKey: "assigned", align: "text-center",key:11,checked: true, canChange: false},
                {title: 'ReferredBy', dataKey: "referred_name", align: "text-center" ,key:12, checked: false, canChange: false},
                {title: '' , dataKey: "delete", align: "text-center",key:13,checked: true, canChange: false},
            ],
            consulted_headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1,checked: true, canChange: false},
                {title: 'Name' , dataKey: "name",align: "text-center", key:2,checked: true, canChange: false},
                {title: 'Email' , dataKey: "email",align: "text-center", key:3,checked: true, canChange: false},
                {title: 'Contact', dataKey: "contact", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Country', dataKey: "country", align: "text-center" ,key:5 ,checked: false, canChange: true },
                {title: 'City', dataKey: "city", align: "text-center" ,key:6,checked: true, canChange: true },
                {title: 'Preferred Country' , dataKey: "", align: "text-center",key:7, checked: false, canChange: true},
                {title: 'Study Area' , dataKey: "discipline", align: "text-center",key:8, checked: false, canChange: true},
                {title: 'AssignedTo' , dataKey: "assigned", align: "text-center",key:9,checked: true, canChange: true},
                {title: 'Branch' , dataKey: "branch", align: "text-center",key:10, checked: true, canChange: true},
                {title: 'Time' , dataKey: "total_time1", align: "text-center",key:11, checked: true, canChange: true},
                {title: 'Conclusion_Note' , dataKey: "concluded_note", align: "text-center",key:12,checked: true, canChange: false},
                {title: 'ReferredBy', dataKey: "referred_name", align: "text-center" ,key:13, checked: false, canChange: false},
                {title: 'Event', dataKey: "eventname", align: "text-center" ,key:14, checked: true, canChange: true},
                
            ],
        }
        this.handleSearchCallback = this.handleSearchCallback.bind(this);
    }

    componentDidMount() {
        this.getAssignedUsers();
        this.checkMettingStatus();
        this.getEvents();
        // this.interval = setInterval(() =>  this.getRegisterUsers() , 35000);
     }
    //  componentWillUnmount() {
    //     clearInterval(this.interval);
    //   }
      // Get all register forms
     async getRegisterUsers () {
        let url = `getRegisterAttendees/${this.state.key}/${this.state.branch}`;
        console.log(url);
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('attendees',res);
            this.setState({
                loading: false,
                all: res.data.data['expo']['data'],
                totalItem: res.data.data['expo']['total'],
                perPage: res.data.data['expo']['per_page'],
                lastPage: res.data.data['expo']['last_page'],
                perItem: res.data.data['expo']['data'].length,
            })
            let exports = res.data.data['expo']['data'].map(function (item) {
                return { 
                  index: item.index,
                  name: item.name,
                  email: item.email,
                  mobile: item.mobile,
                  country: item.country,
                  city: item.city,
                  status: item.status,
                  preferred_country: item.preferred_country,
                  created_at: moment(item.created_at).format('YYYY-MM-D'),
                };
              })
              this.setState({selectedExpoAttendee: exports})
              let exportsConcluded = res.data.data['expo']['data'].map(function (item) {
                return { 
                  index: item.index,
                  name: item.name,
                  email: item.email,
                  mobile: item.mobile,
                  country: item.country,
                  city: item.city,
                  assignedTo: item.assignedTo,
                  preferred_country: item.preferred_country,
                  branch: item.branch,
                  status: item.status,
                  total_time: item.total_time1,
                  concluded_note: item.concluded_note,
                  created_at: moment(item.created_at).format('YYYY-MM-D'),
                };
              })
              this.setState({selectedExpoConcluded: exportsConcluded})
              let options = [];
              res.data.data['expo']['data'].forEach((item) => {
                if(item.started == 1){
                    return options = item;
                }
              })
              if(options.id){
                this.setState({
                    dealingClientId: options.id,
                    dealingClient: options.name,
                    start: options.start_time1})
              }
        },(err) => {
            console.log(err);
        });
     }
      // Get all register forms
     async checkMettingStatus () {
        let url = `checkMeetingStatus`;
        get_ApiManager('', url,(res) => {
            console.log('check meeting',res);
            if(res.data.data.client_name){
                this.setState({
                    dealingClient: res.data.data.client_name,
                    dealingClientId: res.data.data.client_id,
                    showEndModel: true,
                    assigned: res.data.data.counselor_id})
            }
        },(err) => {
            console.log(err);
        });
     }
      // Get all users with total assigned client
     async getAssignedUsers() {
        let currentPage, perPage = 0;
        let url = `expoAssignedToUsers`;
        view_ApiManager(url, currentPage, perPage,(res) => {
        console.log('total-assign',res);
        this.setState({allUsers: res.data.data['counselors'], branch : res.data.data['branch']} ,
        () => {
            this.getRegisterUsers();
        });
        },(err) => {
            console.log(err);
        });
     }
      // Table sorting
     sorting = (col) => {
        const sortResult = Sorting(this.state.all, col, this.state.order)
        this.setState({order: sortResult[0], all: sortResult[1]})
     }
      // CallBack function to get changed status name and value and call api to save results
    handleCallback = (i, perPage) =>{
        if(this.state.search == ''){
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage}, () => {  this.getRegisterUsers();  }); 
            } else {this.setState({ currentPage : i}, () => { this.getRegisterUsers(); }); }
        } else{
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage}, () => {  this.handleSearchCallback(this.state.search);  }); 
            } else {this.setState({ currentPage : i}, () => { this.handleSearchCallback(this.state.search); }); }
        }
    }
      //Delete a user
      async deleteUser(delete_id, name) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover ("+ name +")",icon: "warning", buttons: true, dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                const data = delete_id;
                let url = 'deleteExpoUser';
                get_ApiManager(data, url,(res) => {
                console.log('User Deleted',res);
                swal("User ("+ name +")"+" unregistered Successfully!", {icon: "success" });
                this.getRegisterUsers(); 
                this.setState({loading: false, currentPage:1});
            },(err) => {
                console.log(err);
            }); 
        }   
       });
    }
    async deleteWebsiteUser(delete_id, name) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover ("+ name +")",icon: "warning", buttons: true, dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                const data = delete_id;
                let url = 'deleteWebsiteUser';
                get_ApiManager(data, url,(res) => {
                console.log('user-deleted',res);
                swal("User ("+ name +")"+" unregistered Successfully!", {icon: "success" });
                this.getRegisterUsers(); 
                this.setState({loading: false, currentPage:1});
            },(err) => {
                console.log(err);
            }); 
        }   
       });
    }
      // Change status
    changeStatus = (id, status, note, reassign) => {
        let url = `changeExpoStatus`;
        this.setState({showButton: false})
        const data = {
            id: id,
            status:status,
            note: note,
            reassign: reassign,
            branch: this.state.branch,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
        console.log('status-changed',res);
        this.setState({showButton: true})
        this.getRegisterUsers();
        },(err) => {
            console.log(err);
        });
    }
      // Handle Change
    async handleChange(assigned, id) {
        let url = 'assignExpoClient';
        const data = {
            id: id,
            assigned:assigned,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('client-assigned',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success"); 
                this.getRegisterUsers(); 
            } 
        },(err) => {
            console.log(err);
        })
    }
    handleEndModelCallback = (check,type,notee) => {
        if(!notee){
            swal({ text: "Please enter note!", icon: "warning", dangerMode: true })
        } else{
            this.setState({showEndModel: false})
            this.endConsulting(this.state.assigned, notee)
        }
    }

    handleModelCallback = (check,type,notee, reassign) => {
        if(type == 'edit'){
            if(notee == ''){
                swal({ text: "Please enter note!", icon: "warning", dangerMode: true })
            } else {
                let status;
                if(!reassign){
                    status = 'concluded';
                } else if(reassign && this.state.key == 'myconsulting'){
                    status = 'consulting';
                } else{
                    status = this.state.key
                }
                this.setState({showNote: false})
                this.changeStatus(this.state.id, status, notee, reassign);}
        } else {
            this.setState({showNote: false})
        }
    }

    async startConsulting (id, name, assigned) {
        let current_time = moment().format("DD/MM/YYYY HH:mm:s");
        this.consultantTime(id, name, assigned, 'start', current_time, '');
        this.handleStartTimeUpdate();
        this.setState({start: current_time, dealingClientId: id, dealingClient: name, showEndModel: true, assigned: assigned });
    }

    async endConsulting (assigned, note) {
        let current_time = moment().format("DD/MM/YYYY HH:mm:s");
        var total = moment.utc(moment(current_time,"DD/MM/YYYY HH:mm:ss").diff(moment(this.state.start,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
        this.consultantTime(this.state.dealingClientId, this.state.dealingClient, assigned, 'end', '', total, note)
        this.setState({start:'', dealingClientId: '', dealingClient: '', totalTime: total});
    }

    consultantTime = (id, name, assigned, from, start, total, note) => {
        let url = 'saveExpoTime';
        const data = {
            id: id,
            name: name,
            totalTime: total,
            from: from,
            assigned: assigned,
            starttime: start,
            note: note
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('time-saved',res);
            this.getAssignedUsers();
            this.getRegisterUsers();
        },(err) => {
            console.log(err);
        })
    }

    data = () => {
      const role = JSON.parse(localStorage.getItem('user_role'));
      let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
      return(
        <div eventKey={this.state.key} className="pt-0 mb-3">
        <div className="row px-2 bg-white border py-1 mb-3">
        <div className="row w-100 pr-1 mb-4 mt-2 pl-1">
          {/* search bar */}
        <MultipleSearchs
         input1={this.state.consulting_headers[1]['checked']  && 'Search Client'}
         input1_type={"input"}
         input2={this.state.consulting_headers[2]['checked']  && 'Search Email'}
         input2_type={"input"}
         input3={this.state.consulting_headers[3]['checked']  && 'Search Contact'}
         input3_type={"input"}
         input4={this.state.consulting_headers[5]['checked']  && 'Search City'}
         input4_type={"input"}
         input5={this.state.consulting_headers[4]['checked']  && 'Search Country'}
         input5_type={"input"}
         input6={this.state.consulting_headers[6]['checked'] && 'PreferredCountry'}
         input6_type={"dropdown"}
         input6Data={preferred_country}
         input6_multiple={"yes"}
         input7={this.state.key == 'consulting' && this.state.consulting_headers[8]['checked'] && 'Search AssignedTo'}
         input7_type={"dropdown"}
         input7_multiple={"yes"}
         input7Data={this.state.allUsers}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={this.state.consulting_headers}
         />
        <div className="table-responsive table-bordered mb-2">
        <table id={this.state.key} className="table bg-white mb-0">
        {/* Headers */}
        <thead className="thead-dark">
        <tr>
        {this.state.consulting_headers.map(head => (
        head.dataKey == 'delete' ? this.state.allPermissions['can_delete'] === 1 &&
        <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th> 
        :
        head.checked &&
        <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}> {head.title}</th> ))}
        </tr>
        </thead>
        {/* End Headers */}
        <tbody>
        {this.state.all.map((value, index) => {
            return(
            <tr key={index++}>
            {this.state.consulting_headers[0]['checked'] && <td className='text-center'>{count++}</td>}
            {this.state.consulting_headers[1]['checked'] && <td className='text-center'>
            <Link to={`/events/expo-detail/${value.id}`}>{value.name}</Link></td>}
            {this.state.consulting_headers[2]['checked'] && <td className='text-center'>{value.email}</td>}
            {this.state.consulting_headers[3]['checked'] && <td className='text-center'>{value.mobile}</td>}
            {this.state.consulting_headers[4]['checked'] && <td className='text-center'>{value.country}</td>}
            {this.state.consulting_headers[5]['checked'] && <td className='text-center'>{value.city}</td>}
            {this.state.consulting_headers[6]['checked'] && <td className='text-center'>{value.preferred_country}</td>}
            {this.state.consulting_headers[7]['checked'] && <td className='text-center w-10'>
            {value.discipline && value.discipline.map((dis,i) => (
            <div><small className='bold'>{++i}: </small>{dis.title}</div>))}
            </td>}
            {this.state.consulting_headers[8]['checked'] && <td className='text-center'>{this.state.allUsers.filter(v=> v.id == value.assigned).map(w => ( w.name))}</td>}
            {this.state.consulting_headers[9]['checked'] && 
            <td className='text-center w-25'>
            <div style={{whiteSpace: 'pre-wrap'}} className="acc-note pl-1 size-15" 
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.concluded_note)}}></div></td>}
            {this.state.consulting_headers[10]['checked'] && <td className='text-center w-5'>
            
            {(!this.state.dealingClientId || this.state.key == 'consulting') && 
            <div onClick={() => this.setState({id: value.id, showNote:true})}>
            <FaIcons.FaRegCheckSquare size={20} color={'rgb(0 169 21)'} />
            </div>}

            {this.state.key == 'myconsulting' &&
            <>
            {this.state.dealingClientId == value.id  ?
            <>
            {!this.state.dealingClientId && 
            <div className='mt-3'><button onClick={() => this.startConsulting(value.id, value.name, value.assigned)} 
            className="btn btn-success">Start</button></div>}
            </> : 
            <>
            {!this.state.dealingClientId && 
            <div className='mt-3'><button onClick={() => this.startConsulting(value.id, value.name, value.assigned)} 
            className="btn btn-success">Start</button></div>}
            </>}
            <div style={{whiteSpace: 'pre-wrap'}} className="acc-note pl-1 size-15" 
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.total_time1)}}></div>
            </>}
             </td>}
             {this.state.consulting_headers[11]['checked'] && <td className='text-center'>{value.referred_name}</td>}
             {this.state.allPermissions['can_delete'] === 1 && <td className='text-center'>
            <a onClick ={() => this.deleteUser(value.id, value.name)} className="btn btn-danger px-2 p-0 m-1 mb-2"> 
            <FaIcons.FaTrashAlt size={12} color={'#fffcfc'} /> </a></td>}
            </tr>
            )
        })}
        </tbody>
        </table>
        </div>
        <Paginations
        currentPage= {this.state.currentPage}
        total= {this.state.totalItem} 
        itemsPerPage= {this.state.perPage}
        lastPage={this.state.lastPage}
        onPageChange= {this.handleCallback} 
        totalItems={this.state.totalItem}
        perItem={this.state.perItem}
        />
        </div>
        </div>
        </div>
        )
    }
    // Show dynamic Headers
    header = (headers) => {
        const role = JSON.parse(localStorage.getItem('user_role'));
        {/* Headers */}
      return(
         <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         (head.dataKey == 'delete' && this.state.allPermissions['can_delete'] === 1) ? 
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th> 
         :
         head.checked &&
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th>))}
         </tr>
         </thead>
      )
        {/* End Headers */}
    }

    handleColumnsCallback = (selectedColumns) => {
        if(this.state.key == 'leads'){
            this.setState({leads_headers: selectedColumns})
        } else if(this.state.key == 'attendee'){
            this.setState({attendees_headers: selectedColumns})
        } else if(this.state.key == 'waiting'){
            this.setState({waiting_headers: selectedColumns})
        } else if(this.state.key == 'consulting' || this.state.key == 'myconsulting'){
            this.setState({consulting_headers: selectedColumns})
        } else if(this.state.key == 'concluded'){
            this.setState({concluded_headers: selectedColumns})
        }
     }
      //Search Api
    handleSearchCallback = (input1, input2, input3, input4, input5, input6, input7, input8, input9) => {
        let Name, Email, Country , City, PreferredCountry = [], Assigned = [], Contact, Branch, Events = [];
        if (input1 === 'clear') {
            this.setState({search:'' ,name: '', email: '', contact:'', country:'', city:'', formType:'', selectedbranch:'', preferCountry:[], assignedTo:[], loading: true,selectedEvents:[]})
          } else if (input1 === 'yes') {
            Name = this.state.name;
            Email = this.state.email;
            Contact = this.state.contact;
            City = this.state.city;
            Country = this.state.country;
            PreferredCountry = this.state.preferCountry;
            Branch = this.state.selectedbranch;
            Assigned = this.state.assignedTo;
            Events = this.state.selectedEvents;
          } else {
            Name = input1;
            Email = input2;
            Contact = input3;
            City = input4;
            Country = input5;
            PreferredCountry = input6;
            Branch = input8;
            Assigned = input7;
            Events = input9;
            this.setState({search:'yes', name:Name, email: Email, contact: Contact, city:City, preferCountry: PreferredCountry, selectedbranch: Branch, assignedTo: []});
          }
          const data = {
            name:Name,
            email:Email,
            country:Country,
            city: City,
            mobile: Contact,
            preferCountry: PreferredCountry ? PreferredCountry : [],
            status: this.state.key,
            assigned: Assigned ? Assigned : [],
            branch: Branch,
            events: Events,
        }
        console.log(data);
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let url = `search/registerAttendees`;
        search_ApiManager(url, data, currentPage, perPage,(res) => {
            console.log('search result',res);
            this.setState({
                loading: false,
                all: res.data.data['expo']['data'],
                totalItem: res.data.data['expo']['total'],
                perPage: res.data.data['expo']['per_page'],
                lastPage: res.data.data['expo']['last_page'],
                perItem: res.data.data['expo']['data'].length,
            });
            let exportsConcluded = res.data.data['expo']['data'].map(function (item) {
                return { 
                  index: item.index,
                  name: item.name,
                  email: item.email,
                  mobile: item.mobile,
                  country: item.country,
                  city: item.city,
                  assignedTo: item.assignedTo,
                  preferred_country: item.preferred_country,
                  branch: item.branch,
                  status: item.status,
                  total_time: item.total_time1,
                  concluded_note: item.concluded_note,
                  created_at: moment(item.created_at).format('YYYY-MM-D'),
                };
              })
              this.setState({selectedExpoConcluded: exportsConcluded})
               let exports = res.data.data['expo']['data'].map(function (item) {
            return { 
              index: item.index,
              name: item.name,
              email: item.email,
              mobile: item.mobile,
              country: item.country,
              city: item.city,
              status: item.status,
              preferred_country: item.preferred_country,
              created_at: moment(item.created_at).format('YYYY-MM-D'),
            };
          })
          this.setState({selectedExpoAttendee: exports})
        },(err) => {
            console.log(err);
        });
        
    }
    async addTable(value, id) {
        let url = 'expoTable';
        const data = {
            id: id,
            tableNo:value,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('table-added',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                // swal("Success!", res.data.message, "success"); 
                this.getAssignedUsers();
            } 
        },(err) => {
            console.log(err);
        })
    }
    changeBranch = (branch) => {
        this.setState({branch: branch});
        let url = `changeBranch/${branch}`;
        console.log(branch)
        get_ApiManager('', url,(res) => {
            console.log('branch-change',res);
            this.getAssignedUsers();
        },(err) => {
            console.log(err);
        })
    }
    changeTabs = (tab) => {
        this.setState({key: tab, currentPage:1, loading: true, bulk: false, bulkIds: []}, () => this.getRegisterUsers());
     }
     handleRunningTimer = () => {
        if (this.state.startTime) {
          const startTime = this.state.startTime;
          const currentTime = moment().format("L LTS");
          const duration = moment(currentTime, "L LTS").diff(
            moment(startTime, "L LTS"),
            "seconds"
          );
          this.setState({
            timer: moment.duration(duration, "seconds").format("*HH:mm:ss")
          });
        }
     };

      handleStartTimeUpdate = () => {
          const updatedStartTime = moment().format("L LTS");
          clearInterval(this.state.timeInterval);
          this.setState({
            startTime: updatedStartTime,
            updatedStartTime: null,
            showStartTimeInput: false,
            timerInterval: setInterval(this.handleRunningTimer, 1000)
          });
        
        this.setState({
          updatedStartTime: null,
          showStartTimeInput: false
        });
     };
     async getEvents() {
        let url = 'getEvents';
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('expo-list',res);
            let selected = res.data.data['data'].find((element) => {
                return element.status == 'active';
              })
            this.setState({
                selectedEvent: selected['name'],
                events: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
        },(err) => {
          console.log(err);
        });
    }

    async changeEvent(value){
        let url = `changeEvent`;
        const data = {
            value: value,
        }
        console.log(data);
        post_ApiManager(data, url,(res) => {
            console.log('events',res);
            this.getRegisterUsers();
            this.getEvents();
            this.setState({selectedEvent: value})
        },(err) => {
            console.log(err);
        })
    }
     // All Bulk
    allBulk = () => {
        const newState = this.state.all.map(obj => {
            return {...obj, checked: true};
        });
        const array = [];
        this.state.all.map(x => {
            array.push({ id: x.id});
        })
        this.setState({all: newState, bulk: true, bulkIds: array});
    }
     // Select Bulk
    selectBulk = () => {
        const newState = this.state.all.map(obj => {
            return {...obj, checked: false};
        });
        this.setState({bulk: !this.state.bulk, bulkIds: [], all: newState});
    }
    onBulkClick = (id, name, checked, tab) => {
        if(checked == true){
            const newState = this.state.all.map(obj => {
                if (obj.id == id) {
                return {...obj, checked: true};
                }
                return obj;
            });
            this.setState({bulkIds: [...this.state.bulkIds, { id:id, name:name}], all: newState, bulk: true});
        }
     }
     onBulkDelete = () => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover these providers",icon: "warning", buttons: true, dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let url = 'bulkDeleteExpo';
                const data = {
                    bulkList: this.state.bulkIds,
                }
                console.log(data);
                post_ApiManager(data, url,(res) => {
                    console.log('deleted',res);
                    if(res.data.error) swal(" ", res.data.error, "error")
                    else {
                        swal("Expo client has been deleted successfully!", {icon: "success" });
                        this.getAssignedUsers();
                        this.setState({bulk: false, bulkIds:[]})
                        // this.handleNotification();
                    };   
                },(err) => {
                    console.log(err);
                });
            }
        }); 
     }

     moveWebsiteLeads = (id) => {
        let url = `moveWebsiteLeads`;
        get_ApiManager(id, url,(res) => {
            console.log('move-website',res);
            this.getRegisterUsers();
        },(err) => {
            console.log(err);
        })
     }

    render(){
     const role = JSON.parse(localStorage.getItem('user_role'));
     let count0 = this.state.currentPage * this.state.perPage - this.state.perPage + 1;
     let count1 = this.state.currentPage * this.state.perPage - this.state.perPage + 1;
     let count2 = this.state.currentPage * this.state.perPage - this.state.perPage + 1;
     let count3 = this.state.currentPage * this.state.perPage - this.state.perPage + 1;
     let count4 = this.state.currentPage * this.state.perPage - this.state.perPage + 1;
      return(
        <>
        {/* <Title title="Study Abroad Expo" back={true}/> */}
        <Title title={this.state.selectedEvent ? this.state.selectedEvent : 'Event'} 
        back={true}
        titleCol={'col-lg-7'} 
        btnCol="col-lg-5 mb-0 text-center pt-lg-1" 
        btnClass="float-right btn btn-success"
        btnUrl="/events/expo-detail/${value.id}?leads=${true}" 
        btn={true}
        btnText="Add Leads" 
        />
        <div className="row w-100 pl-3 pr-2">
        {this.state.allPermissions['expo-2022'] === 1 ?
        <div className="row w-100">
        {this.state.allPermissions['floor_manager'] === 1 &&
        <div className='row j-content-right'>

        <div className='col-2 mr-2'>
        <select onChange={(e) => this.changeEvent(e.target.value)} name='selectedEvent'
        className="form-select acc-form w-100 mb-1">
        <option value=''>Select Event</option> 
        {this.state.events.map(value => 
        <option selected={value.status == 'active' ? true : false} 
        key={value.name} value={value.name}>{value.name}</option>
        )}
        </select>
        </div>

        <div className='col-2 mr-1'>
        <select onChange={(e) => this.changeBranch(e.target.value)} name='branch'
        className="form-select acc-form w-100 mb-1">
        <option value=''>Select Branch</option> 
        {[].concat(expo_branches).sort((a, b) => a.value > b.value ? 1 : -1).map(value => 
        <option selected={value.title === this.state.branch ? true : false} 
        key={value.value} value={value.title}>{value.title}</option>
        )}
        </select>
        </div>

        </div>}
        <Row className="w-100">
        <Col>
        <Tabs activeKey={this.state.key} defaultActiveKey="register" 
         className="app-tabs ml-0 border rounded mt-0" onSelect={(k) => this.changeTabs(k)}>
           {/* Website Leads */}
        <Tab eventKey="website_leads" title="Website Leads">
         {this.state.loading ? <Loader /> :
         <div eventKey="website_leads" className="pt-0 mb-3">
         <div className="row px-1 bg-white py-1 mb-3">
         <div className="row w-100 pr-1 mb-4 mt-2 pl-1">
          {/* search bar */}
        <MultipleSearchs
         input1={this.state.website_headers[1]['checked']  && 'Search Client'}
         input1_type={"input"}
         input2={this.state.website_headers[2]['checked']  && 'Search Email'}
         input2_type={"input"}
         input3={this.state.website_headers[3]['checked']  && 'Search Contact'}
         input3_type={"input"}
         input5={this.state.website_headers[4]['checked']  && 'Search Country'}
         input5_type={"input"}
         input4={this.state.website_headers[9]['checked']  && 'Select Form Type'}
         input4_type={"dropdown"}
         input4_multiple={"no"}
         input4Data={form_type}
         input4_filter_by={"title"}
         input6={'Destination Country'}
         input6_type={"input"}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={this.state.website_headers}
         />
        <div className='w-100 mr-2 text-right'>
        <small className='underline pointer heading-blue' onClick={() => this.allBulk()}> Select All  </small>
        <FaIcons.FaRegCheckSquare color='#4343ff' onClick={() => this.selectBulk()} className='underline' size={14} />
        </div>
         <div className="table-responsive table-bordered mb-2">
         <table id="leads" className="table bg-white mb-0">
         {this.header(this.state.website_headers)}
         <tbody>
         {this.state.all.map(value => {
            return(
              <tr key={value.id}>
              {this.state.website_headers[0]['checked'] && <td className='text-center'>
              {this.state.bulk === true ? <input type='checkbox' checked={value.checked} 
              onClick={(e) => this.onBulkClick(value.id, value.name, e.target.checked, 'website')} /> : count4++}</td>}
              {this.state.website_headers[1]['checked'] && <td className='text-center'>
              {/* <Link to={`/events/expo-detail/${value.id}?addedleads=${true}`}> */}
              {value.name}</td>}
              {this.state.website_headers[2]['checked'] && <td className='text-center'>{value.email}</td>}
              {this.state.website_headers[3]['checked'] && <td className='text-center'>{value.phone}</td>}
              {this.state.website_headers[4]['checked'] && <td className='text-center capital'>{value.country}</td>}
              {this.state.website_headers[5]['checked'] && <td className='text-center capital'>{value.destination}</td>}
              {this.state.website_headers[6]['checked'] && <td className='text-center capital'>{value.education_level}</td>}
              {this.state.website_headers[7]['checked'] && <td className='text-center capital'>{value.university}</td>}
              {this.state.website_headers[8]['checked'] && <td className='text-center capital'>{value.inquiry}</td>}
              {this.state.website_headers[9]['checked'] && <td className='text-center capital'>{value.type}
              {value.type === 'immigration' && 
              <> <br/> {value.resume_path &&
              <a target="_blank" className='bold' href={main_url()+'/'+value.resume_path}>View File</a>}</>}</td>}

              <td className='text-center'>
              {this.state.allPermissions['can_delete'] === 1 &&
              <a onClick ={() => this.deleteWebsiteUser(value.id, value.name)} className="btn btn-danger px-2 p-0 m-1 mb-2"> 
              <FaIcons.FaTrashAlt size={12} color={'#fffcfc'} /> </a>}
              <br/>
              <div className='bold'>
              {value.moved === 0 ?
              <button className='btn btn-primary' onClick={() => this.moveWebsiteLeads(value.id)}>Move to Clients</button>
              : 'Moved to Clients'}</div>
              </td>
              <td className='text-center'>{moment(value.created_at).format('D MMM YYYY')}</td>
              </tr>
            )
         })}
         </tbody>
         </table>
        {this.state.bulk == true && this.state.bulkIds[0] &&
        <div className='d-flex mb-2 bg-white p-2 border'>
        {this.state.allPermissions['can_delete'] === 1 &&
        <button className='btn btn-danger' onClick={() => this.onBulkDelete()}>Delete Selected</button>}
        </div>}
         </div>
         <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handleCallback} 
        />
         {/* Exports */}
         {this.state.allPermissions['can_delete'] === 1 &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Expo.pdf'} 
         csvfilename={'Expo.csv'} 
         xlsxfilename={'Expo'} 
         header="Expo List"
         columns={this.state.leads_headers}
         data={this.state.selectedExpoAttendee} />
         </div>}
         </div>
         </div>
         </div>}
         </Tab>
         {/* Leads */}
         <Tab eventKey="leads" title="Leads">
         {this.state.loading ? <Loader /> :
         <div eventKey="leads" className="pt-0 mb-3">
         <div className="row px-1 bg-white py-1 mb-3">
         <div className="row w-100 pr-1 mb-4 mt-2 pl-1">
          {/* search bar */}
        <MultipleSearchs
         input1={this.state.leads_headers[1]['checked']  && 'Search Client'}
         input1_type={"input"}
         input2={this.state.leads_headers[2]['checked']  && 'Search Email'}
         input2_type={"input"}
         input3={this.state.leads_headers[3]['checked']  && 'Search Contact'}
         input3_type={"input"}
         input4={this.state.leads_headers[5]['checked']  && 'Search City'}
         input4_type={"input"}
         input5={this.state.leads_headers[4]['checked']  && 'Search Country'}
         input5_type={"input"}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={this.state.leads_headers}
         />
        <div className='w-100 mr-2 text-right'>
        <small className='underline pointer heading-blue' onClick={() => this.allBulk()}> Select All  </small>
        <FaIcons.FaRegCheckSquare color='#4343ff' onClick={() => this.selectBulk()} className='underline' size={14} />
        </div>
         <div className="table-responsive table-bordered mb-2">
         <table id="leads" className="table bg-white mb-0">
         {this.header(this.state.leads_headers)}
         <tbody>
         {this.state.all.map(value => {
            return(
              <tr key={value.id}>
              {this.state.leads_headers[0]['checked'] && <td className='text-center'>
              {this.state.bulk === true ? <input type='checkbox' checked={value.checked} 
              onClick={(e) => this.onBulkClick(value.id, value.name, e.target.checked, 'leads')} /> : count0++}</td>}
              {this.state.leads_headers[1]['checked'] && <td className='text-center'>
              <Link to={`/events/expo-detail/${value.id}?addedleads=${true}`}>{value.name}</Link></td>}
              {this.state.leads_headers[2]['checked'] && <td className='text-center'>{value.email}</td>}
              {this.state.leads_headers[3]['checked'] && <td className='text-center'>{value.mobile}</td>}
              {this.state.leads_headers[4]['checked'] && <td className='text-center'>{value.country}</td>}
              {this.state.leads_headers[5]['checked'] && <td className='text-center'>{value.city}</td>}
              {this.state.leads_headers[6]['checked'] && <td className='text-center'>{value.addedby}</td>}
              {this.state.leads_headers[7]['checked'] && <td className='text-center'>{value.referred_name}</td>}
              {this.state.allPermissions['can_delete'] === 1 && <td className='text-center'>
              <a onClick ={() => this.deleteUser(value.id, value.name)} className="btn btn-danger px-2 p-0 m-1 mb-2"> 
              <FaIcons.FaTrashAlt size={12} color={'#fffcfc'} /> </a>
              <br/>
              <div className='bold'>
              {value.status == 'leads' ?
              <Link to={`/events/expo-detail/${value.id}?addedleads=${true}`}>Move to Registered</Link>
              : 'Moved to Registered'}</div>
              </td>}
              </tr>
            )
         })}
         </tbody>
         </table>
        {this.state.bulk == true && this.state.bulkIds[0] &&
        <div className='d-flex mb-2 bg-white p-2 border'>
        {this.state.allPermissions['can_delete'] === 1 &&
        <button className='btn btn-danger' onClick={() => this.onBulkDelete()}>Delete Selected</button>}
        </div>}
         </div>
         <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handleCallback} 
        />
         {/* Exports */}
         {this.state.allPermissions['can_delete'] === 1 &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Expo.pdf'} 
         csvfilename={'Expo.csv'} 
         xlsxfilename={'Expo'} 
         header="Expo List"
         columns={this.state.leads_headers}
         data={this.state.selectedExpoAttendee} />
         </div>}
         </div>
         </div>
         </div>}
         </Tab>
         {/* Register Attendees */}
         <Tab eventKey="attendee" title="Registered Attendees">
         {this.state.loading ? <Loader /> :
         <div eventKey="attendee" className="pt-0 mb-3">
         <div className="row px-1 bg-white py-1 mb-3">
         <div className="row w-100 pr-1 mb-4 mt-2 pl-1">
          {/* search bar */}
        <MultipleSearchs
         input1={this.state.attendees_headers[1]['checked']  && 'Search Client'}
         input1_type={"input"}
         input2={this.state.attendees_headers[2]['checked']  && 'Search Email'}
         input2_type={"input"}
         input3={this.state.attendees_headers[3]['checked']  && 'Search Contact'}
         input3_type={"input"}
         input4={this.state.attendees_headers[5]['checked']  && 'Search City'}
         input4_type={"input"}
         input5={this.state.attendees_headers[4]['checked']  && 'Search Country'}
         input5_type={"input"}
         input6={this.state.attendees_headers[6]['checked'] && 'Preferred Country'}
         input6_type={"dropdown"}
         input6_multiple={"no"}
         input6Data={preferred_country}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={this.state.attendees_headers}
         />
        <div className='w-100 mr-2 text-right'>
        <small className='underline pointer heading-blue' onClick={() => this.allBulk()}> Select All  </small>
        <FaIcons.FaRegCheckSquare color='#4343ff' onClick={() => this.selectBulk()} className='underline' size={14} />
        </div>
         <div className="table-responsive table-bordered mb-2">
         <table id="attendees" className="table bg-white mb-0">
         {this.header(this.state.attendees_headers)}
         <tbody>
         {this.state.all.map(value => {
            return(
              <tr key={value.id}>
              {this.state.attendees_headers[0]['checked'] && <td className='text-center'>{this.state.bulk === true ? 
              <input type='checkbox' checked={value.checked} 
              onClick={(e) => this.onBulkClick(value.id, value.name, e.target.checked, 'attendee')} /> : count1++}</td>}
              {this.state.attendees_headers[1]['checked'] && <td className='text-center'>
              <Link to={`/events/expo-detail/${value.id}`}>{value.name}</Link></td>}
              {this.state.attendees_headers[2]['checked'] && <td className='text-center'>{value.email}</td>}
              {this.state.attendees_headers[3]['checked'] && <td className='text-center'>{value.mobile}</td>}
              {this.state.attendees_headers[4]['checked'] && <td className='text-center'>{value.country}</td>}
              {this.state.attendees_headers[5]['checked'] && <td className='text-center'>{value.city}</td>}
              {this.state.attendees_headers[6]['checked'] && <td className='text-center'>{value.preferred_country}</td>}
              {this.state.attendees_headers[7]['checked'] &&<td className='text-center'>
              {value.discipline && value.discipline[0]  && 
              <>
              {value.discipline.map((dis,i) => (
              <div><small className='bold'>{++i}: </small>{dis.title}</div>))}</>}
              </td>}
              {this.state.attendees_headers[8]['checked'] && <td className='text-center'>{value.referred_name}</td>}
              <td className='text-center w-15'>
              
              {this.state.showButton ? 
              <>
              {this.state.allPermissions['floor_manager'] === 1 &&
              <button onClick ={() => this.changeStatus(value.id, 'waiting')} className='btn btn-success'>Move to Waiting</button>}
              </>
              :
              <img src={smallSpinner} width="15" className="pt-2" alt="loading" />}</td>
              {this.state.allPermissions['can_delete'] === 1 && <td className='text-center'>
              <a onClick ={() => this.deleteUser(value.id, value.name)} className="btn btn-danger px-2 p-0 m-1 mb-2"> 
              <FaIcons.FaTrashAlt size={12} color={'#fffcfc'} /> </a></td>}
              </tr>
            )
         })}
         </tbody>
         </table>
         {this.state.bulk == true && this.state.bulkIds[0] &&
        <div className='d-flex mb-2 bg-white p-2 border'>
        {this.state.allPermissions['can_delete'] === 1 &&
        <button className='btn btn-danger' onClick={() => this.onBulkDelete()}>Delete Selected</button>}
        </div>}
         </div>
         <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handleCallback} 
        />
         {/* Exports */}
         {this.state.allPermissions['can_delete'] === 1 &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Expo.pdf'} 
         csvfilename={'Expo.csv'} 
         xlsxfilename={'Expo'} 
         header="Expo List"
         columns={this.state.attendees_headers}
         data={this.state.selectedExpoAttendee} />
         </div>}
         </div>
         </div>
         </div>}
         </Tab>
          {/* Waiting */}
          {this.state.allPermissions['floor_manager'] === 1 &&
         <Tab eventKey="waiting" title="Waiting">
         {this.state.loading ? <Loader /> :
         <div eventKey="waiting" className="pt-0 mb-3">
         <div className="row px-2 bg-white border py-1 mb-3">
         <div className="row w-100 pr-1 mb-4 mt-2 pl-1">
         <MultipleSearchs
         input1={this.state.waiting_headers[1]['checked']  && 'Search Client'}
         input1_type={"input"}
         input2={this.state.waiting_headers[2]['checked']  && 'Search Email'}
         input2_type={"input"}
         input3={this.state.waiting_headers[3]['checked']  && 'Search Contact'}
         input3_type={"input"}
         input4={this.state.waiting_headers[5]['checked']  && 'Search City'}
         input4_type={"input"}
         input5={this.state.waiting_headers[4]['checked']  && 'Search Country'}
         input5_type={"input"}
         input6={this.state.waiting_headers[6]['checked'] && 'Preferred Country'}
         input6_type={"dropdown"}
         input6_multiple={"no"}
         input6Data={preferred_country}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={this.state.waiting_headers}
         />
         <div className="table-responsive table-bordered mb-2"> 
         <table id="waiting" className="table bg-white mb-0">
         {this.header(this.state.waiting_headers)}
         <tbody>
         {this.state.all.map(value => {
            return(
            <tr key={value.id}>
            {this.state.waiting_headers[0]['checked'] && <td className='text-center'>{count2++}</td>}
            {this.state.waiting_headers[1]['checked'] && <td className='text-center'>
            <Link to={`/events/expo-detail/${value.id}`}>{value.name}</Link></td>}
            {this.state.waiting_headers[2]['checked'] && <td className='text-center'>{value.email}</td>}
            {this.state.waiting_headers[3]['checked'] && <td className='text-center'>{value.mobile}</td>}
            {this.state.waiting_headers[4]['checked'] && <td className='text-center'>{value.country}</td>}
            {this.state.waiting_headers[5]['checked'] && <td className='text-center'>{value.city}</td>}
            {this.state.waiting_headers[6]['checked'] && <td className='text-center'>{value.preferred_country}</td>}
            {this.state.waiting_headers[7]['checked'] && <td className='text-center'>
            {value.discipline && value.discipline.map((dis,i) => (
            <div><small className='bold'>{++i}: </small>{dis.title}</div>))}
            </td>}
            {this.state.waiting_headers[8]['checked'] && <td className='text-center'>{value.referred_name}</td>}
            {this.state.waiting_headers[9]['checked'] && 
            <td className='text-center w-15'>
            <select className="form-select app-form" name="assignedID" 
            onChange={(e) => this.handleChange(e.target.value, value.id)} >
            <option value='0'>Assign To </option>
            {[].concat(this.state.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map((v) =>
            <option key={v.id} className="light" value={v.id}>{v.name}</option> )}
            </select> 
            </td>}
            {this.state.allPermissions['can_delete'] === 1 &&
            <td className='text-center'>
            <a onClick ={() => this.deleteUser(value.id, value.name)} className="btn btn-danger px-2 p-0 m-1 mb-2"> 
            <FaIcons.FaTrashAlt size={12} color={'#fffcfc'} /> </a></td>}
            </tr>
            )
         })}
         </tbody>
         </table>
         </div>
         <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         onPageChange= {this.handleCallback} 
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
        />
        
         </div>
         </div>
         </div>}
         </Tab>}
          {/* Consulting */}
         {this.state.allPermissions['floor_manager'] === 1 &&
         <Tab eventKey="consulting" title="Allocated">
         {this.state.loading ? <Loader /> : this.data() }
         </Tab>}
           {/* My Consulting */}
         <Tab eventKey="myconsulting" title="My Allocation">
         {this.state.loading ? <Loader /> : this.data() }
         </Tab>
          {/* Concluded */}
         <Tab eventKey="concluded" title="Concluded">
         {this.state.loading ? <Loader /> :
         <div eventKey="concluded" className="pt-0 mb-3">
         <div className="row px-2 bg-white border py-1 mb-3">
         <div className="row w-100 pr-1 mb-4 mt-2 pl-1">
         {/* search bar */}
        <MultipleSearchs
         input1={this.state.consulted_headers[1]['checked']  && 'Search Client'}
         input1_type={"input"}
         input2={this.state.consulted_headers[2]['checked']  && 'Search Email'}
         input2_type={"input"}
         input3={this.state.consulted_headers[3]['checked']  && 'Search Contact'}
         input3_type={"input"}
         input4={this.state.consulted_headers[5]['checked']  && 'Search City'}
         input4_type={"input"}
         input5={this.state.consulted_headers[4]['checked']  && 'Search Country'}
         input5_type={"input"}
         input6={this.state.consulted_headers[6]['checked'] && 'PreferredCountry'}
         input6_type={"dropdown"}
         input6Data={preferred_country}
         input6_multiple={"yes"}
         input7={this.state.key == 'consulting' || this.state.key == 'concluded' && this.state.consulted_headers[8]['checked'] && 'Search AssignedTo'}
         input7_type={"dropdown"}
         input7_multiple={"yes"}
         input7Data={this.state.allUsers}
         input8={this.state.consulted_headers[9]['checked']  && 'Select Branch'}
         input8_type={"dropdown"}
         input8_multiple={"no"}
         input8Data={expo_branches}
         input9={this.state.consulted_headers[13]['checked']  && 'Select Event'}
         input9_type={"dropdown"}
         input9_multiple={"no"}
         input9Data={this.state.events}
         input9_filter_by={"name"}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         allColumns={this.state.consulted_headers}
         /> 
         <div className="table-responsive table-bordered mb-2">
         <table id="concluded" className="table bg-white mb-0">
         {/* Headers */}
         <thead className="thead-dark">
         <tr>
         {this.state.consulted_headers.map(head => (
          head.checked &&
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}> {head.title}</th> ))}
         </tr>
         </thead>
         {/* End Headers */}
         <tbody>
         {this.state.all.map((value, index) => {
            return(
              <tr key={index}>
              {this.state.consulted_headers[0]['checked'] && <td className='text-center'>{count3++}</td>}
              {this.state.consulted_headers[1]['checked'] && <td className='text-center'>
              <Link to={`/events/expo-detail/${value.id}`}>{value.name}</Link></td>}
              {this.state.consulted_headers[2]['checked'] && <td className='text-center'>{value.email}</td>}
              {this.state.consulted_headers[3]['checked'] && <td className='text-center'>{value.mobile}</td>}
              {this.state.consulted_headers[4]['checked'] && <td className='text-center'>{value.country}</td>}
              {this.state.consulted_headers[5]['checked'] && <td className='text-center'>{value.city}</td>}
              {this.state.consulted_headers[6]['checked'] && <td className='text-center'>{value.preferred_country}</td>}
              {this.state.consulted_headers[7]['checked'] && <td className='text-center w-10'>
              {value.discipline && value.discipline.map((dis,i) => (
              <div><small className='bold'>{++i}: </small>{dis.title}</div>))}
              </td>}
              {this.state.consulting_headers[8]['checked'] && <td className='text-center'>{this.state.allUsers.filter(v=> v.id == value.assigned).map(w => ( w.name))}</td>}
              {this.state.consulted_headers[9]['checked'] && <td className='text-centesr'>{value.branch}</td>}
              {this.state.consulted_headers[10]['checked'] && <td className='text-centesr'>
              <div style={{whiteSpace: 'pre-wrap'}} className="acc-note pl-1 size-15" 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.total_time1)}}></div>
              </td>}
              {this.state.consulted_headers[11]['checked'] && 
              <td className='text-center w-20'>
              <div style={{whiteSpace: 'pre-wrap'}} className="acc-note pl-1 size-15" 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.concluded_note)}}></div></td>}
               {this.state.consulted_headers[12]['checked'] && <td className='text-center'>{value.referred_name}</td>}
              {this.state.consulted_headers[13]['checked'] && 
              <td className='text-center w-20'>{value.exponame}</td>}
              </tr>
            )
         })}
         </tbody>
         </table>
         </div>
         <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         onPageChange= {this.handleCallback} 
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
        />
          {/* Exports */}
          {this.state.allPermissions['can_delete'] === 1 &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Expo.pdf'} 
         csvfilename={'Expo.csv'} 
         xlsxfilename={'Expo'} 
         header="Expo List"
         columns={this.state.consulted_headers}
         data={this.state.selectedExpoConcluded} />
         </div>}
         </div>
         </div>
         </div>}
         </Tab>
        </Tabs>

        {this.state.loading ? '' :
        <div className='row pt-lg-3 pt-1 py-2 mb-5'>
        <div className='card table-card'>
        <div className='card-header shadow text-center pb-0'>
        <h5 className='text-center bold font-f-monst-sans bg-white-smoke size-14 p-3 mb-3 pt-3 w-100'>Assigned User List </h5>
        <div className='card-block px-1 pb-3 ml-lg-2'>
        <div className='table-responsive overflow-inherit'>
        <table className='table table-hover table-borderless'>
        <thead>
        <tr>
        <th className="col-lg-1 text-left">Table#</th>
        <th className='col-lg-5 text-left'>Counselor</th>
        <th className="col-lg-2">Assigned Clients</th>
        <th className='col-lg-2 text-left'>Consulting</th>
        </tr>
        </thead>
        {this.state.allUsers[0] &&
        <tbody>
        {[].concat(this.state.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map((value, index) => {
        return(
        <tr key={index++}>
        <td className="text-left col-lg-1 ml-0 pl-0">
        <input type="number" name="table_no" className="form-control acc-form col-lg-9 mb-1" 
        onChange={(e) => this.addTable(e.target.value, value.id)} defaultValue={value.table_no} /></td>
        <td className="text-left col-lg-5 pl-0">{value.name}</td>
        <td className="text-center col-lg-2">{value.total_assign}</td>
        <td className="text-left col-lg-2">{value.consulting}</td>
        </tr>)})}
        </tbody>}
        </table>
        </div>
        </div>
        </div>
        </div>
        </div>}
        </Col>
        </Row>
        </div>
        :'You dont have permission to access this pages!'}
        </div>
        {/* add note */}
        <Modals
         show={this.state.showNote}
         title={'Conclusion Note'}
         data={'Add Conclusion Note'} 
         type={'expo'}
         reschedule={true}
         textarea="true"
         reassign={true}
         allUsers={this.state.allUsers}
         parentCallback={this.handleModelCallback}
        />
         {/* add note */}
         <Modals
         show={this.state.showEndModel}
         title={'Conclusion Note'}
         data={'Add Conclusion Note'} 
         type={'expo'}
         textarea="true"
         reassign={false}
         endButton={true}
         startTime={this.state.showEndModel}
         timer={this.state.timer}
         parentCallback={this.handleEndModelCallback}
        />
        </>
        )
    }
}
export default ExpoAugSep;
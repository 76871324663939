
import React, { useState,useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { createPdfFromHtml } from "./logic";
import { get_ApiManager } from "../ApiManager/ApiManager";
import moment from 'moment';
import DOMPurify from 'dompurify'
import Loader from '../Loader/Loader'
import { gstbycountry } from "../../utilis/appStatus";

const Global = createGlobalStyle`
 /* paper.css */
 https://github.com/cognitom/paper-css
 /* @page { margin: 0 } */
 #print {
   margin: 0;
   font-family: "IPAexGothic", sans-serif;
 }

 .sheet {
   margin: 0;
   overflow: hidden;
   position: relative;
   box-sizing: border-box;
   page-break-after: always;
 }

 /** Paper sizes **/
 #print.A3               .sheet { width: 297mm; height: 419mm }
 #print.A3.landscape     .sheet { width: 420mm; height: 296mm }
 #print.A4               .sheet { width: 210mm; height: 296mm }
 #print.A4.landscape     .sheet { width: 297mm; height: 209mm }
 #print.A5               .sheet { width: 148mm; height: 209mm }
 #print.A5.landscape     .sheet { width: 210mm; height: 147mm }
 #print.letter           .sheet { width: 216mm; height: 279mm }
 #print.letter.landscape .sheet { width: 280mm; height: 215mm }
 #print.legal            .sheet { width: 216mm; height: 356mm }
 #print.legal.landscape  .sheet { width: 357mm; height: 215mm }
 
 /** Padding area **/
 .sheet.padding-10mm { padding: 10mm }
 .sheet.padding-15mm { padding: 15mm }
 .sheet.padding-20mm { padding: 20mm }
 .sheet.padding-25mm { padding: 25mm }
 
 /** For screen preview **/
 @media screen {
   body {
     background: #E0E0E0;
     height: 100%;
   }
   .sheet {
     background: #FFFFFF;
     margin: 5mm auto;
     padding: 5mm 0;
   }
 }
 
 /** Fix for Chrome issue #273306 **/
 @media print {
   #print.A3.landscape            { width: 420mm }
   #print.A3, #print.A4.landscape { width: 297mm }
   #print.A4, #print.A5.landscape { width: 210mm }
   #print.A5                      { width: 148mm }
   #print.letter, #print.legal    { width: 216mm }
   #print.letter.landscape        { width: 280mm }
   #print.legal.landscape         { width: 357mm }
 }
`;

class EditInvoicePDF extends React.Component {
 constructor(props) {
   super(props);
 }
 printContent;
 render() {
   return (
     <>
       <Global />
       <div id="print" className="A4">
         <Sheet />
         <div style={{ position: "fixed", top: "200vh" }}>
           <div
             ref={el => {
               this.printContent = el;
             }}
           >
             <Sheet />
           </div>
         </div>
       </div>
       <div className="d-flex j-content-center mt-2">
       <button className="btn btn-primary pl-4 pr-4 mb-5" onClick={this.handleClick}>Download</button>
       </div>
     </>
   );
 }

 handleClick = () => {
   createPdfFromHtml(this.printContent);
 };
}
export default EditInvoicePDF

const Sheet = () => {
   const[settings, setSettings] = useState([]);
   const[detail, setDetail] = useState([]);
   const[payments, setPayments] = useState([]);
   const[creditList, setCreditList] = useState([]);
   const[netCost, setNetCost] = useState('');
   const[gstAmount, setGstAmount] = useState('');
   const[creditTotal, setCreditTotal] = useState('');
   const[gst, setGst] = useState(10);
   
   useEffect( () => {
       const params = new URL(window.location.href).searchParams;
       const InvoiceNo = params.get('invoiceNo');
       getInvoiceDetail(InvoiceNo);
       getInvoiceSettings();
   }, []);
     // Invoice Settings
   const getInvoiceSettings =  () => {
     let url = 'getinvoiceSetting';
     get_ApiManager('', url,(res) => {
         console.log('General Settings',res);
         setSettings(res.data['data']);
     },(err) => {
         console.log(err);
     })
   }
     // Invoice Detail
   const getInvoiceDetail = (id) => {
     let url = `invoiceDetail/${id}`;
     get_ApiManager('', url,(res) => {
         console.log('invoice-detail',res);
         setDetail(res.data['data']['detail']);
         setPayments(res.data['data']['payments']);
         setCreditList(res.data['data']['creditList']);
         let totalArray=[];
         res.data['data']['creditList'].map(x => {
            return totalArray.push(Math.fround(x.credit_amount));
         })
         let sum = totalArray.reduce((a,v) =>  a =  a+v, 0.00 );
         sum = parseFloat(sum).toFixed(2);
         let totalcost = res.data['data']['detail']['total_amount'];
         let total = totalcost-parseFloat(sum).toFixed(2);
         if(res.data['data']['payments'][0]){
          let planSum=0;
          res.data['data']['payments'].map(v => {
              planSum = planSum+v.amount;
          })
          if(planSum > total){
              total = planSum-total
          } else{
              total = total-planSum
          }
          // total = total-parseFloat(v).toFixed(2);
          setCreditTotal(total);
          } else{
              setCreditTotal(total);
          }
         let tax = res.data['data']['detail']['tax'];
         if(tax === 'exclusive' || tax === 'inclusive'){
            let gst = gstbycountry.filter(value => value.title == res.data['data']['detail']['currency']);
            setGst(gst[0]['value']);
            gst = gst[0]['value']; 
            let exclusive = totalcost * gst/110;
            let finalamount = parseFloat(totalcost)-parseFloat(exclusive);
            finalamount = parseFloat(finalamount).toFixed(2)
            let gstamount = parseFloat(exclusive).toFixed(2);
            setNetCost(finalamount);
            setGstAmount(gstamount); 
        }
     },(err) => {
         console.log(err);
     })
   }
   
    return (
     detail['id'] ?
     <div className="sheet padding-10mm">
       <div className="row  pl-2 pr-2">
       <div className="col-6">
       <h1 className="font-f-sans pt-3" style={{fontSize:'46px'}}>Invoice</h1></div>
       <img className="col-6" src={`${process.env.PUBLIC_URL}/assets/img/c_logo.jpg`} 
       style={{textAlign:'right', width:'40%', justifyContent:'right', marginLeft:'9%'}} 
       height="auto" />
         {/* Payment Invoice Info */}
       <div className="row">
       <div className="col-7">
       {/* Provider Detail */}
       <div className="size-14 font-f-sans pr-5">
       {detail['clientname'] &&  detail['clientname']}<br/>
       {detail['clientemail']}
       </div>
       </div>
       <div className="col-2 mt-2 pl-2 text-left">

         <div className="mb-3">
         <h6 className="bold font-f-sans mb-0" style={{fontSize: '12.5px'}}>Invoice Date</h6>
         <p className="font-f-sans" style={{fontSize: '12.5px'}}>{moment(detail['costdate']).format("D MMMM YYYY")}</p>
         </div>

         <div className="mb-3">
         <h6 className="bold font-f-sans mb-0" style={{fontSize: '12.5px'}}>Invoice No#</h6>
         <p className="font-f-sans" style={{fontSize: '12.5px'}}>INV-{detail['invoiceNo']}</p>
         </div>

         <div className="mb-3">
         <h6 className="bold font-f-sans mb-0" style={{fontSize: '12.5px'}}>Reference</h6>
         <p className="font-f-sans" style={{fontSize: '12.5px'}}>{detail['reference']}</p>
         </div>

         {detail['tax'] === 'exclusive' || detail['tax'] === 'inclusive' ?
         <div className="mb-3">
         <h6 className="bold font-f-sans mb-0" style={{fontSize: '12.5px'}}>ABN</h6>
         <p className="font-f-sans" style={{fontSize: '12.5px'}}>{settings['abn']}</p>
         </div>:''}

       </div>
       <div className="col-3 mt-2">
       <p className="font-f-sans" style={{fontSize: '12.5px'}}>Aptitude Migration Pty Ltd <br/>
         {settings['c_address']}<br/>
         P: {settings['c_phone']}<br/>
         M: {settings['c_mobile']}<br/>
         E: {settings['c_email']}</p>
       </div>
       </div>

       <div className='row mt-2'>
       <div className='col-10'><label className='font-f-sans size-13 bold mb-0'>Description</label></div>
       <div className='col-2 text-center pl-lg-5'><label className='font-f-sans size-13 bold mb-0'>Amount </label></div>
       <hr className='mb-1 mt-1' style={{height:1.5, opacity:0.45}}/>
       {/* Descriptions */}
       <div className='col-11 mb-5 pb-2'>
       <div className="font-f-sans size-14 size-16" style={{whiteSpace: 'pre-wrap'}} 
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail['description'])}}></div>
       </div>
       {/* Amount */}
       <div className='col-1 acc-label2 size-15 text-center'>
       {parseFloat(detail['total_amount']).toFixed(2)}
       </div>
       <hr className='my-2'/>
       {/* If Discount is offered then show */}
       {creditList.map(value => {
       return(
       <>
       <div className='row' key={value.id}>
        <div className='col-11'>
        <label className='acc-label mb-0 size-13'>
        <div className="font-f-sans size-14 size-16" style={{whiteSpace: 'pre-wrap'}} 
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.description)}}></div>
        </label>
        </div>
        <div className='col-1 acc-label2 size-15 text-center'>-{parseFloat(value.credit_amount).toFixed(2)} </div>
      </div>
      <hr className='my-2'/> </>)})}
      </div>
        {/* Table row */}
        <div className='d-flex mb-2' style={{flexDirection:'row-reverse'}}>
        <div className='col-lg-5 pr-2'>
        {detail['tax'] === 'exclusive' || detail['tax'] === 'inclusive' ?
        <div className='row'>
        <div className='col-7 text-right'>
        <label className='acc-label2 '>Net Amount </label>
        </div>
        <div className='col-5 text-right'>
        <label className='acc-label2'>{netCost}</label>
        </div>
        <div className='col-7 text-right'>
        <label className='acc-label2 '>Total (GST {gst}% {
            detail['tax'] === 'exclusive' ? ' excl':
            detail['tax'] === 'inclusive' ? ' incl' : ''})</label>
        </div>
        <div className='col-5 text-right'>
        <label className='acc-label2'>{gstAmount}</label>
        </div>
        </div>:''}
       <div className='row' style={{borderTop: '1.4px solid rgb(58 58 58)'}}>
       <div className='col-7 text-right'>
       <label className='acc-label2 black bold mb-0'>Total {detail['currency']}</label>
       </div>
       <div className='col-5 text-right'>
       <label className='acc-label2 bold'>{parseFloat(detail['total_amount']).toFixed(2)}</label>
       </div>
       </div>
       {/* render array od payment date and amount */}
       <div className='row' style={{borderTop: '1.4px solid rgb(58 58 58)'}}>
       {payments.map(v =>  (
       <>
       <div className='col-7 text-right' key={v.id}>
       <label className='acc-label2 mb-0'><span style={{color:'#484848a3'}}>Less</span><span style={{color: 'rgb(255 75 75)'}}> Payment </span> 
       <br/> <span className='size-14'>{moment(v.paymentdate).format("D MMM YYYY")}</span></label> 
       </div>
       <div className='col-5 text-right'>
       <label className='acc-label2'>{parseFloat(v.amount).toFixed(2)}</label>
       </div>
       </>))}
       {creditList.map(value => {
        return(
        <div className='row' key={value.id}>
        <div className='col-7 text-right'>
        <label className='acc-label2 black'>Waived / Discount
        <br/> <span className='size-14'>{moment(value.credit_date).format("D MMMM YYYY")}</span></label>
        </div>
        <div className='col-5 text-right black'>
        <label className='acc-label2'>-{parseFloat(value.credit_amount).toFixed(2)}</label>
        </div>
        </div> )})}
       <div className='row' style={{borderTop: '1.4px solid rgb(58 58 58)', borderBottom: '1.4px solid rgb(58 58 58)'}}>
       <div className='col-7 text-right'>
       <label className='acc-label2 black bold'>Amount Due {detail['currency']}</label>
       </div>
       <div className='col-5 text-right'>
       <label className='acc-label2 bold black'>{parseFloat(creditTotal).toFixed(2)}</label>
       </div>
       </div>
       </div>

       </div>
       </div>
         {/* Due Date Row */}
        <div className='d-flex mb-2'>
       <div className='col-lg-4'>
       <div className='row'>
       <div className='col-12 text-left'>
       <label className='acc-label2 black bold mb-0'>Due Date: {moment(detail['duedate']).format("D MMM YYYY")}</label>
       <br/>
       <label className='font-f-sans size-13'>
       Our Banking Details are:<br/>
       Account Name: {settings['b_account_name']} <br/>
       Bsb: {settings['bsb']}<br/>
       Acc: {settings['acc']} <br/>
       Bank: {settings['b_name']} <br/>
       </label>
       </div>
       </div>
       </div>
       </div>

     </div>
    </div>: <Loader />
   );
};
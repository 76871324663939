import React, { useEffect, useRef, useState } from 'react';
import './dashboard.css';
import { get_ApiManager } from '../ApiManager/ApiManager';
import Chart from 'chart.js/auto';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Bar } from 'react-chartjs-2';
import { countries_code } from '../../utilis/constants';

const AppStatusDashboard = () => {
  const migrationChartRef = useRef(null);
  const educationChartRef = useRef(null);
  const migrationChartInstance = useRef(null); // For Migration chart instance
  const educationChartInstance = useRef(null); // For Education chart instance
  const [activeTab, setActiveTab] = useState('migration');
  const [intakes, setIntakes] = useState([]);
  const [intakeData, setIntakeData] = useState([]);
  const [intakeName ,setIntakeName] = useState('');
  const [providers, setProviders] = useState({
    labels: [],
    datasets: []
  });
  const [headers, setHeaders] = useState([ 
    {title: 'Intake' , dataKey: "name",key:2, align: "text-center", checked: true, canChange: false},
    {title: 'Prospect', dataKey: "coursename", align: "text-center",key:3, checked: true, canChange: false},
    {title: 'Received:' , dataKey: "providername", align: "text-center",key:4, checked: true, canChange: false},
    {title: 'Lodged', dataKey: 'superagentname', align:"text-center",key:5, checked: true, canChange: true},
    {title: 'RFI Rec', dataKey: 'subagentname', align: "text-center", key:6, checked: true, canChange: true},
    {title: 'RFI Sub', dataKey: 'subagentname', align: "text-center", key:6, checked: true, canChange: true},
    {title: 'Full Offer' , dataKey: "appstatus", align:"text-center", key:7, checked: true, canChange: true},
    {title: 'Conditional Offer' , dataKey: "appstatus", align:"text-center", key:7, checked: true, canChange: true},
    {title: 'Discontinued' , dataKey: "appstatus", align:"text-center", key:7, checked: true, canChange: true},
    {title: 'Coe Rec' , dataKey: "appstatus", align:"text-center", key:7, checked: true, canChange: true},
    {title: 'Offer Accepted' , dataKey: "appstatus", align:"text-center", key:7, checked: true, canChange: true},
    {title: 'Refused' , dataKey: "appstatus", align:"text-center", key:7, checked: true, canChange: true},
    {title: 'Refund applied' , dataKey: "appstatus", align:"text-center", key:7, checked: true, canChange: true},
    {title: 'Withdrawn' , dataKey: "appstatus", align:"text-center", key:7, checked: true, canChange: true},
    {title: 'Total' , dataKey: "appstatus", align:"text-center bold", key:7, checked: true, canChange: true},
   
]);
  const [intakeDataTotal, setIntakeDataTotal] = useState([]);

  const options = {
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: 0,
        max: 10,
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return value;
          },
        },
      },
      y: {
        ticks: {
          autoSkip: false, // Ensure all labels are displayed
          padding: 10, // Add more padding between labels and bars
          font: {
            size: 14, // Adjust font size if needed
          },
        },
      },
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 1,
        barThickness: 70, // Set consistent bar thickness
      },
    },
    layout: {
      padding: {
        left: 10, // Adds padding on the left side between bars and labels
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        display: true,
      },
      title: {
        display: true,
        text: 'Intake by University',
        font: {
          size: 14,
        },
        padding: {
          top: 30,
          bottom: 10,
        },
      },
    },
    responsive: true,
  };
  
  useEffect(() => {
      getAppReport();
  }, [activeTab]); 

   const  getAppReport = () => {
    let url = `appReport/${activeTab}`;
    console.log(url)
    get_ApiManager('', url, (res) => {
      console.log('report', res);
      const apiData = res.data.data['statuses'];
      const labels = apiData.map(item => item.name);
      const data = apiData.map(item => item.count);
      const total = data.reduce((acc, count) => acc + count, 0);
  
      // Determine which chart to update based on the active tab
      const myChartRef = activeTab === 'education' 
        ? educationChartRef.current.getContext('2d') 
        : migrationChartRef.current.getContext('2d');
  
      // Destroy the existing chart instance if it exists
      if (activeTab === 'education' && educationChartInstance.current) {
        educationChartInstance.current.destroy();
      } else if (activeTab === 'migration' && migrationChartInstance.current) {
        migrationChartInstance.current.destroy();
      }
      const backgroundColors = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#FF9F40', '#9966FF',
        '#C9CBCF', '#8E44AD', '#1ABC9C', '#F39C12', '#E74C3C', '#3498DB',
        '#2ECC71', '#D35400', '#34495E', '#9B59B6', '#2E86C1' // New distinct color (blue)
      ];
      
      // Truncate or expand the color array to match the number of data points
      const chartColors = backgroundColors.slice(0, data.length);
  
      // Create the new chart instance
      const newChartInstance = new Chart(myChartRef, {
        type: 'doughnut',
        data: {
          labels: labels, // Ensure labels are aligned with data
          datasets: [{
            data: data, // Ensure data is aligned with labels
            backgroundColor: chartColors, // Ensure colors match the data length
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 0, // Adjust the padding to move the chart to the left
            }
          },
          plugins: {
            legend: {
              position: 'right',
              labels: {
                padding: 10,
                font: {
                  size: 14
                }
              }
            },
            tooltip: {
              mode: 'nearest',  // Ensure the tooltip is displayed for the nearest segment
              intersect: false,  // Allow hover to trigger even when not intersecting
              callbacks: {
                label: function(tooltipItem) {
                  console.log('Tooltip Item:', tooltipItem);
                  const dataIndex = tooltipItem.dataIndex;
                  const label = tooltipItem.label || 'Unknown';
                  const value = tooltipItem.raw || 'No data';
                
                  return `${label}: ${value}`;
                }
              }
            },
          },
          hover: {
            mode: 'nearest',  // Hover should trigger on the nearest element
            intersect: false, // Allow hover without direct intersection
          },
        },
        plugins: [{
          id: 'centerText',
          beforeDraw: (chart) => {
            const ctx = chart.ctx;
            ctx.save();
            const centerX = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
            const centerY = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
            ctx.font = '26px Montserrat sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'black';
            ctx.fillText(total, centerX, centerY); // Display total in the center
            ctx.restore();
          }
        }]
      });
      
      
  
      // Assign the new chart instance to the correct reference
      if (activeTab === 'education') {
        educationChartInstance.current = newChartInstance;
      } else if (activeTab === 'migration') {
        migrationChartInstance.current = newChartInstance;
      }
    }, (err) => {
      console.log(err);
    });
  }
  const getIntakes = (country) => {
    let url = `getIntakeByCountry/${country}`;
    console.log(url)
        get_ApiManager('', url,(res) => {
            console.log('intakes-country',res);
            setIntakeData(res.data.data['intakes'])
        },(err) => {
            console.log(err);
        })
  }
  const getIntakeRecord = (intakeid) => {
    let url = `getIntakeByIntake/${intakeid}`;
    console.log(url)
    get_ApiManager('', url,(res) => {
        console.log('intake',res);
        setIntakeName(res.data.data['intake_title'])
        setIntakeData(res.data.data['response'])
        setIntakeDataTotal(res.data.data['statuses'])
       
        const dataIntake = res.data.data['dataIntake'] || { labels: [], data: [] };
        const labels = dataIntake.labels.length ? dataIntake.labels.map(item => item) : [];
        const intakeData = dataIntake.data.length ? dataIntake.data.map(item => item) : [];

    setProviders({
      labels: labels,
      datasets: [
        {
          label: 'Intake',
          data: intakeData,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#FF9F40'],
  
        }
      ]
    });
    },(err) => {
        console.log(err);
    })
  }
  
  return (
    <div className="row pb-4 pt-2">
      <div className='row mb-4'>
        <div className="col-md-6 col-sm-12 px-2 col-lg-6 col-xl-12 px-2 col-xs-12 padding align-items-stretch">
          <div className='card tile'>
            <ul className="nav nav-tabs chart-tabs" id="chartTabs" role="tablist">
              <li className="nav-item">
                <button
                  className={`nav-link charttab ${activeTab === 'migration' ? 'active' : ''}`}
                  id="migration-tab"
                  onClick={() => setActiveTab('migration')} >
                  Visa Application
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link charttab ${activeTab === 'education' ? 'active' : ''}`}
                  id="education-tab"
                  onClick={() => setActiveTab('education')}
                >
                  Admission Application
                </button>
              </li>
            </ul>
            <div className="tab-content" id="chartTabContent">
              <div className={`tab-pane fade ${activeTab === 'migration' ? 'show active' : ''}`} id="migration" role="tabpanel" aria-labelledby="migration-tab">
                <div className="card" style={{ paddingRight: "2rem" }} id="migrationChartParentContainer">
                  <canvas className="customCanvas" id="migrationChart" ref={migrationChartRef} style={{ padding: '10px' }}></canvas>
                </div>
              </div>
              <div className={`tab-pane fade ${activeTab === 'education' ? 'show active' : ''}`} id="education" role="tabpanel" aria-labelledby="education-tab">
                <div className="card" style={{ paddingRight: "2rem" }} id="educationChartParentContainer">
                  <canvas className="customCanvas" id="educationChart" ref={educationChartRef} style={{ padding: '0px' }}></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row px-2'>
      <div className='card tile'>
        <div className="tab-content">
          <div className='row m-3'>
          <div className='col-lg-2'>
          <select className="form-select app-form " onChange={(e) => getIntakes(e.target.value)}>
          <option value="">Select Country</option>
          {[].concat(countries_code).sort((a, b) => a.value > b.value ? 1 : -1).map((value, index) => 
          <option key={index} value={value.id}>{value.value}</option> )}
          </select>
          </div>
          <div className='col-lg-2 mx-lg-3'>
          <select className="form-select app-form " onChange={(e) => getIntakeRecord(e.target.value)}>
          <option value="">Select Intake</option>
          {intakes.map((value,index)=> 
            <option key={index} value={value.value}>{value.label}</option> )}
          </select>
          </div>
          </div>
          <div className="table-responsive mt-2">
          <table className="table table-bordered table-hover table-striped">
            <thead className="thead-light">
            {!intakeName ?
              <tr>
              {headers.map(head => (
              head.checked &&
              <th id={head.dataKey} className={head.align} key={head.key}>
              {head.title}</th>
              ))}
              </tr>
            :
              <tr>
                <th>Intake</th>
                <th>Prospect</th>
                <th>Recieved</th>
                <th>Lodged</th>
                <th>Conditional/Full Offer</th>
                <th>Offer Accepted</th>
                <th>COE Recieved</th>
                <th>Refused</th>
                <th>Refund</th>
                <th>Discointinued</th>
                <th>Withdrawn</th>
              </tr>}
            </thead>
            <tbody>
            {!intakeName ?
            <>
            {Object.entries(intakeData).map(([intakeTitle, statuses], index) => (
                <tr key={index}>
                    <td className='text-center'>{intakeTitle}</td>
                            {Object.entries(statuses).map(([status, count]) => (
                              <td className='text-center'>{count} </td>
                            ))}
              
                </tr>
            ))}
            </>
            :
            intakeDataTotal ? (
                  <tr >
                    <td className='text-center'>{intakeName}</td>
                    <td className='text-center'>{intakeDataTotal.prospect}</td>
                    <td className='text-center'>{intakeDataTotal.received}</td>
                    <td className='text-center'>{intakeDataTotal.lodged}</td>
                    <td className='text-center'>{intakeDataTotal.conditional_offer_received + intakeDataTotal.full_offer_received}</td>
                    <td className='text-center'>{intakeDataTotal.offer_accepted}</td>
                    <td className='text-center'>{intakeDataTotal.coe_received}</td>
                    <td className='text-center'>{intakeDataTotal.refused}</td>
                    <td className='text-center'>{intakeDataTotal.refund_applied}</td>
                    <td className='text-center'>{intakeDataTotal.discontinued}</td>
                    <td className='text-center'>{intakeDataTotal.application_withdrawn}</td>
                  </tr>
            ) : (
              <tr>
                <td colSpan="10" className='text-center'>no record found</td>
              </tr>
            )}
          </tbody>

          </table>
        </div>
        </div>
        <div className='row mt-2 px-3'>
      <div className=''>
      {providers.labels.length > 0 && <Bar data={providers} options={options} />}

    </div>
      </div>
        </div>
      </div>
    </div>
  );
}

export default AppStatusDashboard;

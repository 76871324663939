import React, { Component } from 'react';
import {  faArrowsToCircle, faCalendarDay, faCompass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {days, ftype, category, in_days, active_status} from '../../utilis/constants'
import { allSortBy, courseSortBy, educationStatus, migrationStatus, reportType, sortBy } from '../../utilis/appStatus';

class EmployeeActivityBars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sDate: null,
            eDate: null,
            status:null,
            days: null,
            type: null,
            userID:null,
            selectedUser:'',
            clientUser:null,
            status:1,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleDaysChange = this.handleDaysChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleClientStatusChange = this.handleClientStatusChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleAppStatusChange = this.handleAppStatusChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleClientManagmentChange = this.handleClientManagmentChange.bind(this);
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value } , () => {
        if(this.state.sDate && this.props.selectedeDate != null){
            this.setState({ days: null } ,() => { this.props.parentCallback(this.state.days, this.state.sDate, null, this.props.selectedType, this.props.selectedID, this.props.selectedStatus) })
        }
        else if(this.state.sDate && this.state.eDate){
            this.setState({ days: null } ,() => { 
                this.props.parentCallback(this.state.days, this.state.sDate, this.state.eDate, this.props.selectedType, this.props.selectedID, this.props.selectedStatus);
             })
        }
     })
    }
    handleDaysChange(e) {
        this.setState({ [e.target.name]: e.target.value } , () => {
            this.setState({sDate: null, eDate:null, } ,()=> 
            { this.props.parentCallback(this.state.days, this.props.selectedsDate, this.props.selectedeDate, this.props.selectedType, this.props.selectedID, this.props.selectedStatus)  })
        })
    }
    handleStatusChange(e) {
        this.setState({ [e.target.name]: e.target.value } , () => {
            this.setState({sDate: null, eDate:null} ,() => 
            { this.props.parentCallback(this.props.selectedDays, this.props.selectedsDate, this.props.selectedeDate, this.state.type, this.props.selectedID)  })
        })
    }
    handleClientStatusChange(e){
        this.props.parentCallback(this.props.days, this.props.selectedsDate, this.props.selectedeDate, e.target.value);
    }

    handleUserChange(e) {
        this.setState({ [e.target.name]: e.target.value } , () => {
            this.setState({sDate: null, eDate:null, selectedUser: e.target.value} ,() => 
            { this.props.parentCallback(this.props.selectedDays, this.props.selectedsDate, this.props.selectedeDate, this.props.selectedType, this.state.userID)  })
        })
    }

    handleClientManagmentChange(e) {
        this.setState({ [e.target.name]: e.target.value } , () => {
            this.setState({sDate: null, eDate:null, selectedUser: e.target.value} ,() => 
            { this.props.parentCallback(this.props.selectedDays, this.props.selectedsDate, this.props.selectedeDate,  this.props.selectedType, this.state.clientUser, this.props.selectedStatus)  })
        })
    }

    handleAppStatusChange(e) {
        this.setState({ [e.target.name]: e.target.value } , () => {
            this.setState({sDate: null, eDate:null} ,() => 
            { this.props.parentCallback(this.props.selectedDays, this.props.selectedsDate, this.props.selectedeDate, this.props.selectedType, this.props.selectedID, this.state.status)  })
        })
    }

    handleTypeChange(e) {
        this.setState({ [e.target.name]: e.target.value } , () => {
            this.setState({sDate: null, eDate:null} ,() => 
            { this.props.parentCallback(this.props.selectedDays, this.props.selectedsDate, this.props.selectedeDate, this.state.type, this.props.selectedID, this.props.selectedStatus)  })
        })
    }

 render(){
    const { sDate } = this.state;
    const status = this.props.selectedType == 'education' ? educationStatus : migrationStatus;
    let Days = this.props.selectedType == 'pending' ? in_days : days;
    const sort_by = this.props.selectedType == 'course' ? courseSortBy : (this.props.selectedType == 'null' || this.props.selectedType == null) || this.props.selectedType == 'license' ? sortBy : allSortBy;
    return(
        <>
        {this.props.followup == true ? 
        <div className="row pr-0 border shadow mb-1 pl-lg-4 pt-2 bg-white">
        <div className="col-lg-3 col-11 pl-lg-0">
        <label className="fira-font bold pr-1" htmlFor="sDate">Start Date</label>
        <input
        className="date-input col-lg-8 pl-2 pr-2 bold"
        type="date"
        name="sDate"
        defaultValue={this.props.selectedsDate}
        onChange={this.handleChange}
        />
        </div>
        {sDate || this.props.selectedeDate != null ? (
        <React.Fragment>
        <div className="col-lg-3 col-11 pl-3 pl-lg-0">
            <label className="fira-font bold pr-1" htmlFor="sDate">End Date</label>
            <input
            className="date-input col-lg-8 bold pl-2 pr-2"
            type="date"
            name='eDate'
            defaultValue={this.props.selectedeDate}
            min={sDate}
            onChange={this.handleChange}
            />
        </div>
        </React.Fragment>
        ) :'' } 
        <div className="col-lg-3 col-11 mt-lg-0 mt-2 pl-0 text-left">
        <div className="input-group form-group">
        <div className="input-group-prependd">
            <span className="input-group-text"><FontAwesomeIcon icon={faCalendarDay} /></span>
        </div>
        <select value={this.props.selectedType} onChange={this.handleStatusChange} name="type" className="form-control s-days">
        <option value="" disabled selected>Please select type</option>
        {[].concat(ftype).sort((a, b) => a.value > b.value ? 1 : -1).map(value => 
        <option key={value.value} className="light bg-grey " value={value.value}>
        {value.title}</option>
        )}
        </select>
        </div>
        </div>
        {!sDate && this.props.selectedsDate == null && (
        <div className="col-lg-3 col-11 text-left">
        <div className="input-group form-group pl-lg-3">
        <div className="input-group-prependd">
            <span className="input-group-text"><FontAwesomeIcon icon={faCalendarDay} /></span>
        </div>
        <select value={this.props.selectedDays} onChange={this.handleDaysChange} name="days" className="form-control s-days">
        <option value="">Please Select</option>
        {Days.map(value => 
        <option key={value.value} className="bg-grey light" value={value.value} >
        {value.title}</option>
        )}
        </select>
        </div>
        </div>
        )}
        {this.props.showUsers == true ? 
        <div className="col-lg-3 col-11 pl-0 text-left">
        <select value={this.props.selectedID} name="userID" onChange={this.handleUserChange} id="users" data-live-search="true" 
        className="days col-lg-10 col-9 form-select ml-2">
        <option value='null'>Please select counselor</option>
        {[].concat(this.props.data).sort((a, b) => a.name > b.name ? 1 : -1).map(value => 
        <option key={value.id} className="light" value={value.id}>
        {value.name}</option>
        )}
        </select>
        </div>:''}
        </div> :

        <div className="row d-flex pr-0 pb-0 pl-1">
        <div className={`${this.props.leads == true || this.props.reports == true ? 'col-lg-3 pl-0 pr-0 col-11' :
        this.props.clients == true ? 'col-lg-3 pl-lg-2 ' : 'col-lg-6 col-6 pl-0'}`}>
        <label className="fira-font bold pr-1" htmlFor="sDate">Start Date</label>
        <input
        className={`${this.props.leads == true || this.props.reports == true ? 'col-lg-8 ' : 
        this.props.clients == true ? 'col-lg-8' : ' col-lg-9 '} date-input bold `}
        type="date"
        name="sDate"
        onChange={this.handleChange}
        defaultValue={this.props.selectedsDate}
        />
        </div>
        {sDate || this.props.selectedeDate != null ? 
        <React.Fragment>
            <div className={`${this.props.leads == true || this.props.reports == true ? 'col-lg-3 pl-0 pr-0 col-11' : 
            this.props.clients == true ? 'col-lg-5' : 'col-lg-6 pr-0'}`}>
            <label className="fira-font bold pr-1" htmlFor="sDate">End Date</label>
            <input
            className={`${this.props.leads == true || this.props.reports == true ? 'col-lg-8' :
            this.props.clients == true ? 'col-lg-5' : 'col-lg-9'} date-input bold`}
            type="date"
            name='eDate'
            min={sDate}
            defaultValue={this.props.selectedeDate}
            onChange={this.handleChange}
            />
            </div>
        </React.Fragment>
        :'' } 
        {!sDate && this.props.selectedsDate == null  && (
        <div className={`${this.props.leads == true || this.props.reports == true ? 'col-lg-2 pl-0 pr-0 mt-2 col-9 mt-lg-0' :
         this.props.clients == true ? 'col-lg-3 pl-lg-2 ': 'col-lg-6 col-6 pr-0 text-right'} `}>
        <div className={`${this.props.leads == true || this.props.reports == true ? 'input-group form-group pl-0 ' : 
        this.props.clients == true ? 'input-group form-group' : 'input-group form-group col-10 float-right' } `}>
        <div className="input-group-prependd">
            <span className="input-group-text ml-0"><FontAwesomeIcon icon={faCalendarDay} /></span>
        </div>
        <select onChange={this.handleDaysChange} name="days" className="form-control s-days">
        <option value="">Select Days</option>
        {days.map(value => 
        <option selected={this.props.days == value.value ? true : false} key={value.value} className="bg-grey light" value={value.value}>
        {value.title}</option>
        )}
        </select>
        </div>
        </div>
        )}

        {this.props.selectedStatus &&
        <>
        <div className='col-lg-3 pl-lg-3'>
        <div className='input-group form-group'>
        <div className="input-group-prependd">
            <span className="input-group-text ml-0"><FontAwesomeIcon icon={faCompass} /></span>
        </div>
        <select onChange={this.handleClientStatusChange} name="status" className="form-control s-days">
        <option value="">Select Active/Inactive Status</option>
        {active_status.map(value => 
        <option selected={this.props.selectedStatus == value.title ? true : false}
         key={value.title} className="bg-grey light">{value.title}</option>
        )}
        </select>
        </div>
        </div>
        </>}

        {this.props.leads == true &&
        <>
        <div className={`col-2`}>
        <select value={this.props.selectedType} name="type" onChange={this.handleTypeChange} data-live-search="true" 
        className="form-select days ml-lg-2 col-9 col-lg-12">
        <option value='null' disabled>Select Category</option>
        {category.map(value => 
        <option key={value.value} className="bg-grey light" value={value.value}>
        {value.title}</option>
        )}
        </select>
        </div>
        </> }
        {this.props.reports == true &&
        <>
        <div className="col-lg-2 col-11 pl-0 pr-0">
        <select name="type" value={this.props.selectedType} onChange={this.handleTypeChange} data-live-search="true" 
        className="col-lg-12 days col-9 form-select ml-lg-2 mt-lg-0 mt-2">
        <option value='' disabled>Select Report Type</option>
        {[].concat(reportType).sort((a, b) => a.title > b.title ? 1 : -1).map(value => 
        <option key={value.value} className="bg-grey light" value={value.value}>
        {value.title}</option>
        )}
        </select>
        </div>
        <div className='col-lg-2 col-11 pl-0 pr-0 ml-lg-2 mt-2 mt-lg-0'>
        <select name="clientUser" value={this.props.selectedID} onChange={this.handleClientManagmentChange} data-live-search="true" 
        className="days col-lg-12 col-9 form-select ml-lg-2">
        <option value=''>Select Sort By</option>
        {sort_by.map(value => 
        <option key={value.value} className="bg-grey light" value={value.value}>
        {value.title}</option>
        )}
        </select>
        </div>
        </>
        }
        </div>}
        </>
    )
    }
}
export default EmployeeActivityBars

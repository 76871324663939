
import React, { Component } from 'react'
import './hr.css'
import Title from '../GenericComponents/Title'
import * as FaIcons from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import Modals from '../GenericComponents/Modal'
import Loader from '../Loader/Loader'
import swal from "sweetalert";
import { get_ApiManager, post_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import Paginations from '../GenericComponents/Pagination'
import MultipleSearch from '../GenericComponents/MultipleSearch'
import moment from 'moment'
import { Sorting } from '../GenericComponents/SortTables'
import ExportOptions from '../GenericComponents/ExportOptions'
import { getrecentActivity } from '../Services/apiServices'

class Attendence extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            name: '', selectedDepartment:'', selectedBranch:"",
            key:'home',
            show: false,
            showModel: false,
            loading: false,
            currentPage: 1,
            totalItem:'',
            lastPage:'',
            perPage: 10,
            recent:[],
            department:[],
            users:[],
            order: 'ASC',
            selectedUsers:[],
            selectedDepartments:[],
            allPermissions: permissions, 
            employee_headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Department' , dataKey: "department",align: "text-center", key:3, checked: true, canChange: false},
                {title: 'Location', dataKey: "location", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Branch', dataKey: "branch", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'Country', dataKey: "country", align: "text-center" ,key:5, checked: true, canChange: true},
                {title: 'Status', dataKey: "out", align: "text-center" ,key:6, checked: true, canChange: true},
                {title: 'Last Activity', dataKey: "out", align: "text-center" ,key:7, checked: true, canChange: true},
            ],
            department_headers: [
                {title: '#' , dataKey: "index", align: "text-center" , key:1, checked: true, canChange: false },
                {title: 'Department Name' , dataKey: "name",align: "text-center", key:2, checked: true, canChange: false },
                {title: 'Type' , dataKey: "type",align: "text-center", key:3, checked: true, canChange: false},
                {title: 'Employees', dataKey: "employee", align: "text-center" ,key:4, checked: true, canChange: true},
                {title: 'In', dataKey: "in", align: "text-center" ,key:5, checked: false, canChange: true},
                {title: 'Out', dataKey: "out", align: "text-center" ,key:6, checked: false, canChange: true},
            ],
        }
    }
    componentDidMount() {
        this.getDepartment();
        this.recentActivity();
    }

      //CallBack function to get changed status name and value and call api to save results
    handleCallback = (toggle, type, name) => {
        this.setState({show: !toggle});
        if(type === 'edit'){
            this.addDepartment(name);
        }
    }
     // Handle Employee CallBack
    handleEmployeeCallback = (toggle, type, department, employee) => {
        if(type === 'edit'){
            if(!department){
                swal({ text: "Please select department!", icon: "warning", dangerMode: true })
            } else if(!employee){
                swal({ text: "Please select employee!", icon: "warning", dangerMode: true })
            } else{
                this.assignEmployee(department, employee);
                this.setState({showModel: !toggle});
            }
        } else{
            this.setState({showModel: !toggle});
        }
    }
     // Add Department
     async addDepartment(name) {
        let url = 'saveDepartment';
        const data = {name : name}
        post_ApiManager(data, url, (res) => {
            console.log('Department Added',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
            }
        },(err) => {
            console.log(err);
        });  
    }
       // Add Department
     async recentActivity() {
        let result = await getrecentActivity();
        console.log('recent-activity',result);
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            this.setState({recent: result.data['data']})
        }
    }
     // Get Users
    async getUsers() {
        this.setState({loading: true});
        let currentPage = this.state.currentPage;
        let perPage;
        if(this.state.key === 'employees'){
            perPage = this.state.perPage;
        } else{
            perPage = 50;
        }
        let url = 'getOffshoreUsers';
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('Get All Users',res);
            this.setState({
                users: res.data.data['data'],
                loading: false,
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            let options = res.data.data['data'].map(function (item) {
                return { 
                    index:item.index,
                    name: item.name,
                    department: item.department,
                    location: item.location,
                    branch: item.branch,
                    country: item.country
                }
            });
            this.setState({selectedUsers: options})
        },(err) => {
            this.setState({loading: false});
            console.log(err);
        });
    }
     // Get Department
    async getDepartment() {
        let url = 'getDepartments';
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('department',res);
            this.setState({
                loading: false,
                department: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            })
            let options = res.data.data['data'].map(function (item) {
                return { 
                    index:item.index,
                    name: item.name,
                    type: item.type,
                    employee: item.employee,
                    in: item.in,
                    out: item.out
                }
            });
            this.setState({selectedDepartments: options})
        },(err) => {
            console.log(err);
        })
    }
      //Search Api
    handleSearchCallback = (keyword, name) => {
        if(name === 'clear'){
            this.setState({name: '', selectedDepartment:'', selectedBranch:""}
            ,() =>  this.getDepartment() );
        } 
        let Name, sDepartment, sBranch;
            if(name === 'input1') {
                Name = keyword; 
                this.setState({name: keyword})
            } else { Name = this.state.name; }
            if(name === 'input2') {
                sDepartment = keyword; 
                this.setState({selectedDepartment: keyword})
            } else { sDepartment = this.state.selectedDepartment }
            if(name === 'input7') {
                sBranch = keyword;
                this.setState({selectedBranch: keyword})
            } else sBranch = this.state.selectedBranch;
            
            this.setState({search: keyword});
            const data = {
                name:Name,
                department:sDepartment,
                branch: sBranch,
            }
            console.log(data);
            let currentPage = this.state.currentPage;
            let perPage = this.state.perPage;
            let url;
            if(this.state.key === 'employees'){
                url = `search/attendenceEmployee`;
            } else{
                url = `search/attendenceDepartment`;
            }
            search_ApiManager(url, data, currentPage, perPage,(res) => {
            console.log('search result',res);
            this.setState({
                loading: false,
                department: res.data.data['data'],
                users: res.data.data['data'],
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            let options = res.data.data['data'].map(function (item) {
                return { 
                    index:item.index,
                    name: item.name,
                    department: item.department,
                    location: item.location,
                    branch: item.branch,
                    country: item.country
                }
            });
            this.setState({selectedUsers: options});
        },(err) => {
            console.log(err);
        });    
    }
     //Assign Employee via Api
    assignEmployee = (department_id, employee_id) => {
        let url = 'assignemployee';
        const data = {
            department_id: department_id,
            employee_id : employee_id,
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('assigned-employee',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
            }
        },(err) => {
            console.log(err);
        });  
    }
     // Change Tab
    tabChange = (k) => {
        if(k == 'employees'){
            this.setState({key:k}, () => {
                this.getUsers();
            })
        } else if (k === 'departments'){
            this.setState({key:k}, () => {
                this.getDepartment();
            })
        } else{
            this.setState({key:k})
        }
    }
     // Add Employee
    addEmployee = () => {
        this.getDepartment();
        this.getUsers();
        this.setState({showModel: true}) 
    }
    // CallBack function handle pagination
    handlePaginationCallback = (i, perPage) =>{
        if(perPage && perPage != ''){
            this.setState({ perPage : perPage, currentPage:1}, () => { this.state.key === 'employees' ? this.getUsers() : this.getDepartment() }); 
        } else {this.setState({ currentPage : i}, () => { this.state.key === 'employees' ? this.getUsers() : this.getDepartment()  }); }
    }
      // Table sorting
    sorting = (col) => {
        if(this.state.key === 'employees'){
            const sortResult = Sorting(this.state.users, col, this.state.order)
            this.setState({order: sortResult[0], users: sortResult[1]})
        } else{
            const sortResult = Sorting(this.state.department, col, this.state.order)
            this.setState({order: sortResult[0], department: sortResult[1]})
        }
     }

    render(){
        let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
        let count1 = this.state.currentPage * this.state.perPage - this.state.perPage +1;
        return(
            <>
            <Title title="Attendance" />
            <div className="row w-100 pl-3 pr-3">
            <div className="row w-100">
            {this.state.allPermissions['hr-management'] === 1 ?
            <Row className="w-100">
            <Col>
            <Tabs activeKey={this.state.key} defaultActiveKey="home" className="app-tabs ml-0 border rounded mt-0"
            onSelect={(k) => this.tabChange(k)}>
            {/* Account Home */}
             <Tab eventKey="home" title="Account Home">
             <div eventKey="home" className="pt-0 mb-3">
             <div className="row p-3 bg-white border pt-1 mb-5 pb-1">
             <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 mb-3 pt-3 mt-3">Account Home</h5>
             {/* Activity Card */}
             <div className='col-lg-6 mb-3'>
             <div className="card card-radius">
             <div className="bg-green title-border-radius"><h5 className="card-title mx-2 my-2 white">Recent Activity</h5></div>
             <div className="card-body p-2 row">
             {/* Status Column */}
             <div className='col-lg-1 col-2'>
             <h5 className="card-title black size-13 bold mb-0">Status</h5>
             {this.state.recent.map((r, k) => (
             <div className='text-center' key={k}>{r.in && r.out ? 
             <FaIcons.FaCircle size={10} color={'red'} />
             :<FaIcons.FaCircle size={10} /> }</div>))}
             </div>
             {/* Name Column */}
             <div className='col-lg-6 col-6'>
             <h5 className="card-title black size-13 bold pl-1 mb-0">Name</h5>
             {this.state.recent.map((r, k) => (
             <Link to={`/attendence/detail/${r.user_id}`}>
             <h5 key={k} className='size-13 font-f-sans underline mt-2'>{r.user}</h5></Link>))}
             </div>
              {/* Last Activity */}
              <div className='col-lg-5 col-4'>
             <h5 className="card-title black size-13 bold ">Last Activity</h5>
             {this.state.recent.map((r, k) => (
             <h6 className='size-13 black font-f-sans'>{r.in && r.out ? 
             <>Out at {moment(r.out).format('h:mm A')} On {moment(r.out).format('DD-MMM-YYYY')}</> : 
             <>In at {moment(r.in).format('h:mm A')} On {moment(r.in).format('DD-MMM-YYYY')} </>}
             </h6> ))}
             </div>
             </div>
             </div>
             </div>
             {/* Shortcuts Card */}
             <div className='col-lg-6 mt-lg-0 mt-3'>
             <div className='mx-lg-5 pl-lg-4'>
             <div className="card card-radius ml-lg-5">
             <div className="bg-green title-border-radius"><h5 className="card-title mx-2 my-2 white">Shortcuts</h5></div>
             <div className="card-body p-2 row j-content-center">
             <button onClick={() => this.addEmployee()} className='col-lg-9 col-11 btn btn-primary'>
             <FaIcons.FaPlus size={13} />  Add New Employee</button>

             <button onClick={() => this.setState({show: true})} className='col-lg-9 col-11 btn btn-primary mt-3'>
             <FaIcons.FaPlus size={13} />  Add New Department</button>
             </div>
             </div>
             </div>
             </div>

              {/* Department Card */}
             <div className='col-lg-6 mb-5 mt-lg-0 mt-4'>
             <div className="card card-radius">
             <div className="bg-green title-border-radius"><h5 className="card-title mx-2 my-2 white">Departments</h5></div>
             <div className="card-body p-2 row">
             {/* Department Name Column */}
             <div className='col-lg-7'>
             <h5 className="card-title black size-13 bold mb-1">Department Name</h5>
             {this.state.department.map((dep, key) => (
              <h6 className='size-13 black' key={key}>{dep.name}</h6> ))}
             </div>
             <div className='col-lg-3'>
             <h5 className="card-title black size-13 bold mb-1 text-center">Employees</h5>
             {this.state.department.map((dep, key) => (
             <h6 className='size-13 black text-center' key={key}>{dep.employee}</h6> ))}
             </div>
             <div className='col-lg-1'>
             <h5 className="card-title black size-13 bold mb-1 text-center">In</h5>
             {this.state.department.map((dep, key) => (
             <h6 className='size-13 black text-center' key={key}>{dep.in}</h6> ))}
             </div>
             <div className='col-lg-1'>
             <h5 className="card-title black size-13 bold mb-1 text-center">Out</h5>
             {this.state.department.map((dep, key) => (
             <h6 className='size-13 black text-center' key={key}>{dep.out}</h6> ))}
             </div>

             </div>
             </div>
             </div>
             </div>
             </div>
             </Tab>
             {/* Employees Tab */}
            <Tab eventKey="employees" title="Employees">
            <>
            <div eventKey={this.state.key} className="pt-0 mb-3">
            <div className="row px-2 bg-white border py-1 mb-5">
            <div className="row w-100 pr-1 mb-2 mt-2 pl-1">
            {/* search bar */}
            <MultipleSearch
            input1={'Search Employee'}
            input2={'Search Department'}
            input7branchdropdown={'Select Branch'}
            onSearchChange={this.handleSearchCallback}
            /> 
            <div className="table-responsive table-bordered mb-2">
            <table id={this.state.key} className="table bg-white mb-0">
            {/* Headers */}
            <thead className="thead-dark">
            <tr>
            {this.state.employee_headers.map(head => (
            head.checked &&
            <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th>  ))}
            </tr>
            </thead>
            {/* End Headers */}
            <tbody>
            {this.state.users.map((value, index) => {
                return(
                <tr key={index++}>
                {this.state.employee_headers[0]['checked'] && <td className='text-center'>{count++}</td>}
                {this.state.employee_headers[1]['checked'] && <td className='text-center'>
                <Link to={`/attendence/detail/${value.id}`}>{value.name}</Link></td>}
                {this.state.employee_headers[2]['checked'] && <td className='text-center'>{value.department ?  value.department : ''}</td>}
                {this.state.employee_headers[3]['checked'] && <td className='text-center'>{value.location ? value.location : ''}</td>}
                {this.state.employee_headers[4]['checked'] && <td className='text-center'>{value.branch ? value.branch : ''}</td>}
                {this.state.employee_headers[5]['checked'] && <td className='text-center'>{value.country ? value.country : ''}</td>}
                {this.state.employee_headers[6]['checked'] && <td className='text-center'>
                {value.in && value.out ? <FaIcons.FaCircle  color={'red'} size={10} />
                : value.in ? <FaIcons.FaCircle size={10}  color='#1de61d'/> : ''}</td>}
                {this.state.employee_headers[7]['checked'] && <td className='text-center'>
                {value.in && value.out ? 
                <>Out at {moment(value.out).format('h:mm A')} On {moment(value.out).format('DD-MMM-YYYY')}</> : 
                value.in ?
                <>In at {moment(value.in).format('h:mm A')} On {moment(value.in).format('DD-MMM-YYYY')} </> : ''}
                </td>}
                </tr>
                )
            })}
            </tbody>
            </table>
            </div>
            <Paginations
            currentPage= {this.state.currentPage}
            total= {this.state.totalItem} 
            itemsPerPage= {this.state.perPage}
            lastPage={this.state.lastPage}
            onPageChange= {this.handlePaginationCallback} 
            totalItems={this.state.totalItem}
            perItem={this.state.perItem}
            />
             {/* Exports */}
            <div className="d-flex mb-5">
            <ExportOptions
            pdffilename={'AttendanceEmployees.pdf'} 
            csvfilename={'AttendanceEmployees.csv'} 
            id="viewemployeediv" 
            header="Attendance Employees"
            xlsxfilename="AttendanceEmployees"
            columns={this.state.employee_headers}
            data={this.state.selectedUsers} />
            </div>
                {/* End Exports */}
           </div>
           </div>
           </div>
           </> 
           </Tab>

            {/* Departments Tab */}
            <Tab eventKey="departments" title="Departments">
            {this.state.loading ? <Loader /> :
            <>
            <div eventKey={this.state.key} className="pt-0 mb-3">
            <div className="row px-2 bg-white border py-1 mb-5">
            <div className="row w-100 pr-1 mb-3 mt-2 pl-1">
            {/* search bar */}
            <MultipleSearch
            input1={'Search Department'}
            onSearchChange={this.handleSearchCallback}
            /> 
            <div className="table-responsive table-bordered mb-2">
            <table id={this.state.key} className="table bg-white mb-0">
            {/* Headers */}
            <thead className="thead-dark">
            <tr>
            {this.state.department_headers.map(head => (
            <th id={head.dataKey} className={head.align} key={head.key} onClick={() => this.sorting(head.dataKey)}>{head.title }</th>  ))}
            </tr>
            </thead>
            {/* End Headers */}
            <tbody>
            {this.state.department.map((value, index) => {
                return(
                <tr key={index++}>
                {this.state.department_headers[0]['checked'] && <td className='text-center'>{count1++}</td>}
                {this.state.department_headers[1]['checked'] && <td className='text-center'>{value.name}</td>}
                {this.state.department_headers[2]['checked'] && <td className='text-center'>{value.type}</td>}
                {this.state.department_headers[2]['checked'] && <td className='text-center'>{value.employee}</td>}
                {this.state.department_headers[3]['checked'] && <td className='text-center'>{value.in}</td>}
                <td className='text-center'>{value.out}</td>
                </tr>
                )
            })}
            </tbody>
            </table>
            </div>
            <Paginations
            currentPage= {this.state.currentPage}
            total= {this.state.totalItem} 
            itemsPerPage= {this.state.perPage}
            lastPage={this.state.lastPage}
            onPageChange= {this.handlePaginationCallback} 
            totalItems={this.state.totalItem}
            perItem={this.state.perItem}
            />
            <div className="d-flex mb-5">
            <ExportOptions
            pdffilename={'AttendanceDepartment.pdf'} 
            csvfilename={'AttendanceDepartment.csv'} 
            id="viewemployeediv" 
            header="Attendance Department"
            xlsxfilename="AttendanceDepartment"
            columns={this.state.department_headers}
            data={this.state.selectedDepartments} />
            </div>
                {/* End Exports */}
           </div>
           </div>
           </div> </> }
            </Tab>

            </Tabs>
            </Col>
            </Row>: 'you dont have permission to access this page!'}
            </div>
            </div>
          <Modals
            show={this.state.show}
            title={'Add New Department'}
            type={'category'}
            data={'Department Name:'}
            parentCallback = {this.handleCallback} />

           <Modals
            show={this.state.showModel}
            title={'Assign Department'}
            type={'attendance'}
            department={this.state.department}
            users={this.state.users}
            parentCallback={this.handleEmployeeCallback} />
          </>
        )
    }
} 
export default Attendence

import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import ExportOptions from '../GenericComponents/ExportOptions'
import Paginations from '../GenericComponents/Pagination';
import EmployeeActivityBars from '../Settings/EmployeeActivityBars';
import Loader from '../Loader/Loader'
import moment from 'moment';
import { search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import { Link } from 'react-router-dom';
import './clients.css';
import MultipleSearch from '../GenericComponents/MultipleSearch';
import { Sorting } from '../GenericComponents/SortTables';
import { educationStatus, migrationStatus } from '../../utilis/appStatus';

class ViewLeads extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            client:'', manager:'', course:'', package:'', provider:'', manager:[], status:[],
            currentPage: 1,
            totalItem:'',
            lastPage:'',
            perPage: 10,
            leads:[],
            allUsers:[],
            selectedLeads:[],
            sDate: null,
            eDate: null,
            userID:null,
            loading:false,
            categoryID:'education',
            type:null,
            days: null,
            order:'ASC',
            search:'',
            allPermissions: permissions,
            perItem:'',
            headers: [
                {title: '#' , dataKey: "index", align: "text-center", key:1, checked: true, canChange: false},
                {title: 'Client Manager' , dataKey: "counselorname",align: "text-center",key:2, checked: true, canChange: false},
                {title: 'Client' , dataKey: "client", key:3, checked: true, canChange: false},
                {title: 'Course/Package', dataKey: "coursename", align: "text-center",key:4, checked: true, canChange: false},
                {title: 'Provider', dataKey: 'providername',align:"text-center",key:5, checked: true, canChange: false},
                {title: 'Type', dataKey: 'category',align:"text-center",key:6, checked: true, canChange: true},
                {title: 'Status', dataKey: 'status',align:"text-center",key:7, checked: true, canChange: true},
                {title: 'Status Changed ', field: "statuschangedate", align:"text-center", key:8, checked: true, canChange: true},
                {title: 'Created At', dataKey: 'created_at', align:"text-center",key:9, checked: false, canChange: true},
            ],
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if(this.state.allPermissions['leads'] === 1){
            this.getAllUsers();
            this.getLeadsReport();
        }
    }
     // Get all users from apis 
     async getAllUsers() {
        let currentPage, perPage = 0;
        let url = 'getAllUsers';
        view_ApiManager(url, currentPage, perPage,(res) => {
        console.log('Get All Users',res);
        this.setState({ allUsers: res.data.data});
        },(err) => {
            console.log(err);
        });
    }
     //CallBack function to get changed status name and value and call api to save results
     handleCallback = (days, startDate, endDate, category) => {
        if(days) this.setState({days: days})
        if(startDate) this.setState({sDate: startDate})
        if(endDate) this.setState({eDate: endDate})
        this.setState({ categoryID: category }, () => {
            if(this.state.search){
                this.handleSearchCallback('', 'dates');
            } else{
                this.getLeadsReport();
            }
         })
    }
     // Get all leads report from apis 
     async getLeadsReport() {
        this.setState({loading: true});
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let $startDate = this.state.sDate;
        let $endDate = this.state.eDate;
        let $days = this.state.days;
        let $category = this.state.categoryID;
        let url = `getLeadsReport/${$days}/${$startDate}/${$endDate}/${$category}`;
        console.log(url);
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('leads report',res);
            this.setState({
                loading: false,
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                leads: res.data.data['data'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
            let options = res.data.data['data'].map(function (item) {
                return { 
                index: item.index,
                counselorname: item.counselorname,
                client: item.client,
                coursename: item.coursename,
                providername: item.providername,
                category: item.category,
                statuschangedate: item.statuschangedate,
                created_at: moment(item.created_at).format('YYYY-MM-D'),
                };
            })
            this.setState({selectedLeads: options})
        },(err) => {
            console.log(err);
        });
    }
     // CallBack function handle pagination
     handlePaginationCallback = (i, perPage) => {
        window.scrollTo(0, 0);
        if(this.state.search == ''){
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.getLeadsReport();  }); 
            } else {this.setState({ currentPage : i}, () => { this.getLeadsReport(); }); }
        } else{
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.handleSearchCallback(this.state.search);  }); 
            } else {this.setState({ currentPage : i}, () => { this.handleSearchCallback(this.state.search); }); }
        }
    }
     // Table sorting
     sorting = (col) => {
        const sortResult = Sorting(this.state.leads, col, this.state.order)
        this.setState({order: sortResult[0], leads: sortResult[1]})
    }
     //Search Api
     handleSearchCallback = (keyword, name) => {
        if(name == 'clear'){
            this.setState({sDate:null, eDate: null, days: null, categoryID:'education', client:'', course:'', provider:'', manager:[], status:[], package:'' }
            ,  () => this.getLeadsReport() );
         } else{
            let Manager, Client, Course, Provider, Package, Status;
            if(name != 'dates'){
                if(name == 'input1') {
                    Client = keyword;
                    this.setState({client: keyword})
                } else Client = this.state.client;
                if(name == 'input2') {
                    Course = keyword;
                    this.setState({course: keyword})
                } else Course = this.state.course;
                if(name == 'input3') {
                    Provider = keyword;
                    this.setState({provider: keyword})
                } else Provider = this.state.provider;
                if(name == 'input4') {
                    Package = keyword;
                    this.setState({package: keyword})
                } else Package = this.state.package;
                if(name == 'input5'){
                    Manager = keyword;
                    this.setState({manager: keyword})
                } else Manager = this.state.manager;
                if(name == 'input6'){
                    Status = keyword;
                    this.setState({status: keyword})
                } else Status = this.state.status;
            }
            const data = {
                manager: Manager,
                client: Client,
                course: Course,
                provider: Provider,
                package: Package, 
                status: Status,
                category: this.state.categoryID,
                startdate: this.state.sDate ? this.state.sDate : 'null',
                enddate: this.state.eDate ? this.state.eDate : 'null',
                days: this.state.days ? this.state.days : 'null',
            }
            console.log(data);

            this.setState({search: keyword});
            let currentPage = this.state.currentPage;
            let perPage = this.state.perPage;
            let url = `search/leads`; 
            search_ApiManager(url, data, currentPage, perPage,(res) => {
                console.log('search result',res);
                this.setState({
                    totalItem: res.data.data['total'],
                    perPage: res.data.data['per_page'],
                    leads: res.data.data['data'],
                    lastPage: res.data.data['last_page'],
                    perItem: res.data.data['data'].length,
                });

                let options = res.data.data['data'].map(function (item) {
                    return { 
                    index: item.index,
                    counselorname: item.counselorname,
                    client: item.client,
                    coursename: item.coursename,
                    providername: item.providername,
                    category: item.category,
                    statuschangedate: item.statuschangedate,
                    created_at: moment(item.created_at).format('YYYY-MM-D'),
                    };
                })
                this.setState({selectedLeads: options})
            },(err) => {
                console.log(err);
            });
        }
    }
     // Multiple Search
     handleColumnsCallback = (selectedColumns) => {
        this.setState({headers: selectedColumns})
    }

  render(){
    const role = JSON.parse(localStorage.getItem('user_role'));
    let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
    let headers = this.state.headers;
    return(
        <>
         <Title title="View Leads" />
         {this.state.loading ? <Loader /> :
         <div className="row w-100 pl-3 pr-3 mb-5">
         {this.state.allPermissions['leads'] === 1 ?
         <>
         <EmployeeActivityBars 
         leads={true}
         days={this.state.days} 
         selectedsDate={this.state.sDate}
         selectedeDate={this.state.eDate}
         selectedType={this.state.categoryID}
         parentCallback = {this.handleCallback} />
        
        <MultipleSearch
         input5Userdropdown={headers[1]['checked'] && 'Search Manager'}
         input1={headers[2]['checked'] && 'Search Client'}
         input2={headers[3]['checked'] && 'Search Course'}
         input3={headers[4]['checked'] && 'Search Provider'}
         input4={headers[5]['checked'] && 'Search Package'}
         input6Statuses={headers[6]['checked'] && 'Search Status'}
         allUsers={this.state.allUsers}
         status={this.state.selectedType == 'education' ? educationStatus : migrationStatus}
         allColumns={headers}
         onSearchChange= {this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         showDates={true}
         />

         <table id="viewleads" className="table table-bordered bg-white mb-1">
         {/* Headers */}
        <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         head.checked &&
         <th id={head.dataKey} className={head.align} onClick={() => this.sorting(head.dataKey)} key={head.key}>
         {head.title}</th>
         ))}
         </tr>
         </thead>
         {/* End Headers */}
         <tbody>
         {this.state.leads.map((value, index) => 
         <tr key={index}>
         {headers[0]['checked'] && <td className='text-center'>{count++}</td>}
         {headers[1]['checked'] && <td className='text-center w-15'>{value.counselorname}</td>}
         {headers[2]['checked'] && <td className='text-left'><Link to={`/clients/view-client-detail/${value.clientid}`}>
         {value.client} </Link></td>}
         {headers[3]['checked'] && <td className='text-center'>{value.coursename}</td>}
         {headers[4]['checked'] && <td className='text-center'>
         <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.providername} </Link></td>}
         {headers[5]['checked'] && <td className='text-center'>{value.category}</td>}
         {headers[6]['checked'] && <td className='text-center'>{value.status}</td>}
         {headers[7]['checked'] && <td className="text-center w-15"> <small>
         {moment(value.statuschangedate).format('D MMMM YYYY')}</small></td>}
         {headers[8]['checked'] && <td className='text-center w-15'><small>{moment(value.created_at).format('D MMMM YYYY')}</small></td>}
         </tr>
         )}
         </tbody>
         </table>
         <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handlePaginationCallback} 
        />
            {/* Exports */}
         {role === 2 &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Leads.pdf'} 
         csvfilename={'Leads.csv'} 
         xlsxfilename={'Leads'} 
         header="Leads List"
         columns={this.state.headers}
         data={this.state.selectedLeads} />
         </div>}
        {/* End Exports */}
         </>: 'You dont have permission to access this pages!'}
         </div>}
        </>
    )
  }
}
export default ViewLeads;

import React, { Component } from 'react'
import Title from '../GenericComponents/Title'
import swal from 'sweetalert'
import Paginations from '../GenericComponents/Pagination'
import EmployeeActivityBars from '../Settings/EmployeeActivityBars'
import Loader from '../Loader/Loader'
import { delete_ApiManager,get_ApiManager,post_ApiManager, search_ApiManager, view_ApiManager } from '../ApiManager/ApiManager'
import ExportOptions from '../GenericComponents/ExportOptions'
import { Link } from 'react-router-dom'
import './clients.css'
import MultipleSearch from '../GenericComponents/MultipleSearch'
import { Sorting } from '../GenericComponents/SortTables'
import moment from 'moment'
import { FaEllipsisH, FaEye, FaTrashAlt } from 'react-icons/fa'

class AssignClients extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.scrollRef = React.createRef();
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        var userDetail = JSON.parse(localStorage.getItem('user')); 
        this.state = {
            name:'', email:'', phone:'', country:'', counselor:[], superAgent: [], subAgent:[],
            getStartDate:'',
            sDate: null,
            eDate: null,
            getEndDate:'',
            allUsers:[],
            totalItem:'',
            clientName:'',
            currentPage: 1,
            assignedID:'',
            lastPage:'',
            perPage: 10,
            open:false,
            search:'',
            days: null,
            allClients:[],
            selectedClients:[],
            order:'ASC',
            userRole: userDetail,
            perItem:'',
            allAgents:[],
            allPermissions: permissions,
            gender:'',
            headers: [
                {title: '#' , dataKey: "index", align: "text-center", key: 1, checked: true, canChange: true },
                {title: 'Counselor', dataKey: 'username', align:"text-center", key: 2, checked: true, canChange: false },
                {title: 'Client' , dataKey: "name", key: 3, checked: true, canChange: false },
                {title: 'Email' , dataKey: "email", align: "text-center", key: 4, checked: true, canChange: false },
                {title: 'Phone' , dataKey: "mobile", align: "text-center ",  key: 5, checked: false, canChange: true },
                {title: 'Country', dataKey: "country", align: "text-center", key: 6, checked: true, canChange: true },
                {title: 'Gender', dataKey: "gender", align: "text-center ",  key: 7, checked: false, canChange: true },
                {title: 'SuperAgent', dataKey: "superagent", align: "text-center ",  key: 8, checked: true, canChange: true },
                {title: 'SubAgent', dataKey: "subagent", align: "text-center ",  key: 9 ,checked: true, canChange: true },
                {title: 'AssignedTo', dataKey: 'assigned', align:"text-center", key: 10, checked: true, canChange: true },
                {title: 'Status', dataKey: 'status', align:"text-center", key: 11, checked: false, canChange: true },
                {title: 'Assign', dataKey: '', align:"text-center", key: 12,checked: true, canChange: true },
                {title: '', dataKey: '', key: 13,checked: false, canChange: false },
            ],
            pdfheaders: [
                {title: '#' , dataKey: "index", align: "text-center", key: 1 },
                {title: 'Counselor', dataKey: 'username', align:"text-left", key: 2 },
                {title: 'Client' , dataKey: "name", align:'w-10', key: 3},
                {title: 'Email' , dataKey: "email", align: "text-center ",  key: 4},
                {title: 'Phone' , dataKey: "mobile", align: "text-center ",  key: 5},
                {title: 'Country', dataKey: "country", align: "text-center ",  key: 6 },
                {title: 'SuperAgent', dataKey: "superagent", align: "text-center ",  key: 7 },
                {title: 'SubAgent', dataKey: "subagent", align: "text-center ",  key: 8 },
                {title: 'CreatedAt', dataKey: "created_at", align:"text-center",  key: 11},
            ],
        }
        this.handleSearchCallback = this.handleSearchCallback.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if(this.state.allPermissions['clients'] === 1){
            this.getClients();
            this.getAgents();
        }
    }

     async getClients() {
        this.setState({loading: true});
        let currentPage = this.state.currentPage;
        let perPage = this.state.perPage;
        let $startDate = this.state.sDate;
        let $endDate = this.state.eDate;
        let $days = this.state.days;
        let url = `getAllClients/${$days}/${$startDate}/${$endDate}/${null}`;
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('All Clients',res);
            this.setState({
                allClients: res.data.data.data['data'],
                loading: false,
                allUsers: res.data.data['users'],
                totalItem: res.data.data.data['total'],
                perPage: res.data.data.data['per_page'],
                lastPage: res.data.data.data['last_page'],
                perItem: res.data.data.data['data'].length,
            });

            let options = res.data.data.data['data'].map(function (item) {
                return { 
                  index: item.index,
                  counselor: item.counselor,
                  name: item.name,
                  email: item.email,
                  phone: item.phone,
                  country: item.country,
                  gender: item.gender,
                  superagent: item.superagent,
                  subagent: item.subagent,
                  assignedTo: item.assignedTo,
                  eduassigned: item.eduAssign,
                  migassign: item.migAssign,
                  status: item.ecount + item.mcount > 0 ? "Closed" :  item.ecount + item.mcount == 0 ? 'Prospect' : "Open",
                  created_at: moment(item.created_at).format('YYYY-MM-D'),
                };
              })
              this.setState({selectedClients: options})
        },(err) => {
            this.setState({loading: false});
            console.log(err);
        });
     }
      // Assign application to user
     async AssignApp(clientID){
        swal({
            title: "Are you sure?",
            text: "You want to assign this application to ("+ this.state.clientName +")", icon: "warning", buttons: true,
          }).then((willDelete) => {
            if (willDelete) {
                this.setState({loading: true});
                let url = 'assignedClient';
                const data = {
                    clientId: clientID,
                    assignedId:this.state.assignedID
                }
                post_ApiManager(data, url,(res) => {
                    console.log('Client Assigned',res);
                    this.setState({loading: false});
                    if(res.data.error) swal(" ", res.data.error, "error")
                    else {swal("Success!", res.data.message, "success"); this.getClients() } 
                },(err) => {
                    console.log(err);
                })
            }
        })
     }
     //CallBack function to get changed status name and value and call api to save results
     handleCallback = (days, startDate, endDate) => {
        this.setState({days: days, sDate: startDate, eDate: endDate }, () => {
            if(this.state.search){
                this.handleSearchCallback('', 'dates');
            } else{
                this.getClients();
            }
        })
    }
     // CallBack function handle pagination
     handlePaginationCallback = (i, perPage) =>{
        window.scrollTo(0,this.scrollRef);
        if(this.state.search == ''){
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.getClients();  }); 
            } else {this.setState({ currentPage : i}, () => { this.getClients(); }); }
        } else{
            if(perPage && perPage != ''){
                this.setState({ perPage : perPage, currentPage:1}, () => {  this.handleSearchCallback(this.state.search);  }); 
            } else {this.setState({ currentPage : i}, () => { this.handleSearchCallback(this.state.search); }); }   
        }
    }

     handleChange(event, clientID) {
        this.setState({assignedID: event.target.value, clientName:event.target.options[event.target.selectedIndex].text},() => {
            this.AssignApp(clientID);
        })
     }
      // Table sorting
     sorting = (col) => {
        const sortResult = Sorting(this.state.allClients, col, this.state.order)
        this.setState({order: sortResult[0], allClients: sortResult[1]})
     }
      //Search Api
     handleSearchCallback = (keyword, name) => {
        if(name == 'clear'){
           this.setState({name: '', email: '', phone:'', mobile:'', country:'', counselor:[], superAgent:[], subAgent:[], sDate:null, eDate: null, days: null}
           ,  () =>  this.getClients() );
        } else{
            let Name = this.state.name, Email = this.state.email, Phone= this.state.phone, Country = this.state.country, Counselor = this.state.counselor, SuperAgent = this.state.superAgent, SubAgent = this.state.subAgent, Gender = this.state.gender, Assigned = this.state.assignedTo;
            if(name != 'dates'){
                if(name == 'input1') {
                    Name = keyword; 
                    this.setState({name: keyword})
                } else { Name = this.state.name; }
                if(name == 'input2') {
                    Email = keyword;
                    this.setState({email: keyword})
                } else Email = this.state.email;
                if(name == 'input3') {
                    Phone = keyword;
                    this.setState({phone: keyword})
                } else Phone = this.state.phone;
                if(name == 'input4') {
                    Country = keyword;
                    this.setState({country: keyword})
                } else Country = this.state.country;
                if(name == 'input5'){
                    Counselor = keyword;
                    this.setState({counselor: keyword})
                } else Counselor = this.state.counselor;
                if(name == 'input6') {
                    SuperAgent = keyword;
                    this.setState({superAgent: keyword})
                } else SuperAgent = this.state.superAgent;
                if(name == 'input7') {
                SubAgent = keyword;
                this.setState({subAgent: keyword})
                } else SubAgent = this.state.subAgent;
                if(name == 'gender') {
                    Gender = keyword;
                    this.setState({gender: keyword})
                } else Gender = this.state.gender;
            }

            this.setState({search: keyword});
            const data = {
                name:Name,
                email:Email,
                phone:Phone,
                country:Country,
                counselor:Counselor,
                superagent:SuperAgent,
                subagent: SubAgent,
                sDate: this.state.sDate ? this.state.sDate : 'null',
                eDate: this.state.eDate ? this.state.eDate : 'null',
                days: this.state.days ? this.state.days : 'null',
                status: this.state.headers[10]['checked'],
                gender: Gender
            }
            console.log(data);
            let currentPage = this.state.currentPage;
            let perPage = this.state.perPage;
            let url = `search/clients`;
            search_ApiManager(url, data, currentPage, perPage,(res) => {
                console.log('search result',res);
                this.setState({
                    allClients: res.data.data['data'],
                    totalItem: res.data.data['total'],
                    perPage: res.data.data['per_page'],
                    lastPage: res.data.data['last_page'],
                    perItem: res.data.data['data'].length,
                });
            },(err) => {
                console.log(err);
            });
        } 
    }
      //Delete a user
     async deleteClient(delete_id, name) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover ("+ name +")",icon: "warning", buttons: true, dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
            let url = 'clients';
            delete_ApiManager(delete_id, url,(res) => {
            console.log('User Deleted',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Client ("+ name +")"+" deleted successfully", {icon: "success" });
                this.setState({loading: false, currentPage:1});
                this.getClients(); 
                this.handleNotification();
            };   
        },(err) => {
            console.log(err);
        }); }   
       });
    }
      //  Call Notification bell Counter
     handleNotification(){
        const socket = this.props.socket;
        const user = this.props.user;
        socket.emit("sendNotification", {
          senderName: user,
        });
     }

     handleColumnsCallback = (selectedColumns) => {
        this.setState({headers: selectedColumns})
     }
     async getAgents(){
        let url = 'getAgentsByName';
        get_ApiManager('', url,(res) => {
            console.log('agents',res);
            this.setState({allAgents: res.data.data})
        },(err) => {
            console.log(err);
        })
     }


  render(){
    const role = JSON.parse(localStorage.getItem('user_role'));
    let count = this.state.currentPage * this.state.perPage - this.state.perPage +1;
    const headers = this.state.headers;
    return(
        <>
        <Title title={'Assign Clients'} 
        btnCol="col-lg-8 mb-0 text-center pt-lg-1" 
        btn={true}
        btnText="Add Client" 
        btnClass="float-right btn btn-success"
        btnUrl="/clients/add-client" 
        />
        {this.state.loading ? <Loader /> :
        <div className="row w-100 pl-3 pr-3 mb-3">
        {this.state.allPermissions['clients'] === 1 ?
        <>
        <EmployeeActivityBars  
         days={this.state.days} 
         clients={true}
         selectedsDate={this.state.sDate}
         selectedeDate={this.state.eDate}
         parentCallback={this.handleCallback} />
    
         <MultipleSearch
         input1={headers[2]['checked'] && 'Search Client'}
         input2={headers[3]['checked'] && 'Search Email'}
         input3={headers[4]['checked'] && 'Search Phone'}
         input4={headers[5]['checked'] && 'Search Country'}
         inputGender={headers[6]['checked'] && 'Search Gender'}
         input5Userdropdown={headers[1]['checked'] && 'Search Counselor'}
         input6Agents={headers[7]['checked'] ? 'Search SuperAgent': ''}
         input7Agents={headers[8]['checked'] && 'Search SubAgent'}
         allUsers={this.state.allUsers}
         allColumns={headers}
         allAgents={this.state.allAgents}
         onSearchChange={this.handleSearchCallback}
         onColumnsChange={this.handleColumnsCallback}
         showDates={true}
         />
       
        <div className='pb-1 d-flex pt-1 font-none' style={{backgroundColor:'#e8e8e8', borderRadius:'3px'}}>
        <div className='ml-2'>Prospect <span className='circles assign-circle ml-1'></span></div>
        <div className='ml-2'>Migrations<span className='circles mig-circle ml-1'></span></div>
        <div className='ml-2'>Educations<span className='circles edu-circle ml-1'></span></div>
        <div className='ml-2'>Open<span className='circles open-circle ml-1'></span></div>
        <div className='ml-2'>Closed<span className='circles close-circle ml-1'></span></div>
        </div>
        <div ref={this.scrollRef}>
        <table id="viewclients" className="table table-bordered bg-white mb-2 table-responsive">
         {/* Headers */}
         <thead className="thead-dark">
         <tr>
         {headers.map(head => (
         head.checked &&
         <th id={head.dataKey} className={head.align} onClick={() => this.sorting(head.dataKey)} key={head.title}>
         {head.title}</th>
         ))}
         </tr>
         </thead>
         {/* End Headers */}
        <tbody>
        {this.state.allClients.map(value => (
        <tr key={value.id}>
        {headers[0]['checked'] && <td className='text-center'>{count++}</td>}
        {headers[1]['checked'] && <td className='text-center name-text w-15'>{value.userid === 0 ? 'Manual Entry' : value.counselor}</td>}
        {headers[2]['checked'] && <td><Link className='w-15' to={`/clients/view-client-detail/${value.id}`}>{value.name}</Link></td>}
        {headers[3]['checked'] && <td className='text-center'>{value.email ? value.email : '--'}</td>}
        {headers[4]['checked'] && <td className='text-center'>{value.mobile ? value.mobile : '--'}</td>}
        {headers[5]['checked'] && <td className='text-center'>{value.country}</td>}
        {headers[6]['checked'] && <td className='text-center'>{value.gender}</td>}
        {headers[7]['checked'] && <td className='text-center'>{value.superagent ? value.superagent : '--'}</td>}
        {headers[8]['checked'] && <td className='text-center'>{value.subagent ? value.subagent : '--'}</td>}
        {headers[9]['checked'] && <td className='text-center'>
        {value.assigned == 0 ?
        <div className='size-14'>Unassigned</div>
        :
        <>
        {this.state.allUsers.map(v => 
        v.id == value.assigned ? 
        <div key={v.id} className='size-14'><span className='circles assign-circle'></span>{v.name}</div>: '')}
        {this.state.allUsers.map(v => 
        v.id == value.eduassign ? 
        <div key={v.id} className='size-14'><span className='circles edu-circle'></span>{v.name}</div> :'')}
        {this.state.allUsers.map(v => 
        v.id == value.migassign ? 
        <div key={v.id} className='size-14'><span className='circles mig-circle'></span>{v.name}</div> :'')}
        </>}
        </td>}
        {headers[10]['checked'] && <td> {value.educationcount > 0 &&
        <span className='circles edu-circle'></span>
        }
        {value.migrationcount > 0 &&
        <span className='circles mig-circle'></span>
        }
        {((value.educationcount <= 0 || value.educationcount == null) 
        && (value.migrationcount <= 0 || value.migrationcount == null)) ?
        <>
        {(value.ecount + value.mcount) > 0 &&
        <> <span className='circles close-circle'> </span>Closed</> }
        {value.ecount + value.mcount == 0 && 
        <><span className='circles assign-circle'> </span>Prospect</>}
        </>
        :
        'Open (' + (value.ecount + value.mcount) + ')' }
        </td>}
        {/* if page is assign then show  */}
        {headers[11]['checked'] && 
        <td className='w-25'>
        <select className="form-select app-form" name="assignedID" 
        onChange={(e) => this.handleChange(e, value.id)} >
        <option value='0'>ReAssign To </option>
        <option value='0' disabled>Unassigned</option>
        {[].concat(this.state.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map(value =>
        <option key={value.id} className="light" value={value.id}>{value.name}</option> )}
        </select> 
        </td>}
        {headers[12]['checked'] && 
        <td className='text-center'>
        <span className="wrapper icon-ref ml-0">
        <span className="dot-icon">
        <a className="a-ref-icon" onClick ={() => this.handleClick(value.id)}>
        <FaEllipsisH size={12} color={'rgb(107 107 107 / 71%)'} />
        </a>
        {this.state.open && this.state.clickedId === value.id &&
        <div className="actions-dots-dropdown">
        {this.state.userRole['role'] === 2 &&
        <button onClick ={() => this.deleteClient(value.id, value.name)}  
        className="btn btn-danger pl-3 pr-3 p-0 m-1 mb-2"> 
        <FaTrashAlt size={12} color={'#fffcfc'} /> </button>}
        <br/>
        <Link to={`/clients/view-client-detail/${value.id}`}>
        <button className="btn btn-info pl-3 pr-3 p-0 m-1 mb-2"> 
        <FaEye size={12} color={'#fffcfc'} /> </button></Link>
        </div>} </span>
        </span>
        </td>}
        </tr>
        ))}
        </tbody>
        </table>
        </div>
        <Paginations
         currentPage= {this.state.currentPage}
         total= {this.state.totalItem} 
         itemsPerPage= {this.state.perPage}
         lastPage={this.state.lastPage}
         totalItems={this.state.totalItem}
         perItem={this.state.perItem}
         onPageChange= {this.handlePaginationCallback} 
        />
         {/* Exports */}
         {role === 2 &&
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'Clients.pdf'} 
         csvfilename={'Clients.csv'} 
         xlsxfilename={'Clients'} 
         header="Clients List"
         columns={this.state.pdfheaders}
         data={this.state.selectedClients} />
         </div>}
        {/* End Exports */}
        </>: 'You dont have permission to access this pages!'}
        </div>}
        </>
    )
  }
}
export default AssignClients;
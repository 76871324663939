
import React, { Component } from 'react'
import swal from 'sweetalert';
import "./hr.css";
import Title from '../GenericComponents/Title';
import Modals from '../GenericComponents/Modal';
import { post_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import Loader from '../Loader/Loader';
import { Link } from 'react-router-dom';
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import moment from 'moment';
import { FaRegEdit} from 'react-icons/fa';
import Multiselect from 'multiselect-react-dropdown';

class UserRoles extends Component {
    constructor(props) {
        super(props);
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            showModel:false,
            totalItem:'',
            currentPage: 1,
            loading: false,
            lastPage:'',
            perPage: 10,
            key:'role',
            perItem:'',
            allRoles:[],
            allTasks:[],
            userModel:false,
            selectedUsers:'',
            selectedRole:'',
            alreadyAdded:[],
            allUsers:[],
            taskModel:false,
            category:'',
            roleId:'',
            allCategory:[],
            showCategory2: false,
            allPermissions: permissions,
            category2:'',
            t_role:'',
            showTaskListModel: false,
            selectedTaskList:[],
            editTaskList: false,
            defaultRole: '',
            edit_id:'',
            role:'',
            managerTaskModel: false,
            selectedManagerTaskList: [],
            showManagerTaskListModel: false,
            editManagerTaskModel: false,
            headers: [
                {name: '#' , field: "id", align: "text-center", key:1},
                {name: 'Name' , field: "name", align: "text-center", key:2},
                {name: 'Permitted Users' , field: "users", align: "text-center", key:3},
                {name: 'Created At', field: '',align: "text-center", key:4},
                {name: '', field: '',align: "text-center", key:5},
                {name: '', field: '',align: "text-center", key:6},
            ],
            taskHeaders:[
                {name: '#' , field: "id", align: "text-center", key:1},
                {name: 'Role' , field: "name", align: "text-center", key:2},
                {name: 'Category', field: '',align: "text-center", key:3},
                {name: '', field: '',align: "text-center", key:4},
            ],
            managerTaskHeaders:[
                {name: '#' , field: "id", align: "text-center", key:1},
                {name: 'Role' , field: "name", align: "text-center", key:2},
                {name: 'Created_at', field: 'created_at',align: "text-center", key:3},
                {name: '', field: '',align: "text-center", key:4},
            ],
            taskList: [{ task: "",}],
            taskList2: [{ task: "",}],
            managerTaskList: [{tasks: "", type:''}],
            allManagerTask:[],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTaskSubmit = this.handleTaskSubmit.bind(this);
        this.handleManagerTaskSubmit = this.handleManagerTaskSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.getRoles();
        this.getAllUsers();
    }

    handleCallback = () => {
        this.setState({showModel: true})
    }

    handleModelCallback = (toggle,type, name) => {
        this.setState({showModel: false})
        if(type === 'edit'){
            let url = 'add-user-role';
            const data = {
                name: name,
                edit_id: this.state.edit_id,
            }
            console.log(data);
            post_ApiManager(data, url,(res) => {
                console.log('role-added',res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    swal("Success!", res.data.message, "success");
                    this.setState({edit_id: false})
                    this.getRoles() 
                } 
            },(err) => {
                console.log(err);
            });
        }
    }

    handleModelCategoryCallback = () => {

    }

    getRoles() {
        let url = `get-user-roles`;
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('all-roles',res);
            this.setState({
                allRoles: res.data.data.data,
                loading: false,
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'].length,
            });
        },(err) => {
                this.setState({loading: false});
                console.log(err);
        }); 
    }

    getTasks() {
        let url = `get-user-tasks`;
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('all-tasks',res);
            this.setState({allTasks: res.data.data});
        },(err) => {
                this.setState({loading: false});
                console.log(err);
        });
    }

    getManagerTasks() {
        let url = `get-manager-tasks`;
        view_ApiManager(url, this.state.currentPage, this.state.perPage,(res) => {
            console.log('manager-tasks',res);
            this.setState({allManagerTask: res.data.data.data});
        },(err) => {
                this.setState({loading: false});
                console.log(err);
        });
    }

    updateRole = (title, edit_id) => {
        this.setState({defaultRole: title, showModel: true,  edit_id: edit_id})

    }

    getAllUsers = () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            this.setState({allUsers: res.data.data})
        },(err) => {
            console.log(err);
        });
     }

    handleSubmit() {
        if(this.state.selectedUsers[0]){
            let url = 'assign-user-role';
            const data = {
                role_id: this.state.selectedRole,
                selectedUsers: this.state.selectedUsers
            }
            console.log(data);
            post_ApiManager(data, url,(res) => {
                console.log('users-added',res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    swal("Success!", res.data.message, "success"); 
                    this.setState({selectedRole:'', userModel: false, selectedUsers:[], alreadyAdded:[]})
                    this.getRoles() ;
                } 
            },(err) => {
                console.log(err);
            });
        } else{
            swal("Error", 'Please select user!',  "error");
        }
     }

    handleTaskSubmit(e){
        e.preventDefault();
        if(!this.state.category){
            swal('error', 'Please enter category!', 'error');
        } else if(!this.state.taskList.every(task => task.task)){
            swal('error', 'Please enter task!', 'error');
        } else{
            let url = 'add-task-list';
            const data = {
            category: this.state.category,
            taskList : this.state.taskList,
            category2 : this.state.category2,
            taskList2: this.state.taskList2,
            role: this.state.t_role,
            edit: this.state.roleId,
            }
            console.log(data);
            post_ApiManager(data, url,(res) => {
                console.log('taslist-added',res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    swal("Success!", res.data.message, "success"); 
                    this.setState({ category: '', category2:'', taskList: [{ task: "",}], taskList2: [{ task: "",}],
                        taskModel: false, editTaskList: false, roleId: '', t_role: '', showCategory2: false
                    })
                    this.getTasks() 
                } 
            },(err) => {
                console.log(err);
            });
        }

     }

     handleManagerTaskSubmit(e){
        e.preventDefault();
        if(!this.state.managerTaskList.every(task => task.tasks)){
            swal('error', 'Please enter task!', 'error');
        } else if(!this.state.managerTaskList.every(task => task.type)){
            swal('error', 'Please select dropdown type!', 'error');
        } else{
            let url = 'add-manager-task';
            const data = {
                role: this.state.t_role,
                managerTaskList : this.state.managerTaskList,
                edit: this.state.editManagerTaskModel,
            }
            console.log(data);
            post_ApiManager(data, url,(res) => {
                console.log('list-added',res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    swal("Success!", res.data.message, "success"); 
                    this.setState({ 
                        managerTaskList: [{ tasks: "", type: 0}], 
                        managerTaskModel:false,
                        roleId: '',
                        t_role: '',
                        editManagerTaskModel: false
                    })
                    this.getManagerTasks() 
                } 
            },(err) => {
                console.log(err);
            });
        }

     }

    onUsersSelect = (selectedList) => {
        this.setState({selectedUsers: selectedList});
    }

    onUsersRemove = (selectedList) => {
        this.setState({selectedUsers: selectedList});
    }

    assignUser =(selectedRole, alreadyAdded) => {
        if(alreadyAdded){
            const usingSplit = alreadyAdded.split(',');
            const options = usingSplit.map((intake) => {
            const name = this.state.allUsers.find((element) => {
                if (element.id == intake) {
                const newName = { name: element.name, id: element.id };
                console.log(newName);
                return newName; // Add a return statement here
                }
            });
            return name;
            });
            this.setState({ alreadyAdded: options.filter(Boolean)});
        }
        this.setState({userModel: true, selectedRole: selectedRole, });
    }

    changeTabs = (tab) => {
        if(tab === 'role'){
            this.setState({key: tab, currentPage:1}, () => this.getRoles());
        } else if(tab === 'managertask'){
            this.setState({key: tab, currentPage:1}, () => this.getManagerTasks());
        } else{
            this.setState({key: tab, currentPage:1}, () => this.getTasks());
        }
     }
     
    generateTasks = (e) => {
        e.preventDefault();
        this.setState({taskModel: true})
     }

     generateManagerTasks = (e) => {
        e.preventDefault();
        this.setState({managerTaskModel: true, managerTaskList: [{  tasks: "", type:''}], editManagerGenerateTasks: false, t_role:'', })
     }

     editGenerateTasks = (e, list, roleid) => {
        e.preventDefault();
        let Category, Category2, Task1, Task2;
        if(list[0]){
            Category = list[0]['category'];
            Task1 = list[0]['tasks'];
            Category2 = list[1] && list[1]['category'] ? list[1]['category']: '';
            Task2 = list[1] && list[1]['tasks'] ? list[1]['tasks'] : [];
        }
        this.setState({
            category: Category,
            category2: Category2,
            taskList: Task1,
            taskList2: Task2[0] ? Task2 : this.state.taskList2,
            taskModel: true,
            editTaskList: true,
            roleId: roleid,
            t_role: roleid,
            showCategory2: Category2 ? true : false})
     }

     editManagerGenerateTasks = (e, list, roleid) => {
        e.preventDefault();
        this.setState({
            t_role: roleid,
            managerTaskList:list,
            managerTaskModel: true,
            editManagerTaskModel: true,
        });
     }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
     }

    handleRemoveClick = (index, e, category) => {
        e.preventDefault();
        if(category === 1){
            const list = [...this.state.taskList];
            list.splice(index, 1);
            this.setState({taskList: list});
        } else if(category === 2){
            const list = [...this.state.taskList2];
            list.splice(index, 1);
            this.setState({taskList2: list});
        } 
       
     };

     handleManagerRemoveClick = (index, e) => {
        e.preventDefault();
        const list = [...this.state.managerTaskList];
        list.splice(index, 1);
        this.setState({managerTaskList: list});
     };

     handleAddClick(e, category) {
        e.preventDefault();
        if(category === 1){
            this.setState({taskList: [...this.state.taskList, {  task: ""}]});
        } else if(category === 2){
            this.setState({taskList2: [...this.state.taskList2, {  task: ""}]});
        } 
    }

    handleManagerAddClick(e) {
        e.preventDefault();
        this.setState({managerTaskList: [...this.state.managerTaskList, {  tasks: "", type:''}]});
    }

     // handle input change
    handleInputChange(e, index, category) {
        console.log(category)
        const { name, value } = e.target;
        if(category === 1){
            const list = [...this.state.taskList];
            list[index][name] = value;
            console.log(list)
            this.setState({taskList: list})
        } else if(category === 2){
            const list = [...this.state.taskList2];
            list[index][name] = value;
            this.setState({taskList2: list})
        } 
     };

     handleManagerInputChange(e, index){
        const { name, value } = e.target;
        console.log(value)
        const list = [...this.state.managerTaskList];
        list[index][name] = value;
        console.log(list)
        this.setState({managerTaskList: list})
     }
     handleManagerRadioChange = (e, index) => {
        const { value } = e.target;
        const managerTaskList = [...this.state.managerTaskList];
        managerTaskList[index].type = parseInt(value, 10);
        this.setState({ managerTaskList });
    }

    addCategory(e){
        e.preventDefault();
        this.setState({showCategory2: true})
     }

    viewList(categories){
        this.setState({selectedTaskList: categories, showTaskListModel: true})
     }

     viewManagerList(list, role){
        this.setState({selectedManagerTaskList: list, role: role, showManagerTaskListModel: true})
     }
     
     closeModel(e){
        e.preventDefault();
        this.setState({category: '', category2:'', taskList: [{ task: "",}], taskList2: [{ task: "",}],
        taskModel: false, editTaskList: false, roleId: '', t_role: '', showCategory2: false})
     }

    render(){
        return ( 
        this.state.allPermissions['manage_roles'] === 1 ?
         <>   
        <Title title="Manage Roles" 
        btnCol="col-lg-8 text-center pt-lg-1" 
        btn={true}
        btnText="Add Role" 
        btnClass="float-right btn btn-primary"
        parentCallback={this.handleCallback} 
        />
        {this.state.loading ? <Loader /> :
        <div className="row w-100 pl-3 pr-3 mb-5 mt-lg-2">
        <Tabs activeKey={this.state.key} onSelect={(k) => this.changeTabs(k)} 
         defaultActiveKey="office" className="role-tabs ml-0 border rounded mb-0">
         <Tab eventKey="role" title="Role Customization">
        <table className="table table-bordered bg-white mb-1">
        <thead className="thead-dark">
          <tr>
          {this.state.headers.map(head => (
          <th id={head.field} className={head.align} key={head.key}>
          {head.name}</th>
          ))}
          </tr>
          </thead>
          <tbody>
          {this.state.allRoles.map((value, index) => (
          <tr key={index}>
          <td className='text-center'>{++index}</td>
          <td className='text-center name-text'>{value.name}</td>
          <td className='text-center'>{value.users.map((user, i) => 
          <div key={i}>{user.name}</div>)} </td>
          {/* <td className='text-center'>{value.addedBy}</td> */}
          <td className='text-center'><small>{moment(value.created_at).format('D MMM YYYY')}</small></td>
          <td className='text-center '>
          <div>
          <button type='button' onClick={() => this.assignUser(value.id, value.granted_users)} className='btn btn-info m-1'>Authorize User</button>
         <br/>
          <Link to={`/grant-permissions?role=${value.name}`}>
          <button type='button' className='btn btn-success m-1'>Grant Permissions</button></Link>
          </div>
          </td>
          <td className='text-center '>
          <div>
          <button onClick={() => this.updateRole(value.name, value.id)} className='btn btn-primary'>
          <FaRegEdit size={10} color={'#ffffffed'} />
          </button>
          </div>
          </td> 
          </tr>
          ))}
          </tbody>
        </table>
        </Tab>
        <Tab eventKey="task" title="Task Generation">
        <div className='my-2 text-right'>
        <button onClick={(e) => this.generateTasks(e)} className='btn btn-success text-right'>Generate Tasks</button>
        <table className="table table-bordered bg-white my-1">
        <thead className="thead-dark">
        <tr>
        {this.state.taskHeaders.map((head, i) => (
        <th id={head.field} className={head.align} key={i}>
        {head.name}</th>
        ))}
        </tr>
        </thead>
        <tbody>
          {this.state.allTasks.map((value, index) => (
        <>
          <tr key={index}>
          <td className='text-center'>{++index}</td>
          <td className='text-center name-text'>{value.name}</td>
          <td className='text-center'>  {value['categories'].map((cat, i) => (
            <div className='capital' key={i}>{cat.category} </div>
          ))} </td>
          <td className='text-center'>
          {value['categories'][0] &&
          <button className='btn btn-info' onClick={() => this.viewList(value['categories'])}>View List</button>}
          {value['categories'][0] &&
          <button onClick={(e) => this.editGenerateTasks(e, value['categories'], value.roleid)} className='btn btn-info ml-2'>Edit <FaRegEdit size={15}/></button>}
          </td>
          </tr>
          </>
          ))}
          </tbody>
        </table>
        </div>
        </Tab>
        <Tab eventKey="managertask" title="Manager Task">
        <div className='my-2 text-right'>
        <button onClick={(e) => this.generateManagerTasks(e)} className='btn btn-success text-right'>Generate Manager Tasks</button>
        <table className="table table-bordered bg-white my-1">
        <thead className="thead-dark">
        <tr>
        {this.state.managerTaskHeaders.map(head => (
        <th id={head.field} className={head.align} key={head.key}>
        {head.name}</th>
        ))}
        </tr>
        </thead>
        <tbody>
          {this.state.allManagerTask.map((value, index) => (
          <tr key={index}>
          <td className='text-center'>{++index}</td>
          <td className='text-center name-text'>{value.name}</td>
          <td className='text-center'><small>{moment(value.created_at).format('D MMM YYYY')}</small></td>
          <td className='text-center'> 
          {/* <button className='btn btn-info' onClick={() => this.viewManagerList(value['tasks'], value.name)}>View List</button> */}
          <button onClick={(e) => this.editManagerGenerateTasks(e, value['tasks'], value.role_id)} className='btn btn-info ml-2'>Edit <FaRegEdit size={15}/></button></td>
          </tr>
          ))}
          </tbody>
        </table>
        </div>
        </Tab>
        </Tabs>
        </div>}
        
        <Modals
        show={this.state.showModel}
        title={'New Role'}
        data={'Name'} 
        type={'category'}
        defaultValue={this.state.defaultRole}
        parentCallback={this.handleModelCallback}/>
        
        <Modal aria-labelledby="contained-modal-title-vcenter" 
        centered enctype="multipart/form-data" show={this.state.userModel} onHide={() => this.setState({selectedRole:'', userModel: false, selectedUsers:[], alreadyAdded:[]})}>  
            <Modal.Header closeButton><div className="text-center col-11 font-f-sans white">Grant Access to Users</div></Modal.Header>  
            <Modal.Body className="ml-2 mr-2">
            <form action=''>
            <div className="row">
            <label className="profile-label">Select Users</label>

            <Multiselect
            options={this.state.allUsers.slice().sort((a, b) => a.name.localeCompare(b.name))}
            className="form-select app-form multi col-11"
            placeholder={'User'}
            displayValue="name"
            onSelect={this.onUsersSelect}
            onRemove={this.onUsersRemove}
            showCheckbox={true}
            disablePreSelectedValues={false}
            hidePlaceholder={false}
            selectedValues={this.state.alreadyAdded}
            /> 

            </div>
            </form>
            </Modal.Body> 
            <Modal.Footer>  
            <Button className="btn btn-success" onClick={this.handleSubmit}> Assign User</Button>  
            <Button className="btn btn-danger" onClick={() => this.setState({selectedRole:'', userModel: false, selectedUsers:[], alreadyAdded:[]})}>Close</Button>  
            </Modal.Footer>  
        </Modal>
        
        <Modal size='lg' aria-labelledby="contained-modal-title-vcenter" 
        centered enctype="multipart/form-data" show={this.state.showTaskListModel} onHide={() => this.setState({showTaskListModel:false})}>  
        <Modal.Header closeButton><div className="text-center col-11 font-f-sans white">Task List</div></Modal.Header>  
        <Modal.Body >
        {this.state.selectedTaskList.map(cat => (
        <>
        <h2 className='row text-center j-content-center p-2 size-18 mb-0 capital' style={{background:'burlywood'}}>{cat.category}</h2>
       {cat['tasks'].map((task, i) => (<p className='m-2 size-16'>{++i}- {task.task}</p>))} 
        </>))}
        </Modal.Body>
        <Modal.Footer>   
        <Button className="btn btn-danger" onClick={() => this.setState({showTaskListModel: false})}>Close</Button>  
        </Modal.Footer>  
        </Modal>
        {/* Manager Task    */}
        <Modal size='lg' aria-labelledby="contained-modal-title-vcenter" 
        centered enctype="multipart/form-data" show={this.state.showManagerTaskListModel} onHide={() => this.setState({showManagerTaskListModel:false, editManagerTaskModel: false})}>  
        <Modal.Header closeButton><div className="text-center col-11 font-f-sans white">Task List</div></Modal.Header>  
        <Modal.Body >
        <h2 className='row text-center j-content-center p-2 size-18 mb-0 capital' 
        style={{background:'burlywood'}}>{this.state.role}</h2>
        {this.state.selectedManagerTaskList.map((cat, i) => (
        <>
        <p className='m-2 size-16'>{++i}- {cat.tasks}</p>
        </>))} 
        </Modal.Body>
        <Modal.Footer>   
        <Button className="btn btn-danger" onClick={() => this.setState({showManagerTaskListModel: false, editManagerTaskModel: false})}>Close</Button>  
        </Modal.Footer>  
        </Modal>

        {/* Task Generation Model */}
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" 
        centered enctype="multipart/form-data" show={this.state.taskModel} onHide={() => this.setState({category: '', category2:'', taskList: [{ task: "",}], taskList2: [{ task: "",}],
        taskModel: false, editTaskList: false, roleId: '', t_role: '', showCategory2: false})}>  
            <Modal.Header closeButton><div className="text-center col-11 font-f-sans white">Generate Tasks for Role</div></Modal.Header>  
            <Modal.Body className="ml-2 mr-2">
            <form action=''>
            <div className="row ">

            <div className='pl-0 row'>
            <div className='col-lg-4 '>
            <label className="profile-label">Select Role</label>
            <select value={this.state.t_role} onChange={this.handleChange} name='t_role' className="form-select acc-form w-100 mb-3">
            <option value=''>Please select</option>
            {this.state.allRoles.map(head => 
            <option key={head.id} value={head.id}>{head.name}</option>)}
            </select>
            </div>

            <div className='col-lg-4 ml-lg-2'>
            <label className="profile-label">Category</label>
            <input type="text" name="category" value={this.state.category} 
            onChange={this.handleChange}  className="card-text form-control acc-form col-11" 
            placeholder='Please enter' />
            </div>

            </div> 

            {this.state.category &&
            <>
            {this.state.taskList.map((x, i) => {
                return(
                <div key={i} className="row">

                <div className='col-lg-8'>
                <label className="profile-label">Task {i + 1}</label>
                <input type="text" name='task' onChange={e => this.handleInputChange(e, i, 1)} value={x.task}
                className="card-text form-control acc-form col-12" placeholder='Please enter' />
                </div>

                <div className="col-4 mt-4 mb-2 px-2">
                {this.state.taskList.length -1 === i &&
                <button className="btn btn-primary mr-1 mt-1" onClick={(e) => this.handleAddClick(e, 1)}>Add More</button>}
                {this.state.taskList.length !== 1 && <button
                className="btn btn-danger mr-1 mt-1"  onClick={(e) => this.handleRemoveClick(i, e, 1)}>Remove</button>}
                </div>
                </div>
                )
                }
            )}
            </>}

            {this.state.category && this.state.taskList[1] && !this.state.category2 &&
            <div className='row mt-4 mb-2'>
            <button className="btn btn-info col-lg-3" onClick={(e) => this.addCategory(e)}>Add More Category</button>
            </div>}
            {this.state.showCategory2 === true &&
            <div className='col-lg-4'>
            <label className="profile-label">Category 2</label>
            <input type="text" name="category2" onChange={this.handleChange} value={this.state.category2}
            className="card-text form-control acc-form col-12" placeholder='Please enter' />
            </div>}
            {this.state.category2 && 
            <>
            {this.state.taskList2.map((x, i) => {
                return(
                    <div key={i} className="row">

                    <div className='col-lg-8'>
                    <label className="profile-label">Task: {i+1}</label>
                    <input type="text" name='task' onChange={e => this.handleInputChange(e, i, 2)} value={x.task}
                    className="card-text form-control acc-form col-12" placeholder='Please enter' />
                    </div>

                    <div className="col-4 mt-4 mb-2 px-2">
                    {this.state.taskList2.length -1 === i &&
                    <button className="btn btn-primary mr-1 mt-1" onClick={(e) => this.handleAddClick(e, 2)}>Add More</button>}
                    {this.state.taskList2.length !== 1 && <button
                    className="btn btn-danger mr-1 mt-1"  onClick={(e) => this.handleRemoveClick(i, e, 2)}>Remove</button>}
                    </div>
                    </div>
                )
            })}
            </>}
    
            </div>
            </form>
            </Modal.Body> 
            <Modal.Footer>  
            <Button className="btn btn-success" onClick={ this.handleTaskSubmit}>Generate</Button>  
            <Button className="btn btn-danger" onClick={(e) => this.closeModel(e)}>Close</Button>  
            </Modal.Footer>  
        </Modal>

        {/* Manager Task */}
        <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" 
        centered enctype="multipart/form-data" show={this.state.managerTaskModel} onHide={() => this.setState({managerTaskModel: false})}>  
            <Modal.Header closeButton><div className="text-center col-11 font-f-sans white">Manager Tasks</div></Modal.Header>  
            <Modal.Body className="mx-1">
            <form action=''>
            <div className="row ">

            <div className='pl-0 row'>
            <div className='col-lg-4 '>
            <label className="profile-label">Select Role for Manager</label>
            <select value={this.state.t_role} onChange={this.handleChange} name='t_role' 
            className="form-select acc-form w-100 mb-3" disabled={this.state.editManagerTaskModel === true ? true: false}>
            <option value=''>Please select</option>
            {this.state.allRoles.map(head => 
            <option key={head.id} value={head.id}>{head.name}</option>)}
            </select>
            </div>

            </div> 

            {this.state.managerTaskList.map((x, i) => {
            return(
                <div key={i} className="row">

                <div className='col-lg-7'>
                <label className="profile-label">Task: {i+1}</label>
                <input type="text" name='tasks' onChange={e => this.handleManagerInputChange(e, i, 2)} value={x.tasks}
                className="card-text form-control acc-form col-12" placeholder='Please enter' />

                </div>

                <div className='col-lg-5 row pl-4'>
                <label className="profile-label bold">Select Dropdown Type</label>

                <div className='col-lg-2'>
                <input type="radio" className="mx-2 bold" name={`type-${i}`} value={3} checked={x.type == 3}
                onChange={(e) => this.handleManagerRadioChange(e, i, 2)} />
                <p className="mx-2 font-f-monst-sans f-w-500">Yes</p>
                <p className="mx-2 font-f-monst-sans f-w-500">No</p>
                </div>

                <div className='col-lg-5'>
                <input type="radio" className="mx-2 bold" name={`type-${i}`} value={2} checked={x.type == 2}
                onChange={(e) => this.handleManagerRadioChange(e, i, 2)} />
                <p className="mx-2 font-f-monst-sans f-w-500">Coordinated</p>
                <p className="mx-2 font-f-monst-sans f-w-500">Not Coordinated</p>
                </div>

                <div className='col-lg-5'>
                <input type="radio" className="mx-2 bold" name={`type-${i}`} value={1}  checked={x.type == 1}
                onChange={(e) => this.handleManagerRadioChange(e, i, 2)} />
                <p className="mx-2 font-f-monst-sans f-w-500">Completed</p>
                <p className="mx-2 font-f-monst-sans f-w-500">Partially Completed</p>
                <p className="mx-2 font-f-monst-sans f-w-500">Not Completed</p>
                </div>

                </div>

                <div className="col-12 mb-2">
                {this.state.managerTaskList.length -1 === i &&
                <button className="btn btn-primary mr-1 mt-1" onClick={(e) => this.handleManagerAddClick(e)}>Add More</button>}
                {this.state.managerTaskList.length !== 1 && <button
                className="btn btn-danger mr-1 mt-1"  onClick={(e) => this.handleManagerRemoveClick(i, e)}>Remove</button>}
                </div>
                </div>
            )
            })}
            
    
            </div>
            </form>
            </Modal.Body> 
            <Modal.Footer>  
            <Button className="btn btn-success" onClick={ this.handleManagerTaskSubmit}>Generate</Button>  
            <Button className="btn btn-danger" onClick={(e) => this.setState({managerTaskModel: false})}>Close</Button>  
            </Modal.Footer>  
        </Modal>
        </>
        : 'You dont have Permission to access this page'
        )
    }
}
export default UserRoles


import React from 'react';
import EditInvoicePDF from './EditInvoicePdf';


const PreviewInvoice = () => {
    return(
    <>
    <EditInvoicePDF />
    </>
    )
}

export default PreviewInvoice

import React ,{useState} from 'react';
import { Link } from 'react-router-dom';
import * as FaIcons from "react-icons/fa";
import Moment from 'react-moment';

const AllNotification = (props) => {
    const closeTab = () => {
        props.parentCallback('true');
    }
  
    return(
        <>
        <div eventkey="home" className="modal-body pt-0">
        {/* <div className="col-12 notify-mark pb-0 pr-0">
        <a className="btn" onClick ={() => markAsSeen()} >Mark all as Read</a></div> */}
        {props.getNotifications.map((value, index) => {
         return(
        value.permission != "not allowed"  &&
        <div key={index} className="pb-2 pt-2 notify row pl-0 w-100">
        <div className="col-2">
        {  value.type === 1 ? <FaIcons.FaUserCheck className="client-assign-c" size={25}/>
        : value.type === 0 && value.applicationtype == 4 ? 
        <FaIcons.FaUserAlt className="appointment-c" size={24}/>
        : value.type === 0 && value.applicationtype == 10 ? 
        <FaIcons.FaRestroom className="appointment-c" size={24}/>
        : value.type === 0 && value.applicationtype !== 10 ? 
        <FaIcons.FaUserAltSlash className="text-delete" size={24}/>
         : value.type === 2 ? <FaIcons.FaMailBulk className="app-c" size={25}/> 
         : value.type === 3 ? <FaIcons.FaMailBulk className="app-c" size={25}/>
         : value.type === 4 ? <FaIcons.FaClipboardList className="note-c" size={25}/>
         : value.type === 5 ? <FaIcons.FaMailBulk className="app-c" size={25}/>
         : value.type === 6 ? <FaIcons.FaUser className="client-assign-c" size={25}/>
         : value.type === 7 ? <FaIcons.FaMailBulk className="app-c" size={25}/>
         : value.type === 8 ? <FaIcons.FaUserTimes className="client-assign-c" size={25}/>
         : value.type === 9 ? <FaIcons.FaStreetView className="client-assign-c" size={25}/>
         : value.type === 10 ? <FaIcons.FaUserCheck className="client-assign-c" size={25}/>
         : value.type === 11 ? <FaIcons.FaClipboardCheck className="followup-completed-c" size={25}/>
         : value.type === 15 ? <FaIcons.FaFileInvoice className="app-c" size={25}/>
         : value.type === 12 ? <FaIcons.FaHandshake className="partner-c" size={25}/>
         : ''
        }
        </div>
        <div className='col-10'>
        {(value.type === 1 || value.type === 6 || value.type === 8 || value.type === 11) ?
        <Link onClick={() => closeTab()} to={`/clients/view-client-detail/${value.clientid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>
        : (value.type === 2 || value.type === 3 ||  value.type === 5 || value.type === 7) ?
        <>
        {value.applicationtype === 1 ?
        <Link onClick={() => closeTab()} to={`/applications/view-migration-application-detail/${value.applicationid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>:
        <Link to={`/applications/view-education-application-detail/${value.applicationid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>}
        </>
        :(value.type === 4) || (value.type === 15)   ?
        <>
        {value.applicationtype === 1 ?
        <Link onClick={() => closeTab()} to={`/applications/view-migration-application-detail/${value.applicationid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>: value.applicationtype === 2 ?
        <Link onClick={() => closeTab()} to={`/applications/view-education-application-detail/${value.applicationid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>:
        <Link onClick={() => closeTab()} to={`/clients/view-client-detail/${value.clientid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>}
        </>
        :value.type === 9 && value.applicationtype != 9?
        <Link onClick={() => closeTab()} to={`/providers/provider-details/${value.clientid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>
        :value.type === 9 && value.applicationtype == 9  ?
        <Link  to={`/providers/provider-details/${value.applicationid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>
        :(value.type === 0 && value.applicationtype === 10) ?
        <Link onClick={() => closeTab()} to={`/appointments/view-appointments`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>
        :(value.type === 0 && value.applicationtype === 4) ?
        <Link onClick={() => closeTab()} to={`/clients/view-client-detail/${value.clientid}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>
        :value.type === 12  ?
        <Link  to={`/agents/view-agent/${value.clientid}/${'detail'}`}>
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>
        </Link>
        :
        <p className="notify-text" dangerouslySetInnerHTML={{ __html: value.notes}}></p>}
        <small className="row notify-date"><Moment date={value.updated_at}  format="D MMM YYYY, hh:mm" withTime></Moment></small>
        </div>
    
        </div>)})}
        </div>
        </>
        )
}

export default AllNotification;

import React ,{useState,useEffect, useRef}from "react"
import { Link, useParams } from "react-router-dom"
import Title from '../GenericComponents/Title'
import Loader from '../Loader/Loader'
import { get_ApiManager, post_ApiManager, search_ApiManager, view_ApiManager } from "../ApiManager/ApiManager"
import moment from 'moment'
import MultipleSearchs from "../GenericComponents/MultipleSearchs"
import { Sorting } from "../GenericComponents/SortTables"
import { category } from "../../utilis/constants"
import swal from "sweetalert"
import { eduStatus, visaStatus } from "../../utilis/appStatus"

const ViewPriority = () => {
    const { type } = useParams();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [client, setClient] = useState('');
    const [provider, setProvider] = useState('');
    const [appType, setAppType] = useState('');
    const [startAcheive, setStartAcheive] = useState('');
    const [endAcheive, setEndAcheive] = useState('');
    const [sCategory, setSCategory] = useState('');
    const [assigned, setAssigned] = useState([]);
    const [status, setStatus] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [selectedPriority, setSelectedPriority] = useState([]);
    const [categorys, setCategorys] = useState('');
    const [allPriority, setAllPriority] = useState([]);
    const [showAchieveSaveButton, setShowAchieveSaveButton] = useState(false); 
    const [acheiveId, setAchieveId] = useState(false);
    const [achieveDate, setAchieveDate] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [headers, setHeaders] = useState([ 
        {title: '#' , dataKey: "index", align: "text-center", key:1, checked: true, canChange: false},
        {title: 'Client' , dataKey: "clientname", align: "text-center", key:2, checked: true, canChange: false},
        {title: 'Category', dataKey: 'categoryname', align:"text-center col-2", key:3, checked: true, canChange: true},
        {title: 'Institute/Provider', dataKey: 'providername', align:"text-center", key:4, checked: true, canChange: false},
        {title: 'App Type', dataKey: 'coursename', align:"text-center", key:5, checked: true, canChange: false},
        {title: 'Status' , dataKey: "status", align:"text-center", key:9, checked: true, canChange: true},
        {title: 'Achieve Status' , dataKey: "priority_date", align:"text-center", key:10, checked: true, canChange: false},
        {title: 'Status Changed', dataKey: "statuschanged", align:"text-center", key:11, checked: true, canChange: true},
        {title: 'Assigned To' , dataKey: "assignedTo", align:"text-center", key:15, checked: true, canChange: true},
        {title: 'Last Activity', dataKey: "last_activity", align:"text-center", key:17, checked: true, canChange: true}, 
    ]);

    useEffect( () => {
        if(!search){
            getPriority();
        } else{
            handleSearchCallback(search);
        }
    }, []);
    
     // Get all provider details
     const getPriority = () => {
        setLoading(true);
        let url = `viewPriority/${type}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('priority',res);
            const mappedRecords = res.data.data;
            const overdueRecords = mappedRecords.filter(item => item.isOverdue);
            const nonOverdueRecords = mappedRecords.filter(item => !item.isOverdue);

            // Sort each group in ascending order of priority_date
            const sortedOverdueRecords = overdueRecords.sort((a, b) => moment(a.priority_date).diff(moment(b.priority_date)));
            const sortedNonOverdueRecords = nonOverdueRecords.sort((a, b) => moment(a.priority_date).diff(moment(b.priority_date)));

            // Combine sorted records, overdue first
            const combinedRecords = sortedOverdueRecords.concat(sortedNonOverdueRecords);

            setAllPriority(combinedRecords);
            setLoading(false);
            setSelectedPriority(combinedRecords);
            let options = res.data.data.map(function (item) {
                return { 
                  index: item.index,
                  name: item.clientname,
                  categoryname: item.categoryname,
                  providername: item.providername,
                  coursename: item.coursename,
                  datelodged: item.datelodged,
                  status: item.status,
                  statuschanged: item.statuschanged,
                  created_at: moment(item.created_at).format('YYYY-MM-D'),
                  last_activity: item.last_activity,
                  assignedTo: item.assignedTo,
                  location: item.location,
                };
              })
              setSelectedPriority(options);
              getAllUsers();
        },(err) => {
            console.log(err);
        });
    }

      // Get all users from apis 
      const getAllUsers = async () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            setAllUsers(res.data.data);
        },(err) => {
            console.log(err);
        });
     }

     const sorting = (col) => {
        const sortResult = Sorting(allPriority, col, order)
        setOrder(sortResult[0]);
        setAllPriority(sortResult[1]);
     }

     const changeAcheiveDate = (value,id, e, category) => {
        e.preventDefault();
        console.log(category)
        setCategorys(category);
        setShowAchieveSaveButton(true);
        setAchieveDate(value);
        setAchieveId(id);
     }
     const onChangeAcheiveDate = (appid, e, cat) => {
        e.preventDefault();
        let url;
        if(cat === 'migration'){
            url = `changeMigAcheiveDate/${appid}/${achieveDate}`;
        } else{
            url = `changeEduAcheiveDate/${appid}/${achieveDate}`;
        }
        console.log(url)
        get_ApiManager('',url,(res) => {
            console.log('changed-acheive',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
                getPriority();
                setShowAchieveSaveButton('');
                setAchieveDate('');
                setAchieveId('');
            }
        },(err) => {
            console.log(err);
        });
     }
     const calculateDaysMessage = (selectedDate) => {
        const today = new Date();
        const priorityDate = new Date(selectedDate);
        const timeDiff = priorityDate - today;
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    
        if (daysDiff > 0) {
          return `${daysDiff} day(s) left.`;
        } else if (daysDiff < 0) {
          return `${Math.abs(daysDiff)} day(s) overdue.`;
        } else {
          return "Today is the priority date.";
        }
      };
      const handleSearchCallback = (input1, input2, input3, input4, input5, input6, input7, input8) => {
        let Client, Provider, AppType, StartAcheive, EndAcheive, Category, AssignedTo, Status;
        console.log(input6)
        if (input1 === 'clear') {
          setSearch('');
        } else{
            if (input1 === 'yes') {
            Client = client;
            Provider = provider;
            AppType = appType;
            StartAcheive = startAcheive;
            EndAcheive = endAcheive;
            Category = sCategory;
            AssignedTo = assigned;
            Status = status;
            } else {
            setSearch('yes');
            Client = input1;
            Provider = input2;
            AppType = input3;
            StartAcheive = input4;
            EndAcheive = input5;
            Category = input6;
            AssignedTo = input7;
            Status = input8;
            setClient(Client);
            setProvider(Provider);
            setAppType(AppType)
            setStartAcheive(StartAcheive);
            setEndAcheive(EndAcheive);
            setSCategory(Category);
            setAssigned(AssignedTo);
            setStatus(Status);
            }
            const data = {
                client : Client,
                provider: Provider,
                appType: AppType,
                startAcheive: StartAcheive,
                endAcheive: EndAcheive,
                category: Category,
                assignedTo: AssignedTo,
                status: Status
            }
            console.log(data);
            let url = `search/priority`;
            search_ApiManager(url, data, 1, 100,(res) => {
                console.log('search result',res);
                const mappedRecords = res.data.data;
                const overdueRecords = mappedRecords.filter(item => item.isOverdue);
                const nonOverdueRecords = mappedRecords.filter(item => !item.isOverdue);
    
                // Sort each group in ascending order of priority_date
                const sortedOverdueRecords = overdueRecords.sort((a, b) => moment(a.priority_date).diff(moment(b.priority_date)));
                const sortedNonOverdueRecords = nonOverdueRecords.sort((a, b) => moment(a.priority_date).diff(moment(b.priority_date)));
    
                // Combine sorted records, overdue first
                const combinedRecords = sortedOverdueRecords.concat(sortedNonOverdueRecords);
                setAllPriority(combinedRecords);    
            },(err) => {
                console.log(err);
            });
        }
    }
          
    return(
        <>
        <Title title={type === 'my' ? 'My Priority' : 'Total Priority'} 
        back={true}  />
        {loading ? <Loader /> :
        <div className="row w-100 pl-3 pr-3 mb-5">
        <MultipleSearchs
         input1={sCategory ? 'Search Client' : ''}
         input1_type={"input"}
         input2={sCategory && 'Search Institute/Provider'}
         input2_type={"input"}
         input3={sCategory && 'Search AppType'}
         input3_type={"input"}
         input4={sCategory && 'Start of Acheive Date'}
         input4_type={"date"}
         input5={sCategory && 'End of Acheive Date'}
         input5_type={"date"}
         input6={'Search Category'}
         input6_type={"dropdown"}
         input6_multiple={"no"}
         input6Data={category}
         input6CallBack={true}
         input7={sCategory && 'Search AssignedTo'}
         input7_type={"dropdown"}
         input7Data={allUsers}
         input7_multiple={"yes"}
         input7_filter_by={"name"}
         input8={sCategory && 'Search Status'}
         input8_type={"dropdown"}
         input8Data={sCategory === 'Education' ? eduStatus : sCategory === 'Migration' ? visaStatus:'' }
         input8_multiple={"yes"}
         onSearchChange={handleSearchCallback}
         allColumns={headers}
         />
        <table id="allmig" className="table table-bordered bg-white table-responsive">
           {/* Headers */}
        <thead className="thead-dark">
        <tr>
        {headers.map(head => (
         head.checked &&
        <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)} key={head.key}>
        {head.title}</th>
        ))}
        </tr>
        </thead>
        <tbody>
        {allPriority.map((value, index) =>
        <tr>
        {headers[0]['checked'] && <td className="text-center">{++index}</td>}
        {headers[1]['checked'] && <td className="text-center">
        <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.clientname}</Link></td>}
        {headers[2]['checked'] && <td className="text-center">{value.categoryname}</td>}
        {headers[3]['checked'] && <td className="text-center name-text">{value.providername}</td>}
        {headers[4]['checked'] && <td className="text-center">
        {value.categoryname === 'migration' ?
        <Link to={`/applications/view-migration-application-detail/${value.appid}`}>{value.coursename}</Link>
        :
        <Link to={`/applications/view-education-application-detail/${value.appid}`}>{value.coursename}</Link>}</td>}
        <td className="text-center">{value.status}</td>
        <td>
        <input type='date' value={(value.priority_date)} onChange={(e) => changeAcheiveDate(e.target.value,value.id, e, value.categoryname)} className="date-input ml-1"/>
        {showAchieveSaveButton === true && value.id === acheiveId ? 
        <button type="button" className="btn btn-success ml-1" onClick={(e) => onChangeAcheiveDate(value.appid, e, value.categoryname)} >Save</button>: ''}
        <br/><small className="name-text">[{value.priority_status}]</small>
        <div className="text-center error">{calculateDaysMessage(value.priority_date)}</div>
        </td>
        <td className="text-center w-15">
        <small> {moment(value.statuschangedate).format('D MMM YYYY')}</small></td>
        <td className="text-center w-10">{value.assignedTo}</td>
        <td className="text-center w-10">
        <small>{moment(value.last_activity).format('D MMM YYYY')}</small>
        </td> 
        </tr>)}
        </tbody>
        </table>
        </div>}
        </>
    )
}
export default ViewPriority
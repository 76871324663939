import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import Search from '../GenericComponents/Search';
import Paginations from '../GenericComponents/Pagination';
import { Link } from 'react-router-dom';
import { get_ApiManager, search_ApiManager, view_ApiManager} from '../ApiManager/ApiManager';
import './counseling.css';
import Modals from '../GenericComponents/Modal'
import SelectionBar from './SelectionBar';


class Counseling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            intakesList:[],
            levelList:[],
            providerList:[],
            counseling:[],
            totalItem:0,
            currentPage: 1,
            perItem:0,
            lastPage:'',
            perPage: 10,
            selectedCountry:'',
            selectedLevel:'',
            selectedDiscipline:'',
            selectedTutionFee:'',
            selectedIntake:'',
            selectedDuration:'',
            selectedCourse:'',
            selectedProvider:[],
            selectedPrerequisit:'',
            selectedCampus:'',
            selectedIntakeId:'',
            selectedProviderId:'',
            show:false,
        }
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        this.getLevels();
        this.getProviders();
    }

     async getIntakes(country){
        let url = "intakesByCountry"
        let data = country;
        get_ApiManager(data, url,(res) => {
            console.log('intakes',res);
            this.setState({intakesList:res.data['data']});
        },(err) => {
            console.log(err);
        })
     }

     async getProviders(){
        let url = "getEduProviders"
        get_ApiManager('', url,(res) => {
            console.log('providers',res);
            this.setState({providerList:res.data['data']});
        },(err) => {
            console.log(err);
        })
     }

     async getLevels(){
        let url = "levelsByCategory"
        let data = '';
        get_ApiManager(data, url,(res) => {
            console.log('levels',res);
            this.setState({levelList:res.data['data']});
        },(err) => {
            console.log(err);
        })
     }

     //CallBack function to get changed status name and value and call api to save results
    handleCallback = (toggle, type, value1, value2) => {
        if(type === 'edit'){
            localStorage.setItem("student_country" ,JSON.stringify(value1));
            localStorage.setItem("residence_country" ,JSON.stringify(value2));
        }
        this.setState({show:!toggle})
    }
     //CallBack function to get changed status name and value and call api to save results
    handleBarCallback = (value, name, optionName) => {
        if(name === 'countries'){
            this.setState({selectedCountry: value})
            this.getIntakes(value);
            this.setState({selectedIntake:'', selectedLevel:''})
        } else if(name === 'levels'){
            this.setState({selectedLevel: value})
        } else if(name === 'course'){
            this.setState({selectedCourse: value})
        } else if(name === 'discipline'){
            this.setState({selectedDiscipline: value})
        } else if(name === 'tutionfee'){
            this.setState({selectedTutionFee: value})
        } else if(name === 'intake'){
            this.setState({selectedIntake: optionName, selectedIntakeId: value})
        } else if(name === 'duration'){
            this.setState({selectedDuration: value})
        } else if(name === 'prerequisit'){
            this.setState({selectedPrerequisit: value})
        } else if(name === 'campus'){
            this.setState({selectedCampus: value})
        } else if(name === 'provider'){
            console.log(value);
            this.setState({selectedProvider: value, selectedProviderId: value})
        }
    }
    updateProviderValue(index){
        const newArray = [...this.state.selectedProvider];
        newArray.splice(index, 1);
        console.log(newArray)
        this.setState({selectedProvider: newArray});
    }
     // Call counseling api
     async getCounseling(){
        let currentPage, perPage, country, discipline, tutionFee, level, intake, duration, campus, provider, course = 'null';
        currentPage = this.state.currentPage;
        perPage = this.state.perPage;
        country = this.state.selectedCountry;
        discipline = this.state.selectedDiscipline;
        tutionFee = this.state.selectedTutionFee;
        level = this.state.selectedLevel;
        intake = this.state.selectedIntakeId;
        duration = this.state.selectedDuration;
        campus = this.state.selectedCampus;
        provider = this.state.selectedProvider;
        course = this.state.selectedCourse;
      
        let url = `counseling`;
        const data = {
            country: country,
            discipline: discipline,
            fee: tutionFee,
            level: level,
            intake: intake,
            duration: duration,
            campus: campus,
            provider: provider,
            course: course
            };
            console.log(data);
            search_ApiManager(url, data, currentPage, perPage, (res) => {
            console.log('counseling' , res);
            this.setState({
                counseling: res.data.data['data'],
                loading: false,
                totalItem: res.data.data['total'],
                perPage: res.data.data['per_page'],
                lastPage: res.data.data['last_page'],
                perItem: res.data.data['data'] &&  res.data.data['data'].length,
            }); 
        },(err) => {
            console.log(err);
        }); 
        
     }
     //Submit form and call api to fetch results
    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        this.getCounseling();
    }
    onClear = () => {
        this.setState({
            selectedLevel:'', 
            selectedCountry:'',
            selectedDiscipline:'', 
            selectedDuration:'',
            selectedIntake:'',
            selectedPrerequisit:'',
            selectedTutionFee:'',
            selectedCourse:'',
            counseling:[],
        })
    }
     // CallBack function handle pagination
    handlePaginationCallback = (i, perPage) => {
        if(perPage && perPage != ''){
            this.setState({ perPage : perPage,currentPage:1}, () => this.getCounseling()  ); 
        } else {this.setState({ currentPage : i}, () =>  this.getCounseling() ); }
    }
  render(){
    return(
        <>
        <Title title="Counseling" />
        <div className="row w-100 pl-4 pr-3 mb-5">
        <div className="row p-3 bg-white border">
        <SelectionBar 
        defaultCountry={this.state.selectedCountry}
        defaultDiscipline={this.state.selectedDiscipline}
        defaultFee={this.state.selectedTutionFee}
        defaultLevel={this.state.selectedLevel}
        defaultIntake={this.state.selectedIntake}
        defaultDuration={this.state.selectedDuration}
        defaultPrerequisit={this.state.selectedPrerequisit}
        defaultCampus={this.state.selectedCampus}
        defaultProvider={this.state.selectedProvider}
        intakesList={this.state.intakesList}
        levelList={this.state.levelList}
        providerList={this.state.providerList}
        defaultCourse={this.state.selectedCourse}
        parentCallback={this.handleBarCallback} />
        <div className="row selectedValues">

        {/* selected country */}
        {this.state.selectedCountry &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({selectedCountry:'', selectedLevel:'', selectedIntake:''})}>
            </button><div className="list-tag">{this.state.selectedCountry}</div>
            </div>}
        {/* selected course */}
        {this.state.selectedCourse &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({selectedCourse:'', selectedLevel:'', selectedIntake:''})}>
            </button><div className="list-tag">{this.state.selectedCourse}</div>
            </div>}
        {/* selected level */}
        {this.state.selectedLevel &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({selectedLevel:''})}>
            </button><div className="list-tag">{this.state.selectedLevel}</div>
            </div>}
        {/* selected discipline */}
        {this.state.selectedDiscipline &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close"  onClick={() => this.setState({selectedDiscipline:''})}>
            </button><div className="list-tag"> {this.state.selectedDiscipline}</div>
            </div>}
        {/* selected tution fee */}
        {this.state.selectedTutionFee &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({selectedTutionFee:''})}>
            </button><div className="list-tag">{this.state.selectedTutionFee} </div>
            </div>}
        {/* selected Intake */}
        {this.state.selectedIntake &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({selectedIntake:''})}>
            </button><div className="list-tag"> {this.state.selectedIntake}</div>
            </div>}
        {/* selected Prerequisit */}
        {this.state.selectedPrerequisit &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({selectedPrerequisit:''})}>
            </button><div className="list-tag"> {this.state.selectedPrerequisit} </div>
            </div>}
        {/* selected Duration */}
        {this.state.selectedDuration &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({selectedDuration:''})}>
            </button><div className="list-tag"> {this.state.selectedDuration} </div>
            </div>}
          {/* selected Campus */}
          {this.state.selectedCampus &&
            <div className="tag-bg w-auto">
            <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.setState({selectedCampus:''})}>
            </button><div className="list-tag"> {this.state.selectedCampus} </div>
            </div>}
         {/* selected Provider */}
         {this.state.selectedProvider &&
        this.state.selectedProvider.map((item, index) => (
            <div key={index} className="tag-bg w-auto">
                <button type="button" className="btn-close w-auto" aria-label="Close" onClick={() => this.updateProviderValue(index)}>
                </button><div className="list-tag">{item.name}</div>
            </div>
        ))}
        </div>

           {/* Search Button */}
        <div className="col-12">
        <div className='d-flex float-right'>
        <button onClick={this.onSubmit} className="btn btn-search mt-2 pl-4 pr-4 mr-2">
        <i className="fa fa-search pr-2" aria-hidden="true"></i>Search Course</button>
        <button type="button" onClick={() => this.onClear()} className="btn btn-primary pr-4 mt-2 pl-4">Reset</button>
        </div>
        </div>

        
        </div>
        {/* Counseling */}
        <h4 className='mt-2 size-18'>Results</h4>
        {this.state.counseling[0] &&
        this.state.counseling.map((value, index) => {
            const filterCampus = value['campus'].split(",");
            const filteredData = filterCampus.filter(item => item.includes(this.state.selectedCampus));
            
        return(
        <div className='mt-2 mb-3' key={index}>
        <div className="card">
        <div className='card-body px-4 pt-4 pxs-4 pts-4 pb-2'>
        <div className='row mb-3'>
        <div className='col-12 col-md-2'>
        <img className='rounded mx-auto d-block ins-logo' 
        src={`${process.env.PUBLIC_URL}/assets/img/userlogo.png`}/>
        </div>
        <div className='col-12 col-md-10'>
        <div className='row'>
        <div className='col-md-9 col-12'>
        <Link className="card-title text-green h5 font-f-monst-sans bold" 
        to={`/providers/provider-details/${value.providerid}`}> {value['name']} </Link>
        <div className="w-100 my-2"></div>
        <p className="font-weight-bold mb-0 size-16 sora-font name-text">{value['providername']} </p>
        <p className="mb-2">
        <span className="font-weight-bold ml-0 size-15 sora-font"> {value.country} </span>
        <span className='ml-1 size-16 sora-font'>{this.state.selectedCampus ? filteredData.map((item) => ({item})) : value.campus} Campus </span>
        </p>
        </div>
        <div className="d-flex">
        {/* Link1 */}
        {value.link1 !== null ? 
        <Link target="_blank" to={value.link1} className='no-underline'> 
        <span className="link1 white mr-1 ml-0"> Covid-Ready </span></Link> :''}
        {/* link2 */}
        {value.link2 !== null  ? 
        <a target="_blank" href={value.link2} className='no-underline'>
        <span className="ml-1 mr-1 link2">
        <i className="fa fa-star mr-1"></i> Onshore Bonus </span></a>: ''}
        {/* Link3 */}
        {value.link3 !== null ?  
        <a target="_blank" href={value.link3} className='no-underline'>
        <span className="link1 white ml-1"> Onshore Course </span></a>:''}
        </div>
        </div>
        <div className="col-md-3 col-12">
        <div className="row justify-content-end showcase-section no-gutters">
        </div>
        </div>

        </div>
        </div>
        <hr className='divider'/>
        <div className='row'>
        <div className='col-12 col-md-12'>
        <div className='row my-2'>
        <div className='col course-info-col'>
        <p className="course-info-text size-12">
        <span className="font-weight-bold pr-2 font-f-sans">Tuition Fee</span>
            {value.country === 'Australia' ? 'AUD ' : 
            value.country === 'Canada' ? 'CAD ' :
            value.country === 'Uk' ? 'GBP ' : ''}
            {value.tution_fee} per year
        </p>
        </div>
        <div className="col course-info-col">
         <p className="course-info-text size-12 px-3">
        <span className="font-weight-bold pr-2 font-f-sans">Intakes:</span>{value.intake}</p>
        </div>
        <div className="col course-info-col">
         <p className="course-info-text size-12 px-3">
        <span className="font-weight-bold pr-2 font-f-sans">Discipline:</span>{value.discipline}</p>
        </div>
        <div className="col course-info-col">
        <p className="course-info-text size-12 px-3">
        <span className="font-weight-bold pr-2 font-f-sans">Duration:</span>{value.duration}</p>
        </div>

        <div className="col course-info-col">
        <p className="course-info-text size-12 px-3">
        <span className="font-weight-bold pr-2 font-f-sans">Level:</span>{value.level}</p>
        </div>
        </div>

        </div>
        </div>
        </div>
        </div>
    
        </div>)})}
        <Paginations
            currentPage= {this.state.currentPage}
            total= {this.state.totalItem} 
            itemsPerPage= {this.state.perPage}
            lastPage={this.state.lastPage}
            totalItems={this.state.totalItem}
            perItem={this.state.perItem}
            onPageChange= {this.handlePaginationCallback} 
        />
        </div>
          {/* show edit profile and change password modal */}
          <Modals 
            show={this.state.show}
            title={'Select your student details'}
            type={'counseling'}
            submitBtnText={'Proceed'}
            parentCallback={this.handleCallback}/>
        </>
    )
  }
}
export default Counseling;